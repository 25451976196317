<template>
  <v-dialog v-if="CrateModal" v-model="CrateModal" max-width="543px">
    <v-card flat class="rounded-lg">
      <v-container class="pa-6">
        <div class="title">Add New Project</div>
        <div style="padding-top: 30px; padding-y: 10px">
          <div class="label">Project Name</div>
          <v-text-field
            placeholder="Enter Name"
            single-line
            outlined
            v-model="name"
            maxlength="30"
            
            :error-messages="createErrorMessage('name')
            "
          ></v-text-field>
          <br />
          <div class="label">Description Project</div>
          <v-textarea
            outlined
            name="input-7-4"
            placeholder="Enter Description"
            v-model="description"
            maxlength="225"
            :error-messages="createErrorMessage('description')"
          ></v-textarea>

          <v-row>
            <v-col cols="6">
              <v-btn
                block
                @click="
                  () => {
                    CrateModal = false;
                    reset();
                  }
                "
                depressed
                dark
                class="rounded-lg accent"
                >Cancel</v-btn
              >
            </v-col>
            <v-col cols="6">
              <v-btn
                block
                class="rounded-lg secondary"
                @click="()=>{createproject()}"
                depressed
                >Create</v-btn
              >
            </v-col>
          </v-row>
        </div>
      </v-container>
    </v-card>
    <dialogfailcreate :message="messagefailcreate" v-model="opendialogfailcreate" />
  </v-dialog>
</template>

<script>
import { useCreateProject } from "./useCreateProject";
import dialogfailcreate from "./dialogFailCreate";
import { ref } from "@vue/composition-api";
import { useNamespacedMutations } from 'vuex-composition-helpers'
const { required } = require("@vuelidate/validators");
const useVuelidate = require("@vuelidate/core").default;


export default {
  components: { dialogfailcreate },
  setup(props, context) {
    const {
      name,
      description,
      descValidation,
      CreateProject,
      CrateModal,
    } = useCreateProject();
    
    const opendialogfailcreate = ref(false);
    const messagefailcreate = ref('')
    const {
      setopendialogcommonsuccess,
    } = useNamespacedMutations("DASHBOARD", [
      "setopendialogcommonsuccess",
    ]);

    const reset = async () => {
      name.value='';
      description.value='';
    };
    
    const $v = useVuelidate(
      {
        name: {
            required, alpha: {
                $message: 'value must alpha or alphanumeric',
                $validator:(v)=>{
                    let val = v 
                    return /^[a-zA-Z0-9- ]+$/.test(val)
                }
            }
        },
        description: { required }
      },
      {
        name,
        description,
      }
    );

    
    function createErrorMessage(element) {
      return $v.value[element].$errors.length
        ? $v.value[element].$errors[0].$message
        : "";
    }

    const createproject = async () => {
      $v.value.$touch();
      console.log($v.value.$invalid);
      if ($v.value.$invalid) return;

      await CreateProject()
        .then((e) => {
          console.log(e);
          context.root.$router.push(`/project/${name.value}`);
          setopendialogcommonsuccess(true);
          name.value='';
          description.value='';
         
        })
        .catch((e) => {
          if(!e.response) return
          const { data } = e.response.data;
          messagefailcreate.value = data;
          opendialogfailcreate.value = true;
          name.value='';
          description.value='';
        });
    };
    

    return {
      reset,
      createErrorMessage,
      messagefailcreate,
      opendialogfailcreate,
      createproject,
      name,
      description,
      descValidation,
      CreateProject,
      CrateModal,
    };
  },
};
</script>

<style scoped>
.v-btn {
  height: 50px !important;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 140%;
}

.title {
  font-style: normal;
  font-weight: bold;
  font-size: 21px;
  line-height: 140%;
  color: #556272;
}

.label {
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 140%;
  color: #556272;
  padding-bottom: 14px;
}
</style>