<template>
  <v-list-item @click="toDetailPage" class="cursor-pointer">
    <v-list-item-title class="d-flex align-center">
      <v-icon style="width:20px; height:20px" class="mr-3">
        $vuetify.icons.broadcastBlue
      </v-icon>

      <div class="py-2">
        <div class="d-flex justify-space-between align-center mb-2">
          <span class="mr-3 primary--text font-weight-bold">
            {{ item.broadcast_name }}
          </span>

          <span :style="{ color: 'lightgray' }">
            {{ $moment(item.send_date).format("DD/MM/YYYY - HH:mm") }}
          </span>
        </div>

        <p>
          {{
            item.description.length > 50
              ? item.description.substring(0, 50) + "..."
              : item.description
          }}
        </p>
      </div>
    </v-list-item-title>
  </v-list-item>
</template>

<script>
export default {
  props: ["item"],
  methods: {
    toDetailPage() {
      // :to="'/broadcast-user/' + item.id"
      this.$router.push({
        path: "/broadcast-user/" + this.item.id,
      });
    },
  },
};
</script>
