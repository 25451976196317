import { ActionContext, Module } from 'vuex'
import axios from '@/lib/axios'

interface State {
    cpumetrics: Array<any>,
    rammetrics: Array<any>,
    inputoutput: Array<any>,
    inputin: Array<any>,
    diskread: Array<any>,
    diskwrite: Array<any>,
}
const store:Module<State, any> = {
    namespaced: true,
    state:{
        cpumetrics:[],
        rammetrics:[],
        inputoutput:[],
        inputin:[],
        diskread:[],
        diskwrite:[],
    },
    mutations:{
        setcpu: (state: State, payload: any)=>state.cpumetrics = payload,
        setram: (state: State, payload: any)=>state.rammetrics = payload,
        
        setinputoutput: (state: State, payload: any)=>state.inputoutput = payload,
        setinputin: (state: State, payload: any)=>state.inputin = payload,
        
        setdiskread: (state: State, payload: any)=>state.diskread = payload,
        setdiskwrite: (state: State, payload: any)=>state.diskwrite = payload,
    },
    actions:{
        getinstancemetricssocket:async(context:ActionContext<State,any>, payload: any)=>{
            if(payload.status == 'stop'){
                axios.instance.get(`metric/all/${payload.id}`,{params:{start: payload.range.start, stop: payload.range.end}}).then((response)=>{
                    const data = response.data.data;
                    context.commit('setcpu', data[0])
                    context.commit('setram', data[1])
                    context.commit('setinputoutput', data[2])
                    context.commit('setinputin', data[3])
                    context.commit('setdiskread', data[4])
                    context.commit('setdiskwrite', data[5])
                })
            }
            if(payload.type=='cpu'){
                const ws = new WebSocket(`${process.env.VUE_APP_WS_BASE_URL_METRIC}/${payload.type}/${payload.id}`);
                ws.onmessage = (res) => {
                    const cpu = JSON.parse(res.data);
                    context.commit('setcpu', cpu)
                }
                ws.onopen = (event) => {
                    console.log('metric cpu Connected.')
                }
                ws.onclose = (event) => {
                    console.log('metric cpu Disconnected.')
                }
            }

            if(payload.type=='ram'){
                const ws = new WebSocket(`${process.env.VUE_APP_WS_BASE_URL_METRIC}/${payload.type}/${payload.id}`);
                ws.onmessage = (res) => {
                    const ram = JSON.parse(res.data);
                    context.commit('setram', ram)
                }
                ws.onopen = (event) => {
                    console.log('metric ram Connected.')
                }
                ws.onclose = (event) => {
                    console.log('metric ram Disconnected.')
                }
            }

            if(payload.type=='network-out'){
                const ws = new WebSocket(`${process.env.VUE_APP_WS_BASE_URL_METRIC}/${payload.type}/${payload.id}`);
                ws.onmessage = (res) => {
                    const data = JSON.parse(res.data);
                    context.commit('setinputoutput', data)
                }
                ws.onopen = (event) => {
                    console.log('metric Network out Connected.')
                }
                ws.onclose = (event) => {
                    console.log('metric Network out Disconnected.')
                }
            }

            if(payload.type=='network-in'){
                const ws = new WebSocket(`${process.env.VUE_APP_WS_BASE_URL_METRIC}/${payload.type}/${payload.id}`);
                ws.onmessage = (res) => {
                    const data = JSON.parse(res.data);
                    context.commit('setinputin', data)
                }
                ws.onopen = (event) => {
                    console.log('metric Network in Connected.')
                }
                ws.onclose = (event) => {
                    console.log('metric Network in Disconnected.')
                }
            }

            if(payload.type=='disk-read'){
                const ws = new WebSocket(`${process.env.VUE_APP_WS_BASE_URL_METRIC}/${payload.type}/${payload.id}`);
                ws.onmessage = (res) => {
                    const data = JSON.parse(res.data);
                    context.commit('setdiskread', data)
                }
                ws.onopen = (event) => {
                    console.log('metric Disk Read Connected.')
                }
                ws.onclose = (event) => {
                    console.log('metric Disk Read Disconnected.')
                }
            }

            if(payload.type=='disk-write'){
                const ws = new WebSocket(`${process.env.VUE_APP_WS_BASE_URL_METRIC}/${payload.type}/${payload.id}`);
                ws.onmessage = (res) => {
                    const data = JSON.parse(res.data);
                    context.commit('setdiskwrite', data)
                }
                ws.onopen = (event) => {
                    console.log('metric Disk Write Connected.')
                }
                ws.onclose = (event) => {
                    console.log('metric Disk Write Disconnected.')
                }
            }
        },
    }
}

export default store