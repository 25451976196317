var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"pa-3 rounded-lg",attrs:{"flat":""}},[_c('v-card-title',{staticClass:"pb-7"},[_c('div',{staticClass:"headline font-weight-bold font--text"},[_vm._v("User Organization")]),_c('v-spacer'),_c('v-btn',{staticClass:"secondary",style:({ 'font-size': ((_vm.fontsize || 14) + "px") }),attrs:{"id":"AddNewMember","depressed":"","height":"50","width":"150"},on:{"click":function () {
          _vm.validateprivilages(['Organization', 'editor']).then(function () {
            _vm.dialogaddmember = true;
          });
        }}},[_vm._v("Add New User")])],1),_c('v-card-text',{class:{ animate: _vm.loading }},[_c('v-data-table',{attrs:{"hide-default-footer":"","headers":_vm.headers,"items":_vm.members,"server-items-length":_vm.pagination.count,"items-per-page":_vm.limit,"options":_vm.options},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.fullname",fn:function(ref){
        var item = ref.item;
return [_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"dotblue mt-2"}),_c('div',[_c('span',{staticClass:"primary--text linkpointer",on:{"click":function () {
                  _vm.memberselectedvalue = item;
                  _vm.opendialogdetailuser = true;
                }}},[_vm._v(_vm._s(item.fullname))]),_c('p',[_vm._v(_vm._s(item.email))])])])]}},{key:"item.project",fn:function(ref){
                var item = ref.item;
return [(_vm.userrole !='Superadmin')?_c('div',[(item.project && item.project.length > 1)?_c('span',{staticClass:"linkpointer",on:{"click":function () {
                _vm.memberselectedvalue = item;
                _vm.opendialoglistproject = true;
              }}},[_vm._v(" "+_vm._s(item.project.length)+" projects ")]):(item.project && item.project.length == 1)?_c('span',[_vm._v(" "+_vm._s(item.project[0].name)+" ")]):_vm._e()]):_c('div',[(item.user_project && item.user_project.length > 1)?_c('span',{staticClass:"linkpointer",on:{"click":function () {
                _vm.memberselectedvalue = item;
                _vm.opendialoglistproject = true;
              }}},[_vm._v(" "+_vm._s(item.user_project.length)+" projects ")]):(item.user_project && item.user_project.length == 1)?_c('span',[_vm._v(" "+_vm._s(item.user_project[0].project.name)+" ")]):_vm._e()])]}},{key:"item.role_name",fn:function(ref){
              var item = ref.item;
return [(item.is_creator)?_c('span',[_vm._v(" Creator ")]):_c('span',[_vm._v(" "+_vm._s(item.role_name == null ? item.role.name : item.role_name)+" ")])]}},{key:"item.verified_at",fn:function(ref){
              var item = ref.item;
return [(item.verified_at!='')?_c('div',[_vm._v("Verified")]):_c('div',[_vm._v("Unverified")])]}},{key:"item.status",fn:function(ref){
              var item = ref.item;
return [_c('div',[_c('v-switch',{attrs:{"true-value":"active","false-value":"deactive","flat":"","readonly":"","disabled":item.role.name == 'Owner' && _vm.userrole != 'Superadmin' && item.is_creator==true,"input-value":item.status},on:{"click":function () {
                _vm.validateprivilages(['Organization', 'editor']).then(function () {
                  _vm.memberselectedvalue = item;
                  _vm.opendialogactivateuser = true;
                });
              }}})],1)]}},{key:"item.created_at",fn:function(ref){
              var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$FormatDate(item.created_at))+" ")]}},{key:"item.action",fn:function(ref){
              var item = ref.item;
return [_c('popupquote',{attrs:{"offsetTop":20,"documentid":'popup' + item.id,"offset-y":"","allow-overflow":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('label',_vm._b({staticClass:"primary--text linkpointer",staticStyle:{"position":"relative","white-space":"nowrap"},on:{"click":function ($event) {
                  on.click($event);
                  _vm.show($event, item);
                
                 
                }}},'label',attrs,false),[_vm._v("More "),_c('v-icon',{class:{
                  rotate: item.open,
                },attrs:{"id":'popup' + item.id,"color":"primary"}},[_vm._v("mdi-chevron-down")])],1)]}}],null,true),model:{value:(item.open),callback:function ($$v) {_vm.$set(item, "open", $$v)},expression:"item.open"}},[_c('v-list',[_c('v-list-item',{attrs:{"disabled":item.verified_at !== '' && item.status === 'active' || item.verified_at !== '' ? true : false},on:{"click":function () {
                  _vm.memberselected = item;
                  _vm.opendialogresendinviteuser = true;
                }}},[_c('v-list-item-title',[_vm._v("Resend Invitation")])],1),_c('v-list-item',{attrs:{"disabled":!(_vm.is_creator.toString() == 'true' && item.is_creator.toString() == 'true')},on:{"click":function () {
                  _vm.validateprivilages(['Organization', 'editor']).then(function () {
                    _vm.memberselectedvalue = item;
                    _vm.opendialogtransferuser = true;
                  });
                }}},[_c('v-list-item-title',[_vm._v("Transfer Creator Role")])],1),_c('v-divider',{staticStyle:{"min-width":"160px"}}),_c('v-list-item',{on:{"click":function () {
                    _vm.validateprivilages(['Organization', 'editor']).then(function () {
                      _vm.memberselected = item;
                      _vm.dialogeditemember = true;
                    });
                }}},[_c('v-list-item-title',[_vm._v("Edit")])],1),_c('v-list-item',{on:{"click":function () {
                  _vm.memberselectedvalue = item;
                  _vm.opendialogdetailuser = true;
                }}},[_c('v-list-item-title',[_vm._v("Detail")])],1),_c('v-list-item',{staticStyle:{"min-height":"1px","margin-left":"-16px","margin-right":"-16px"}},[_c('v-divider',{staticStyle:{"min-width":"160px"}})],1),_c('v-list-item',{attrs:{"disabled":item.is_creator === true && _vm.userrole !== 'Superadmin' ? true : false},on:{"click":function () {
                  _vm.validateprivilages(['Organization', 'editor']).then(function () {
                    _vm.memberselected = item;
                    _vm.dialogdeletemember = true;
                  });
              
              }}},[_c('v-list-item-title',{staticClass:"error--text"},[_vm._v("Delete")])],1)],1)],1)]}},{key:"footer",fn:function(ref){
              var props = ref.props;
return [_c('custom-footer-datatable',{attrs:{"hidden":true,"props":props},on:{"input":function (val) {
              _vm.options = Object.assign({}, _vm.options, val);
            }}})]}}])})],1),(_vm.organization)?_c('dialogAddMember',{attrs:{"organization":_vm.organization},on:{"added":function ($event) {
        _vm.fetchmembers(_vm.options).then(function () {
          _vm.opendialoghasbeensaved = true;
        });
      }},model:{value:(_vm.dialogaddmember),callback:function ($$v) {_vm.dialogaddmember=$$v},expression:"dialogaddmember"}}):_vm._e(),_c('dialogDeleteMember',{on:{"confirm":function () {
        _vm.deletememberselected().then(function () {
          _vm.dialogdeletemember = false;
          _vm.setmessagecommonsuccess('Your data has been removed.');
          _vm.setopendialogcommonsuccess(true);
          _vm.fetchmembers(_vm.options);
        });
      }},model:{value:(_vm.dialogdeletemember),callback:function ($$v) {_vm.dialogdeletemember=$$v},expression:"dialogdeletemember"}}),_c('dialogSuccess',{attrs:{"message":_vm.dialogemsgsuccess,"title":_vm.dialogetitlesuccess},model:{value:(_vm.opendialogsuccess),callback:function ($$v) {_vm.opendialogsuccess=$$v},expression:"opendialogsuccess"}}),_c('dialogRecendInviteUser',{on:{"confirm":function () {
        _vm.resendinvitememberselected().then(function () {
          _vm.dialogResendInviteUser = false;
          _vm.opendialogsuccess=true;
          _vm.dialogemsgsuccess='Email has been sent, Please wait if mail has not appeared';
          _vm.dialogetitlesuccess='Email has been sent!';
          _vm.fetchmembers(_vm.options);
        });
      }},model:{value:(_vm.opendialogresendinviteuser),callback:function ($$v) {_vm.opendialogresendinviteuser=$$v},expression:"opendialogresendinviteuser"}}),(_vm.memberselectedvalue && _vm.opendialogtransferuser)?_c('dialogTransferRoleUser',{attrs:{"memberselectedvalue":_vm.memberselectedvalue},on:{"transfer":function () {
          _vm.dialogTransferRoleUser = false;
          _vm.setmessagecommonsuccess('Success Transfer Role Creator.');
          _vm.setopendialogcommonsuccess(true);
          _vm.fetchmembers(_vm.options);
      }},model:{value:(_vm.opendialogtransferuser),callback:function ($$v) {_vm.opendialogtransferuser=$$v},expression:"opendialogtransferuser"}}):_vm._e(),(_vm.memberselected && _vm.dialogeditemember)?_c('dialogEditMember',{attrs:{"memberselectedvalue":_vm.memberselected},on:{"updated":function (val) {
        _vm.fetchmembers(_vm.options);
      }},model:{value:(_vm.dialogeditemember),callback:function ($$v) {_vm.dialogeditemember=$$v},expression:"dialogeditemember"}}):_vm._e(),(_vm.opendialogdetailuser && _vm.memberselectedvalue)?_c('dialogDetailUser',{attrs:{"user":_vm.memberselectedvalue},model:{value:(_vm.opendialogdetailuser),callback:function ($$v) {_vm.opendialogdetailuser=$$v},expression:"opendialogdetailuser"}}):_vm._e(),(_vm.opendialoglistproject && _vm.memberselectedvalue)?_c('dialogListProject',{attrs:{"user":_vm.memberselectedvalue},model:{value:(_vm.opendialoglistproject),callback:function ($$v) {_vm.opendialoglistproject=$$v},expression:"opendialoglistproject"}}):_vm._e(),_c('dialogHasBeenSaved',{model:{value:(_vm.opendialoghasbeensaved),callback:function ($$v) {_vm.opendialoghasbeensaved=$$v},expression:"opendialoghasbeensaved"}}),(_vm.memberselectedvalue)?_c('dialogActivateUser',{attrs:{"userorganization":"user","onoff":_vm.memberselectedvalue.status == 'active' ? 'off' : 'on'},on:{"confirm":function () {
        _vm.changestatus(_vm.memberselectedvalue).then(function () {
          _vm.opendialogactivateuser = false;
          _vm.memberselectedvalue.status =
            _vm.memberselectedvalue.status == 'active' ? 'deactive' : 'active';
        });
      }},model:{value:(_vm.opendialogactivateuser),callback:function ($$v) {_vm.opendialogactivateuser=$$v},expression:"opendialogactivateuser"}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }