<template>
  <v-dialog v-model="value" scrollable persistent max-width="85%">
    <v-card>
      <v-card-title>
        <h1 class="tnc-title">
          Terms of Service
        </h1>
      </v-card-title>

      <v-card-text>
        <p
          style="margin-top: 30px; margin-bottom: 20px; color: #2C94D2"
          class="tnc-subtitle"
        >
          Introduction
        </p>

        <p>
          Welcome to Lintasarta Terms of Service! The first portion of our Terms
          of Service is an introduction, which is structured like an FAQ and
          lays out some high-level concepts before diving into other terms.
        </p>

        <p
          style="margin-top: 50px; margin-bottom: 20px; color: #2C94D2"
          class="tnc-subtitle"
        >
          Terms & Conditions of the TOS
        </p>

        <p>
          1. Eligibility & Registration
          <br />
          1.1 Before you use our Websites and Services, you must ensure that
          such use is in compliance with all laws, rules and regulations
          applicable to you. Your right to access the Websites and Services is
          revoked to the extent your use thereof is prohibited or to the extent
          our provision thereof conflicts with any applicable law, rule or
          regulation. You are responsible for making these determinations before
          using the Websites and Services.
          <br /><br />
          1.2 The Websites and Services are not targeted towards, nor intended
          for use by, anyone under the age of 18. By using the Websites and
          Services, you represent and warrant to us that: (a) you are 18 years
          of age or older; or (b) you otherwise have sufficient legal consent,
          permission and capacity to use the Websites and Services in applicable
          jurisdiction(s) as determined by you.
          <br /><br />
          1.3 To access the Services and some features of the Websites, you must
          register for an account (“Account”). When you register for your
          Account, you may be required to provide us with some information about
          yourself, such as your name, email address, and a valid form of
          payment, and you may also provide optional information about yourself
          on a voluntary basis. Account information, and our use and disclosure
          thereof, is subject to the Privacy Policy and Data Processing
          Agreement.
          <br /><br />
          1.4 We may, in our sole discretion, refuse to provide or continue
          providing the Websites and Services to any person or entity and change
          eligibility criteria at any time, including if you fail to comply with
          the TOS. We reserve the right to deactivate, terminate, prevent access
          to, disable services for, and/or delete any Accounts or access to the
          Websites and Services at any time at our sole discretion.
        </p>
      </v-card-text>

      <v-card-actions>
        <v-row>
          <v-col>
            <v-btn
              depressed
              height="45"
              block
              class="secondary"
              @click="
                () => {
                  $emit('input', false);
                }
              "
            >
              Okay
            </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: ["value"],
};
</script>

<style scoped>
.tnc-title {
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: 38px;
  letter-spacing: 0em;
  text-align: left;
}

.tnc-subtitle {
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
}
</style>
