var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{staticClass:"rounded-lg",attrs:{"flat":""}},[_c('v-container',{staticClass:"pa-7"},[_c('div',{staticClass:"vpc-title"},[_vm._v("Settings Project")]),_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('h4',{staticClass:"pb-2"},[_vm._v("Name")]),_c('v-text-field',{attrs:{"maxlength":"30","placeholder":"Enter Project Name","single-line":"","outlined":"","error-messages":_vm.$v.name.$errors.length ? _vm.$v.name.$errors[0].$message : ''},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}}),_c('h4',{staticClass:"pb-2"},[_vm._v("Description")]),_c('v-textarea',{attrs:{"maxlength":"255","outlined":"","single-line":"","name":"input-7-4","placeholder":"Enter Description Project","error-messages":_vm.$v.description.$errors.length
                    ? _vm.$v.description.$errors[0].$message
                    : ''},model:{value:(_vm.desc),callback:function ($$v) {_vm.desc=$$v},expression:"desc"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"5"}},[_c('v-btn',{staticClass:"secondary",attrs:{"block":"","depressed":""},on:{"click":_vm.updateProject}},[_c('span',{staticStyle:{"font-size":"16px"}},[_vm._v("Save")])])],1)],1)],1)],1)],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{staticClass:"rounded-lg",attrs:{"flat":""}},[_c('v-container',{staticClass:"pa-7"},[_c('v-row',[_c('v-col',{attrs:{"cols":"9"}},[_c('h2',[_vm._v("Delete Project")]),_c('p',[_vm._v(" To delete a project, you must move or delete all resources within it. Default project cannot be deleted. ")])]),_c('v-col',{staticClass:"text-center",attrs:{"cols":"3"}},[_c('v-btn',{staticClass:"error",staticStyle:{"width":"150px"},attrs:{"selectedproject":_vm.currentProj,"depressed":"","dark":""},on:{"click":_vm.hapusProject}},[_c('span',{staticStyle:{"font-size":"14px"}},[_vm._v("Delete Project")])])],1)],1)],1)],1)],1)],1),(_vm.opendeleteproject)?_c('dialogDeleteProj',{attrs:{"selectedproject":_vm.currentProj},on:{"close":function () {
        _vm.opendeleteproject = false;
      },"deleteproject":function (){
      _vm.opendialogareyousure = true
    }},model:{value:(_vm.opendeleteproject),callback:function ($$v) {_vm.opendeleteproject=$$v},expression:"opendeleteproject"}}):_vm._e(),(_vm.opendialogareyousure)?_c('dialogAreYouSure',{attrs:{"project":_vm.currentProj},on:{"deleted":function (){
    
    
  },"error":function (response) {
        _vm.opendeleteproject = false;
        _vm.dialogerrormsg = response.data.data;
        _vm.opendialogerror = true;
      }},model:{value:(_vm.opendialogareyousure),callback:function ($$v) {_vm.opendialogareyousure=$$v},expression:"opendialogareyousure"}}):_vm._e(),_c('dialogErrorDelete',{attrs:{"message":_vm.dialogerrormsg},model:{value:(_vm.opendialogerror),callback:function ($$v) {_vm.opendialogerror=$$v},expression:"opendialogerror"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }