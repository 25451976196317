<template>
  <v-dialog v-model="dialog" v-if="selectedStorageSnapshot" max-width="500">
    <v-card>
      <v-card-title>
        <div class="headline font-weight-bold font--text pb-4">
          Create Storage from Snapshot
        </div>
      </v-card-title>
      <v-card-text class="pb-0">
        <p>Are you sure you want to create storage from snapshot <b>{{ selectedStorageSnapshot.name }}</b> ?</p>
        <v-row>
          <v-col cols="12" class="pt-0">
            <p class="font-weight-bold">Select Billing Type</p>
            <v-select
              id="BillingType"
              v-model="selectedBillingType"
              :items="billingTypes"
              :error-messages="createErrorMessage('selectedBillingType')"
              outlined
              placeholder="Select Billing Type"
            >
              <template v-slot:selection="{ item }">
                {{ item.text }}
              </template>
            </v-select>
          </v-col>
          <v-col cols="12" 
            class="pt-0"
            v-if="selectedBillingType == 'Trial'"
          >
            <p><b>Trial Voucher</b></p>
            <v-select
              id="trialVoucher"
              placeholder="Choose trial voucher to use"
              v-model="selectedTrialVoucher"
              :error-messages="createErrorMessage('selectedTrialVoucher')"
              :items="vouchers"
              item-text="voucher.name"
              item-value="id"
              outlined
            ></v-select>
          </v-col>
          <v-col class="pt-0" cols="12">
            <p class="mb-0" :class="{ 'error--text': $v.selectedStorageSize.$errors.length }">
              <span class="font-weight-bold">Select Volume Size</span>
              <span
                  class="ml-2"
                  style="font-size: 10px; position: absolute"
                  v-if="$v.selectedStorageSize.$errors.length"
              >*{{ createErrorMessage('selectedStorageSize') }}</span>
            </p>
          </v-col>
          <v-col class="d-flex pt-0 flex-wrap">
            <v-card
                v-if="customSize"
                width="170"
                disabled
                readonly
                border-color="primary"
                outlined
                :class="`custom-flavor-card rounded-lg select-instance mb-4 ${isCustomSizeHighlighted ? 'highlight selected' : ''}`"
                style="text-align: center; margin-right: 20px"
            >
              <v-card-text class="pa-0">
                <v-text-field
                  v-model="customSize"
                  class="px-4 pt-4"
                  @keydown="(ev) => customSize = addGBText(ev, customSize)"
                  style="width: 170px"
                  max="100"
                  flat
                  label="Enter Size in GB"
                  @click="() => {
                    selectedStorageSize = null
                    isCustomSizeHighlighted = true
                  }"
                  @blur="() => isCustomSizeHighlighted = !customSize ? false : true"
                ></v-text-field>
              </v-card-text>
            </v-card>
            <div
              v-for="storage in filteredVolumeSizes.filter(x => x.volume_size == selectedStorageSnapshot.storage.size)"
              :key="storage.id"
            >
            <!-- <div
              v-for="storage in type_id ? filteredVolumeSizes.filter((s) => s.type_id === type_id) : filteredVolumeSizes"
              :key="storage.id"
            > -->
              <v-card
                  v-if="storage.volume_size"
                  disabled
                  width="170"
                  :class="{ highlight: selectedStorageSize ? is(selectedStorageSize.id, storage.id) : false }"
                  @click="
                  () => {
                    selectedStorageSize = storage;
                    customSize = null
                    isCustomSizeHighlighted = false
                  }
                "
                  border-color="primary"
                  outlined
                  class="rounded-lg select-instance mb-4"
                  style="text-align: center; margin-right: 20px"
              >
                <!-- <v-card-text class="pb-0 pt-5"> -->
                  <!-- <p class="font-weight-bold" style="margin-bottom: 2.5rem">
                    {{ IDRformat.format(storage.price_per_month) }}/mo
                  </p> -->
                  <!-- <p>{{ IDRformat.format(storage.price_per_hours) }}/hour</p> -->
                <!-- </v-card-text> -->
                <!-- <v-divider/> -->
                <v-card-text class="flavor-card">
                  <p class="font-weight-bold mb-0">{{ storage.volume_size }} GB</p>
                </v-card-text>
              </v-card>
            </div>
          </v-col>
          <v-col sm="12" class="pt-0">
            <p class="font-weight-bold">Storage Name</p>
            <v-text-field
                maxlength="50"
                id="NameVolume"
                v-model="storageName"
                full-width
                placeholder="Type storage name"
                outlined
                :error-messages="createErrorMessage('storageName')"
            >
            </v-text-field>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-row class="ma-2">
          <v-col cols="6">
            <v-btn
              color="accent"
              block
              depressed
              height="50"
              @click="
                () => {
                  $emit('close');
                }
              "
            >
              Cancel
            </v-btn>
          </v-col>
          <v-col cols="6">
            <v-btn
              color="secondary"
              block
              height="50"
              depressed
              @click="doCreate"
            >
              Create Storage
            </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>

import { useCreatedInstance } from "../instance/compositionapi/usecreatedinstance";
import { ref, onMounted, computed } from '@vue/composition-api'
import { useCreateStorage } from "../storage/composableapi/usecreatestorage";
import { useVoucher } from '@/modules/voucher/useVoucher'
const { required, requiredIf } = require("@vuelidate/validators");
const useVuelidate = require("@vuelidate/core").default;
import { useStorage } from "../instance/compositionapi/usestorage";
import { addGBText, removeGBText } from '@/lib/formatter'
import libProject from '@/lib/project'


export default {
  props: ['value', 'selectedStorageSnapshot', 'selectedInstance'],
  computed: {
    dialog: {
      get: function () {
        return this.value;
      },
      set: function (val) {
        this.$emit("close", val);
      },
    },
  },
  setup (props, context) {
    function createErrorMessage(element) {
      return $v.value[element].$errors.length
          ? $v.value[element].$errors[0].$message
          : "";
    }
    const { instanceSelect, fetchinstances } = useCreatedInstance();
    const { createstorage } = useCreateStorage()
    const instance = ref(null)
    const selectedBillingType = ref(null)
    const selectedTrialVoucher = ref(null)
    const selectedStorageSize = ref(null)
    const storageName = ref(null)
    const customSize = ref(null)
    const isCustomSizeHighlighted = ref(false)
    const filteredVolumeSizes = computed(() => {
      let showCustomFlavor = process.env.VUE_APP_SHOW_CUSTOM_DISK_FLAVORS == 'true' ? true : false
      const filtered = volumeSize.value.filter(x => !x.is_default && x.status == 'active').filter(x => {
        if (showCustomFlavor) return true
        else {
          if (x.organization_id) return false // custom flavor has org id
          return true
        }
      }).sort((a, b) => a.volume_size - b.volume_size)

      return filtered
    })

    const $v = useVuelidate(
        {
          selectedTrialVoucher: {
            required: requiredIf((val) => {
              return selectedBillingType.value === "Trial";
            }),
          },
          selectedBillingType: { required },
          storageName: { required },
          customSize: {
            required: requiredIf((val) => {
              return customSize.value === null && selectedStorageSize.value === null;
            }),
          },
          selectedStorageSize: {
            required: requiredIf((val) => {
              return customSize.value === null && selectedStorageSize.value === null;
            }),
          },
        },
        {
          selectedBillingType,
          customSize,
          storageName,
          selectedTrialVoucher,
        }
    );

    const billingTypes = ref([
      {
        text: 'Pay Per Use',
        value: 'PPU',
      },
      {
        text: 'Trial',
        value: 'Trial',
      },
    ])
    const { fetchVouchers, vouchers } = useVoucher()
    const { volumeSize, fetchVolumeSize } = useStorage()
    
    onMounted(async () => {
      // await fetchinstances()
      let isFlavorExists = filteredVolumeSizes.value.filter(x => x.volume_size == props.selectedStorageSnapshot.size)
      if (isFlavorExists.length) selectedStorageSize.value = isFlavorExists[0]
      else customSize.value = props.selectedStorageSnapshot.storage.size + ' GB'

      await fetchVolumeSize({ limit: 999 })
      await fetchVouchers()
      if (props.selectedInstance) instance.value = instanceSelect.value.filter(option => option.value.id == props.selectedInstance.id)[0]
    })

    const doCreate = async () => {
      $v.value.$touch()
      if ($v.value.$invalid) return false

      const activeProjectID = await libProject.getActiveProjectID()
      let payload = {
        project_id: activeProjectID, 
        image_id: props.selectedStorageSnapshot.id,
        name: storageName.value,
        billing_type: selectedBillingType.value,
      }

      if (selectedStorageSize.value) payload.package_storage_id = selectedStorageSize.value.id
      else if (customSize.value) payload.custom_size = parseInt(removeGBText(customSize.value)) 
      
      if (selectedBillingType.value == 'Trial') payload.voucher_id = selectedTrialVoucher.value.id
      const response = await createstorage(payload)
      if (response.status == 200) {
        selectedBillingType.value = null
        selectedStorageSize.value = null
        customSize.value = null
        storageName.value = null
        $v.value.$reset()
        context.emit('close')
      }
    }
    
    return {
      is(a, b) {
        return a == b;
      },
      $v,
      customSize,
      addGBText,
      removeGBText,
      isCustomSizeHighlighted,
      filteredVolumeSizes,
      volumeSize,
      selectedStorageSize,
      createErrorMessage,
      instanceSelect,
      instance,
      doCreate,
      selectedBillingType,
      billingTypes,
      fetchVouchers,
      vouchers,
      storageName,
      selectedTrialVoucher,
    }
  }
};
</script>

<style>
.highlight {
  border: 1px solid #2c94d2 !important;
}
</style>