<template>
  <div>
    <v-row>
      <v-col cols="12" v-if="projectBilling.length">
        <v-card class="rounded-lg" flat>
          <v-container fluid class="pa-7">
            <v-row>
              <v-col cols="12" class="py-0">
                <p class="project-name">{{ projectBilling[0].project.name }}</p>
              </v-col>
              <v-col class="d-flex mb-2 pt-0">
                <div>
                  <p class="font-weight-bold fz-21 mb-4 mr-4">
                    {{ getBillingTypeLabel(selectedType) }} | Cost for {{ currentPeriod }}
                  </p>
                  <p>
                    This is the current cost for your usage this billing period. A
                    breakdown of your cost is available below.
                  </p>
                </div>
                <v-spacer />
                <div class="summary-billed">
                  <b class="font--text">Billed</b>
                  <!-- <p v-if="$route.params && $route.params.billing_type && $route.params.billing_type.toLowerCase() === 'other'" class="total-billed">{{ IDRformat.format(otherTotalBilled) }}</p>
                  <p v-else class="total-billed">{{ IDRformat.format(totalBilled) }}</p> -->
                  <p class="total-billed">{{ IDRformat.format(totalBilled) }}</p>
                </div>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <div class="total primary--text">
                </div>
              </v-col>
            </v-row>
            <br />
            <!-- <template v-if="selectedType.toLowerCase() !== 'other'"> -->
              <div v-if="billServices && billServices.instances.length">
                <p class="font-weight-bold fz-21">
                  Instance Detail
                </p>
                <v-data-table
                  class="datatable-services"
                  :headers="instanceHeaders"
                  :items="billServices && billServices.instances ? billServices.instances : []"
                  hide-default-footer
                >
                  <template v-slot:item.name="{ item }">
                    <span class="linkpointer">{{ item.instance.name || 'N/A' }}</span>
                  </template>
                  <template v-slot:item.spec="{ item }">
                    {{ item.package_instance.vcpu }} vCPU / {{ item.package_instance.ram }} GB Memory
                  </template>
                  <template v-slot:item.price="{ item }">
                    {{ IDRformat.format(item.package_instance.price_per_hours) }} / hour
                  </template>
                  <template v-slot:item.usage="{ item }">
                    <div class="d-flex align-center">
                      <v-tooltip v-if="item.shift_to_monthly" top color="#333" transition="slide-y-reverse-transition">
                        <template v-slot:activator="{ on, attrs }">
                          <div v-on="on" v-bind="attrs">
                            <v-icon class="mr-3" v-on="on" v-bind="attrs" color="primary">mdi-alert-circle-outline</v-icon>
                          </div>
                        </template>
                        <span>Service usage has exceeded <b>95%</b> in one month, payment will be shifted from hourly to monthly</span>
                      </v-tooltip>
                      <span v-if="item.usage_hours">{{ item.usage_hours > 1 ? `${item.usage_hours} hours` : `${item.usage_hours} hour` }}</span>
                      <span v-else>-</span>
                    </div>
                  </template>
                  <template v-slot:item.billed="{ item }">
                    <span v-if="item.shift_to_monthly">{{ IDRformat.format(item.package_instance.price_per_month) }}</span>
                    <span v-else>{{ IDRformat.format(item.billed) }}</span>
                  </template>
                  <template v-slot:body.append>
                    <tr style="border:none" v-if="billServices && billServices.instances && billServices.instances.length">
                      <td class="border-bottom-none"></td>
                      <td class="border-bottom-none"></td>
                      <td class="border-bottom-none"></td>
                      <td class="border-bottom-none"></td>
                      <td class="border-bottom-none">
                        <div>
                          <p class="font-weight-bold">Total</p>
                        </div>
                        <div class="d-flex flex-row justify-start">
                          {{ billServices.instances.length ? IDRformat.format(billServices.instances.reduce((total, item) => total + item.billed, 0)) : '0' }}
                        </div>
                      </td>
                    </tr>
                  </template>
                  <template
                    v-slot:footer="{ props }"
                    v-if="billServices && billServices.instances && billServices.instances.length > 10"
                  >
                    <custom-footer-datatable :props="props" />
                  </template>
                </v-data-table>
              </div>

              <div v-if="billServices && billServices.root_disks.length">
                <p class="font-weight-bold fz-21 mt-4">
                  Root Disk Detail
                </p>
                <v-data-table
                  class="datatable-services"
                  :headers="storageHeaders"
                  :items="billServices && billServices.root_disks ? billServices.root_disks : []"
                  :items-per-page="10"
                  hide-default-footer
                >
                  <template v-slot:item.name="{ item }">
                    <span class="linkpointer">{{ item.instance.name || 'N/A' }}</span>
                  </template>
                  <template v-slot:item.spec="{ item }">
                    {{ item.package_storage.volume_size }} GB
                  </template>
                  <template v-slot:item.price="{ item }">
                    {{ IDRformat.format(item.package_storage.price_per_month) }} / month
                  </template>
                  <template v-slot:item.usage="{ item }">
                    {{ item.usage_hours > 1 ? `${item.usage_hours} hours` : `${item.usage_hours} hour` }}
                  </template>
                  <template v-slot:item.billed="{ item }">
                    {{ IDRformat.format(item.billed) }}
                  </template>
                  <template v-slot:body.append>
                    <tr style="border:none" v-if="billServices && billServices.root_disks && billServices.root_disks.length">
                      <td class="border-bottom-none"></td>
                      <td class="border-bottom-none"></td>
                      <td class="border-bottom-none"></td>
                      <td class="border-bottom-none"></td>
                      <td class="border-bottom-none">
                        <div>
                          <p class="font-weight-bold">Total</p>
                        </div>
                        <div class="d-flex flex-row justify-start">
                          {{ IDRformat.format(billServices.root_disks.reduce((total, item) => total + item.billed, 0)) }}
                        </div>
                      </td>
                    </tr>
                  </template>
                  <template
                    v-slot:footer="{ props }"
                    v-if="billServices && billServices.storages && billServices.storages.length > 10"
                  >
                    <custom-footer-datatable :props="props" />
                  </template>
                </v-data-table>
              </div>

              <div v-if="billServices && billServices.storages.length">
                <p class="font-weight-bold fz-21 mt-4">
                  Storage Detail
                </p>
                <v-data-table
                  class="datatable-services"
                  :headers="storageHeaders"
                  :items="billServices && billServices.storages ? billServices.storages : []"
                  :items-per-page="10"
                  hide-default-footer
                >
                  <template v-slot:item.name="{ item }">
                    <span class="linkpointer">{{ item.storage.name || 'N/A' }}</span>
                  </template>
                  <template v-slot:item.spec="{ item }">
                    {{ item.package_storage.volume_size }} GB
                  </template>
                  <template v-slot:item.price="{ item }">
                    {{ IDRformat.format(item.package_storage.price_per_month) }} / month
                  </template>
                  <template v-slot:item.usage="{ item }">
                    {{ item.usage_hours > 1 ? `${item.usage_hours} hours` : `${item.usage_hours} hour` }}
                  </template>
                  <template v-slot:item.billed="{ item }">
                    {{ IDRformat.format(item.billed) }}
                  </template>
                  <template v-slot:body.append>
                    <tr style="border:none" v-if="billServices && billServices.storages && billServices.storages.length">
                      <td class="border-bottom-none"></td>
                      <td class="border-bottom-none"></td>
                      <td class="border-bottom-none"></td>
                      <td class="border-bottom-none"></td>
                      <td class="border-bottom-none">
                        <div>
                          <p class="font-weight-bold">Total</p>
                        </div>
                        <div class="d-flex flex-row justify-start">
                          {{ IDRformat.format(billServices.storages.reduce((total, item) => total + item.billed, 0)) }}
                        </div>
                      </td>
                    </tr>
                  </template>
                  <template
                    v-slot:footer="{ props }"
                    v-if="billServices && billServices.storages && billServices.storages.length > 10"
                  >
                    <custom-footer-datatable :props="props" />
                  </template>
                </v-data-table>
              </div>
              
              <div v-if="billServices && billServices.object_storages.length">
                <p class="font-weight-bold fz-21 mt-4">
                  Object Storage Detail
                </p>
                <v-data-table
                  class="datatable-services"
                  :headers="objectStorageHeaders"
                  :items="billServices && billServices.object_storages ? billServices.object_storages : []"
                  :items-per-page="10"
                  hide-default-footer
                >
                  <template v-slot:item.name="{ item }">
                    <span class="linkpointer">{{ item.object_storage.name || 'N/A' }}</span>
                  </template>
                  <template v-slot:item.spec="{ item }">
                    {{ item.package_object_storage.object_storage_size }} GB
                  </template>
                  <template v-slot:item.price="{ item }">
                    {{ IDRformat.format(item.package_object_storage.price_per_month) }} / month
                  </template>
                  <template v-slot:item.usage="{ item }">
                    {{ item.usage_hours > 1 ? `${item.usage_hours} hours` : `${item.usage_hours} hour` }}
                  </template>
                  <template v-slot:item.billed="{ item }">
                    {{ IDRformat.format(item.billed) }}
                  </template>
                  <template v-slot:body.append>
                    <tr style="border:none" v-if="billServices && billServices.storages && billServices.storages.length">
                      <td class="border-bottom-none"></td>
                      <td class="border-bottom-none"></td>
                      <td class="border-bottom-none"></td>
                      <td class="border-bottom-none"></td>
                      <td class="border-bottom-none">
                        <div>
                          <p class="font-weight-bold">Total</p>
                        </div>
                        <div class="d-flex flex-row justify-start">
                          {{ billServices.object_storages.length ? IDRformat.format(billServices.object_storages.reduce((total, item) => total + item.billed, 0)) : '0' }}
                        </div>
                      </td>
                    </tr>
                  </template>
                </v-data-table>
              </div>
              
              <template v-if="isDevModeSnapshotCharging && $route.params && $route.params.billing_type && $route.params.billing_type.toLowerCase() === 'other'">
                <template v-if="snapshotItems.length">
                  <p class="font-weight-bold fz-21 mt-4">
                    Snapshot
                  </p>

                  <v-data-table
                    class="datatable-services"
                    :headers="snapshotHeaders"
                    :items="snapshotItems"
                    :items-per-page="10"
                    hide-default-footer
                  >
                    <template v-slot:item.size="{ item }">
                      <!-- {{ item.size }} {{ item.image_name === 'Snapshot' ? 'GB' : 'x' }} -->
                      {{ item.size }} GB
                    </template>

                    <template v-slot:item.billed="{ item }">
                      {{ IDRformat.format(item.billed) }}
                    </template>

                    <template v-slot:body.append>
                      <tr style="border:none" v-if="snapshotItems.length">
                        <td class="border-bottom-none"></td>
                        <td class="border-bottom-none"></td>
                        <td class="border-bottom-none">
                          <div>
                            <p class="font-weight-bold">Total</p>
                          </div>
                          <div class="d-flex flex-row justify-start">
                            {{ snapshotItems.length ? IDRformat.format(snapshotItems.reduce((total, item) => total + item.billed, 0)) : '0' }}
                          </div>
                        </td>
                      </tr>
                    </template>

                    <template
                      v-slot:footer="{ props }"
                      v-if="snapshotItems.length"
                    >
                      <custom-footer-datatable  :props="props" />
                    </template>
                  </v-data-table>
                </template>

                <template v-if="floating_ipItems.length">
                  <p class="font-weight-bold fz-21 mt-4">
                    Floating IP
                  </p>

                  <v-data-table
                    class="datatable-services"
                    :headers="floating_ipHeaders"
                    :items="floating_ipItems"
                    :items-per-page="10"
                    hide-default-footer
                  >
                    <template v-slot:item.ip_address="{ item }">
                      {{ item.ip_address }}
                    </template>

                    <template v-slot:item.total="{ item }">
                      {{ item.total }} x
                    </template>

                    <template v-slot:item.billed="{ item }">
                      {{ IDRformat.format(item.billed) }}
                    </template>

                    <template v-slot:body.append>
                      <tr style="border:none" v-if="floating_ipItems.length">
                        <td class="border-bottom-none"></td>
                        <td class="border-bottom-none"></td>
                        <td class="border-bottom-none">
                          <div>
                            <p class="font-weight-bold">Total</p>
                          </div>
                          <div class="d-flex flex-row justify-start">
                            {{ floating_ipItems.length ? IDRformat.format(floating_ipItems.reduce((total, item) => total + item.billed, 0)) : '0' }}
                          </div>
                        </td>
                      </tr>
                    </template>

                    <template
                      v-slot:footer="{ props }"
                      v-if="floating_ipItems.length"
                    >
                      <custom-footer-datatable  :props="props" />
                    </template>
                  </v-data-table>
                </template>
              </template>
          </v-container>
        </v-card>
      </v-col>
    </v-row>
    <billingdetail />
    <!-- <dialogInstanceDetail
      title="Instance Detail"
    />
    <dialogInstanceDetail
      title="Storage Detail"
    /> -->
    <!-- <dialogSpecificationinfo v-model="opendialogSpecificationinfo" /> -->
  </div>
</template>
<script>

import billingdetail from "./billingdetail";
import { ref, watch, onMounted, computed, reactive } from "@vue/composition-api";
import {
  useNamespacedGetters,
  useNamespacedState,
  useNamespacedActions,
} from "vuex-composition-helpers";
import { BILLING } from "./namespace";
import Vue from "vue";
import { IDRformat } from "@/lib/formatter";
import dialogInstanceDetail from "./dialogInstanceDetail";
import dialogSpecificationinfo from "./dialogSpecificationInfo";
const html2pdf = require("html2pdf.js");
import moment from 'moment'

// const isDevModeSnapshotCharging = process.env.VUE_APP_DEV_MODE === 'fase-1.3'
const isDevModeSnapshotCharging = true

export default {
  components: {
    billingdetail,
    // dialogInstanceDetail,
    // dialogSpecificationinfo,
  },

  data: () => ({
    dates: {
      start: new Date(),
      end: new Date(),
      isDevModeSnapshotCharging,
    },
  }),
  computed: {
    dateRangeText() {
      return this.dates.join(" - ");
    },
  },

  setup(props, context) {
    const { id: projectID, billing_type: selectedType } = context.root.$route.params
    const { 
      fetchBillingOrganization,
      fetchBillingHistory,
      postBillingOrganization
    } = useNamespacedActions('BILLING', [
      'fetchBillingOrganization',
      'fetchBillingHistory',
      postBillingOrganization
    ])

    const { 
      projectBilling,
      billing,
    } = useNamespacedState('BILLING', [
      'projectBilling',
      'billing',
    ])

    const historyHeaders = ref([
      { text: 'Date', value: 'date' },
      { text: 'Billed', value: 'billed' },
      { text: 'Action', value: 'action', width: 100 },
    ])

    const servicesHeaders = ref([
      { text: 'Billing Type', value: 'billing_type' },
      { text: 'Amount of Service', value: 'amount' },
      { text: 'Billed', value: 'billed' },
    ])

    const instanceHeaders = ref([
      { text: 'Service Name', value: 'name', width: 300 },
      { text: 'Specification', value: 'spec', width: 300 },
      { text: 'Price', value: 'price', width: 300 },
      { text: 'Usage', value: 'usage', width: 300 },
      { text: 'Billed', value: 'billed', width: 300 },
    ])

    const objectStorageHeaders = ref([
      { text: 'Service Name', value: 'name', width: 300  },
      { text: 'Specification', value: 'spec', width: 300  },
      { text: 'Price', value: 'price', width: 300  },
      { text: 'Usage', value: 'usage', width: 300  },
      { text: 'Billed', value: 'billed', width: 300  },
    ])

    const storageHeaders = ref([
      { text: 'Service Name', value: 'name', width: 300  },
      { text: 'Specification', value: 'spec', width: 300  },
      { text: 'Price', value: 'price', width: 300  },
      { text: 'Usage', value: 'usage', width: 300  },
      { text: 'Billed', value: 'billed', width: 300  },
    ])

    const otherServicesHeaders = ref([
      { text: 'Service Name', value: 'name' },
      { text: 'Total', value: 'total' },
      { text: 'Total Price', value: 'billed' },
    ])

    let otherServicesItems = reactive([])
    const totalBilled = ref(0)
    const otherTotalBilled = ref(0)

    const snapshotHeaders = ref([
      { text: 'Service Name', value: 'image_name' },
      { text: 'Total', value: 'size' },
      { text: 'Total Price', value: 'billed' },
    ])

    const floating_ipHeaders = ref([
      { text: 'Service Name', value: 'ip_address' },
      { text: 'Total', value: 'total' },
      { text: 'Total Price', value: 'billed' },
    ])

    const snapshotItems = ref([])
    const floating_ipItems = ref([])

    let billServices = ref(null)
    let services = reactive({
      ppm: {
        billing_type: 'PPM',
        billed: 0,
        instances: [],
        storages: [],
        object_storages: [],
        root_disks: [],
      },
      ppu: {
        billing_type: 'PPU',
        billed: 0,
        instances: [],
        storages: [],
        object_storages: [],
        root_disks: [],
      },
      other: {
        billing_type: 'Other',
        billed: 0,
        instances: [],
        storages: [],
        object_storages: [],
        root_disks: [],
        snapshots: [],
        floating_ips: [],
      },
    })

    const resetDataServices = () => {
      services = {
        ppm: {
          billing_type: 'PPM',
          billed: 0,
          instances: [],
          storages: [],
          object_storages: [],
          root_disks: [],
        },
        ppu: {
          billing_type: 'PPU',
          billed: 0,
          instances: [],
          storages: [],
          root_disks: [],
        },
        other: {
          billing_type: 'Other',
          billed: 0,
          instances: [],
          storages: [],
          root_disks: [],
          snapshots: [],
          floating_ips: [],
        },
      }
    }

    const sortedBillingHistory = computed(() => {
      return projectBilling.value.sort((a, b) => {
        if (a.date > b.date) return -1
        return 1
      })
    })

    const currentPeriod = ref('')
    let from = ref(null)
    let to = ref(null)

    onMounted(async () => {
      await fetchBillingOrganization()
      await fetchBillingHistory({
        project_id: projectID,
        start: billing.value.date_start,
        end: billing.value.date_end,
      })
      from.value = moment(billing.value.date_start)
      to.value = moment(billing.value.date_end)
      currentPeriod.value = `${from.value.format('DD MMMM YYYY')} - ${to.value.format('DD MMMM YYYY')}`

      projectBilling.value.map(dailyBill => {
        if (dailyBill.instances) {
          dailyBill.instances.filter(x => x.billing_type == selectedType).map(instance => {
            let billingType = selectedType.toLowerCase()
            let index = services[billingType].instances.findIndex(x => x.instance_id == instance.instance_id)
            if (index >= 0) {
              if (instance.shift_to_monthly) {
                services[billingType].instances[index] = instance.package_instance.price_per_month
              } else {
                let prevBill = services[billingType].instances[index].billed
                let prevUsage = services[billingType].instances[index].usage_hours
                instance.billed += prevBill
                instance.usage_hours += prevUsage
                services[billingType].instances[index] = instance
              }
            } else {
              if (instance.shift_to_monthly) instance.billed = instance.package_instance.price_per_month
              services[billingType].instances.push(instance)
            }
          })
        }

        if (dailyBill.storages) {
          dailyBill.storages.map(storage => {
            let billingType = 'ppu'
            let index = services[billingType].storages.findIndex(x => x.storage_id == storage.storage_id)
            if (index >= 0) {
              let prevBill = services[billingType].storages[index].billed
              storage.billed += prevBill
              services[billingType].storages[index] = storage
            } else services[billingType].storages.push(storage)
          })
        }
        
        if (dailyBill.root_disks) {
          dailyBill.root_disks.map(root_disk => {
            let billingType = 'ppu'
            let index = services[billingType].root_disks.findIndex(x => x.instance_id == root_disk.instance_id)
            if (index >= 0) {
              let prevBill = services[billingType].root_disks[index].billed
              root_disk.billed += prevBill
              services[billingType].root_disks[index] = root_disk
            } else services[billingType].root_disks.push(root_disk)
          })
        }

        if (dailyBill.object_storages) {
          dailyBill.object_storages.map(object_storage => {
            let billingType = 'ppu'
            let index = services[billingType].object_storages.findIndex(x => x.object_storage_id == object_storage.object_storage_id)
            if (index >= 0) {
              services[billingType].object_storages[index].billed += object_storage.billed
            } else services[billingType].object_storages.push(object_storage)
          })
        }

        if (isDevModeSnapshotCharging && dailyBill.other.snapshot && dailyBill.other.snapshot.history && dailyBill.other.snapshot.history.length) {
          dailyBill.other.snapshot.history.map(snapshot => {
            let billingType = 'other'
            services[billingType].snapshots.push(snapshot)
          })
        }

        snapshotItems.value = services.other.snapshots

        if (isDevModeSnapshotCharging && dailyBill.other.floating_ip && dailyBill.other.floating_ip.history && dailyBill.other.floating_ip.history.length) {
          dailyBill.other.floating_ip.history.map(snapshot => {
            let billingType = 'other'
            services[billingType].floating_ips.push(snapshot)
          })
        }

        floating_ipItems.value = services.other.floating_ips
      })

      services.ppu.billed = (services.ppu.instances.reduce((total, item) => total + item.billed, 0)) + (services.ppu.root_disks.reduce((total, item) => total + item.billed, 0)) + (services.ppu.storages.reduce((total, item) => total + item.billed, 0)) + (services.ppu.object_storages.reduce((total, item) => total + item.billed, 0))
      services.ppm.billed = (services.ppm.instances.reduce((total, item) => total + item.billed, 0)) + (services.ppm.root_disks.reduce((total, item) => total + item.billed, 0)) + (services.ppm.storages.reduce((total, item) => total + item.billed, 0)) + (services.ppm.object_storages.reduce((total, item) => total + item.billed, 0))
      const snapshotTotal = services.other.snapshots.reduce((total, item) => total + item.billed, 0)
      const floatingIPTotal = services.other.floating_ips.reduce((total, item) => total + item.billed, 0)
      const otherTotal = selectedType.toLowerCase() === 'other' ? snapshotTotal + floatingIPTotal : 0
      services.other.billed = otherTotal + (services.other.instances.reduce((total, item) => total + item.billed, 0)) + (services.other.root_disks.reduce((total, item) => total + item.billed, 0)) + (services.other.storages.reduce((total, item) => total + item.billed, 0)) + (services.other.object_storages.reduce((total, item) => total + item.billed, 0))
      totalBilled.value = totalBilled.value + otherTotalBilled.value + Math.round(services.ppu.billed + services.ppm.billed + services.other.billed)
      billServices.value = services[selectedType.toLowerCase()]
    })

    const getBillingTypeLabel = (type) => {
      if (type == 'PPU') return 'Pay Per Use'
      else if (type == 'PPM') return 'Pay Per Metrics'
      else if (type == 'Other') return 'At Once'
    }
    
    return {
      snapshotHeaders,
      snapshotItems,
      floating_ipHeaders,
      floating_ipItems,
      getBillingTypeLabel,
      selectedType,
      fetchBillingHistory,
      totalBilled,
      otherTotalBilled,
      projectBilling,
      sortedBillingHistory,
      billing,
      billServices,
      historyHeaders,
      servicesHeaders,
      currentPeriod,
      IDRformat,
      moment,
      instanceHeaders,
      storageHeaders,
      objectStorageHeaders,
      otherServicesHeaders,
      otherServicesItems,
      vcalendar: ref(false),
      log: (x) => console.log(x)
    };
  },
};
</script>
<style>
.border-bottom-none {
  border: none !important;
}
</style>
<style lang="scss" scoped>
.summary-billed {
  padding: 1.5rem;
  background: rgba(44, 148, 210, 0.02);
  border: 1px solid #2C94D2;
  border-radius: 10px;
  min-width: 300px;
}

.summary-billed p {
  font-size: 36px;
  color: #1F60A8;
  font-weight: 600;
  margin-bottom: 0;
}

.project-name {
  font-weight: bold;
  font-size: 21px;
  display: block;
  color: #1F60A8;
}

.history {
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 140%;
  color: #556272;
}
.total {
  font-style: normal;
  font-weight: 600;
  font-size: 36px;
  line-height: 140%;
  color: #556272;
}
.page-title {
  font-style: normal;
  font-weight: bold;
  font-size: 21px;
  line-height: 140%;
  color: #556272;
}

.page-content {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 140%;
  color: #556272;
}

.detail-btn {
  cursor: pointer;
}

.date {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 140%;
  color: #a5b3bf;
}

.amount {
  font-style: normal;
  font-weight: bold;
  line-height: 140%;
  color: #556272;
  font-size: 14px;
}

.detail {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 140%;
  color: #1f60a8;
}

.bill-title {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 160%;
  color: #556272;
}

.history-billing {
  table-layout: auto;
}

.history-billing tr {
  border-bottom: thin solid;
}

td {
  height: 60px !important;
}

.v-data-table {
  ::v-deep table {
    border-bottom: thin solid rgba(0, 0, 0, 0.12);
  }
}

.v-data-table > .v-data-table__wrapper > table > tbody > tr > td,
.v-data-table > .v-data-table__wrapper > table > thead > tr > td,
.v-data-table > .v-data-table__wrapper > table > tfoot > tr > td {
  font-size: 14px;
}
</style>