import api from '../../lib/api'

export default {
  namespaced: true,
  state: {
    isLoading: false
  },
  getters: {
    isLoading: (state) => {
      return state.isLoading
    }
  },
  mutations: {
    isLoading(state, status) {
      state.isLoading = status
    }
  },
  actions: {
    save: async (context, payload) => {
      try {
        context.commit('isLoading', true)
        let data = {
          organization_id: '00000000-AAAA-BBBB-LA11-N3WU53R00001',
          name: payload.projectName,
          description: payload.description,
        }
        var result = await api.POST('user/projects', data)
        context.commit('isLoading', false)
        return result
      } catch (e) {
        context.commit('isLoading', false)
        return e;
      }
    }
  }
}