import api from '../../lib/api'

export default {
    namespaced: true,
    actions: {
        signup: async (context, payload) => {
            let formData = new FormData()

            formData.append('type', payload.type)
            formData.append('fullname', payload.fullname)
            formData.append('email', payload.email)
            formData.append('password', payload.password)
            formData.append('phone_number', payload.phone)
            formData.append('npwp', payload.npwp)
            formData.append('npwp_file', payload.npwp_file.files[0])
            formData.append('ktp_file', payload.ktp_file.files[0])

            const result = await api.POSTFORMDATA('user/register', formData)
            return result
        }
    }
}