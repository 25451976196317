import main from './main'
import objectstorage from './objectstorage'
import createObjectStorage from './createobjectstorage'
import objectstoragedetail from './objectstoragedetail'
import empty from './empty'
import axios from '../../lib/axios'
import beforeenterpermission from '../../lib/beforeenterpermission'
import beforeselectfirst from '../../lib/beforeselectfirst'
import libProject from '@/lib/project'
import handlepermissionprivilages from '../../lib/handlepermissionprivilages'

export default {
    install(Vue, { VueEvent }) {
        const OBJECTSTORAGE = {
            namespaced: true,
            state: {
                accessKeys: [],
                objectStorages: [],
                objectStorage: null,
                objectStorageUsage: null,
                s3Flavors: [],
                s3DefaultPrice: null,
                isLoading: false,
            },
            mutations: {
                setAccessKeys: (state, payload) => state.accessKeys = payload,
                setObjectStorages: (state, payload) => state.objectStorages = payload,
                setObjectStorage: (state, payload) => state.objectStorage = payload,
                setObjectStorageUsage: (state, payload) => state.objectStorageUsage = payload,
                setS3Flavors: (state, payload) => state.s3Flavors = payload,
                setS3DefaultPrice: (state, payload) => state.s3DefaultPrice = payload,
                setIsLoading: (state, payload) => state.isLoading = payload,
            },
            actions: {
                fetchAccessKeys: async ({ commit, dispatch }, payload) => {
                    const { id } = payload
                    const response = await axios.instance.get(`/user/object-storages-users/${id}`)
                    commit('setAccessKeys', response.data.data)
                    return response
                },
                fetchObjectStorage: async ({ commit, dispatch }, payload) => {
                    const { id } = payload
                    const response = await axios.instance.get(`/user/object-storages/${id}`)
                    const { data } = response.data
                    if(data) commit('setObjectStorage', data)
                    return response
                },
                fetchObjectStorages: async ({ commit, dispatch }, payload) => {
                    const role = localStorage.getItem('role')
                    const currentProj = JSON.parse(localStorage.getItem('currentProj'))
                    let params = {}
                    params = {
                        pro_id: currentProj.id
                    }
                    commit('setObjectStorages', [])
                    commit('setIsLoading', true)
                    const response = await axios.instance.get('/user/object-storages', { params })
                    commit('setIsLoading', false)
                    commit('setObjectStorages', response.data.data)
                    return response
                },
                fetchS3Flavors: async ({ commit, dispatch }, payload = { page: 1, limit: 10, search: '', organization_id: null }) => {
                    try {
                        if(!payload.organization_id) payload.organization_id = await libProject.getCurrentOrgID()
                        const response = await axios.instance.get('/flavor/object-storage', {
                            params: { ...payload }
                        })
                        const { storage } = response.data.data
                        commit('setS3Flavors', storage)
                        return response
                    } catch (e) {
                        dispatch('HOMEPAGE/showErrorToast', 'A problem encountered while getting S3 flavors', { root: true })
                    }
                },
                fetchS3DefaultPrice: async ({ commit, dispatch }) => {
                    try {
                        const response = await axios.instance.get('/flavor/object-storage/default')
                        commit('setS3DefaultPrice', response.data.data)
                        return response
                    } catch (e) {
                        dispatch('HOMEPAGE/showErrorToast', 'A problem encountered while getting S3 default flavor', { root: true })
                    }
                },
                createObjectStorage: async ({ commit, dispatch }, payload) => {
                    try {
                        commit('setIsLoading', true)
                        const response = await axios.instance.post('/user/object-storages', payload)
                        commit('setIsLoading', false)
                        dispatch('fetchObjectStorages')
                        dispatch('HOMEPAGE/showSuccessToast', 'Object storage has been succesfully created', { root: true })
                        return response
                    } catch (e) {
                        commit('setIsLoading', false)
                        
                        const err = e.response.data.data

                        let errorMessage = 'A problem encountered while creating object storage'
                        if(err && err.includes('Conflict. Resource already exists.')) errorMessage = 'Conflicted resource name. Please choose another name.'
                        else if(err && err.includes('already exist')) errorMessage = 'Name is already exists'
                        // if(err && err.includes('Your balance not enough')) errorMessage = 'Your balance not enough'
                        else if (e && e.response && e.response.data && e.response.data.data.includes('Your balance not enough')) {
                            commit('DASHBOARD/setopendialogbalance', true, { root: true })
                            commit('DASHBOARD/setopendialogbalancemsg', e.response.data.data, { root: true })
                        }

                        dispatch('HOMEPAGE/showErrorToast', errorMessage, { root: true })
                    }
                },
                deleteObjectStorage: async ({ commit, dispatch }, payload) => {
                    try {
                        commit('setIsLoading', true)
                        const { id, name } = payload
                        const response = await axios.instance.delete(`/user/object-storages/${id}`)
                        commit('setIsLoading', false)
                        await dispatch('fetchObjectStorages')
                        return response
                    } catch (e){
                        commit('setIsLoading', false)
                        const err = e.response.data.data
                        let errorMessage = 'A problem encountered while trying to destroy object storage'
                        if (err.includes('you don\'t have access key to delete object storage')) errorMessage = 'You don\'t have access key to this object storage.'
                        dispatch('HOMEPAGE/showErrorToast', errorMessage, { root: true })
                        return e.response
                    }
                },
                createAccessKey: async ({ commit, dispatch }, payload) => {
                    try {
                        commit('setIsLoading', true)
                        const response = await axios.instance.post('/user/object-storages-users', payload)
                        await dispatch('fetchAccessKeys', { id: payload.object_storage_id })
                        commit('setIsLoading', false)
                        dispatch('HOMEPAGE/showSuccessToast', 'New Access Key has been succesfully created', { root: true })
                        return response
                    } catch (e){
                        const err = e.response.data.data
                        let errorMessage = 'A problem encountered while creating new access key'
                        if (err.includes('Conflict. Resource already exists')) errorMessage = 'Key name already exists'
                        commit('setIsLoading', false)
                        dispatch('HOMEPAGE/showErrorToast', errorMessage, { root: true })
                    }
                },
                revealAccessKey: async ({ commit, dispatch }, payload) => {
                    try {
                        commit('setIsLoading', true)
                        const response = await axios.instance.post('/user/object-storages-secret-access-key', payload)
                        commit('setIsLoading', false)
                        return response
                    } catch (e){
                        const error = e.response.data.data
                        let errorMessage = 'A problem encountered while creating new access key'
                        if(error.includes('wrong password')) errorMessage = 'Oops, password does not match'
                        commit('setIsLoading', false)
                        dispatch('HOMEPAGE/showErrorToast', errorMessage, { root: true })
                    }
                },
                deleteAccessKey: async ({ commit, dispatch }, payload) => {
                    try {
                        commit('setIsLoading', true)
                        const { id, object_storage_id } = payload
                        const response = await axios.instance.delete(`/user/object-storages-delete-access-key/${id}`, payload)
                        await dispatch('fetchAccessKeys', { id: object_storage_id })
                        commit('setIsLoading', false)
                        dispatch('HOMEPAGE/showSuccessToast', 'Access Key has been succesfully deleted', { root: true })
                        return response
                    } catch (e) {
                        commit('setIsLoading', false)
                        dispatch('HOMEPAGE/showErrorToast', 'A problem encountered while deleting access key', { root: true })
                    }
                },
                updateCORS: async ({ commit, dispatch }, payload) => {
                    try {
                        commit('setIsLoading', true)
                        const { id, rules } = payload
                        const response = await axios.instance.put(`/user/object-storages-cors/${id}`, rules)
                        await dispatch('fetchObjectStorage', { id })
                        commit('setIsLoading', false)
                        dispatch('HOMEPAGE/showSuccessToast', 'CORS has been successfully updated', { root: true })
                        return response
                    } catch (e){
                        commit('setIsLoading', false)
                        dispatch('HOMEPAGE/showErrorToast', 'A problem encountered while updating CORS', { root: true })
                        await dispatch('fetchObjectStorage', { id: payload.id })
                    }
                },
                fetchUsage: async ({ commit, dispatch }, payload) => {
                    const { id } = payload
                    const response = await axios.instance.get(`/user/object-storages-usage/${id}`)
                    const { data } = response.data
                    if(data) commit('setObjectStorageUsage', data)
                    return response
                }
            }
        }
        Vue.addModules({
            OBJECTSTORAGE
        })
        Vue.addRoutetoDashboard({
            path: 'object-storage',
            component: main,
            beforeEnter: (to, from, next) => {
                beforeenterpermission(Vue, next, 'Object Storage', 'disable', false)().then(()=>{
                    return beforeselectfirst(Vue)()
                }).then(()=>{
                    next()
                }).catch(()=>{
                    if(from.path == '/'){
                      next('/')
                    }
                  })
            },
            meta: {
                permission: ['instance-view'],
                breadcrumb: {
                    text: 'Object Storage',
                    to: '/object-storage',
                },
            },
            children: [
                { path: '', component: objectstorage },
                {
                    beforeEnter: (to, from, next) => {
                        handlepermissionprivilages(Vue, next, 'Object Storage', 'editor', true)().then(()=>{
                            next()
                        }).catch(()=>{
                            if(from.path == '/'){
                              next('/')
                            }
                          })
                    },
                    path: 'create', component: createObjectStorage, meta: {
                        breadcrumb: {
                            text: 'Create Object Storage',
                            to: '/object-storage/create',
                        },

                    }
                },
                {
                    path: ':objectsrtorageid', component: objectstoragedetail, meta: {
                        breadcrumb: {
                            text: 'Object Storage Detail',
                            to: '',
                        },
                    },
                    children: [
                        { path: '', redirect: 'overview' },
                        { path: 'overview', component: empty },
                        { path: 'access-keys', component: empty },
                        { path: 'settings', component: empty }
                    ]
                }]
        })
        VueEvent.listen('after-store-created', (store) => {
            store.commit('ROLEPERMISSION/addmenumodulepermission',
                {
                    header: 'MANAGE', headerno: 1,
                    name: 'Object Storage', link: 'object-storage', icon: 'objectstorageIcon', sort: 3, permission: ['objectstorage-sidemenu']
                }
            )
        })
    }
}
