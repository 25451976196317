<template>
  <v-container fluid class="pa-0">
    <v-expansion-panel
      :style="{ 'background-color': $vuetify.theme.themes.light.primary }"
    >
      <v-expansion-panel-header> MANAGE SUPER ADMIN </v-expansion-panel-header>
      <v-expansion-panel-content>
        <!-- <v-list-item @click.native="()=>{setCurrentOrg(null)}" to="/" exact>
					<v-list-item-icon>
						<v-icon>$vuetify.icons.instanceIcon</v-icon>
					</v-list-item-icon>
					<v-list-item-content>
						<v-list-item-title>Dashboard</v-list-item-title>
					</v-list-item-content>
				</v-list-item> -->
        <v-list-item
          @click.native="
            () => {
              setCurrentOrg(null);
            }
          "
          to="/manage-organization"
          exact
        >
          <v-list-item-icon>
            <v-icon>$vuetify.icons.gg_organizationIcon</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Manage Organization</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          @click.native="
            () => {
              setCurrentOrg(null);
            }
          "
          to="/manage-audit"
          exact
        >
          <v-list-item-icon>
            <v-icon>$vuetify.icons.manageauditIcon</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Manage Audit</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          @click.native="
            () => {
              setCurrentOrg(null);
            }
          "
          to="/manage-user"
          exact
        >
          <v-list-item-icon>
            <v-icon>$vuetify.icons.manageuserIcon</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Manage User</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <!-- <v-list-item @click.native="()=>{setCurrentOrg(null)}" to="/manage-superadmin" exact>
					<v-list-item-icon>
						<v-icon>$vuetify.icons.managesuperadminIcon</v-icon>
					</v-list-item-icon>
					<v-list-item-content>
						<v-list-item-title>Manage Super Admin</v-list-item-title>
					</v-list-item-content>
				</v-list-item> -->

        <v-list-item
          @click.native="
            () => {
              setCurrentOrg(null);
            }
          "
          to="/manage-voucher"
          exact
        >
          <v-list-item-icon>
            <v-icon>$vuetify.icons.voucherIcon</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Manage Voucher</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          @click.native="
            () => {
              setCurrentOrg(null);
            }
          "
          to="/manage-pricing"
          exact
        >
          <v-list-item-icon>
            <v-icon>$vuetify.icons.manageflavorsIcon</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Manage Pricing</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          @click.native="
            () => {
              setCurrentOrg(null);
            }
          "
          to="/manage-os"
          exact
        >
          <v-list-item-icon>
            <v-icon>$vuetify.icons.manageosIcon</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Manage Image</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item to="/mailbox-log" exact>
          <v-list-item-icon>
            <v-icon>$vuetify.icons.emailIcon</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Mailbox Log</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item
          @click.native="
            () => {
              setCurrentOrg(null);
            }
          "
          to="/broadcast"
          exact
        >
          <v-list-item-icon>
            <v-icon>$vuetify.icons.broadcast</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Broadcast</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <!-- <v-list-item @click.native="()=>{setCurrentOrg(null)}" to="/manage-ticket" exact>
					<v-list-item-icon>
						<v-icon>$vuetify.icons.managetiketIcon</v-icon>
					</v-list-item-icon>
					<v-list-item-content>
						<v-list-item-title>Manage Ticket</v-list-item-title>
					</v-list-item-content>
				</v-list-item> -->

        <!-- <v-list-item @click.native="() => { setCurrentOrg(null) }" to="/commitment-fee" exact>
					<v-list-item-icon>
						<v-icon>$vuetify.icons.voucherIcon</v-icon>
					</v-list-item-icon>
					<v-list-item-content>
						<v-list-item-title>Commitment Fee</v-list-item-title>
					</v-list-item-content>
				</v-list-item> -->
				
			</v-expansion-panel-content>
		</v-expansion-panel>
		<v-expansion-panel
			:style="{ 'background-color': $vuetify.theme.themes.light.primary }"
		>
		<v-expansion-panel-header> MANAGE PROJECT </v-expansion-panel-header>
			<v-expansion-panel-content>
				<v-list-item @click="createselectfirst('/instance')()">
					<v-list-item-icon>
						<v-icon>$vuetify.icons.instanceIcon</v-icon>
					</v-list-item-icon>
					<v-list-item-content>
						<v-list-item-title>Instance</v-list-item-title>
					</v-list-item-content>
				</v-list-item>
				<v-list-item @click="createselectfirst('/storage')()">
					<v-list-item-icon>
						<v-icon>$vuetify.icons.storageIcon</v-icon>
					</v-list-item-icon>
					<v-list-item-content>
						<v-list-item-title>Storage</v-list-item-title>
					</v-list-item-content>
				</v-list-item>
				<v-list-item @click="createselectfirst('/object-storage')()">
					<v-list-item-icon>
						<v-icon>$vuetify.icons.objectstorageIcon</v-icon>
					</v-list-item-icon>
					<v-list-item-content>
						<v-list-item-title>Object Storage</v-list-item-title>
					</v-list-item-content>
				</v-list-item>
				<v-list-item @click="createselectfirst('/image')()">
					<v-list-item-icon>
						<v-icon>$vuetify.icons.imagesIcon</v-icon>
					</v-list-item-icon>
					<v-list-item-content>
						<v-list-item-title>Images</v-list-item-title>
					</v-list-item-content>
				</v-list-item>
				<v-list-item @click="createselectfirst('/network')()">
					<v-list-item-icon>
						<v-icon>$vuetify.icons.networkIcon</v-icon>
					</v-list-item-icon>
					<v-list-item-content>
						<v-list-item-title>Network</v-list-item-title>
					</v-list-item-content>
				</v-list-item>
				<v-list-item @click="createselectfirst('/security')()">
					<v-list-item-icon>
						<v-icon>$vuetify.icons.securityIcon</v-icon>
					</v-list-item-icon>
					<v-list-item-content>
						<v-list-item-title>Security</v-list-item-title>
					</v-list-item-content>
				</v-list-item>
				<v-list-item @click="createselectfirst('/alerting')()">
					<v-list-item-icon>
						<v-icon>$vuetify.icons.alertingIcon</v-icon>
					</v-list-item-icon>
					<v-list-item-content>
						<v-list-item-title>Alerting</v-list-item-title>
					</v-list-item-content>
				</v-list-item>
			</v-expansion-panel-content>
		</v-expansion-panel>
		<!-- <v-expansion-panel
			:style="{ 'background-color': $vuetify.theme.themes.light.primary }"
		>
			<v-expansion-panel-header> BILLING </v-expansion-panel-header>
			<v-expansion-panel-content>
				<v-list-item @click="createselectfirst('/billing')()">
					<v-list-item-icon>
						<v-icon>$vuetify.icons.billingIcon</v-icon>
					</v-list-item-icon>
					<v-list-item-content>
						<v-list-item-title>Billing</v-list-item-title>
					</v-list-item-content>
				</v-list-item>
				<v-list-item @click="createselectfirst('/billing/payment')()">
					<v-list-item-icon>
						<v-icon>$vuetify.icons.billingPaymentIcon</v-icon>
					</v-list-item-icon>
					<v-list-item-content>
						<v-list-item-title>Billing Payment</v-list-item-title>
					</v-list-item-content>
				</v-list-item>
			</v-expansion-panel-content>
		</v-expansion-panel> -->
    <dialogSelectFirst
      v-model="opendialogselectfirst"
      @selectproject="
        () => {
          opendialogselectorg = true;
        }
      "
    />
    <dialogSelectOrg v-model="opendialogselectorg" />
  </v-container>
</template>
<script>
import { ref } from "@vue/composition-api";
import {
  useNamespacedMutations,
  useNamespacedState,
} from "vuex-composition-helpers";
import dialogSelectFirst from "./dialogSelectFirst";
import dialogSelectOrg from "./dialogSelectOrg";
export default {
  components: { dialogSelectFirst, dialogSelectOrg },
  setup(props, context) {
    const { setCurrentOrg } = useNamespacedMutations("SUPERADMIN", [
      "setCurrentOrg",
    ]);
    const { currentOrg } = useNamespacedState("SUPERADMIN", ["currentOrg"]);
    const opendialogselectfirst = ref(false);
    const createselectfirst = (path) => {
      return function() {
        context.root.$router.push(path);
      };
    };
    return {
      opendialogselectfirst,
      setCurrentOrg,
      currentOrg,
      createselectfirst,
      opendialogselectorg: ref(false),
    };
  },
};
</script>
