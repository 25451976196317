import { render, staticRenderFns } from "./dialogEditRestore.vue?vue&type=template&id=e035c24a&scoped=true&"
import script from "./dialogEditRestore.vue?vue&type=script&lang=js&"
export * from "./dialogEditRestore.vue?vue&type=script&lang=js&"
import style0 from "./dialogEditRestore.vue?vue&type=style&index=0&id=e035c24a&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e035c24a",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
installComponents(component, {VBtn,VCard,VCardText,VCol,VDialog,VRow,VTextField})
