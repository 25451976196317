var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"rounded-lg elevation-0"},[_c('v-container',{staticClass:"px-7 py-4",attrs:{"fluid":""}},[_c('Header',{on:{"onSearch":function($event){return _vm.getBroadcasts($event, 1, 10, [], [])}}}),_c('v-row',[_c('v-col',[_c('v-data-table',{attrs:{"hide-default-footer":"","items":_vm.tblItems,"items-per-page":10,"server-items-length":_vm.pagination.count,"headers":_vm.tblHeaders,"options":_vm.pagination},on:{"update:options":function($event){_vm.pagination=$event}},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_vm._v(" "+_vm._s(_vm.loading ? "Loading ..." : "No data available")+" ")]},proxy:true},{key:"item.description",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.description && item.description.length > 100 ? item.description.substring(0, 100) + "..." : item.description)+" ")]}},{key:"item.send_date",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$moment(item.send_date).format("HH:mm:ss"))+" "),_c('br'),_vm._v(" "+_vm._s(_vm.$moment(item.send_date).format("DD/MM/YYYY"))+" ")]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(new Date(item.send_date).getTime() > new Date().getTime() ? "Draft" : "Sent")+" ")]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('DropdownBtn',{attrs:{"id":item.id,"is-draft":new Date(item.send_date).getTime() > new Date().getTime()},on:{"refreshData":function($event){return _vm.getBroadcasts('', 1, 10, [], [])}}})]}},{key:"footer",fn:function(ref){
var props = ref.props;
return [(
                (_vm.pagination && _vm.pagination.count > 10) ||
                  (_vm.pagination && _vm.pagination.count === 10)
              )?_c('custom-footer-datatable',{attrs:{"props":props},on:{"input":function (val) {
                  _vm.pagination = Object.assign({}, _vm.pagination, val);
                }}}):_vm._e()]}}])})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }