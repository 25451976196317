var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"margin-bottom":"3rem"}},[_c('v-data-table',{staticClass:"elevation-0",attrs:{"headers":_vm.images.headers,"items":_vm.customImages.filter(function (x) { return x.is_custom && !x.is_snapshot; }),"items-per-page":10,"hide-default-footer":""},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"dotblue mt-2"}),_c('div',[_c('span',{staticClass:"primary--text"},[_vm._v(_vm._s(item.name))]),_c('p',[_vm._v("Version "+_vm._s(item.version))])])])]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [(item.is_upload_success)?_c('p',{staticClass:"mb-0 primary--text"},[_vm._v("Active")]):(item.is_upload_failed)?_c('p',{staticClass:"mb-0"},[_vm._v("Failed")]):_c('p',{staticClass:"mb-0 red--text"},[_vm._v("Not Ready Yet")])]}},{key:"item.size",fn:function(ref){
var item = ref.item;
return [_c('p',{staticClass:"mb-0"},[_vm._v(_vm._s(item.size ? ((item.size) + " GB") : '-'))])]}},{key:"item.created",fn:function(ref){
var item = ref.item;
return [_c('p',{staticClass:"mb-0"},[_vm._v(_vm._s(_vm.moment(item.created_at).format("DD/MM/YYYY")))])]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"linkpointer error--text",on:{"click":function () {
            _vm.selectedCustomImage = item;
            _vm.showDialogDeleteCustomImage = true;
          }}},[_vm._v("Delete")])]}},(_vm.customImages.length > 5)?{key:"footer",fn:function(ref){
          var props = ref.props;
return [_c('custom-footer-datatable',{attrs:{"props":props}})]}}:null,{key:"no-data",fn:function(){return [(_vm.isLoading)?_c('v-progress-circular',{attrs:{"indeterminate":"","color":"primary"}}):_c('div',{staticClass:"my-7 text-center"},[_c('p',{staticClass:"font-weight-bold",staticStyle:{"font-size":"16px","margin-bottom":"10px","text-transform":"capitalize"}},[_vm._v(" Looks like you don’t have any Images ")]),_c('v-row',{staticClass:"d-flex justify-center"},[_c('v-col',{staticClass:"text-center",staticStyle:{"color":"#a5b3bf","font-weight":"normal"},attrs:{"cols":"12","lg":"6","md":"12"}},[_c('p',{staticStyle:{"font-size":"12px","color":"#a5b3bf","margin-bottom":"20px"}},[_vm._v(" You can get started by importing an image via URL, drag/dropping your image on the the page, or clicking the Upload button. ")])])],1),_c('v-btn',{staticClass:"secondary",attrs:{"to":"/image/customimage","width":"100%","max-width":"300","height":"50","depressed":"","exact":""}},[_vm._v(" Import Image ")])],1)]},proxy:true}],null,true)}),_c('dialogDeleteCustomImage',{attrs:{"selectedCustomImage":_vm.selectedCustomImage},model:{value:(_vm.showDialogDeleteCustomImage),callback:function ($$v) {_vm.showDialogDeleteCustomImage=$$v},expression:"showDialogDeleteCustomImage"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }