var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"rounded-lg pa-4 mt-3",attrs:{"flat":""}},[_c('v-card-text',{staticClass:"d-flex flex-row align-center"},[_c('v-col',{staticClass:"d-flex flex-row align-center"},[_c('p',{staticClass:"mb-0 font-weight-bold fz-21"},[_vm._v("Mailbox Log")])])],1),_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"md":"4"}},[_c('v-menu',{ref:"menu1",attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"value":_vm.rangeText,"placeholder":"DD/MM/YYYY","persistent-hint":"","readonly":"","clearable":"","outlined":""},on:{"click:clear":_vm.clearDate}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.vcalendar),callback:function ($$v) {_vm.vcalendar=$$v},expression:"vcalendar"}},[_c('v-date-picker',{attrs:{"range":"","no-title":""},on:{"change":function($event){_vm.vcalendar = false}},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}})],1)],1),_c('v-col',{attrs:{"md":"4"}},[_c('v-autocomplete',{attrs:{"id":"FilterOrg","search-input":_vm.searchorganization,"items":_vm.listOrgMailbox,"outlined":"","item-text":"org_names","item-value":"id","placeholder":"Filter Organization","attach":"#FilterOrgAttach","clearable":"","hide-details":""},on:{"update:searchInput":function($event){_vm.searchorganization=$event},"update:search-input":function($event){_vm.searchorganization=$event}},scopedSlots:_vm._u([{key:"append-outer",fn:function(){return [_c('div',{attrs:{"id":"FilterOrgAttach"}})]},proxy:true}]),model:{value:(_vm.filterorganization),callback:function ($$v) {_vm.filterorganization=$$v},expression:"filterorganization"}})],1),_c('v-col',{attrs:{"md":"4"}},[_c('v-select',{attrs:{"hide-details":"","clearable":"","items":[
            { name: 'Success', id: 1 },
            { name: 'Failed', id: 0 },
            { name: 'Resend', id: 2 } ],"item-text":"name","item-value":"id","outlined":"","placeholder":"Filter Status"},model:{value:(_vm.filterstatus),callback:function ($$v) {_vm.filterstatus=$$v},expression:"filterstatus"}})],1)],1)],1),_c('v-card-text',[_c('v-data-table',{attrs:{"id":"my-table","hide-default-footer":"","headers":_vm.headers,"hide-default-header":"","items-per-page":10,"search":_vm.search,"items":_vm.listmailbox,"server-items-length":_vm.pagination.count,"options":_vm.options},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"header",fn:function(ref){
          var props = ref.props;
          var on = ref.on;
return [_c('v-data-table-header',_vm._g(_vm._b({attrs:{"sort-icon":"mdi-menu-down"}},'v-data-table-header',props,false),on))]}},{key:"item.created_at",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$moment(item.created_at).format("HH:mm:ss DD/MM/YYYY"))+" ")]}},{key:"item.status",fn:function(ref){
          var item = ref.item;
return [_c('div',{staticClass:"min-width-70"},[(item.status == 1)?_c('div',[_vm._v("Success")]):(item.status == 2)?_c('div',[_vm._v("Resend")]):_c('div',[_vm._v("Failed")])])]}},{key:"item.trigger",fn:function(ref){
          var item = ref.item;
return [_c('div',{staticClass:"min-width-70"},[_vm._v(" "+_vm._s(item.trigger)+" ")])]}},{key:"item.organization_name",fn:function(ref){
          var item = ref.item;
return [_c('div',{staticClass:"min-width-100"},[_vm._v(" "+_vm._s(item.organization_name ? item.organization_name : "-")+" ")])]}},{key:"item.action",fn:function(ref){
          var item = ref.item;
return [(item.status == 1)?_c('div',[_vm._v("-")]):_vm._e(),(item.status === 2)?_c('p',{staticClass:"accent--text mb-0"},[_vm._v(" Resend ")]):_vm._e(),(item.status === 0)?_c('p',{staticClass:"primary--text resend-btn mb-0",on:{"click":function($event){_vm.listmailboxselectedvalue = item;
            _vm.opendialogresendemail = true;}}},[_vm._v(" Resend ")]):_vm._e()]}},{key:"footer",fn:function(ref){
            var props = ref.props;
return [_c('custom-footer-datatable',{attrs:{"props":props},on:{"input":function (val) {
              _vm.options = Object.assign({}, _vm.options, val);
            }}})]}}],null,true),model:{value:(_vm.listmailboxselected),callback:function ($$v) {_vm.listmailboxselected=$$v},expression:"listmailboxselected"}})],1),(_vm.opendialogresendemail && _vm.listmailboxselectedvalue)?_c('dialogResendEmail',{attrs:{"item":{ listmailboxselectedvalue: _vm.listmailboxselectedvalue, listmailboxselected: _vm.listmailboxselected }},on:{"close":function($event){_vm.opendialogresendemail = false},"submit":_vm.resendEmail},model:{value:(_vm.opendialogresendemail),callback:function ($$v) {_vm.opendialogresendemail=$$v},expression:"opendialogresendemail"}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }