<template>
  <div>
    <v-row>
      <v-col cols="12" v-if="projectBilling.length">
        <v-card class="rounded-lg" flat>
          <v-container fluid class="pa-7">
            <v-row>
              <v-col cols="12" class="py-0">
                <p class="project-name">{{ projectBilling[0].project.name }}</p>
              </v-col>
              <v-col class="d-flex mb-2 pt-0">
                <div>
                  <p class="font-weight-bold fz-21 mb-4 mr-4">
                    Cost for {{ moment(selectedDate).format('DD MMMM YYYY') }}
                  </p>
                  <p>
                    This is the current cost for your usage this billing period. A
                    breakdown of your cost is available below.
                  </p>
                </div>
                <v-spacer />
                <div class="summary-billed">
                  <b class="font--text">Billed</b>
                  <p class="total-billed">{{ IDRformat.format(totalBilled) }}</p>
                </div>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <div class="total primary--text">
                </div>
              </v-col>
            </v-row>
            <br />
            
            <div v-if="services.instances.length">
              <p class="font-weight-bold fz-21">
                Instance Detail
              </p>
              <v-data-table
                class="datatable-services"
                :headers="instanceHeaders"
                :items="services.instances"
                hide-default-footer
              >
                <template v-slot:item.name="{ item }">
                  <span class="linkpointer">{{ item.instance.name || 'N/A' }}</span>
                </template>
                <template v-slot:item.spec="{ item }">
                  {{ item.package_instance.vcpu }} vCPU / {{ item.package_instance.ram }} GB Memory
                </template>
                <template v-slot:item.price="{ item }">
                  {{ IDRformat.format(item.package_instance.price_per_hours) }} / hour
                </template>
                <template v-slot:item.usage="{ item }">
                  <span v-if="item.usage_hours">{{ item.usage_hours > 1 ? `${item.usage_hours} hours` : `${item.usage_hours} hour` }}</span>
                  <span v-else>-</span>
                </template>
                <template v-slot:item.billed="{ item }">
                  {{ IDRformat.format(item.billed) }}
                </template>
                <template v-slot:body.append>
                  <tr style="border:none" v-if="services.instances.length">
                    <td class="border-bottom-none"></td>
                    <td class="border-bottom-none"></td>
                    <td class="border-bottom-none"></td>
                    <td class="border-bottom-none"></td>
                    <td class="border-bottom-none">
                      <div>
                        <p class="font-weight-bold">Total</p>
                      </div>
                      <div class="d-flex flex-row justify-start">
                        {{ IDRformat.format(totalBilledInstances) }}
                      </div>
                    </td>
                  </tr>
                </template>
              </v-data-table>
            </div>
            
            <div v-if="services.root_disks.length">
              <p class="font-weight-bold fz-21 pt-4">
                Root Disk Detail
              </p>
              <v-data-table
                class="datatable-services"
                :headers="storageHeaders"
                :items="services.root_disks"
                hide-default-footer
              >
                <template v-slot:item.name="{ item }">
                  <span class="linkpointer">{{ item.instance.name || 'N/A' }}</span>
                </template>
                <template v-slot:item.spec="{ item }">
                  {{ item.package_storage ? `${item.package_storage.volume_size} GB` : '-' }}
                </template>
                <template v-slot:item.price="{ item }">
                  {{ IDRformat.format(item.package_storage.price_per_month) }} / month
                </template>
                <template v-slot:item.billed="{ item }">
                  {{ IDRformat.format(item.billed) }}
                </template>
                <template v-slot:item.usage="{ item }">
                  {{ item.usage_hours > 1 ? `${item.usage_hours} hours` : `${item.usage_hours} hour` }}
                </template>
                <template v-slot:body.append>
                  <tr style="border:none" v-if="services.root_disks.length">
                    <td class="border-bottom-none"></td>
                    <td class="border-bottom-none"></td>
                    <td class="border-bottom-none"></td>
                    <td class="border-bottom-none"></td>
                    <td class="border-bottom-none">
                      <div>
                        <p class="font-weight-bold">Total</p>
                      </div>
                      <div class="d-flex flex-row justify-start">
                        {{ IDRformat.format(totalBilledRootDisks) }}
                      </div>
                    </td>
                  </tr>
                </template>
              </v-data-table>
            </div>
           
           <div v-if="services.storages.length">
            <p class="font-weight-bold fz-21 pt-4">
              Storage Detail
            </p>
            <v-data-table
              class="datatable-services"
              :headers="storageHeaders"
              :items="services.storages"
              hide-default-footer
            >
              <template v-slot:item.name="{ item }">
                <span class="linkpointer">{{ item.storage.name || 'N/A' }}</span>
              </template>
              <template v-slot:item.spec="{ item }">
                {{ item.package_storage ? `${item.package_storage.volume_size} GB` : '-' }}
              </template>
              <template v-slot:item.price="{ item }">
                {{ IDRformat.format(item.package_storage.price_per_month) }} / month
              </template>
              <template v-slot:item.usage="{ item }">
                  {{ item.usage_hours > 1 ? `${item.usage_hours} hours` : `${item.usage_hours} hour` }}
                </template>
              <template v-slot:item.billed="{ item }">
                {{ IDRformat.format(item.billed) }}
              </template>
              <template v-slot:body.append>
                <tr style="border:none" v-if="services.storages.length">
                  <td class="border-bottom-none"></td>
                  <td class="border-bottom-none"></td>
                  <td class="border-bottom-none"></td>
                  <td class="border-bottom-none"></td>
                  <td class="border-bottom-none">
                    <div>
                      <p class="font-weight-bold">Total</p>
                    </div>
                    <div class="d-flex flex-row justify-start">
                      {{ IDRformat.format(totalBilledStorages) }}
                    </div>
                  </td>
                </tr>
              </template>
            </v-data-table>
           </div>

          <div v-if="services.object_storages.length">
            <p class="font-weight-bold fz-21 pt-4">
              Object Storage Detail
            </p>
              <v-data-table
                class="datatable-services"
                :headers="storageHeaders"
                :items="services.object_storages"
                hide-default-footer
              >
                <template v-slot:item.name="{ item }">
                  <span class="linkpointer">{{ item.object_storage.name || 'N/A' }}</span>
                </template>
                <template v-slot:item.spec="{ item }">
                  {{ item.package_object_storage ? `${item.package_object_storage.object_storage_size} GB` : '-' }}
                </template>
                <template v-slot:item.price="{ item }">
                  {{ IDRformat.format(item.package_object_storage.price_per_month) }} / month
                </template>
                <template v-slot:item.usage="{ item }">
                  {{ item.usage_hours > 1 ? `${item.usage_hours} hours` : `${item.usage_hours} hour` }}
                </template>
                <template v-slot:item.billed="{ item }">
                  {{ IDRformat.format(item.billed) }}
                </template>
                <template v-slot:body.append>
                  <tr style="border:none" v-if="services.object_storages.length">
                    <td class="border-bottom-none"></td>
                    <td class="border-bottom-none"></td>
                    <td class="border-bottom-none"></td>
                    <td class="border-bottom-none"></td>
                    <td class="border-bottom-none">
                      <div>
                        <p class="font-weight-bold">Total</p>
                      </div>
                      <div class="d-flex flex-row justify-start">
                        {{ IDRformat.format(totalBilledObjectStorages) }}
                      </div>
                    </td>
                  </tr>
                </template>
              </v-data-table>
            </div>

            <template v-if="isDevModeChargingForSnapshot && services.snapshots.length">
              <p class="font-weight-bold fz-21 mt-4">
                Snapshot
              </p>

              <v-data-table
                class="datatable-services"
                :headers="snapshotHeaders"
                :items="services.snapshots"
                :items-per-page="services.snapshots.length"
                hide-default-footer
              >
                <template v-slot:item.size="{ item }">
                  <!-- {{ item.size }} {{ item.image_name === 'Snapshot' ? 'GB' : 'x' }} -->
                  {{ item.size }} GB
                </template>

                <template v-slot:item.billed="{ item }">
                  {{ IDRformat.format(item.billed) }}
                </template>

                <template v-slot:body.append>
                  <tr style="border:none" v-if="services.snapshots.length">
                    <td class="border-bottom-none"></td>
                    <td class="border-bottom-none"></td>
                    <td class="border-bottom-none">
                      <div>
                        <p class="font-weight-bold">Total</p>
                      </div>
                      <div class="d-flex flex-row justify-start">
                        {{ services.snapshots.length ? IDRformat.format(services.snapshots.reduce((total, item) => total + item.billed, 0)) : '0' }}
                      </div>
                    </td>
                  </tr>
                </template>

                <template
                  v-slot:footer="{ props }"
                  v-if="services.snapshots.length"
                >
                  <custom-footer-datatable  :props="props" />
                </template>
              </v-data-table>
            </template>

            <template v-if="isDevModeChargingForSnapshot && services.floating_ips.length">
              <p class="font-weight-bold fz-21 mt-4">
                Floating IP
              </p>

              <v-data-table
                class="datatable-services"
                :headers="floating_ipHeaders"
                :items="services.floating_ips"
                :items-per-page="services.floating_ips.length"
                hide-default-footer
              >
                <template v-slot:item.ip_address="{ item }">
                  {{ item.ip_address }}
                </template>

                <template v-slot:item.total="{ item }">
                  {{ item.total }} x
                </template>

                <template v-slot:item.billed="{ item }">
                  {{ IDRformat.format(item.billed) }}
                </template>

                <template v-slot:body.append>
                  <tr style="border:none" v-if="services.floating_ips.length">
                    <td class="border-bottom-none"></td>
                    <td class="border-bottom-none"></td>
                    <td class="border-bottom-none">
                      <div>
                        <p class="font-weight-bold">Total</p>
                      </div>
                      <div class="d-flex flex-row justify-start">
                        {{ services.floating_ips.length ? IDRformat.format(services.floating_ips.reduce((total, item) => total + item.billed, 0)) : '0' }}
                      </div>
                    </td>
                  </tr>
                </template>

                <template
                  v-slot:footer="{ props }"
                  v-if="services.floating_ips.length"
                >
                  <custom-footer-datatable  :props="props" />
                </template>
              </v-data-table>
            </template>

          </v-container>
        </v-card>
      </v-col>
    </v-row>
    <billingdetail />
    <!-- <dialogInstanceDetail
      title="Instance Detail"
    />
    <dialogInstanceDetail
      title="Storage Detail"
    /> -->
    <!-- <dialogSpecificationinfo v-model="opendialogSpecificationinfo" /> -->
  </div>
</template>
<script>

import billingdetail from "./billingdetail";
import { ref, watch, onMounted, computed, reactive } from "@vue/composition-api";
import {
  useNamespacedGetters,
  useNamespacedState,
  useNamespacedActions,
} from "vuex-composition-helpers";
import { BILLING } from "./namespace";
import Vue from "vue";
import { IDRformat } from "@/lib/formatter";
import dialogInstanceDetail from "./dialogInstanceDetail";
import dialogSpecificationinfo from "./dialogSpecificationInfo";
const html2pdf = require("html2pdf.js");
import moment from 'moment'

const isDevModeChargingForSnapshot = true

export default {
  components: {
    billingdetail,
    // dialogInstanceDetail,
    // dialogSpecificationinfo,
  },

  data: () => ({
    dates: {
      start: new Date(),
      end: new Date(),
    },
    isDevModeChargingForSnapshot,
  }),
  computed: {
    dateRangeText() {
      return this.dates.join(" - ");
    },
  },

  setup(props, context) {
    const { id: projectID, date: selectedDate } = context.root.$route.params
    const { 
      fetchBillingOrganization,
      fetchBillingHistory,
    } = useNamespacedActions('BILLING', [
      'fetchBillingOrganization',
      'fetchBillingHistory',
    ])

    const { 
      projectBilling,
      billing,
    } = useNamespacedState('BILLING', [
      'projectBilling',
      'billing',
    ])

    const historyHeaders = ref([
      { text: 'Date', value: 'date' },
      { text: 'Billed', value: 'billed' },
      { text: 'Action', value: 'action', width: 100 },
    ])

    const instanceHeaders = ref([
      { text: 'Service Name', value: 'name', width: 300  },
      { text: 'Specification', value: 'spec', width: 300  },
      { text: 'Price', value: 'price', width: 300  },
      { text: 'Usage', value: 'usage', width: 300  },
      { text: 'Billed', value: 'billed', width: 300  },
    ])

    const servicesHeaders = ref([
      { text: 'Billing Type', value: 'billing_type' },
      { text: 'Amount of Service', value: 'amount' },
      { text: 'Billed', value: 'billed' },
    ])

    const objectStorageHeaders = ref([
      { text: 'Service Name', value: 'name', width: 300 },
      { text: 'Specification', value: 'spec', width: 300 },
      { text: 'Price', value: 'price', width: 300 },
      { text: 'Usage', value: 'usage', width: 300 },
      { text: 'Billed', value: 'billed', width: 300 },
    ])

    const storageHeaders = ref([
      { text: 'Service Name', value: 'name', width: 300  },
      { text: 'Specification', value: 'spec', width: 300  },
      { text: 'Price', value: 'price', width: 300  },
      { text: 'Usage', value: 'usage', width: 300 },
      { text: 'Billed', value: 'billed', width: 300  },
    ])

    const snapshotHeaders = ref([
      { text: 'Service Name', value: 'image_name' },
      { text: 'Total', value: 'size' },
      { text: 'Total Price', value: 'billed' },
    ])

    const floating_ipHeaders = ref([
      { text: 'Service Name', value: 'ip_address' },
      { text: 'Total', value: 'total' },
      { text: 'Total Price', value: 'billed' },
    ])

    const totalBilled = ref(0)
    const totalBilledInstances = ref(0)
    const totalBilledStorages = ref(0)
    const totalBilledRootDisks = ref(0)
    const totalBilledObjectStorages = ref(0)
    const totalBilledSnapshots = ref(0)
    const totalBilledFloatingIPs = ref(0)

    let billServices = ref([])
    let services = reactive({
      billed: 0,
      instances: [],
      storages: [],
      object_storages: [],
      root_disks: [],
      snapshots: [],
      floating_ips: [],
    })

    const resetDataServices = () => {
        services.billed = 0
        services.instances = []
        services.storages = []
        services.object_storages = []
        services.root_disks = []
        services.snapshots = []
        services.floating_ips = []
    }

    watch(projectBilling, bills => {
      resetDataServices()
      if (!bills.length) return
      let bill = bills[0]
      services.billed = bill.billed
      services.instances = bill.instances || []
      services.storages = bill.storages || []
      services.object_storages = bill.object_storages || []
      services.root_disks = bill.root_disks || []

      if (bill.other && bill.other.snapshot && bill.other.snapshot.history && bill.other.snapshot.history.length) {
        services.snapshots = bill.other.snapshot.history
      }

      if (bill.other && bill.other.floating_ip && bill.other.floating_ip.history && bill.other.floating_ip.history.length) {
        services.floating_ips = bill.other.floating_ip.history
      }

      totalBilledInstances.value = services.instances.reduce((total, item) => total + item.billed, 0)
      totalBilledStorages.value = services.storages.reduce((total, item) => total + item.billed, 0)
      totalBilledObjectStorages.value = services.object_storages.reduce((total, item) => total + item.billed, 0)
      totalBilledRootDisks.value = services.root_disks.reduce((total, item) => total + item.billed, 0)
      totalBilledSnapshots.value = services.snapshots.reduce((total, item) => total + item.billed, 0)
      totalBilledFloatingIPs.value = services.floating_ips.reduce((total, item) => total + item.billed, 0)
      
      totalBilled.value = totalBilledSnapshots.value + totalBilledFloatingIPs.value + totalBilledInstances.value + totalBilledRootDisks.value + totalBilledStorages.value + totalBilledObjectStorages.value
    })

    const sortedBillingHistory = computed(() => {
      return projectBilling.value.sort((a, b) => {
        if (a.date > b.date) return -1
        return 1
      })
    })

    const currentPeriod = ref('')
    let from = ref(null)
    let to = ref(null)

    onMounted(async () => {
      await fetchBillingHistory({
        project_id: projectID,
        start: selectedDate,
        end: selectedDate,
      })
      from.value = moment(selectedDate)
      to.value = moment(selectedDate)
      currentPeriod.value = `${from.value.format('DD MMMM YYYY')} - ${to.value.format('DD MMMM YYYY')}`
    })
    
    return {
      snapshotHeaders,
      floating_ipHeaders,
      instanceHeaders,
      storageHeaders,
      objectStorageHeaders,
      selectedDate,
      services,
      fetchBillingHistory,
      totalBilled,
      totalBilledInstances,
      totalBilledStorages,
      totalBilledRootDisks,
      totalBilledObjectStorages,
      projectBilling,
      sortedBillingHistory,
      billing,
      billServices,
      historyHeaders,
      servicesHeaders,
      currentPeriod,
      IDRformat,
      moment,
      vcalendar: ref(false),
    };
  },
};
</script>
<style>
.border-bottom-none {
  border: none !important;
}
</style>
<style lang="scss" scoped>
.summary-billed {
  padding: 1.5rem;
  background: rgba(44, 148, 210, 0.02);
  border: 1px solid #2C94D2;
  border-radius: 10px;
  min-width: 300px;
}

.summary-billed p {
  font-size: 36px;
  color: #1F60A8;
  font-weight: 600;
  margin-bottom: 0;
}

.project-name {
  font-weight: bold;
  font-size: 21px;
  display: block;
  color: #1F60A8;
}

.history {
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 140%;
  color: #556272;
}
.total {
  font-style: normal;
  font-weight: 600;
  font-size: 36px;
  line-height: 140%;
  color: #556272;
}
.page-title {
  font-style: normal;
  font-weight: bold;
  font-size: 21px;
  line-height: 140%;
  color: #556272;
}

.page-content {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 140%;
  color: #556272;
}

.detail-btn {
  cursor: pointer;
}

.date {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 140%;
  color: #a5b3bf;
}

.amount {
  font-style: normal;
  font-weight: bold;
  line-height: 140%;
  color: #556272;
  font-size: 14px;
}

.detail {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 140%;
  color: #1f60a8;
}

.bill-title {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 160%;
  color: #556272;
}

.history-billing {
  table-layout: auto;
}

.history-billing tr {
  border-bottom: thin solid;
}

td {
  height: 60px !important;
}

.v-data-table {
  ::v-deep table {
    border-bottom: thin solid rgba(0, 0, 0, 0.12);
  }
}

.v-data-table > .v-data-table__wrapper > table > tbody > tr > td,
.v-data-table > .v-data-table__wrapper > table > thead > tr > td,
.v-data-table > .v-data-table__wrapper > table > tfoot > tr > td {
  font-size: 14px;
}
</style>