import { ref, SetupContext, watch, onMounted } from "@vue/composition-api";
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import {
  useNamespacedActions,
  useNamespacedState,
} from "vuex-composition-helpers";
import { ALERTING } from "./namespace";

export const useAlert = (props: any, context: SetupContext) => {
  const { addAlerting } = useNamespacedActions(ALERTING, ["addAlerting"]);
  const recipientalert = ref([]);
  const alertingedit: any = ref([]);
  const recipients = ref([]);
  const { instances } = useNamespacedState("INSTANCE", ["instances"]);

  const { fetchinstances } = useNamespacedActions("INSTANCE", [
    "fetchinstances",
  ]);

  const SetRecipient = (payload: any) => {
    recipients.value = payload;
  };
  const setalertingedit = (payload: any) => {
    alertingedit.value = payload;
    SetRecipient(payload.recipientalert);
  };
  // watch(alertingedit,(val)=>{
  //     name.value = val.name
  //     metrics.value = val.metrics
  //     trigger.value = val.trigger
  //     percent.value = val.percent
  //     minutes.value = val.minutes
  //     appliedto.value = val.appliedto
  //     recipientalert.value = val.recipientalert
  // })
  // const EditAlerting = async()=>{
  //     const Value = {
  //         name: name.value,
  //         metrics: metrics.value,
  //         trigger: trigger.value,
  //         percent: percent.value,
  //         minutes: minutes.value,
  //         appliedto: appliedto.value,
  //         recipientalert: recipientalert.value
  //     }
  //     $v.value.$touch()
  //     if($v.value.$errors.length){
  //         const ref = context.refs[$v.value.$errors[0].$property+'Ref'] as any
  //         ref.focus()
  //     }else{
  //         return await alertingedit.value.ref.update(Value)
  //     }
  // }

  const {
    fetchmembers,
    fetchMembersBySuperAdmin,
  } = useNamespacedActions("MEMBER", [
    "fetchmembers",
    "fetchMembersBySuperAdmin",
  ]);

  const { members } = useNamespacedState("MEMBER", ["members"]);

  const {
    alert,
    alerts,
    isLoading,
    pagination,
  } = useNamespacedState("ALERTING", [
    "alert",
    "alerts",
    "isLoading",
    "pagination",
  ]);

  const {
    fetchAlerts,
    fetchAlertByID,
    createAlert,
    updateAlert,
    deleteAlert,
  } = useNamespacedActions("ALERTING", [
    "fetchAlerts",
    "fetchAlertByID",
    "createAlert",
    "updateAlert",
    "deleteAlert",
  ]);

  const currentOrg: any = localStorage.getItem("currentOrg");
  const organization_id = JSON.parse(currentOrg).id;

  const role: any = localStorage.getItem("role");
  const project_id: any = localStorage.getItem("projectid");

  onMounted(() => {
    fetchinstances();
    if (role === "Superadmin")
      fetchMembersBySuperAdmin({
        page: 1,
        itemsPerPage: -1,
        search: "",
        organization_id,
        verified:true,
        project_id
      });
    else fetchmembers({ page: 1, itemsPerPage: -1, search: "", verified:true, project_id });
  });

  return {
    // state
    alerts,
    alert,
    isLoading,
    members,
    instances,

    // actions
    fetchAlerts,
    fetchAlertByID,
    createAlert,
    updateAlert,
    deleteAlert,
    fetchmembers,
    fetchinstances,
    fetchMembersBySuperAdmin,

    recipientalert,
    alertingedit,
    recipients,
    SetRecipient,
    pagination,
    setalertingedit,
  };
};
