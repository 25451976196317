<template>
  <div>
    <v-card class="rounded-lg mt-3" flat>
      <v-container>
        <v-row>
          <v-col cols="1">
            <div
              class="mx-2"
              style="
                padding-left: 30;
                width: 50px;
                height: 50px;
                background: #f1f2f2;
                border-radius: 50%;
              "
            ></div>
          </v-col>
          <v-col class="px-0">
            <p class="mb-0 fz-21 font-weight-bold">{{ vpc.name }}</p>
            <p class="mb-0 accent--text">
              {{ vpc.region }} / {{ vpc.ip_prefix }} / {{ vpc.network_size }} 
              <!-- <span class="linkpointer font-weight-bold">Copy</span> -->
            </p>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
    <div style="height: 30px; width: auto" />
    <v-card flat class="rounded-lg">
      <v-tabs v-model="tabs" height="64" slider-size="4">
        <v-tab class="px-8 fz-14">Overview</v-tab>
        <v-tab class="px-8 fz-14">Resources</v-tab>
        <v-tab class="px-8 fz-14">Settings</v-tab>
      </v-tabs>
      <v-divider />
      <v-tabs-items v-model="tabs">
        <v-tab-item>
          <v-card-text class="pa-8">
            <p class="fz-21 font-weight-bold">Description</p>
            <p class="mb-3">
              {{ vpc.description }}
            </p>
            <br />
            <v-divider />
            <div style="width: auto; height: 30px" />
            <p class="fz-21 font-weight-bold">Network Details</p>
            <v-row>
              <v-col>
                <p>
                  <b>IP Range:</b> {{ vpc.ip_prefix }}/{{ vpc.network_size }}
                  <!-- <span class="linkpointer font-weight-bold">Copy</span> -->
                </p>
              </v-col>
              <!-- <v-col>
                <p><b>Available Addresses: </b> 4.089</p>
              </v-col> -->
              <v-col>
                <!-- <p><b>Resource: </b> 1</p> -->
              </v-col>
            </v-row>
          </v-card-text>
        </v-tab-item>

        <v-tab-item>
          <v-card-text class="pa-8">
            <p class="fz-21 font-weight-bold">Instance</p>
            <v-data-table
              :headers="headerload"
              hide-default-footer
              :items="vpc.instances"
            >
              <template v-slot:item.name="{ item }">
                <div>
                  <p class="mb-0">
                    <span class="linkpointer">{{ item.name }}</span>
                  </p>
                  <p class="mb-0">{{ item.spec }}</p>
                </div>
              </template>
              <template v-slot:item.ip="{ item }">
                <div>
                  <p class="mb-0">
                    {{ item.ip_address_internal }}
                  </p>
                </div>
              </template>
              <!-- <template v-slot:item.action>
                <span class="primary--text">Edit</span>
                <span style="cursor: pointer" class="pl-3 error--text"
                  >Delete</span
                >
              </template> -->
            </v-data-table>
          </v-card-text>
        </v-tab-item>

        <v-tab-item>
          <v-card-text class="pa-8">
            <p class="fz-21 font-weight-bold">VPC Network Settings</p>
            <br />
            <v-row>
              <v-col md="6">
                <p class="mb-3"><b>Name</b></p>
                <v-text-field  maxlength="50" outlined v-model="vpc.name"></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col md="6">
                <p class="mb-3"><b>Description</b></p>
                <v-textarea outlined v-model="vpc.description"> </v-textarea>
              </v-col>
            </v-row>
            <v-row>
              <v-col md="6">
                <v-btn height="55" 
                  :disabled="isLoading"
                  block 
                  depressed 
                  color="secondary"
                  @click="
                    () => {
                      validateprivilages(['Network', 'editor', true]).then(() => {
                       updateVPC(vpc)
                      });
                    }
                  "
                  >
                  <div v-if="isLoading">
                    <beat-loader :loading="isLoading" :color="'white'" :size="'10px'"></beat-loader>
                  </div>
                  <span v-else>Save</span>
                </v-btn>
              </v-col>
            </v-row>
            <br/>
            <v-divider />
            <div style="width: auto; height: 30px" />
            <p class="fz-21 font-weight-bold">Network Details</p>
            <p><b>IP Range:</b> {{ vpc.ip_prefix }}/{{ vpc.network_size }}</p>
          <br/>
          <v-divider/>
          <div class="d-flex flex-row align-center ">
            <div class="mb-4">
              <div style="width: auto; height: 30px" />
            <p class="fz-21 font-weight-bold">Destroy</p>
            <p>To destroy a VPC network, you must destroy all resources within it first. The default VPC cannot be destroyed.</p>
            </div>
            <v-spacer/>
            <v-btn 
            
            @click="
              () => {
                validateprivilages(['Network', 'editor', true]).then(() => {
                  opendialogdeletevpc = true
                });
              }
            "
            style="border: 1px solid #EB5757;background-color:white"  depressed height="45" width="150" class="error--text">Destroy</v-btn>
          </div>
          
          <v-divider/>

          </v-card-text>
        </v-tab-item>
      </v-tabs-items>
    </v-card>
    <dialogDeleteVpc v-model="opendialogdeletevpc"/>
  </div>
</template>
<script>
import { ref, onMounted } from "@vue/composition-api";
import dialogDeleteVpc from './dialogDeleteVpc'
import { useNamespacedActions, useNamespacedState } from 'vuex-composition-helpers'
import { NETWORKVPC } from '../namespace.ts'

export default {
  components: { dialogDeleteVpc },
  setup(_, context) {
    const { vpcid } = context.root._route.params
    const { vpc, isLoading } = useNamespacedState(NETWORKVPC, ['vpc', 'isLoading'])
    const {
      DETAIL_VPC,
      UPDATE_VPC,
    } = useNamespacedActions(NETWORKVPC, ['DETAIL_VPC', 'UPDATE_VPC'])

    onMounted(async () => {
      await DETAIL_VPC({ id: vpcid })
    })


    const {
      validateprivilages,
      validateprivilagesync,
    } = useNamespacedActions("HOMEPAGE", [
      "validateprivilages",
      "validateprivilagesync",
    ]);

    return {
      validateprivilages,
      validateprivilagesync,
      vpc,
      isLoading,
      opendialogdeletevpc: ref(false),
      tabs: ref(),
      updateVPC: async (vpc) => {
        await UPDATE_VPC({
          id: vpc.id,
          name: vpc.name,
          description: vpc.description,
          region: vpc.region,
          ip: vpc.ip_prefix,
          network_size: vpc.network_size
        })
      },
      headerload: ref([
        { text: "Name", value: "name" },
        { text: "IP Address", value: "ip" },
        // {
        //   text: "Action",
        //   value: "action",
        //   sortable: false,
        //   align: "center",
        //   width: 150,
        // },
      ]),
      loaddata: ref([
        {
          name: "Instance 1",
          spec: "2 vCPU/ 2GB RAM",
          status: "Running",
          ip: "192.168.100.0",
        },
      ]),
    };
  },
};
</script>