<template>
  <div>
    <v-row>
      <v-col cols="12" v-if="projectBilling.length">
        <v-card class="rounded-lg" flat>
          <v-container fluid class="pa-7">
            <v-row>
              <v-col cols="12" class="py-0">
                <p class="project-name">{{ projectBilling[0].project.name }}</p>
              </v-col>
              <v-col class="d-flex mb-2 pt-0">
                <div>
                  <p class="font-weight-bold fz-21 mb-4 mr-4">
                    Cost for {{ currentPeriod }}
                  </p>
                  <p>
                    This is the current cost for your usage this billing period. A
                    breakdown of your cost is available below.
                  </p>
                </div>
                <v-spacer />
                <div class="summary-billed">
                  <b class="font--text">Billed</b>
                  <p class="total-billed">{{ IDRformat.format(totalBilled) }}</p>
                </div>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <div class="total primary--text">
                </div>
              </v-col>
            </v-row>
            <br />
            <v-data-table
              class="datatable-services"
              :headers="servicesHeaders"
              :items="billServices"
              hide-default-footer
            >
              <template v-slot:item.billing_type="{ item }">
                <router-link
                  :to="`/billing/${projectID}/${item.billing_type}`"
                >{{ getBillingTypeLabel(item.billing_type) }}</router-link>
              </template>
              <template v-slot:item.amount="{ item }">
                {{ item.instances.length + item.root_disks.length + item.storages.length + item.object_storages.length || '-'  }}
              </template>
              <template v-slot:item.billed="{ item }">
                {{ item.billed ? IDRformat.format(Math.round(item.billed)) : '-' }}
              </template>
              <template v-slot:body.append>
                <tr style="border:none">
                  <td class="border-bottom-none"></td>
                  <td class="border-bottom-none"></td>
                  <td class="border-bottom-none">
                    <div>
                      <p class="font-weight-bold">Total</p>
                    </div>
                    <div class="d-flex flex-row justify-start">
                      {{ IDRformat.format(totalBilled) }}
                    </div>
                  </td>
                </tr>
              </template>
            </v-data-table>
          </v-container>
        </v-card>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <v-card class="rounded-lg pa-4 mt-3" flat>
          <v-card-text class="d-flex flex-row align-center">
            <div class="headline font-weight-bold font--text">
              History Billing
            </div>
            <v-spacer />

            <div style="height: auto; width: 20px" />
            <!-- <v-btn
              class="secondary"
              dark
              depressed
              height="45"
              width="111"
              style="font-size: 14px"
            >
              Download
            </v-btn> -->
          </v-card-text>


          <v-card-text>
            <v-data-table 
              hide-default-footer 
              :headers="historyHeaders"
              :items="sortedBillingHistory"
              :items-per-page="10"
            >
              <template v-slot:item.billed="{ item }">
                {{ IDRformat.format(Math.round(item.billed)) }}
              </template>
              <template v-slot:item.date="{ item }">
                {{ moment(item.date).format('DD/MM/YYYY') }}
              </template>
              <template v-slot:item.action="{ item }">
                <router-link
                  :to="`/billing/${item.project_id}/history/${item.date}`"
                >Detail</router-link>
              </template>
              <template v-slot:no-data>
                <div class="mt-8">
                  <p class="font-weight-bold fz-16">
                    Looks like you don’t have any History Billing
                  </p>
                  <p class="accent-text font-weight-light fz-12">
                    Build and release faster with scalable instance products in the cloud. Provides flexible server configurations sized for any application, industry leading price to performance, and predictable pricing that is the same across regions and usage volumes.
                  </p>
                </div>
              </template>
              <template
                v-slot:footer="{ props }"
                v-if="sortedBillingHistory.length"
              >
                <custom-footer-datatable  :props="props" />
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <!-- <billingdetail /> -->
    <!-- <dialogInstanceDetail
      title="Instance Detail"
    />
    <dialogInstanceDetail
      title="Storage Detail"
    /> -->
    <!-- <dialogSpecificationinfo v-model="opendialogSpecificationinfo" /> -->
  </div>
</template>
<script>

import billingdetail from "./billingdetail";
import { ref, watch, onMounted, computed, reactive } from "@vue/composition-api";
import {
  useNamespacedGetters,
  useNamespacedState,
  useNamespacedActions,
} from "vuex-composition-helpers";
import { BILLING } from "./namespace";
import Vue from "vue";
import { IDRformat } from "@/lib/formatter";
import dialogInstanceDetail from "./dialogInstanceDetail";
import dialogSpecificationinfo from "./dialogSpecificationInfo";
const html2pdf = require("html2pdf.js");
import moment from 'moment'
import project from '@/lib/project';

const isDevModeChargingForSnapshot = true

export default {
  components: {
    // billingdetail,
    // dialogInstanceDetail,
    // dialogSpecificationinfo,
  },

  data: () => ({
    dates: {
      start: new Date(),
      end: new Date(),
    },
  }),
  computed: {
    dateRangeText() {
      return this.dates.join(" - ");
    },
  },

  setup(props, context) {
    const { id: projectID } = context.root.$route.params
    const { 
      fetchBillingOrganization,
      fetchBillingHistory,
    } = useNamespacedActions('BILLING', [
      'fetchBillingOrganization',
      'fetchBillingHistory',
    ])

    const { 
      projectBilling,
      billing,
    } = useNamespacedState('BILLING', [
      'projectBilling',
      'billing',
    ])

    const historyHeaders = ref([
      { text: 'Date', value: 'date' },
      { text: 'Billed', value: 'billed' },
      { text: 'Action', value: 'action', width: 100 },
    ])

    const servicesHeaders = ref([
      { text: 'Billing Type', value: 'billing_type' },
      { text: 'Amount of Service', value: 'amount' },
      { text: 'Billed', value: 'billed' },
    ])

    const totalBilled = ref(0)

    let billServices = ref([])
    let services = reactive({
      ppm: {
        billing_type: 'PPM',
        billed: 0,
        instances: [],
        storages: [],
        object_storages: [],
        root_disks: [],
      },
      ppu: {
        billing_type: 'PPU',
        billed: 0,
        instances: [],
        storages: [],
        object_storages: [],
        root_disks: [],
      },
      other: {
        billing_type: 'Other',
        billed: 0,
        instances: [],
        storages: [],
        object_storages: [],
        root_disks: [],
        snapshots: [],
        floating_ips: [],
      },
    })

    const resetDataServices = () => {
        services.ppm.billing_type = 'PPM'
        services.ppm.billed = 0
        services.ppm.instances = []
        services.ppm.storages = []
        services.ppm.object_storages = []
        services.ppm.root_disks = []

        services.ppu.billing_type = 'PPU'
        services.ppu.billed = 0
        services.ppu.instances = []
        services.ppu.storages = []
        services.ppu.object_storages = []
        services.ppu.root_disks = []

        services.other.billing_type = 'Other'
        services.other.billed = 0
        services.other.instances = []
        services.other.storages = []
        services.other.object_storages = []
        services.other.root_disks = []
        services.other.snapshots = []
        services.other.floating_ips = []
    }

    const getBillingTypeLabel = (type) => {
      if (type == 'PPU') return 'Pay Per Use'
      else if (type == 'PPM') return 'Pay Per Metrics'
      else if (type == 'Other') return 'At Once'
    }

    const sortedBillingHistory = ref([])

    const currentPeriod = ref('')
    let from = ref(null)
    let to = ref(null)

    onMounted(async () => {
      await fetchBillingOrganization()
      await fetchBillingHistory({
        project_id: projectID,
        start: billing.value.date_start,
        end: billing.value.date_end,
      })
      from.value = moment(billing.value.date_start)
      to.value = moment(billing.value.date_end)
      currentPeriod.value = `${from.value.format('DD MMMM YYYY')} - ${to.value.format('DD MMMM YYYY')}`

      await resetDataServices()
      projectBilling.value.map(dailyBill => {
        if (dailyBill.instances) {
          dailyBill.instances.filter(instance => !['Fixed', 'Trial'].includes(instance.billing_type)).map(instance => {
            let billingType = ['PPM', 'PPU'].includes(instance.billing_type) ? instance.billing_type.toLowerCase() : 'other'
            let index = services[billingType].instances.findIndex(x => x.instance_id == instance.instance_id)
            if (index >= 0) {
              if (instance.shift_to_monthly) {
                services[billingType].instances[index] = instance.package_instance.price_per_month
              } else {
                let prevBill = services[billingType].instances[index].billed
                instance.billed += prevBill
                services[billingType].instances[index] = instance
              }
            } else {
              if (instance.shift_to_monthly) instance.billed = instance.package_instance.price_per_month
              services[billingType].instances.push(instance)
            }
          })
        }

        if (dailyBill.storages) {
          dailyBill.storages.map(storage => {
            let billingType = 'ppu'
            let index = services[billingType].storages.findIndex(x => x.storage_id == storage.storage_id)
            if (index >= 0) {
              let prevBill = services[billingType].storages[index].billed
              storage.billed += prevBill
              services[billingType].storages[index] = storage
            } else services[billingType].storages.push(storage)
          })
        }

        if (dailyBill.root_disks) {
          dailyBill.root_disks.map(storage => {
            let billingType = 'ppu'
            let index = services[billingType].root_disks.findIndex(x => x.instance_id == storage.instance_id)
            if (index >= 0) {
              let prevBill = services[billingType].root_disks[index].billed
              storage.billed += prevBill
              services[billingType].root_disks[index] = storage
            } else services[billingType].root_disks.push(storage)
          })
        }

        if (dailyBill.object_storages) {
          dailyBill.object_storages.map(object_storage => {
            let billingType = 'ppu'
            let index = services[billingType].object_storages.findIndex(x => x.object_storage_id == object_storage.object_storage_id)
            if (index >= 0) {
              let prevBill = services[billingType].object_storages[index].billed
              object_storage.billed += prevBill 
              services[billingType].object_storages[index] = object_storage
            } else services[billingType].object_storages.push(object_storage)
          })
        }

        if (isDevModeChargingForSnapshot && dailyBill.other.snapshot && dailyBill.other.snapshot.history && dailyBill.other.snapshot.history.length) {
          dailyBill.other.snapshot.history.map(snapshot => {
            let billingType = 'other'
            services[billingType].snapshots.push(snapshot)
          })
        }

        if (isDevModeChargingForSnapshot && dailyBill.other.floating_ip && dailyBill.other.floating_ip.history && dailyBill.other.floating_ip.history.length) {
          dailyBill.other.floating_ip.history.map(floating_ip => {
            let billingType = 'other'
            services[billingType].floating_ips.push(floating_ip)
          })
        }
      })
      services.ppu.billed = (services.ppu.instances.reduce((total, item) => total + item.billed, 0)) + (services.ppu.root_disks.reduce((total, item) => total + item.billed, 0)) + (services.ppu.storages.reduce((total, item) => total + item.billed, 0)) + (services.ppu.object_storages.reduce((total, item) => total + item.billed, 0))
      services.ppm.billed = (services.ppm.instances.reduce((total, item) => total + item.billed, 0))
      const snapshotTotal = services.other.snapshots.reduce((total, item) => total + item.billed, 0)
      const floatingIPTotal = services.other.floating_ips.reduce((total, item) => total + item.billed, 0)
      services.other.billed = snapshotTotal + floatingIPTotal + (services.other.instances.reduce((total, item) => total + item.billed, 0)) + (services.other.root_disks.reduce((total, item) => total + item.billed, 0)) + (services.other.storages.reduce((total, item) => total + item.billed, 0)) + (services.other.object_storages.reduce((total, item) => total + item.billed, 0))

      totalBilled.value = Math.round(services.ppu.billed + services.ppm.billed + services.other.billed)
      billServices.value = [
        services.ppu,
        services.ppm,
        services.other,
      ]
      sortedBillingHistory.value = projectBilling.value.sort((a, b) => {
        if (a.date > b.date) return -1
        return 1
      })
    })
    
    return {
      projectID,
      fetchBillingHistory,
      totalBilled,
      projectBilling,
      sortedBillingHistory,
      billing,
      billServices,
      historyHeaders,
      servicesHeaders,
      currentPeriod,
      IDRformat,
      moment,
      vcalendar: ref(false),
      getBillingTypeLabel,
    };
  },
};
</script>
<style>
.border-bottom-none {
  border: none !important;
}
</style>
<style lang="scss" scoped>
.summary-billed {
  padding: 1.5rem;
  background: rgba(44, 148, 210, 0.02);
  border: 1px solid #2C94D2;
  border-radius: 10px;
  min-width: 300px;
}

.summary-billed p {
  font-size: 36px;
  color: #1F60A8;
  font-weight: 600;
  margin-bottom: 0;
}

.project-name {
  font-weight: bold;
  font-size: 21px;
  display: block;
  color: #1F60A8;
}

.history {
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 140%;
  color: #556272;
}
.total {
  font-style: normal;
  font-weight: 600;
  font-size: 36px;
  line-height: 140%;
  color: #556272;
}
.page-title {
  font-style: normal;
  font-weight: bold;
  font-size: 21px;
  line-height: 140%;
  color: #556272;
}

.page-content {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 140%;
  color: #556272;
}

.detail-btn {
  cursor: pointer;
}

.date {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 140%;
  color: #a5b3bf;
}

.amount {
  font-style: normal;
  font-weight: bold;
  line-height: 140%;
  color: #556272;
  font-size: 14px;
}

.detail {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 140%;
  color: #1f60a8;
}

.bill-title {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 160%;
  color: #556272;
}

.history-billing {
  table-layout: auto;
}

.history-billing tr {
  border-bottom: thin solid;
}

td {
  height: 60px !important;
}

.v-data-table {
  ::v-deep table {
    border-bottom: thin solid rgba(0, 0, 0, 0.12);
  }
}

.v-data-table > .v-data-table__wrapper > table > tbody > tr > td,
.v-data-table > .v-data-table__wrapper > table > thead > tr > td,
.v-data-table > .v-data-table__wrapper > table > tfoot > tr > td {
  font-size: 14px;
}
</style>