var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.selectedStorageSnapshot)?_c('v-dialog',{attrs:{"max-width":"500"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',[_c('div',{staticClass:"headline font-weight-bold font--text pb-4"},[_vm._v(" Create Storage from Snapshot ")])]),_c('v-card-text',{staticClass:"pb-0"},[_c('p',[_vm._v("Are you sure you want to create storage from snapshot "),_c('b',[_vm._v(_vm._s(_vm.selectedStorageSnapshot.name))]),_vm._v(" ?")]),_c('v-row',[_c('v-col',{staticClass:"pt-0",attrs:{"cols":"12"}},[_c('p',{staticClass:"font-weight-bold"},[_vm._v("Select Billing Type")]),_c('v-select',{attrs:{"id":"BillingType","items":_vm.billingTypes,"error-messages":_vm.createErrorMessage('selectedBillingType'),"outlined":"","placeholder":"Select Billing Type"},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.text)+" ")]}}],null,false,2149027369),model:{value:(_vm.selectedBillingType),callback:function ($$v) {_vm.selectedBillingType=$$v},expression:"selectedBillingType"}})],1),(_vm.selectedBillingType == 'Trial')?_c('v-col',{staticClass:"pt-0",attrs:{"cols":"12"}},[_c('p',[_c('b',[_vm._v("Trial Voucher")])]),_c('v-select',{attrs:{"id":"trialVoucher","placeholder":"Choose trial voucher to use","error-messages":_vm.createErrorMessage('selectedTrialVoucher'),"items":_vm.vouchers,"item-text":"voucher.name","item-value":"id","outlined":""},model:{value:(_vm.selectedTrialVoucher),callback:function ($$v) {_vm.selectedTrialVoucher=$$v},expression:"selectedTrialVoucher"}})],1):_vm._e(),_c('v-col',{staticClass:"pt-0",attrs:{"cols":"12"}},[_c('p',{staticClass:"mb-0",class:{ 'error--text': _vm.$v.selectedStorageSize.$errors.length }},[_c('span',{staticClass:"font-weight-bold"},[_vm._v("Select Volume Size")]),(_vm.$v.selectedStorageSize.$errors.length)?_c('span',{staticClass:"ml-2",staticStyle:{"font-size":"10px","position":"absolute"}},[_vm._v("*"+_vm._s(_vm.createErrorMessage('selectedStorageSize')))]):_vm._e()])]),_c('v-col',{staticClass:"d-flex pt-0 flex-wrap"},[(_vm.customSize)?_c('v-card',{class:("custom-flavor-card rounded-lg select-instance mb-4 " + (_vm.isCustomSizeHighlighted ? 'highlight selected' : '')),staticStyle:{"text-align":"center","margin-right":"20px"},attrs:{"width":"170","disabled":"","readonly":"","border-color":"primary","outlined":""}},[_c('v-card-text',{staticClass:"pa-0"},[_c('v-text-field',{staticClass:"px-4 pt-4",staticStyle:{"width":"170px"},attrs:{"max":"100","flat":"","label":"Enter Size in GB"},on:{"keydown":function (ev) { return _vm.customSize = _vm.addGBText(ev, _vm.customSize); },"click":function () {
                  _vm.selectedStorageSize = null
                  _vm.isCustomSizeHighlighted = true
                },"blur":function () { return _vm.isCustomSizeHighlighted = !_vm.customSize ? false : true; }},model:{value:(_vm.customSize),callback:function ($$v) {_vm.customSize=$$v},expression:"customSize"}})],1)],1):_vm._e(),_vm._l((_vm.filteredVolumeSizes.filter(function (x) { return x.volume_size == _vm.selectedStorageSnapshot.storage.size; })),function(storage){return _c('div',{key:storage.id},[(storage.volume_size)?_c('v-card',{staticClass:"rounded-lg select-instance mb-4",class:{ highlight: _vm.selectedStorageSize ? _vm.is(_vm.selectedStorageSize.id, storage.id) : false },staticStyle:{"text-align":"center","margin-right":"20px"},attrs:{"disabled":"","width":"170","border-color":"primary","outlined":""},on:{"click":function () {
                  _vm.selectedStorageSize = storage;
                  _vm.customSize = null
                  _vm.isCustomSizeHighlighted = false
                }}},[_c('v-card-text',{staticClass:"flavor-card"},[_c('p',{staticClass:"font-weight-bold mb-0"},[_vm._v(_vm._s(storage.volume_size)+" GB")])])],1):_vm._e()],1)})],2),_c('v-col',{staticClass:"pt-0",attrs:{"sm":"12"}},[_c('p',{staticClass:"font-weight-bold"},[_vm._v("Storage Name")]),_c('v-text-field',{attrs:{"maxlength":"50","id":"NameVolume","full-width":"","placeholder":"Type storage name","outlined":"","error-messages":_vm.createErrorMessage('storageName')},model:{value:(_vm.storageName),callback:function ($$v) {_vm.storageName=$$v},expression:"storageName"}})],1)],1)],1),_c('v-card-actions',[_c('v-row',{staticClass:"ma-2"},[_c('v-col',{attrs:{"cols":"6"}},[_c('v-btn',{attrs:{"color":"accent","block":"","depressed":"","height":"50"},on:{"click":function () {
                _vm.$emit('close');
              }}},[_vm._v(" Cancel ")])],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-btn',{attrs:{"color":"secondary","block":"","height":"50","depressed":""},on:{"click":_vm.doCreate}},[_vm._v(" Create Storage ")])],1)],1)],1)],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }