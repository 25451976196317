<template>
  <!-- <p>instance</p> -->
  <v-card class="rounded-lg elevation-0">
    <v-container fluid class="pa-7">
      <v-row justify="center">
        <v-col class="d-flex align-center" cols="8">
          <p class="mb-0 fz-21 font-weight-bold">List Project</p>
        </v-col>
        <v-col cols="4" class="d-flex justify-end align-center">
          <!-- <v-btn
            v-if="projects.length >0"
            depressed
            height="45"
            width="150"
            @click="
              () => {
                validatelimitcreateproject().then(() => {
                  CrateModal = true;
                });
              }
            "
            class="secondary"
          >
            <span style="font-weight: 700">Create New Project</span>
          </v-btn> -->
        </v-col>
      </v-row>
      <v-row v-if="isloading">
        <v-col class="text-center pa-15">
          <v-progress-circular
            :size="200"
            color="primary"
            indeterminate
          ></v-progress-circular>
        </v-col>
      </v-row>

      <v-row v-if="!isloading && projects.length">
        <v-col cols="4" v-for="project in projects" :key="project.id">
          <v-card
            outlined
            class="rounded-lg"
            style="border-color: #2c94d2; height: 100%; min-height: 213px"
          >
            <v-card-text>
              <v-row>
                
                <v-col class="py-0 pr-0">
                  <div class="d-flex flex-row align-center">
                    <v-icon style="width: 30px; height: 30px"
                      >$vuetify.icons.projectIcon</v-icon
                    >
                    <router-link
                      class="ml-3"
                      @click.native="openProject(project.id)"
                      :to="{ path: `/project/${encodeUri(project.name)}/` }"
                    >
                      <span
                        style="
                          font-family: Fira Sans;
                          font-style: normal;
                          font-weight: 800;
                          font-size: 14px;
                          line-height: 120%;
                        "
                        class="primary--text"
                        >{{ project.name }}</span
                      >
                    </router-link>
                  </div>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" class="instanceinfo">
                  <v-row
                    ><v-col cols="4"><p>Project ID</p></v-col
                    ><v-col cols="8"
                      ><p>{{ project.id }}</p></v-col
                    ></v-row
                  >

                  <v-row
                    ><v-col cols="4"><p>Description</p></v-col
                    ><v-col cols="8"
                      ><p style="text-align: justify">
                        {{ project.description }}
                      </p></v-col
                    ></v-row
                  >
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row v-if="!isloading && !projects.length">
        <v-col>
          <v-card outlined flat class="pa-8 rounded-lg">
            <div class="text-center">
              <div class="font-weight-bold font--text fz-16">
                <span
                  >It's look like You don't have any project</span
                >
              </div>
              <div style="height:30px" />
              <v-row>
                <v-col cols="3"></v-col>
                <v-col cols="6">
                  <v-btn class="primary px-16" depressed @click="
              () => {
                validatelimitcreateproject().then(() => {
                  CrateModal = true;
                });
              }
            ">
                    Create New Project
                  </v-btn>
                </v-col>
              </v-row>
            </div>
          </v-card>
        </v-col>
      </v-row>
      <v-menu
        v-model="showMenu"
        :position-x="x - 10"
        :position-y="y"
        absolute
        allow-overflow
      >
        <v-list>
          <v-list-item>
            <v-list-item-title>Add Floating IP</v-list-item-title>
          </v-list-item>
          <v-list-item>
            <v-list-item-title>Access Console</v-list-item-title>
          </v-list-item>
          <v-list-item>
            <v-list-item-title>Resize Instance</v-list-item-title>
          </v-list-item>
          <v-list-item>
            <v-list-item-title>View Usage</v-list-item-title>
          </v-list-item>
          <v-list-item>
            <v-list-item-title>Enable Backup</v-list-item-title>
          </v-list-item>
          <v-list-item>
            <v-list-item-title>Create Snapshot</v-list-item-title>
          </v-list-item>
          <v-list-item><v-divider /></v-list-item>
          <v-list-item>
            <v-list-item-title>Delete Instance</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-container>
  </v-card>
</template>
<script>
import { onMounted, ref } from "@vue/composition-api";
import { INSTANCE } from "../instance/namespace";
import {
  useNamespacedActions,
  useNamespacedState,
} from "vuex-composition-helpers";
import { PROJECT } from "@/modules/project/namespace";
import createproject from "./createproject";
import { useCreateProject } from "./useCreateProject";
export default {
  setup() {
    const { openModal } = createproject();
    const { fetchprojects } = useNamespacedActions(PROJECT, ["fetchprojects"]);
    const isloading = ref(true)
    onMounted(() => {
      fetchprojects();
      isloading.value = false
    });
    // const { instances } = useNamespacedState(INSTANCE, ['instances'])
    const { projects } = useNamespacedState(PROJECT, ["projects"]);

    const selected = ref("grid");
    const { changeProjectTo } = useNamespacedActions(PROJECT, ["changeProjectTo"]);
    const openProject = (id) => {
      changeProjectTo(id);
    };
    const { CrateModal } = useCreateProject();
    const { validatelimitcreateproject } = useNamespacedActions(PROJECT, [
      "validatelimitcreateproject",
    ]);
    return {
      validatelimitcreateproject,
      encodeUri: (val) => encodeURI(val),
      fetchprojects,
      CrateModal,
      openProject,
      projects,
      selected,
      openModal,
      isloading,
    };
  },

  data() {
    return {
      showMenu: false,
      headers: [
        { text: "Name", value: "name" },
        { text: "IP Address", value: "ipaddress" },
        { text: "Region", value: "region" },
        { text: "Created date", value: "createddate" },
        { text: "Tags", value: "tags" },
        { text: "Action", sortable: false, align: "center", value: "action" },
      ],

      x: 0,
      y: 0,
    };
  },
  methods: {
    
    show(e) {
      e.preventDefault();
      this.showMenu = false;
      this.x = e.clientX;
      this.y = e.clientY;
      this.$nextTick(() => {
        
        this.showMenu = true;
      });
    },
  },
  

};
</script>
<style lang="scss" scoped>
[role="menu"] {
  box-shadow: none;
  &::before {
    content: url(/tri.png);
    position: relative;
    left: 5px;
  }
  .v-list {
    border-radius: 5px;
    margin: -8px 2px 2px 2px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }
}
.v-icon {
  ::v-deep .v-icon__component {
    height: 30px;
    width: 30px;
  }
}
p {
  margin-bottom: 0px;
  .ipaddress {
    display: inline-block;
    width: 50px;
    position: relative;
    .tik {
      position: absolute;
      right: 10px;
      display: inline-block;
    }
  }
}
.select-view {
  border: 6px solid #f2f2f2;
  border-radius: 4px;
  background-color: #f2f2f2;
  opacity: 0.5;
  &.selected {
    opacity: 1;
  }
}

.instanceinfo {
  &.col {
    padding-bottom: 0px;
  }
  .col:first-child {
    p {
      font-weight: 700;
    }
  }
  .col {
    padding: 0px 12px 0px 12px;
    font-size: 14px;
    margin-bottom: 15px;
  }
}
</style>