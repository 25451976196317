var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"margin-bottom":"3rem"}},[_c('v-data-table',{staticClass:"elevation-0",attrs:{"id":"DataTableListInstance","hide-default-footer":true,"server-items-length":_vm.instancePaginator.count,"search":_vm.instanceSearch,"options":_vm.instanceOptions,"headers":_vm.headers,"items":_vm.instances,"items-per-page":_vm.itemsPerPage},on:{"update:options":function($event){_vm.instanceOptions=$event}},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [(_vm.isLoading)?_c('v-progress-circular',{attrs:{"indeterminate":"","color":"primary"}}):_c('div',{staticClass:"my-7 text-center"},[_c('p',{staticClass:"font-weight-bold",staticStyle:{"font-size":"16px","margin-bottom":"10px","text-transform":"capitalize"}},[_vm._v(" Looks like you don’t have any Instances ")]),_c('v-row',{staticClass:"d-flex justify-center"},[_c('v-col',{staticClass:"text-center",staticStyle:{"color":"#a5b3bf"},attrs:{"cols":"12","lg":"6","md":"12"}},[_c('p',{staticStyle:{"font-size":"12px","color":"#a5b3bf","margin-bottom":"20px","font-weight":"normal"}},[_vm._v(" Build and release faster with scalable instance products in the cloud. Provides flexible server configurations sized for any application, industry leading price to performance, and predictable pricing that is the same across regions and usage volumes. ")])])],1),_c('v-btn',{staticClass:"secondary",attrs:{"to":"/instance/create","width":"100%","max-width":"300","height":"50","depressed":"","exact":""}},[_vm._v(" Create Instance ")])],1)]},proxy:true},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex instance-name",staticStyle:{"align-items":"center"}},[_c('v-tooltip',{attrs:{"top":"","color":"#333","transition":"slide-y-reverse-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[(item.is_deleting)?_c('beat-loader',{staticClass:"mr-2",attrs:{"isLoading":true,"color":'#eb5757',"size":'10px'}}):(item.error_message)?_c('v-icon',_vm._g(_vm._b({staticClass:"mr-3",attrs:{"color":"error"}},'v-icon',attrs,false),on),[_vm._v("mdi-close-circle")]):(!item.error_message && item.openstack_vm_uuid)?_c('v-icon',_vm._g(_vm._b({staticClass:"mr-3",attrs:{"color":_vm.getStatusColor(item)}},'v-icon',attrs,false),on),[_vm._v("mdi-circle")]):(!item.error_message && !item.openstack_vm_uuid)?_c('beat-loader',{staticClass:"mr-2",attrs:{"isLoading":true,"color":'#1f60a8',"size":'10px'}}):_vm._e()],1)]}}],null,true)},[(item.is_deleting)?_c('span',[_vm._v("Deleting")]):(item.error_message)?_c('span',[_vm._v(_vm._s(item.error_message))]):(!item.error_message && item.openstack_vm_uuid)?_c('span',[_vm._v(" "+_vm._s(item.state ? _vm.capitalizeFirstLetter(item.state.replaceAll('_', ' ')) : 'N/A'))]):(!item.error_message && !item.openstack_vm_uuid)?_c('span',[_vm._v("Building")]):_vm._e()]),_c('div',[(!item.openstack_vm_uuid || item.is_deleting)?_c('span',[_vm._v(_vm._s(item.instanceName))]):_c('router-link',{attrs:{"to":{ path: ("/project/" + _vm.projectname + "/" + (item.id) + "/") }}},[_c('span',{staticClass:"primary--text"},[_vm._v(_vm._s(item.instanceName))])]),(item.image)?_c('p',[_vm._v(_vm._s(item.image.name)+" "+_vm._s(item.image.file_system)+" "+_vm._s(item.image.version_number))]):_vm._e(),_c('p',[_vm._v(_vm._s(item.package.vcpu)+"vCPU / "+_vm._s(item.package.ram)+"GB RAM / "+_vm._s(item.package.root_disk)+"GB Root Disk ("+_vm._s(item.storage_type)+")")])],1)],1)]}},{key:"item.ipaddress",fn:function(ref){
var item = ref.item;
return [_c('table',{staticClass:"table-ip",staticStyle:{"border-bottom":"none"}},[(item.openstack_vm_uuid && item.ipv4)?_c('tr',[_c('td',{staticStyle:{"padding-top":"0 !important","padding-bottom":"0 !important"}},[_vm._v("Internal")]),_c('td',{staticStyle:{"padding-top":"0 !important","padding-bottom":"0 !important"}},[_c('span',{staticClass:"mx-2"},[_vm._v(":")]),_vm._v(" "+_vm._s(item.ipv4))])]):_vm._e(),(item.openstack_vm_uuid && item.ip_gateway)?_c('tr',[_c('td',{staticStyle:{"padding-top":"0 !important","padding-bottom":"0 !important"}},[_vm._v("Gateway")]),_c('td',{staticStyle:{"padding-top":"0 !important","padding-bottom":"0 !important"}},[_c('span',{staticClass:"mx-2"},[_vm._v(":")]),_vm._v(" "+_vm._s(item.ip_gateway))])]):_vm._e(),(item.openstack_vm_uuid &&item.ipext)?_c('tr',[_c('td',{staticStyle:{"padding-top":"0 !important","padding-bottom":"0 !important"}},[_vm._v("External")]),_c('td',{staticStyle:{"padding-top":"0 !important","padding-bottom":"0 !important"}},[_c('span',{staticClass:"mx-2"},[_vm._v(":")]),_vm._v(" "+_vm._s(item.ipext))])]):_vm._e()])]}},{key:"item.createddate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$moment(new Date(item.createddate)).format('DD/MM/YYYY'))+" ")]}},{key:"item.package_storage",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item)+" ")]}},{key:"item.billing_type",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getBillingTypeLabel(item.billing_type))+" ")]}},{key:"item.region",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.region.city.toUpperCase())+" ")]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('popupquote',{attrs:{"offsetTop":20,"coordinate":_vm.coordinate,"documentid":'popup' + item.id,"offset-y":"","allow-overflow":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('label',_vm._b({staticClass:"primary--text linkpointer",staticStyle:{"position":"relative","white-space":"nowrap"},on:{"click":function ($event) {
                on.click($event);
                _vm.show($event, item);
              }}},'label',attrs,false),[_vm._v("More "),_c('v-icon',{class:{
                rotate: item.id == _vm.activemenuinstance.id && _vm.popo,
              },attrs:{"id":'popup' + item.id,"color":"primary"}},[_vm._v("mdi-chevron-down ")])],1)]}}],null,true),model:{value:(_vm.popo),callback:function ($$v) {_vm.popo=$$v},expression:"popo"}},[_c('v-list',[(!item.floating_ip)?_c('v-list-item',{attrs:{"disabled":_vm.disabledField 
              || _vm.isFloatingIPLoading
              || !item.openstack_vm_uuid
              || item.is_deleting
              || ![null, ''].includes(item.revert_expired)
              || !['ACTIVE', 'SHUTOFF'].includes(item.state),"exact":""},on:{"click":function () {
              _vm.assignFloatingIP({ id: item.id })
            }}},[_c('v-list-item-title',[_vm._v("Add Floating IP")])],1):_c('v-list-item',{attrs:{"disabled":_vm.disabledField
              || _vm.isFloatingIPLoading
              || ![null, ''].includes(item.revert_expired)
              || !['ACTIVE', 'SHUTOFF'].includes(item.state),"exact":""},on:{"click":function () {
              _vm.unassignFloatingIP(item.floating_ip.id)
            }}},[_c('v-list-item-title',[_vm._v("Unassign Floating IP")])],1),_c('v-list-item',{attrs:{"disabled":_vm.disabledField 
              || !_vm.activemenuinstance.openstack_vm_uuid
              || _vm.activemenuinstance.is_deleting
              || ![null, ''].includes(_vm.activemenuinstance.revert_expired)
              || !['ACTIVE', 'SHUTOFF'].includes(_vm.activemenuinstance.state)},on:{"click":function () {
                _vm.openConsole(
                  item.project_id,
                  item.openstack_vm_uuid
                );
              }}},[_c('v-list-item-title',[_vm._v("Access Console")])],1),_c('v-list-item',{attrs:{"disabled":_vm.disabledField 
              || !_vm.activemenuinstance.openstack_vm_uuid 
              || _vm.activemenuinstance.is_deleting 
              || ![null, ''].includes(_vm.activemenuinstance.revert_expired)
              || !['ACTIVE', 'SHUTOFF'].includes(_vm.activemenuinstance.state),"to":'/instance/' + _vm.activemenuinstance.id + '/resize',"exact":""}},[_c('v-list-item-title',[_vm._v("Resize Instance")])],1),_c('v-list-item',{attrs:{"disabled":_vm.disabledField 
              || !_vm.activemenuinstance.openstack_vm_uuid 
              || _vm.activemenuinstance.is_deleting 
              || ![null, ''].includes(_vm.activemenuinstance.revert_expired)
              || !['ACTIVE', 'SHUTOFF'].includes(_vm.activemenuinstance.state),"exact":""},on:{"click":function($event){_vm.openResizeRootDisk = true}}},[_c('v-list-item-title',[_vm._v("Resize Root Disk")])],1),_c('v-list-item',{attrs:{"disabled":_vm.disabledField 
              || !_vm.activemenuinstance.openstack_vm_uuid
              || _vm.activemenuinstance.is_deleting
              || ![null, ''].includes(_vm.activemenuinstance.revert_expired)
              || !['ACTIVE', 'SHUTOFF'].includes(_vm.activemenuinstance.state),"to":'/instance/' + _vm.activemenuinstance.id + '/usage'}},[_c('v-list-item-title',[_vm._v("View Usage")])],1),_c('v-list-item',{attrs:{"disabled":"","to":'/instance/' + _vm.activemenuinstance.id + '/backups'}},[_c('v-list-item-title',[_vm._v("Enable Backup")])],1),_c('v-list-item',{staticStyle:{"border-bottom":"1px solid lightgray"},attrs:{"disabled":_vm.disabledField
              || !_vm.activemenuinstance.openstack_vm_uuid
              || _vm.activemenuinstance.is_deleting
              || ![null, ''].includes(_vm.activemenuinstance.revert_expired)
              || !['ACTIVE', 'SHUTOFF'].includes(_vm.activemenuinstance.state),"to":'/instance/' + _vm.activemenuinstance.id + '/snapshots'}},[_c('v-list-item-title',[_vm._v("Create Snapshot")])],1),_c('v-list-item',{attrs:{"disabled":_vm.disabledField || _vm.activemenuinstance.is_deleting},on:{"click":function () {
                _vm.validateprivilages(['Instance', 'editor']).then(
                  function () {
                    _vm.opendialogdelete = true;
                  }
                );
              }}},[_c('v-list-item-title',{staticClass:"error--text"},[_vm._v("Delete Instance ")])],1)],1)],1)]}},(_vm.instances.length)?{key:"footer",fn:function(ref){
              var props = ref.props;
return [_c('custom-footer-datatable',{attrs:{"props":props},on:{"input":function (val) { return _vm.instanceOptions = val; }}})]}}:null],null,true)}),(_vm.activemenuinstance)?_c('dialogDeleteInstance',{attrs:{"selectedinstance":_vm.activemenuinstance},model:{value:(_vm.opendialogdelete),callback:function ($$v) {_vm.opendialogdelete=$$v},expression:"opendialogdelete"}}):_vm._e(),(_vm.activemenuinstance)?_c('dialogResizeRootDisk',{attrs:{"selectedInstance":_vm.activemenuinstance},on:{"close":function($event){_vm.openResizeRootDisk = false}},model:{value:(_vm.openResizeRootDisk),callback:function ($$v) {_vm.openResizeRootDisk=$$v},expression:"openResizeRootDisk"}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }