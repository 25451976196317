<template>
  <div>
    <!-- contoh template -->
    <v-row>
      <v-col cols="12">
        <v-card flat class="rounded-lg">
          <v-container class="pa-4">
            <v-row>
              <v-col cols="12">
                <div class="headline font-weight-bold font--text">
                  Create Instance
                </div>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="d-flex flex-row">
                <div
                  id="SelectOperatingSystem"
                  :style="{
                    border: `2px solid ${
                      selecttypeinstance == 'operatingsystem'
                        ? '#2C94D2'
                        : '#BDBDBD'
                    }`,
                    width: '233px',
                    cursor: 'pointer',
                  }"
                  @click="
                    () => {
                      selecttypeinstance = 'operatingsystem';
                    }
                  "
                  class="d-flex align-center flex-row px-4 rounded-lg"
                >
                  <v-radio-group v-model="selecttypeinstance">
                    <v-radio value="operatingsystem"></v-radio>
                  </v-radio-group>
                  <p style="font-size: 16px" class="mb-0 font-weight-bold">
                    Operating System
                  </p>
                </div>
                <div style="width: 30px; height: auto" />
                <div
                  :style="{
                    border: `2px solid ${
                      selecttypeinstance == 'instanceimage'
                        ? '#2C94D2'
                        : '#BDBDBD'
                    }`,
                    width: '233px',
                    cursor: 'pointer',
                  }"
                  @click="
                    () => {
                      selecttypeinstance = 'instanceimage';
                    }
                  "
                  class="d-flex align-center flex-row px-4 rounded-lg"
                >
                  <v-radio-group v-model="selecttypeinstance">
                    <v-radio value="instanceimage"></v-radio>
                  </v-radio-group>
                  <p style="font-size: 16px" class="mb-0 font-weight-bold">
                    Instance Image
                  </p>
                </div>
              </v-col>
            </v-row>
            <small class="error--text">
              {{
                $v.selecttypeinstance.$errors.length
                  ? $v.selecttypeinstance.$errors[0].$message
                  : ""
              }}
            </small>
            <!-- new ui/ux select os -->
            <v-row v-if="selecttypeinstance == 'operatingsystem'">
              <v-col cols="12" class="mb-0 pb-0">
                <p class="font-weight-bold">
                  Select Operating System & Version
                </p>
              </v-col>

              <v-col class="d-flex flex-wrap pt-0 pl-0 ml-0">
                <v-sheet max-width="100%" class="mt-0 pl-0 ml-0">
                  <v-slide-group
                    class="py-2 pt-0 ma-0"
                    active-class="success"
                    show-arrows
                  >
                    <v-card
                      v-for="os in distinctOS"
                      :key="os.value"
                      @click="
                        () => {
                          osSelected = os.value;
                        }
                      "
                      border-color="primary"
                      outlined
                      class="rounded-lg select-instance pb-0 "
                      :class="{
                        selected: isSelected(os.value),
                      }"
                      style="margin-right: 20px; min-width:170px; max-width:210px; max-height:210px;"
                      :style="{
                        border: `2px solid ${
                          os.value === osSelected ? '#2C94D2' : '#BDBDBD'
                        }`,
                        width: '233px',
                        cursor: 'pointer',
                      }"
                    >
                      <!-- :style="{
                        border: $v.osSelected.$errors.length
                          ? '1px solid #eb5757 !important'
                          : '',
                      }" -->
                      <!-- <v-overlay
                        :absolute="true"
                        :value="os.value !== osSelected"
                        :opacity="0.1"
                        color="#000000"
                      ></v-overlay> -->
                      <v-card-text class="pb-0 px-2 text-center">
                        <div class="pa-0 ma-0 d-flex justify-center">
                          <v-img
                            style="max-height: 75px; max-width: 75px;"
                            :aspect-ratio="1 / 1"
                            :src="os.logo"
                            :alt="os.text"
                            class="mb-2"
                          />
                        </div>
                        <div class="pa-0 ma-0">
                          <p
                            class="font-weight-bold "
                            :style="{
                              color: `${
                                os.value === osSelected ? '#2C94D2' : '#BDBDBD'
                              }`,
                            }"
                          >
                            {{ os.text }}
                          </p>
                        </div>
                      </v-card-text>
                      <v-divider
                        :style="{
                          border: `1px solid ${
                            os.value === osSelected ? '#2C94D2' : '#BDBDBD'
                          }`,
                          width: '233px',
                          cursor: 'pointer',
                          color: `${
                            os.value === osSelected ? '#2C94D2' : '#BDBDBD'
                          }`,
                          background: `${
                            os.value === osSelected ? '#2C94D2' : '#BDBDBD'
                          }`,
                        }"
                        class="mb-1"
                      />
                      <v-card-text class="ma-0 pa-0 ">
                        <v-select
                          v-if="os.value === osSelected"
                          id="SelectVersion"
                          v-model="versionSelected"
                          :error-messages="
                            $v.versionSelected.$errors.length
                              ? $v.versionSelected.$errors[0].$message
                              : ''
                          "
                          :items="
                            images
                              .filter((x) => x.os_id === osSelected)
                              .map((x) => {
                                return {
                                  text: `${x.version_number} ${x.version_name}`,
                                  value: x.id,
                                };
                              })
                          "
                          class="ma-0 px-0 py-0 select-version"
                          placeholder="Select Version"
                          :style="{
                            color: `${
                              os.value === osSelected ? '#2C94D2' : '#BDBDBD'
                            }`,
                          }"
                          outlined
                        >
                          <template
                            class="pa-0 ma-0"
                            v-slot:selection="{ item }"
                          >
                            <p
                              class="font-weight-medium mb-0 my-0 pa-0"
                              :style="{
                                color: `${
                                  os.value === osSelected
                                    ? '#2C94D2'
                                    : '#BDBDBD'
                                }`,
                              }"
                            >
                              {{ item.text }}
                            </p>
                          </template>
                          <template
                            class="rounded-xl rounded-t-0"
                            v-slot:item="{ item }"
                          >
                            {{ item.text }}
                          </template>
                        </v-select>
                        <v-select
                          v-else
                          id="SelectVersion"
                          :error-messages="
                            $v.versionSelected.$errors.length
                              ? $v.versionSelected.$errors[0].$message
                              : ''
                          "
                          :items="
                            images
                              .filter((x) => x.os_id === osSelected)
                              .map((x) => {
                                return {
                                  text: `${x.version_number} ${x.version_name}`,
                                  value: x.id,
                                };
                              })
                          "
                          class="ma-0 px-0 py-0 select-version"
                          placeholder="Select Version"
                          :style="{
                            color: `${
                              os.value === osSelected ? '#2C94D2' : '#BDBDBD'
                            }`,
                          }"
                          outlined
                        >
                          <template
                            class="pa-0 ma-0"
                            v-slot:selection="{ item }"
                          >
                            <p
                              class="font-weight-medium mb-0 my-0 pa-0"
                              :style="{
                                color: `${
                                  os.value === osSelected
                                    ? '#2C94D2'
                                    : '#BDBDBD'
                                }`,
                              }"
                            >
                              {{ item.text }}
                            </p>
                          </template>
                          <template class="" v-slot:item="{ item }">
                            {{ item.text }}
                          </template>
                        </v-select>
                      </v-card-text>
                    </v-card>
                  </v-slide-group>
                </v-sheet>
              </v-col>
            </v-row>

            <!-- old ui/ux select os -->
            <!-- <v-row v-if="selecttypeinstance == 'operatingsystem'">
              <v-col cols="12" class="pb-0">
                <p class="font-weight-bold">Select Operating System</p>
              </v-col>
              <v-col sm="12" md="4" class="pt-0">
                <v-select
                  id="SelectOS"
                  v-model="osSelected"
                  outlined
                  :items="distinctOS"
                  :error-messages="
                    $v.osSelected.$errors.length
                      ? $v.osSelected.$errors[0].$message
                      : ''
                  "
                  placeholder="Select OS"
                >
                </v-select>
              </v-col>
              <v-col sm="12" md="4" class="pt-0">
                <v-select
                  id="SelectVersion"
                  v-model="versionSelected"
                  :disabled="!osSelected"
                  :error-messages="
                    $v.versionSelected.$errors.length
                      ? $v.versionSelected.$errors[0].$message
                      : ''
                  "
                  outlined
                  :items="
                    images
                      .filter((x) => x.os_id === osSelected)
                      .map((x) => {
                        return {
                          text: x.version_number,
                          value: x.version_number,
                        };
                      })
                  "
                  placeholder="Select Version"
                >
                </v-select>
              </v-col>
              <v-col sm="12" md="4" class="pt-0">
                <v-select
                  id="SelectBitVersion"
                  v-model="bitVersionSelected"
                  :disabled="!osSelected || !versionSelected"
                  :error-messages="
                    $v.versionSelected.$errors.length
                      ? $v.versionSelected.$errors[0].$message
                      : ''
                  "
                  outlined
                  :items="
                    images
                      .filter(
                        (x) =>
                          x.os_id === osSelected &&
                          x.version_number === versionSelected
                      )
                      .map((x) => {
                        return {
                          text: x.version_name,
                          value: x.id,
                        };
                      })
                  "
                  placeholder="Select Bit Version"
                >
                </v-select>
              </v-col>
            </v-row> -->

            <v-row v-if="selecttypeinstance == 'instanceimage'">
              <v-col sm="12" md="6">
                <p class="font-weight-bold">Instance Image</p>
                <v-select
                  id="selectCustomImages"
                  v-model="selectedCustomImage"
                  :error-messages="
                    $v.selectedCustomImage.$errors.length
                      ? $v.selectedCustomImage.$errors[0].$message
                      : ''
                  "
                  outlined
                  :items="selectCustomImages"
                  placeholder="Choose Custom Image"
                >
                </v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col sm="12" md="6" class="pt-0">
                <p class="font-weight-bold">Choose Region</p>
                <v-select
                  id="SelectRegion"
                  v-model="selectedRegion"
                  outlined
                  :items="selectregions"
                  placeholder="Choose Region"
                >
                </v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col md="6" sm="12" class="pt-0">
                <p class="font-weight-bold">VPC Network</p>
                <v-select
                  id="VPCNetwork"
                  placeholder="Select VPC Network"
                  v-model="vpcidselected"
                  :error-messages="
                    $v.vpcidselected.$errors.length
                      ? $v.vpcidselected.$errors[0].$message
                      : ''
                  "
                  :items="vpcItems"
                  outlined
                ></v-select>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" md="6" class="pt-0">
                <p><b>Instance Billing Type</b></p>
                <v-select
                  id="billingType"
                  placeholder="Choose Instance Billing Type"
                  v-model="selectedBillingType"
                  :error-messages="
                    $v.selectedBillingType.$errors.length
                      ? $v.selectedBillingType.$errors[0].$message
                      : ''
                  "
                  :items="billingTypes"
                  outlined
                ></v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col
                cols="12"
                md="6"
                class="pt-0"
                v-if="selectedBillingType == 'Trial'"
              >
                <p><b>Trial Voucher</b></p>
                <v-select
                  id="trialVoucher"
                  placeholder="Choose trial voucher to use"
                  v-model="selectedTrialVoucher"
                  :error-messages="
                    $v.selectedTrialVoucher.$errors.length
                      ? $v.selectedTrialVoucher.$errors[0].$message
                      : ''
                  "
                  :items="vouchers"
                  item-text="voucher.name"
                  item-value="id"
                  outlined
                ></v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="6" class="py-0">
                <p><b>Choose a Plan</b></p>
                <v-select
                  id="chooseaplan"
                  placeholder="Choose a Plan"
                  v-model="planSelected"
                  :disabled="!selectedRegion"
                  :error-messages="
                    $v.planSelected.$errors.length
                      ? $v.planSelected.$errors[0].$message
                      : ''
                  "
                  :items="plans"
                  outlined
                ></v-select>
              </v-col>
            </v-row>

            <!-- new slider for instance size -->
            <v-row>
              <v-col
                v-if="filteredFlavors && Object.keys(planSelected).length > 0"
                cols="12"
                class="mb-2"
              >
                <div>
                  <p class="mb-0">
                    <span class="font-weight-bold">Instance Size</span>
                  </p>
                </div>
              </v-col>

              <v-col class="d-flex flex-wrap pt-0" id="SelectInstanceSize">
                <v-card
                  v-if="
                    Object.keys(planSelected).length && !filteredFlavors.length
                  "
                  width="170"
                  height="100"
                  border-color="primary"
                  outlined
                  class="rounded-lg select-instance mb-5 mt-4"
                  style="margin-right: 20px"
                  disabled
                >
                  <v-card-text class="pb-0 text-center">
                    <p class="mb-0">
                      There is no available flavor for selected plan & region
                    </p>
                  </v-card-text>
                </v-card>

                <v-sheet
                  v-if="
                    Object.keys(planSelected).length && filteredFlavors.length
                  "
                  max-width="100%"
                  class="mt-0"
                >
                  <v-slide-group
                    class="py-4 pt-0"
                    active-class="success"
                    show-arrows
                  >
                    <v-card
                      v-for="instance in filteredFlavors"
                      :key="instance.id"
                      @click="
                        () => {
                          instanceselected = instance.id;
                        }
                      "
                      :disabled="instance.outOfStock"
                      border-color="primary"
                      outlined
                      class="rounded-lg select-instance"
                      :class="{
                        selected: isSelected(instance.id),
                      }"
                      style="margin-right: 20px; min-width:170px;"
                      :style="{
                        border: $v.instanceselected.$errors.length
                          ? '1px solid #eb5757 !important'
                          : '',
                      }"
                    >
                      <v-card-text class="m-0 p-0" style="padding: 20px">
                        <p class="fz-12 p-0" style="margin-bottom:0;">
                          <b>{{ instance.vcpu }} CPU</b> / {{ instance.ram }} GB
                          Memory
                          <br />
                        </p>
                        <p v-if="instance.gpu" class="fz-12 ma-0 p-0">
                          with <b>{{ instance.gpu }}</b> unit{{
                            instance.gpu > 1 ? "s" : ""
                          }}
                          Nvidia T4
                        </p>
                        <p
                          v-if="instance.outOfStock"
                          class="text-center mb-0"
                          style="font-size: 10px;"
                        >
                          Out of Stock
                        </p>
                      </v-card-text>
                    </v-card>
                  </v-slide-group>
                </v-sheet>
              </v-col>
            </v-row>

            <!-- old slider for instance size -->
            <!-- <v-row>
              <v-col
                v-if="masterInstances && Object.keys(planSelected).length > 0"
                cols="12"
              >
                <p class="mb-0">
                  <span class="font-weight-bold mr-5">Instance Size</span>
                </p>
              </v-col>
              <v-col
                style="margin-bottom: -20px"
                class="d-flex flex-wrap"
                id="SelectInstanceSize"
              >
                <v-card
                  v-if="
                    Object.keys(planSelected).length && !masterInstances.length
                  "
                  width="170"
                  height="100"
                  border-color="primary"
                  outlined
                  class="rounded-lg select-instance mb-5"
                  style="margin-right: 20px"
                  disabled
                >
                  <v-card-text class="pb-0 text-center">
                    <p class="font-weight-bold mb-0">
                      There is no available flavor for selected plan
                    </p>
                  </v-card-text>
                </v-card>
                <div
                  class="d-flex flex-wrap"
                  v-if="
                    Object.keys(planSelected).length && masterInstances.length
                  "
                >
                  <v-card
                    width="170"
                    v-for="instance in masterInstances"
                    :key="instance.id"
                    @click="
                      () => {
                        instanceselected = instance.id;
                      }
                    "
                    border-color="primary"
                    outlined
                    class="rounded-lg select-instance mb-5"
                    :class="{ selected: isSelected(instance.id) }"
                    style="margin-right: 20px"
                    :style="{
                      border: $v.instanceselected.$errors.length
                        ? '1px solid #eb5757 !important'
                        : '',
                    }"
                  >
                    <v-card-text class="pb-0 text-center">
                      <p class="font-weight-bold mb-0">
                          {{
                            `${IDRformat.format(
                              instance.vcpu * defaultcpu.price_per_month
                            )}/mo`
                          }}
                        </p>
                        <p>
                          {{
                            `${IDRformat.format(
                              instance.ram * defaultcpu.price_per_hours
                            )}/hour`
                          }}
                        </p>
                    </v-card-text>
                    <v-divider/>
                    <v-card-text class="m-0 p-0">
                      <p style="font-size: 12px; margin: 0; padding: 0">
                        <b> {{ instance.vcpu }} CPU </b>
                        / {{ instance.ram }} GB Memory

                        <br />
                        <b>{{ instance.root_disk }} GB</b> SSD

                        <span v-if="instance.gpu" class="fz-12 m-0 p-0">
                          with <b>{{ instance.gpu }}</b> unit{{
                            instance.gpu > 1 ? "s" : ""
                          }}
                          Nvidia T4
                        </span>
                      </p>
                    </v-card-text>
                  </v-card>
                </div>
                <span
                  class="mt-0 mb-5"
                  style="color: #eb5757"
                  v-if="$v.instanceselected.$errors.length"
                >
                  Please select one instance size
                </span>
              </v-col>
            </v-row> -->

            <!-- <v-row>
              <v-col cols="12" md="6" class="pt-0">
                <p><b>Volume Type</b></p>
                <v-select
                  id="billingType"
                  placeholder="Choose Volume Type"
                  v-model="selectedVolumeType"
                  :error-messages="
                    $v.selectedVolumeType.$errors.length
                      ? $v.selectedVolumeType.$errors[0].$message
                      : ''
                  "
                  :items="volumeTypes"
                  outlined
                ></v-select>
              </v-col>
            </v-row> -->

            <v-row v-if="isDevModeStorageTiering2">
              <v-col cols="12" md="6" class="pt-0">
                <p><b>Storage Type</b></p>
                <v-select
                  id="vm_root_disk_type_id"
                  placeholder="Choose Storage Type"
                  v-model="vm_root_disk_type_id"
                  :error-messages="
                    $v.vm_root_disk_type_id &&
                    $v.vm_root_disk_type_id.$errors.length
                      ? $v.vm_root_disk_type_id.$errors[0].$message
                      : ''
                  "
                  :items="volumeTypes"
                  outlined
                />
              </v-col>
            </v-row>

            <v-row>
              <v-col class="py-0">
                <p class="font-weight-bold">
                  Instance Root Disk
                  <span
                    class="primary--text ml-2"
                    style="font-size: 12px;"
                    v-if="useRootDiskFromSnapshot"
                  >
                    *Instance root disk size automatically uses the size of root
                    disk in the selected snapshot.
                  </span>
                  <span
                    :class="{
                      'error--text': $v.rootDiskSelected.$errors.length,
                    }"
                    class="ml-2"
                    style="font-size: 10px;"
                    v-if="$v.rootDiskSelected.$errors.length"
                  >
                    *{{ $v.rootDiskSelected.$errors[0].$message }}
                  </span>
                  <span
                    :class="{
                      'error--text': $v.customRootDiskSize.$errors.length,
                    }"
                    class="ml-2 pt-1"
                    style="font-size: 10px;"
                    v-if="$v.customRootDiskSize.$errors.length"
                  >
                    *{{ $v.customRootDiskSize.$errors[0].$message }}
                  </span>
                </p>
              </v-col>
            </v-row>

            <v-row>
              <v-slide-group
                class="py-4 pt-0"
                active-class="success"
                show-arrows
              >
                <v-col class="d-flex py-0 flex-wrap">
                  <v-card
                    tabindex="0"
                    min-width="170"
                    border-color="primary"
                    outlined
                    :readonly="useRootDiskFromSnapshot"
                    :class="
                      `custom-flavor-card rounded-lg select-instance mb-4 ${
                        isCustomRootDiskSizeHighlighted
                          ? 'highlighted selected'
                          : ''
                      }`
                    "
                    style="text-align: center; margin-right: 20px"
                    id="customSizeRootDisk"
                  >
                    <!-- <v-card-text class="pb-0 pt-5">
                      <p class="font-weight-bold mb-2">
                        {{
                          IDRformat.format(
                              defaultstoragesize[0].price_per_month *
                              (defaultstoragesize[0].size || 1)
                          )
                        }}/mo
                      </p>
                      <p>
                        {{
                          IDRformat.format(
                              defaultstoragesize[0].price_per_month *
                              (defaultstoragesize[0].size || 1)
                          )
                        }}/hour
                      </p>
                    </v-card-text> -->
                    <!-- <v-divider/> -->
                    <v-card-text class="pa-0">
                      <v-text-field
                        class="px-4 pt-2"
                        style="width: 170px"
                        flat
                        label="Enter Size in GB"
                        :disabled="useRootDiskFromSnapshot"
                        @keydown="
                          (ev) =>
                            (customRootDiskSize = addGBText(
                              ev,
                              customRootDiskSize
                            ))
                        "
                        v-model="customRootDiskSize"
                        @focus="
                          () => {
                            rootDiskSelected = null;
                            isCustomRootDiskSizeHighlighted = true;
                          }
                        "
                        @blur="
                          () => {
                            if (!customRootDiskSize)
                              isCustomRootDiskSizeHighlighted = false;
                          }
                        "
                      ></v-text-field>
                    </v-card-text>
                  </v-card>
                  <!-- <v-card
                    width="170"
                    :class="{
                      bordererror: $v.rootDiskSelected.$errors.length,
                      highlight: rootDiskSelected
                        ? is(rootDiskSelected.id, storage.id)
                        : false,
                      selected: rootDiskSelected
                        ? is(rootDiskSelected.id, storage.id)
                        : false,
                    }"
                    v-for="storage in selectedVolumeType ? volumeSize.filter((flavorStorage) => {
                      if (showCustomDiskFlavors)
                        return (
                          flavorStorage.status === 'active' &&
                          flavorStorage.volume_size
                        );
                      else
                        return (
                          flavorStorage.status === 'active' &&
                          flavorStorage.volume_size &&
                          !flavorStorage.organization_id
                        );
                      }).filter((size) => size.type_id === selectedVolumeType) : volumeSize.filter((flavorStorage) => {
                        if (showCustomDiskFlavors)
                          return (
                            flavorStorage.status === 'active' &&
                            flavorStorage.volume_size
                          );
                        else
                          return (
                            flavorStorage.status === 'active' &&
                            flavorStorage.volume_size &&
                            !flavorStorage.organization_id
                          );
                      })"
                    :key="storage.id"
                    @click="
                      () => {
                        rootDiskSelected = storage;
                        isCustomRootDiskSizeHighlighted = false;
                        customRootDiskSize = null;
                      }
                    "
                    border-color="primary"
                    outlined
                    class="rounded-lg select-instance mb-4 flavor-card"
                    style="text-align: center; margin-right: 20px"
                  > -->
                  <v-card
                    width="170"
                    :class="{
                      bordererror: $v.rootDiskSelected.$errors.length,
                      highlight: rootDiskSelected
                        ? is(rootDiskSelected.id, storage.id)
                        : false,
                      selected: rootDiskSelected
                        ? is(rootDiskSelected.id, storage.id)
                        : false,
                    }"
                    v-for="storage in vm_root_disk_type_id // if vm_root_disk_type_id selected
                      ? rootDiskVolumeSize.filter(
                          (v) => v.type_id === vm_root_disk_type_id
                        ) // else
                      : rootDiskVolumeSize"
                    :key="storage.id"
                    :disabled="useRootDiskFromSnapshot"
                    @click="
                      () => {
                        rootDiskSelected = storage;
                        isCustomRootDiskSizeHighlighted = false;
                        customRootDiskSize = null;
                      }
                    "
                    border-color="primary"
                    outlined
                    class="rounded-lg select-instance mb-4 flavor-card"
                    style="text-align: center; margin-right: 20px"
                  >
                    <!-- <v-card-text class="pb-0 pt-5">
                      <p class="font-weight-bold mb-4">
                        {{ IDRformat.format(storage.price_per_month) }}/mo
                      </p>
                    </v-card-text>
                    <v-divider/> -->
                    <v-card-text>
                      <p class="font-weight-bold mb-0">
                        {{ storage.volume_size }} GB
                      </p>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-slide-group>
            </v-row>

            <v-row v-if="!isDevModeStorageTiering2">
              <v-col class="pt-0">
                <p class="font-weight-bold">Instance Data Disk</p>
                <v-btn
                  @click="
                    () => {
                      addvolume = true;
                    }
                  "
                  depressed
                  style="border: 3px solid #1f60a8"
                  outlined
                  height="45"
                  width="150"
                  class="primary--text"
                  >Add Data Disk
                </v-btn>
              </v-col>
            </v-row>

            <v-row v-if="isDevModeStorageTiering2">
              <v-col>
                <p
                  style="font-size: 21px; margin-bottom: 10px"
                  class="font-weight-bold"
                >
                  Default Instance Data Disk
                </p>
              </v-col>
            </v-row>

            <template v-if="isDevModeStorageTiering2">
              <v-row>
                <v-col cols="12" md="5" class="pt-0">
                  <p><b>Storage Type</b></p>
                  <v-select
                    id="billingType"
                    placeholder="Choose Storage Type"
                    v-model="defaultDiskType"
                    :error-messages="defaultDiskTypeError"
                    :items="volumeTypes"
                    outlined
                  ></v-select>
                </v-col>

                <v-col cols="12" md="5" class="pt-0">
                  <p><b>Size</b></p>
                  <v-select
                    id="size"
                    placeholder="Choose Size"
                    v-model="defaultDiskSize"
                    :error-messages="defaultDiskSizeError"
                    :items="
                      defaultDiskType
                        ? dataDisks
                            .filter((disk) => disk.type_id === defaultDiskType)
                            .map((disk2) => {
                              return {
                                text: disk2.volume_size + ' GB',
                                value: disk2.id,
                              };
                            })
                        : dataDisks.map((disk2) => {
                            return {
                              text: disk2.volume_size + ' GB',
                              value: disk2.id,
                            };
                          })
                    "
                    outlined
                  />
                </v-col>

                <v-col cols="12" md="2" class="pt-0">
                  <p style="opacity: 0">
                    asd
                  </p>

                  <v-btn
                    @click="addDefaultDisk"
                    depressed
                    outlined
                    height="48"
                    width="100%"
                    class="secondary white--text"
                  >
                    Add
                  </v-btn>
                </v-col>
              </v-row>

              <v-row>
                <v-col>
                  <!-- err msg -->
                  <p
                    class="error--text"
                    :style="{
                      opacity: addDefaultDiskError ? 1 : 0,
                    }"
                  >
                    <b>{{ addDefaultDiskError }}</b>
                  </p>
                </v-col>
              </v-row>
            </template>

            <v-row v-if="isDevModeStorageTiering2" class="align-center">
              <v-col>
                <h2>Custom Instance Data Disk (max 12 Data Disk)</h2>
              </v-col>

              <!-- <v-col style="width: 100%; display: flex; justify-content: end;"> -->
              <v-col class="d-flex flex-column align-end">
                <v-btn
                  @click="addCustomDataDisk"
                  depressed
                  style="border: 3px solid #1f60a8"
                  outlined
                  height="45"
                  width="150"
                  class="primary--text"
                >
                  Add Custom Data Disk
                </v-btn>

                <p
                  class="error--text"
                  :style="{
                    opacity: customDiskError ? 1 : 0,
                  }"
                >
                  {{ customDiskError }}
                </p>
              </v-col>
            </v-row>

            <v-row v-if="isDevModeStorageTiering2">
              <v-col>
                <v-data-table
                  :headers="customDataDiskHeaders"
                  :items="customDataDiskItems"
                  :items-per-page="13"
                  hide-default-footer
                >
                  <template v-slot:item.type="{ item }">
                    <!-- <template="{ item }"> -->
                    <!-- {{ item.type }} -->

                    <v-select
                      placeholder="Choose Storage Type"
                      :value="item.type"
                      @input="
                        (e) => {
                          customDiskItemChange(item.index, 'type', e);
                        }
                      "
                      :error-messages="
                        item.typeError ? 'Value is required' : ''
                      "
                      :items="volumeTypes"
                      outlined
                      :disabled="item.disable"
                      :filled="item.disable"
                    />
                  </template>

                  <template v-slot:item.size="{ item }">
                    <!-- <template="{ item }"> -->
                    <!-- {{ item.size }} -->

                    <v-text-field
                      :value="item.size"
                      @keypress="isNumber($event)"
                      @input="
                        (e) => {
                          customDiskItemChange(
                            item.index,
                            'size',
                            e ? parseInt(e) : e
                          );
                        }
                      "
                      :error-messages="item.sizeError"
                      outlined
                      placeholder="Enter in GB"
                      :disabled="item.disable"
                      :filled="item.disable"
                    >
                      <template v-if="item.size" v-slot:append>
                        <span class="font--text">
                          GB
                        </span>
                      </template>
                    </v-text-field>
                  </template>

                  <template v-slot:item.index="{ item }">
                    <v-btn
                      @click="deleteCustomDataDisk(item.index)"
                      depressed
                      outlined
                      height="45"
                      width="150"
                      class="error white--text"
                    >
                      Delete
                    </v-btn>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>

            <!-- <v-row v-if="!isDevModeStorageTiering && addvolume === true"> -->
            <v-row v-else>
              <v-col class="d-flex pt-0 flex-wrap">
                <v-card
                  tabindex="0"
                  min-width="170"
                  :key="defaultstoragesize.id"
                  border-color="primary"
                  outlined
                  :class="
                    `rounded-lg py-0 select-instance custom-flavor-card mb-4 ${
                      isCustomSizeHighlighted ? 'highlighted selected' : ''
                    }`
                  "
                  style="text-align: center; margin-right: 20px"
                >
                  <v-card-text class="pa-0">
                    <v-text-field
                      class="px-4 pt-2"
                      style="width: 170px"
                      flat
                      label="Enter Size in GB"
                      v-model="customSize"
                      @keydown="
                        (ev) => (customSize = addGBText(ev, customSize))
                      "
                      @focus="
                        () => {
                          storageselected = null;
                          isCustomSizeHighlighted = true;
                        }
                      "
                      @blur="
                        () => {
                          if (!customSize) isCustomSizeHighlighted = false;
                        }
                      "
                    ></v-text-field>
                  </v-card-text>
                </v-card>

                <v-card
                  width="170"
                  :class="{
                    highlight: storageselected
                      ? is(storageselected.id, storage.id)
                      : false,
                    selected: storageselected
                      ? is(storageselected.id, storage.id)
                      : false,
                  }"
                  v-for="storage in selectedVolumeType
                    ? dataDisks.filter(
                        (disk) => disk.type_id === selectedVolumeType
                      )
                    : dataDisks"
                  :key="storage.id"
                  @click="
                    () => {
                      storageselected = storage;
                      isCustomSizeHighlighted = false;
                      customSize = null;
                    }
                  "
                  border-color="primary"
                  outlined
                  class="rounded-lg select-instance mb-4 flavor-card"
                  style="text-align: center; margin-right: 20px"
                >
                  <v-card-text>
                    <p class="font-weight-bold mb-0">
                      {{ storage.volume_size }} GB
                    </p>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>

            <v-divider />
            <v-row>
              <v-col>
                <p
                  style="font-size: 21px; margin-bottom: 10px"
                  class="font-weight-bold"
                >
                  Authentication
                </p>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="6" md="4" class="d-flex flex-nowrap">
                <v-card
                  @click="
                    () => {
                      authentication = 'ssh';
                    }
                  "
                  :style="{
                    border: $v.authentication.$errors.length
                      ? '2px solid #eb5757 !important'
                      : `2px solid ${
                          authentication == 'ssh' ? '#2C94D2' : '#BDBDBD'
                        }`,
                  }"
                  class="rounded-lg d-flex flex-grow-1"
                  flat
                  outlined
                >
                  <v-card-title class="d-flex flex-nowrap">
                    <div>
                      <v-radio-group
                        :error-messages="
                          $v.authentication.$errors.length
                            ? $v.authentication.$errors[0].$message
                            : ''
                        "
                        hide-details
                        v-model="authentication"
                        style="margin-top: -10px"
                      >
                        <v-radio class="authentication" value="ssh"></v-radio>
                      </v-radio-group>
                    </div>
                    <div class="ml-2">
                      <p style="font-size: 21px" class="my-0 font-weight-bold">
                        SSH Keys
                      </p>
                      <p class="my-0 accent--text" style="font-size: 12px">
                        A more secure authentication method
                      </p>
                    </div>
                  </v-card-title>
                </v-card>
              </v-col>
              <v-col cols="12" sm="6" md="4" class="d-flex flex-nowrap">
                <v-card
                  @click="
                    () => {
                      authentication = 'password';
                    }
                  "
                  :style="{
                    border: $v.authentication.$errors.length
                      ? '2px solid #eb5757 !important'
                      : `2px solid ${
                          authentication == 'password' ? '#2C94D2' : '#BDBDBD'
                        }`,
                  }"
                  class="rounded-lg d-flex flex-grow-1"
                  flat
                  outlined
                >
                  <v-card-title class="d-flex flex-nowrap">
                    <div>
                      <v-radio-group
                        :error-messages="
                          $v.authentication.$errors.length
                            ? $v.authentication.$errors[0].$message
                            : ''
                        "
                        hide-details
                        style="margin-top: -10px"
                        v-model="authentication"
                      >
                        <v-radio
                          class="authentication"
                          value="password"
                        ></v-radio>
                      </v-radio-group>
                    </div>
                    <div class="ml-2">
                      <p style="font-size: 21px" class="my-0 font-weight-bold">
                        Password
                      </p>
                      <p class="my-0 accent--text" style="font-size: 12px">
                        Create a root password to access VM (less secure)
                      </p>
                    </div>
                  </v-card-title>
                </v-card>
              </v-col>
            </v-row>
            <span v-if="$v.authentication.$errors.length" class="ml-3 red--text"
              >You are required to choose one authentication method</span
            >
            <v-row style="position: relative">
              <transition name="slide-fade">
                <v-col
                  cols="12"
                  md="12"
                  v-show="authentication == 'ssh'"
                  class="ssh"
                >
                  <v-card class="rounded-lg d-flex flex-grow-1" flat outlined>
                    <v-card-title class="d-flex flex-nowrap">
                      <div class="ml-2">
                        <div class="d-flex mb-0">
                          <p class="font-weight-bold">Choose your SSH Keys</p>
                        </div>

                        <div class="d-flex flex-wrap">
                          <div
                            class="d-flex flex-wrap mr-5"
                            v-for="sshkey in sshkeys"
                            :key="sshkey.id"
                          >
                            <v-checkbox
                              v-model="selectedssh"
                              :key="sshkey.id"
                              :value="sshkey.id"
                              class="m-0 mr-0"
                              :label="sshkey.name"
                            ></v-checkbox>
                            <v-icon
                              color="red"
                              @click="
                                () => {
                                  keyid = sshkey.id;
                                  openDialogDeleteSsh = true;
                                }
                              "
                              >mdi-delete-outline</v-icon
                            >
                            <v-icon
                              color="secondary"
                              @click="
                                () => {
                                  sshkeyselected = sshkey;
                                  dialogupdate = true;
                                }
                              "
                              >mdi-pencil-box-outline</v-icon
                            >
                          </div>
                        </div>

                        <div class="d-flex">
                          <v-btn
                            depressed
                            id="NewKey"
                            height="45"
                            @click="dialogssh = true"
                            color="secondary"
                            outlined
                          >
                            <span style="font-size: 16px">Add New SSH Key</span>
                          </v-btn>
                        </div>
                      </div>
                    </v-card-title>
                  </v-card>
                </v-col>
              </transition>
              <transition name="slide-fade">
                <v-col
                  cols="12"
                  v-show="authentication == 'password'"
                  class="password"
                >
                  <v-card flat outlined class="rounded-lg">
                    <v-card-text class="pb-0">
                      <span class="font-weight-bold" style="font-size: 16px"
                        >Create root password</span
                      >
                    </v-card-text>
                    <v-card-text>
                      <v-text-field
                        id="createpassword"
                        type="password"
                        :error-messages="
                          $v.password.$errors.length
                            ? $v.password.$errors[0].$message
                            : ''
                        "
                        placeholder="Type your password"
                        outlined
                        v-model="password"
                      ></v-text-field>
                      <p class="accent--text">
                        <v-icon color="primary" left
                          >mdi-alert-circle-outline
                        </v-icon>
                        You will not be sent an email containing the Instance
                        details or password. Please store your password
                        securely.
                      </p>
                    </v-card-text>
                  </v-card>
                </v-col>
              </transition>
            </v-row>
            <!--<v-row>
              <v-col cols="12" sm="12" md="6">
                <p class="font-weight-bold">Add tags</p>
                <v-autocomplete
                    id="AddTags"
                    class="tags"
                    hide-no-data
                    small-chips
                    v-model="tags"
                    multiple
                    :chips="true"
                    :items="itemstags"
                    hide-details
                    outlined
                    placeholder="Type tags here"
                    clearable
                    append-icon=""
                    deletable-chips
                >
                  <template v-slot:item="{ item }">
                    {{ item }}
                  </template>
                </v-autocomplete>
              </v-col>
            </v-row>-->
            <!--<v-row>
              <v-col cols="12">
                <p class="font-weight-bold">Instance Amount</p>
                <v-row>
                  <v-col cols="12" sm="12" md="6">
                    <v-text-field
                      v-model="instanceAmount"
                      type="number"
                      outlined
                      placeholder="Instance Amount (max 5)"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>-->

            <v-row>
              <v-col cols="12">
                <p class="font-weight-bold">Instance Name</p>
                <v-row>
                  <v-col cols="12" sm="12" md="6">
                    <v-text-field
                      maxlength="50"
                      id="instancename"
                      v-model="instanceName"
                      :error-messages="
                        $v.instanceName.$errors.length
                          ? $v.instanceName.$errors[0].$message
                          : ''
                      "
                      outlined
                      placeholder="Instance Name"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>

            <!--            <v-row>-->
            <!--              <v-col cols="12" sm="12" md="6">-->
            <!--                <p class="font-weight-bold">Voucher</p>-->
            <!--                <v-text-field-->
            <!--                    outlined-->
            <!--                    placeholder="Type voucher here"-->
            <!--                ></v-text-field>-->
            <!--              </v-col>-->
            <!--            </v-row>-->
            <v-row>
              <v-col cols="6">
                <v-btn
                  depressed
                  style="background-color: #a5b3bf !important"
                  block
                  height="58"
                  class="primary"
                  to="/instance"
                  exact
                >
                  <span style="font-size: 16px">Cancel</span>
                </v-btn>
              </v-col>
              <v-col cols="6">
                <v-btn
                  depressed
                  id="SubmitCreateInstance"
                  @click="createInstance"
                  block
                  height="58"
                  class="secondary"
                >
                  <span style="font-size: 16px">Create Instance</span>
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-col>
    </v-row>
    <dialogcreatessh
      v-model="dialogssh"
      @close="
        () => {
          dialogssh = false;
        }
      "
    />
    <dialogupdatessh
      v-if="sshkeyselected && dialogupdate"
      :sshselected="sshkeyselected"
      v-model="dialogupdate"
      @close="
        () => {
          dialogupdate = false;
        }
      "
    />

    <v-dialog v-model="openDialogDeleteSsh" max-width="500">
      <v-card>
        <v-card-title>
          <div class="headline font-weight-bold font--text pb-4">
            Delete this Ssh key
          </div>
        </v-card-title>
        <v-card-text class="pb-0">
          <p>Are you sure you delete this ssh key?</p>
        </v-card-text>
        <v-card-actions>
          <v-row class="ma-2">
            <v-col cols="6">
              <v-btn
                color="accent"
                block
                depressed
                height="50"
                @click="
                  () => {
                    openDialogDeleteSsh = false;
                  }
                "
              >
                Cancel
              </v-btn>
            </v-col>
            <v-col cols="6">
              <v-btn
                color="error"
                block
                height="50"
                depressed
                @click="
                  () => {
                    deletekey(keyid);
                  }
                "
              >
                <beat-loader
                  v-if="isLoading"
                  :loading="isLoading"
                  :color="'white'"
                  :size="'10px'"
                  style="transform: translateY(3px)"
                  class="mr-2"
                ></beat-loader>
                Yes
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import dialogcreatessh from "./dialogcreatessh";
import dialogupdatessh from "./dialogupdatessh.vue";
import { IDRformat } from "../../lib/formatter";
import { useRegion } from "./compositionapi/useregion";
import { useVpc } from "./compositionapi/usevpc";
import { useImages } from "./compositionapi/useimages";
import { useInstance } from "./compositionapi/useinstance";
import { useStorage } from "./compositionapi/usestorage";
import { useSSHkeys } from "./compositionapi/usesshkeys";
import { useVoucher } from "../voucher/useVoucher";
import { useCreateInstance } from "./compositionapi/usecreateinstance";
import { ref, watch, onMounted, computed } from "@vue/composition-api";
import { addGBText, removeGBText } from "@/lib/formatter";
import { INSTANCE } from "./namespace";

const { required, requiredIf, helpers } = require("@vuelidate/validators");
const useVuelidate = require("@vuelidate/core").default;
import { useFlavor } from "../superadmin-manageflavors/useflavor";
import {
  useNamespacedMutations,
  useNamespacedState,
  useNamespacedActions,
} from "vuex-composition-helpers";
import Vue from "vue";
import api from "@/lib/api";

const isDevModeStorageTiering2 = true;

export default {
  components: {
    dialogcreatessh,
    dialogupdatessh,
  },
  setup(props, context) {
    props;
    const {
      plans,
      planSelected,
      planValidation,
      $v: planvalidator,
    } = useFlavor(props, context);
    const { fetchVouchers, vouchers } = useVoucher();
    const $router = context.root.$router;
    const useComposable = {
      ...useRegion(),
      ...useVpc(),
      ...useImages(),
      ...useInstance(),
      ...useStorage(),
      ...useSSHkeys(),
    };
    const {
      showErrorToast,
      showSuccessToast,
    } = useNamespacedActions("HOMEPAGE", [
      "showErrorToast",
      "showSuccessToast",
    ]);
    const {
      selectedRegion,
      // bitVersionSelected,
      versionSelected,
      osSelected,
      vpcidselected,
      instanceselected,
      storageselected,
      rootDiskSelected,
      tags,
      images,
      version,
      defaultstoragesize,
      fetchimages,
      fetchCustomImages,
      fetchVolumeSize,
      volumeSize,
      customImages,
      masterInstances,
      fetchMasterInstances,
    } = useComposable;

    const rootDiskVolumeSize = computed(() =>
      volumeSize.value.filter((flavorStorage) => {
        if (showCustomDiskFlavors)
          return flavorStorage.status === "active" && flavorStorage.volume_size;
        else
          return (
            flavorStorage.status === "active" &&
            flavorStorage.volume_size &&
            !flavorStorage.organization_id
          );
      })
    );

    const dataDisks = computed(() =>
      volumeSize.value.filter(
        (flavorStorage) =>
          flavorStorage.status === "active" && flavorStorage.volume_size
      )
    );

    const filteredFlavors = ref([]);
    const showCustomDiskFlavors =
      process.env.VUE_APP_SHOW_CUSTOM_DISK_FLAVORS == "true" ? true : false;

    const customDataDiskHeaders = [
      { text: "Storage Type", value: "type", sortable: false },
      { text: "Size", value: "size", sortable: false },
      { text: "Action", value: "index", sortable: false },
    ];

    const customDataDiskItems = ref([]);

    let diskSizeUsed = 0;

    const getDiskSizeUsed = async () => {
      try {
        const res = await api.GET("/user/usage");
        diskSizeUsed = res.data.storage_usage.storage_usage;
      } catch (err) {
        if (
          err &&
          err.response &&
          err.response.data &&
          err.response.data.data !== "record not found"
        ) {
          context.root.$store.dispatch(
            "HOMEPAGE/showErrorToast",
            "Sorry, an error occurred while getting disk size used"
          );
        }
      }
    };

    const checkCustomDataDiskFields = () => {
      const totalSize = customDataDiskItems.value.reduce(
        (num, obj) => num + obj.size,
        0
      );

      for (let i = 0; i < customDataDiskItems.value.length; i++) {
        if (!customDataDiskItems.value[i].type) {
          customDataDiskItems.value[i].typeError = true;
        } else {
          customDataDiskItems.value[i].typeError = false;
        }

        if (!customDataDiskItems.value[i].size) {
          customDataDiskItems.value[i].sizeError = "Value is required";
        } else {
          customDataDiskItems.value[i].sizeError = "";
        }
      }
    };

    const customDiskError = ref("");

    const addCustomDataDisk = () => {
      if (customDiskError.value) customDiskError.value = "";

      const emptyTypeOrSize =
        customDataDiskItems.value.length &&
        customDataDiskItems.value.some((item) => !item.type || !item.size);
      // const areFieldsWeird = emptyTypeOrSize;

      // max custom disks = 12
      if (
        customDataDiskItems.value.length &&
        customDataDiskItems.value.length === 12
      ) {
        customDiskError.value = "*Max 12 items";
      }

      // size or type is empty
      else if (emptyTypeOrSize) {
        customDiskError.value = "*Type or size is empty";
        checkCustomDataDiskFields();
      }

      // add new field
      else {
        customDataDiskItems.value.push({
          index: Math.random()
            .toString(36)
            .slice(2),
          type: "",
          size: null,
          typeError: false,
          sizeError: "",
          disable: false,
        });
      }
    };

    const deleteCustomDataDisk = (index) => {
      const index2 = customDataDiskItems.value.findIndex(
        (item) => item.index === index
      );
      customDataDiskItems.value.splice(index2, 1);
    };

    const customDiskItemChange = (index, property, value) => {
      const index2 = customDataDiskItems.value.findIndex(
        (item) => item.index === index
      );
      customDataDiskItems.value[index2][property] = value;
      checkCustomDataDiskFields();
    };

    const selectedTrialVoucher = ref(null);
    const selectedCustomImage = ref({});
    const selectCustomImages = computed(() => {
      return (
        customImages.value
          // .filter((x) => {
          //   if (x.is_snapshot && x.is_upload_success) return true;
          //   else if (x.is_backup) return true;
          //   else if (!x.is_backup && x.is_upload_success) return true;
          //   return false;
          // })
          // .filter((x) => {
          //   if (x.is_snapshot || x.is_backup) {
          //     if (x.openstack_snapshot_id || x.openstack_backup_id) return true;
          //     else return false;
          //   }
          //   return true;
          // })
          .sort((x) => (x.is_snapshot ? 1 : -1))
          .map((x) => {
            return {
              text: x.is_snapshot
                ? `(Snapshot) ${x.name}`
                : x.is_backup
                ? `(Backup) ${x.name}`
                : `${x.name} (version ${x.version})`,
              value: `${x.id}`,
            };
          })
      );
    });

    const distinctOS = computed(() => {
      let osIds = [];
      return images.value
        .filter((img) => {
          if (!osIds.includes(img.os_id)) {
            osIds.push(img.os_id);
            return true;
          }
          return false;
        })
        .map((x) => {
          return {
            text: x.os_name,
            value: x.os_id,
            logo: x.os_logo,
            version: x.version_name,
          };
        });
    });

    const selectedVolumeType = ref("");
    const selectedStorageType = ref("");
    const vm_root_disk_type_id = ref("");
    const selectedBillingType = ref("Fixed");
    const authentication = ref("");
    const selectedssh = ref([]);
    const password = ref("");
    const instanceAmount = ref(1);
    const instanceName = ref("");
    const selecttypeinstance = ref("");
    const vpcItems = ref([]);
    const $v = useVuelidate(
      {
        instanceName: {
          required,
          $autoDirty: true,
          whitespaceStart: helpers.withMessage(
            "Can not start with whitespace",
            (val) => {
              if (val[0] === " ") return false;
              return true;
            }
          ),
          whitespaceEnd: helpers.withMessage(
            "Can not end with whitespace",
            (val) => {
              let endChar = val.length - 1;
              if (val[endChar] === " ") return false;
              return true;
            }
          ),
          specialChars: helpers.withMessage(
            "Can not contain special character",
            (val) => {
              return !val.match(/[^A-Za-z0-9-_]/g);
            }
          ),
        },
        selectedRegion: { required, $autoDirty: true },
        selecttypeinstance: { required },
        vpcidselected: { required, $autoDirty: true },
        planSelected: { required, $autoDirty: true },
        vm_root_disk_type_id: isDevModeStorageTiering2
          ? { required, $autoDirty: true }
          : { $autoDirty: true },
        // selectedVolumeType: { required, $autoDirty: true },
        selectedStorageType: isDevModeStorageTiering2
          ? {
              required: requiredIf((val) => {
                return storageselected.value;
              }),
              $autoDirty: true,
            }
          : { $autoDirty: true },
        storageselected: isDevModeStorageTiering2
          ? {
              required: requiredIf((val) => {
                return selectedVolumeType.value;
              }),
              $autoDirty: true,
            }
          : { $autoDirty: true },
        selectedBillingType: { required, $autoDirty: true },
        selectedTrialVoucher: {
          required: requiredIf((val) => {
            return selectedBillingType.value === "Trial";
          }),
        },
        osSelected: {
          required: requiredIf((val) => {
            return selecttypeinstance.value === "operatingsystem";
          }),
        },
        versionSelected: {
          required: requiredIf((val) => {
            return selecttypeinstance.value === "operatingsystem";
          }),
        },
        // bitVersionSelected: {
        //   required: requiredIf((val) => {
        //     return selecttypeinstance.value === "operatingsystem";
        //   }),
        // },
        selectedCustomImage: {
          required: requiredIf((val) => {
            return selecttypeinstance.value === "instanceimage";
          }),
        },
        rootDiskSelected: {
          required: requiredIf((val) => {
            return (
              customRootDiskSize.value === null &&
              rootDiskSelected.value === null
            );
          }),
          moreThan20: helpers.withMessage(
            "Custom root disk size must be atleast 20 GB",
            () => {
              if (!rootDiskSelected.value) return true;
              if (useRootDiskFromSnapshot.value) return true;
              let value = parseInt(rootDiskSelected.value.volume_size);
              return value >= 20;
            }
          ),
        },
        customRootDiskSize: {
          required: requiredIf((val) => {
            return (
              (customRootDiskSize.value == null ||
                customRootDiskSize.value == "") &&
              rootDiskSelected.value === null
            );
          }),
          moreThan20: helpers.withMessage(
            "Custom root disk size must be atleast 20 GB",
            () => {
              if (!customRootDiskSize.value) return true;
              if (useRootDiskFromSnapshot.value) return true;
              let value = parseInt(removeGBText(customRootDiskSize.value));
              return value >= 20;
            }
          ),
        },
        instanceselected: { required, $autoDirty: true },
        authentication: { required, $autoDirty: true },
        // selectedssh: {
        // conditional: (value) => {
        //   if (authentication.value == 'ssh') {
        //     return !!value
        //   } else {
        //     return true
        //   }
        // }
        // },
        password: {
          conditional: {
            $message: "Value is required",
            $validator: (value) => {
              if (authentication.value == "password") {
                return !!value;
              } else {
                return true;
              }
            },
          },
        },
      },
      {
        vm_root_disk_type_id,
        customRootDiskSize,
        rootDiskSelected,
        selectedStorageType,
        storageselected,
        // selectedVolumeType,
        selectedBillingType,
        selecttypeinstance,
        selectedRegion,
        versionSelected,
        // bitVersionSelected,
        selectedCustomImage,
        instanceselected,
        authentication,
        selectedssh,
        osSelected,
        vpcidselected,
        planSelected,
        password,
        instanceName,
        selectedTrialVoucher,
      }
    );

    const defaultDiskType = ref("");
    const defaultDiskTypeError = ref("");
    const defaultDiskSize = ref("");
    const defaultDiskSizeError = ref("");
    const addDefaultDiskError = ref("");

    const addDefaultDisk = () => {
      addDefaultDiskError.value = "";

      const totalSize = customDataDiskItems.value.reduce(
        (num, obj) => num + obj.size,
        0
      );

      if (customDataDiskItems.value.length === 12) {
        addDefaultDiskError.value = "Max = 12 items";
        return;
      }

      if (!defaultDiskType.value) {
        defaultDiskTypeError.value = "Value is required";
        return;
      }

      if (!defaultDiskSize.value) {
        defaultDiskSizeError.value = "Value is required";
        return;
      }

      const disk = dataDisks.value.find(
        (disk) => disk.id === defaultDiskSize.value
      );

      customDataDiskItems.value.push({
        index: Math.random()
          .toString(36)
          .slice(2),
        type: defaultDiskType.value,
        package_storage_id: defaultDiskSize.value,
        size: disk ? disk.volume_size : null,
        typeError: false,
        sizeError: "",
        disable: true,
      });
    };

    watch(vm_root_disk_type_id, () => {
      if (!useRootDiskFromSnapshot) {
        rootDiskSelected.value = null;
      }
    });

    watch(defaultDiskType, () => {
      addDefaultDiskError.value = "";
      defaultDiskSize.value = "";
      defaultDiskTypeError.value = "";
    });

    watch(defaultDiskSize, () => {
      addDefaultDiskError.value = "";
      defaultDiskSizeError.value = "";
    });

    watch(storageselected, (newVal) => {
      // if (newVal && selectedVolumeType.value) {
      //   addDefaultDisk()
      // }

      if (isDevModeStorageTiering2 && customDataDiskItems.value.length) {
        for (let i = 0; i < customDataDiskItems.value.length; i++) {
          if (customDataDiskItems.value[i].package_storage_id) {
            const disk = dataDisks.value.find((disk) => disk.id === newVal);

            customDataDiskItems.value[i].package_storage_id = newVal;
            customDataDiskItems.value[i].size = disk.volume_size;
          }
        }
      }
    });

    watch(selectedVolumeType, (newVal) => {
      storageselected.value = null;

      // if (newVal && storageselected.value) {
      //   addDefaultDisk()
      // }

      if (isDevModeStorageTiering2 && customDataDiskItems.value.length) {
        for (let i = 0; i < customDataDiskItems.value.length; i++) {
          if (customDataDiskItems.value[i].package_storage_id) {
            customDataDiskItems.value[i].type = newVal;
          }
        }
      }
    });

    // watch(selectedVolumeType, () => {
    //   rootDiskSelected.value = null;
    // });

    watch(osSelected, () => {
      versionSelected.value = "";
      context.root.$nextTick(() => {
        $v.value.versionSelected.$reset();
      });
    });

    watch(masterInstances, () => {
      filteredFlavors.value = masterInstances.value
        .filter((x) => {
          if (x.status == "active") {
            if (x.usage_limit == -1 || x.usage < x.usage_limit) return true; //check flavor limit (unused)
            return false;
          }
          return false;
        })
        .map((x) => {
          if (selectedRegion.value) {
            if (
              x.vcpu <=
                selectedRegion.value.cpu_limit -
                  selectedRegion.value.cpu_usage &&
              x.ram <=
                selectedRegion.value.ram_limit -
                  selectedRegion.value.ram_usage &&
              x.gpu <=
                selectedRegion.value.gpu_limit - selectedRegion.value.gpu_usage
            )
              return x;
            else return { ...x, outOfStock: true };
          }
          return x;
        })
        .sort((a, b) => (a.vcpu > b.vcpu ? 1 : -1))
        .sort((a, b) => (a.ram > b.ram ? -1 : 1))
        .sort((a, b) => {
          if (selectedRegion.value) {
            if (
              a.vcpu <=
                selectedRegion.value.cpu_limit -
                  selectedRegion.value.cpu_usage &&
              a.ram <=
                selectedRegion.value.ram_limit -
                  selectedRegion.value.ram_usage &&
              a.gpu <=
                selectedRegion.value.gpu_limit - selectedRegion.value.gpu_usage
            )
              return -1;
            else return 1;
          }
        });
    });

    watch([planSelected, selectedRegion], (val) => {
      let params = {
        type_id: planSelected.value,
        region: selectedRegion.value.name,
      };
      fetchMasterInstances(params);
    });

    watch(authentication, () => {
      // $v.value.password.$reset()
      // $v.value.selectedssh.$reset()
    });

    const useRootDiskFromSnapshot = ref(false);
    watch(selecttypeinstance, (val) => {
      if (selecttypeinstance != "instanceimage") {
        customRootDiskSize.value = null;
        rootDiskSelected.value = null;
        useRootDiskFromSnapshot.value = false;
      }
    });

    watch(selectedCustomImage, (val) => {
      let image = customImages.value.filter((x) => x.id == val)[0];
      console.log(image);
      if (image.metadata.length) {
        let rootDiskSize = image.metadata.filter((x) => x.type == "Rootdisk")[0]
          .size;
        let flavor = volumeSize.value.filter(
          (x) =>
            x.status == "active" &&
            !x.organization_id &&
            x.volume_size == rootDiskSize
        );
        if (flavor.length) rootDiskSelected.value = flavor[0];
        else customRootDiskSize.value = `${rootDiskSize} GB`;
        useRootDiskFromSnapshot.value = true;
      } else {
        customRootDiskSize.value = null;
        rootDiskSelected.value = null;
        useRootDiskFromSnapshot.value = false;
      }
    });

    const messagefail = ref("");
    const { createinstance } = useCreateInstance();
    const opendialogfailcreate = ref(false);
    const {
      setopendialogreachlimit,
      setopendialogbalance,
      setopendialogbalancemsg,
    } = useNamespacedMutations("DASHBOARD", [
      "setopendialogreachlimit",
      "setopendialogbalance",
      "setopendialogbalancemsg",
    ]);

    const sshkeyselected = ref();

    const openDialogDeleteSsh = ref(false);
    const keyid = ref("");

    const { isLoading } = useNamespacedState("INSTANCE", ["isLoading"]);

    const {
      createsshkey,
      deletesshkey,
      getsshkey,
    } = useNamespacedActions(INSTANCE, [
      "createsshkey",
      "deletesshkey",
      "getsshkey",
    ]);

    function updatekey(val) {
      getsshkey(val);
    }

    function deletekey(val) {
      deletesshkey(val).then(() => {
        showSuccessToast("Keypair deleted successfully");
        this.openDialogDeleteSsh = false;
      });
    }

    async function createInstance() {
      $v.value.$touch();
      if ($v.value.$invalid) {
        window.scrollTo({ top: 0, behavior: "smooth" });
        return;
      }

      const emptyTypeOrSize =
        customDataDiskItems.value.length &&
        customDataDiskItems.value.some((item) => !item.type || !item.size);
      const areFieldsWeird = emptyTypeOrSize;

      if (isDevModeStorageTiering2 && areFieldsWeird) {
        checkCustomDataDiskFields();
        window.scrollTo({ top: 0, behavior: "smooth" });
        return;
      }

      let additionalInstancePayload = {};

      if (isDevModeStorageTiering2) {
        additionalInstancePayload.vm_root_disk_type_id =
          vm_root_disk_type_id.value;
      }

      if (isDevModeStorageTiering2 && customDataDiskItems.value.length) {
        const itemsFormatted = customDataDiskItems.value.map((item) => {
          let additionalProp = {
            custom_size: item.size,
          };

          if (item.package_storage_id) {
            additionalProp = { package_storage_id: item.package_storage_id };
          }

          return {
            package_storage_type_id: item.type,
            ...additionalProp,
          };
        });

        if (!additionalInstancePayload.storages) {
          additionalInstancePayload.storages = itemsFormatted;
        } else {
          additionalInstancePayload.storages = [
            ...additionalInstancePayload.storages,
            ...itemsFormatted,
          ];
        }
      }

      let newinstance = isDevModeStorageTiering2
        ? {
            name: instanceName.value,
            region: selectedRegion.value.name,
            vpc_id: vpcidselected.value,
            package_instance_id: instanceselected.value,
            instance_amount: 1,
            billing_type: selectedBillingType.value,
            // package_storage_type_id: selectedVolumeType.value,
            ...additionalInstancePayload,
          }
        : {
            name: instanceName.value,
            region: selectedRegion.value.name,
            vpc_id: vpcidselected.value,
            package_instance_id: instanceselected.value,
            instance_amount: 1,
            billing_type: selectedBillingType.value,
            // package_storage_type_id: selectedVolumeType.value,
          };

      if (!isDevModeStorageTiering2 && storageselected.value)
        newinstance.package_storage_id = storageselected.value;

      if (selectedBillingType.value == "Trial")
        newinstance.voucher_id = selectedTrialVoucher.value;

      if (rootDiskSelected.value)
        newinstance.vm_root_disk_id = rootDiskSelected.value.id;

      if (customRootDiskSize.value) {
        const flavorSameSize = volumeSize.value.filter(
          (x) =>
            parseInt(x.volume_size) ===
              parseInt(removeGBText(customRootDiskSize.value)) &&
            x.type_id === vm_root_disk_type_id.value
        );
        if (flavorSameSize.length > 0)
          newinstance.vm_root_disk_id = flavorSameSize[0].id;
        else
          newinstance.vm_root_disk_custom_size = parseInt(
            removeGBText(customRootDiskSize.value)
          );
      }

      if (storageselected.value)
        newinstance.package_storage_id = storageselected.value.id;

      if (customSize.value) {
        const flavorSameSize = volumeSize.value.filter(
          (x) =>
            parseInt(x.volume_size) === parseInt(removeGBText(customSize.value))
        );
        if (flavorSameSize.length > 0)
          newinstance.package_storage_id = flavorSameSize[0].id;
        else newinstance.custom_size = parseInt(removeGBText(customSize.value));
      }

      if (selecttypeinstance.value === "operatingsystem")
        newinstance.image_os_id = versionSelected.value;
      else if (selecttypeinstance.value === "instanceimage")
        newinstance.image_id = selectedCustomImage.value;

      if (authentication.value == "ssh") {
        newinstance.ssh_key_id = selectedssh.value;
      } else if (authentication.value == "password") {
        newinstance.password_manual = password.value;
      }

      // newinstance.package_storage_id = vm_root_disk_type_id === defaultstoragesize[0].type.id ? defaultstoragesize[0].id : defaultstoragesize[1].id
      createinstance(newinstance)
        .then(() => {
          this.$router.push("/instance");
        })
        .catch((e) => {
          if (
            e.response &&
            e.response.data &&
            e.response.data.code == "INS-007"
          ) {
            let errorMessage =
              "A problem encountered while trying to create instance";
            if (
              e.response.data.data.includes(
                "instance name in project must be unique"
              )
            )
              errorMessage =
                "Instance name has been already used in this project.";
            else if (
              e.response.data.data.includes(
                "total instance voucher has reached limit"
              )
            )
              errorMessage =
                "Total instance quota of trial voucher has reached the limit.";
            else if (
              e.response.data.data.includes(
                "this package has already reached region limit"
              )
            )
              errorMessage =
                "Unable to use the instance flavor due to stock availability.";
            else if (
              e.response.data.data.includes(
                "RAM instance voucher has reached limit"
              )
            )
              errorMessage =
                "RAM quota of trial voucher has reached the limit.";
            else if (
              e.response.data.data.includes(
                "CPU instance voucher has reached limit"
              )
            )
              errorMessage =
                "CPU quota of trial voucher has reached the limit.";
            else if (
              e.response.data.data.includes(
                "instance root disk size voucher has reached limit"
              )
            )
              errorMessage =
                "Root disk quota of trial voucher has reached the limit.";
            else if (
              e.response.data.data.includes(
                "ram limit has reached in this region"
              )
            )
              errorMessage =
                "Unable to use the flavor in this region due to limitation. Please contact administrator.";
            else if (
              e.response.data.data.includes(
                "cpu limit has reached in this region"
              )
            )
              errorMessage =
                "Unable to use the flavor in this region due to limitation. Please contact administrator.";
            else if (
              e.response.data.data.includes("Instance has exceeded the limit")
            )
              errorMessage =
                "Instance has exceeded the limit. Please contact administrator.";
            else if (
              e.response.data.data.includes("vCPU has exceeded the limit")
            )
              errorMessage =
                "vCPU has exceeded the limit. Please contact administrator.";
            else if (
              e.response.data.data.includes("RAM has exceeded the limit")
            )
              errorMessage =
                "RAM has exceeded the limit. Please contact administrator.";
            else if (
              e.response.data.data.includes("Storage has exceeded the limit")
            )
              errorMessage =
                "Storages has exceeded the limit. Please contact administrator.";
            else if (
              e.response.data.data.includes(
                "Storage sizes has exceeded the limit"
              )
            )
              errorMessage =
                "Storage sizes has exceeded the limit. Please contact administrator.";
            else if (
              e.response.data.data.includes(
                "Network ports has exceeded the limit"
              )
            )
              errorMessage =
                "Network ports has exceeded the limit. Please contact administrator.";
            // else if(e.response.data.data.includes('Your balance not enough')) context.root.$store.dispatch('HOMEPAGE/showErrorToast', e.response.data.data, { root: true })
            else if (e.response.data.data.includes("Your balance not enough")) {
              setopendialogbalance(true);
              setopendialogbalancemsg(e.response.data.data);
              errorMessage = null;
            } else {
              setopendialogreachlimit(true);
              errorMessage = null;
            }

            if (errorMessage)
              context.root.$store.dispatch(
                "HOMEPAGE/showErrorToast",
                errorMessage,
                { root: true }
              );
          }
        });
    }

    function enterSizeInGB(e) {
      if (e.code.match(/^Digit/)) {
        defaultstoragesize.value[0].size = Number(
          "" + defaultstoragesize.value[0].size + e.key
        );
      } else if (e.code == "Backspace") {
        defaultstoragesize.value[0].size = Math.floor(
          defaultstoragesize.value[0].size / 10
        );
      }
    }

    const billingTypes = [
      // {
      //   text: "Fixed",
      //   value: "Fixed",
      // },
      // {
      //   text: "Monthly",
      //   value: "Monthly",
      // },
      {
        text: "Pay Per Use (PPU)",
        value: "PPU",
      },
      {
        text: "Trial",
        value: "Trial",
      },
      // {
      //   text: "Pay Per Metrics (PPM)",
      //   value: "PPM",
      // },
    ];

    const customRootDiskSize = ref(null);
    const isCustomRootDiskSizeHighlighted = ref(false);

    const customSize = ref(null);
    const isCustomSizeHighlighted = ref(false);

    onMounted(async () => {
      await fetchVouchers();
    });

    return {
      vm_root_disk_type_id,
      rootDiskVolumeSize,
      defaultDiskType,
      defaultDiskTypeError,
      defaultDiskSize,
      defaultDiskSizeError,
      addDefaultDiskError,
      addDefaultDisk,
      dataDisks,
      customDiskError,
      customDiskItemChange,
      deleteCustomDataDisk,
      addCustomDataDisk,
      customDataDiskHeaders,
      customDataDiskItems,
      is: (a, b) => {
        return a == b;
      },
      vouchers,
      selectedTrialVoucher,
      addGBText,
      removeGBText,
      isCustomSizeHighlighted,
      customSize,
      // selectedVolumeType,
      selectedStorageType,
      storageselected,
      selectedBillingType,
      billingTypes,
      selectedCustomImage,
      selectCustomImages,
      customImages,
      distinctOS,
      messagefail,
      opendialogfailcreate,
      enterSizeInGB,
      useRootDiskFromSnapshot,
      plans,
      planSelected,
      planValidation,
      instanceAmount,
      authentication,
      selectedssh,
      password,
      $v,
      ...useComposable,
      IDRformat,
      instanceName,
      voucher: ref(""),
      createInstance,
      selecttypeinstance,
      addvolume: ref(false),
      vpcItems,
      customRootDiskSize,
      isCustomRootDiskSizeHighlighted,
      rootDiskSelected,
      filteredFlavors,
      showCustomDiskFlavors,
      deletekey,
      sshkeyselected,
      updatekey,
      openDialogDeleteSsh,
      keyid,
      isLoading,
      dialogupdate: ref(false),
    };
  },
  data() {
    return {
      dialogssh: false,
      volumeTypes: [],
      // isDevModeStorageTiering,
      isDevModeStorageTiering2,
    };
  },
  async created() {
    await this.fetchregions();
    const vpcs = await this.fetchvpcs();
    this.vpcItems = vpcs.map((vpc) => {
      return {
        text: vpc.name,
        value: vpc.id,
      };
    });
    await this.fetchimages();
    await this.fetchCustomImages();
    await this.fetchsshkeys();
    await this.fetchVolumeSize({ limit: 999 });
    await this.fetchdefaultstoragesize();
    await this.getStorageTypes();
  },
  beforeRouteEnter(to, from, next) {
    const { id } = to.params;
    Vue.store
      .dispatch("INSTANCE/validateInstanceCreate", null)
      .then(() => {
        next();
      })
      .catch(() => {
        if (from.path == "/") {
          next("/");
        }
      });
  },
  methods: {
    formatPrice(num) {
      const numString = num.toString();
      const sisa = numString.length % 3;
      let rupiah = numString.substr(0, sisa);
      let ribuan = numString.substr(sisa).match(/\d{3}/g);
      let separator = "";

      if (ribuan) {
        separator = sisa ? "," : "";
        rupiah += separator + ribuan.join(",");
      }

      return "IDR " + rupiah;
    },
    async getStorageTypes() {
      if (isDevModeStorageTiering2) {
        try {
          const res = await api.GET("/flavor/storage/type");

          if (res.data.length) {
            const newArr = res.data.map((type) => {
              const { name, id } = type;
              const formattedName = {
                Premium: "Premium Volume SSD with 2250 - 4500 IOPS",
                Standard: "Standard Volume SSD with 750 - 2500 IOPS",
              };

              return {
                text: isDevModeStorageTiering2 ? formattedName[name] : name,
                value: id,
              };
            });

            this.volumeTypes = newArr;
          }
        } catch {
          this.$store.dispatch(
            "HOMEPAGE/showErrorToast",
            "Sorry, an error occurred while displaying storage types"
          );
        }
      }
    },
    // number input
    isNumber(e) {
      const charCode = e.which ? e.which : e.keyCode;

      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        e.preventDefault();
      } else {
        return true;
      }
    },
  },
};
</script>

<style lang="css">
#customSize .v-label--active {
  top: 10px !important;
}
.out-of-stock {
  background-color: #dedede !important;
}

.v-input--is-disabled .v-input__slot {
  background: #fff !important;
}
</style>

<style scoped lang="scss">
.select-instance,
.select-storage {
  cursor: pointer;

  &.selected {
    border-color: #2c94d2;

    .v-divider {
      border-color: #2c94d2;
    }
  }
}

.v-card.select-storage {
  p {
    margin-bottom: 0px;
    text-align: left;
  }
}

.v-card__title {
  line-height: 1.5rem;
}

.slide-fade-enter-active {
  transition: all 0.3s;

  &.ssh {
    position: relative;
  }

  &.password {
    position: absolute;
  }
}

.slide-fade-leave-active {
  &.ssh {
    position: relative;
  }

  &.password {
    position: absolute;
  }

  transition: all 0.3s;
}

.slide-fade-enter,
.slide-fade-leave-to {
  /* .slide-fade-leave-active below version 2.1.8 */
  &.ssh {
    transform: translateX(-1200px);
  }

  &.password {
    transform: translateX(1200px);
  }

  opacity: 0;
}

.v-input--is-disabled {
  ::v-deep .v-input__slot {
    background: #f1f2f2;
  }
}

.authentication {
  ::v-deep .v-icon {
    height: 30px;
    width: 30px;
  }

  ::v-deep .v-icon__svg {
    height: 30px;
    width: 30px;
  }
}

.bordererror {
  border: 1px solid red;
}

.v-input--radio-group {
  ::v-deep .v-messages__message {
    position: absolute;
    width: 100px;
  }
}
.select-version.v-text-field--outlined {
  ::v-deep fieldset {
    border-color: transparent;
  }
}
.v-application p,
.v-application label,
.v-application td,
.v-application .select-version .v-menu__content {
  border-radius: 15px 15px 50px 50px;
}
</style>
