<template>
  <v-dialog persistent max-width="500" v-model="dialog">
    <v-card class="pt-7">
      <v-card-text>
        <div v-if="organization" class="headline font-weight-bold font--text">
          Edit Organization
        </div>
        <div v-else class="headline font-weight-bold font--text">
          Add New Organization
        </div>
      </v-card-text>
      <v-card-text>
        <p class="titletext">Name of Organization</p>
        <v-text-field
          maxlength="30"
          v-model="name"
          :error-messages="
            $v.name.$errors.length ? $v.name.$errors[0].$message : ''
          "
          outlined
          placeholder="Enter Name"
        ></v-text-field>
      </v-card-text>
      <v-card-text v-if="!organization">
        <p class="titletext">Full Name</p>
        <v-text-field
          :disabled="!!organization"
          v-model="full_name"
          :error-messages="
            $v.full_name.$errors.length ? $v.full_name.$errors[0].$message : ''
          "
          maxlength="50"
          outlined
          placeholder="Enter Full Name"
        ></v-text-field>
      </v-card-text>
      
      <v-card-text v-show="!organization">
        <p class="titletext">Email</p>
        <v-text-field
          maxlength="50"
          v-show="!organization"
          :disabled="!!organization"
          v-model="email"
          :error-messages="
            $v.email.$errors.length ? $v.email.$errors[0].$message : ''
          "
          outlined
          placeholder="Enter Email"
        ></v-text-field>
      </v-card-text>
      <v-card-text>
        <p class="titletext">Details</p>
        <v-textarea
          maxlength="255"
          placeholder="Enter Details"
          :error-messages="
            $v.details.$errors.length ? $v.details.$errors[0].$message : ''
          "
          flat
          outlined
          v-model="details"
        ></v-textarea>
      </v-card-text>
      <v-card-text>
        <p class="titletext">Address</p>
        <v-textarea
          maxlength="255"
          placeholder="Enter Address"
          :error-messages="
            $v.address.$errors.length ? $v.address.$errors[0].$message : ''
          "
          flat
          outlined
          v-model="address"
        ></v-textarea>
      </v-card-text>
      <v-card-text  v-show="!organization">
        <p class="titletext">Personal Phone Number</p>
        <v-text-field
          outlined
          placeholder="Enter Personal Phone Number"
          :error-messages="
            $v.phone_number_user.$errors.length
              ? $v.phone_number_user.$errors[0].$message
              : ''
          "
          v-mask="[/[1-9]/, /[0-9]/, /[0-9]/, ' ' ,/[0-9]/, /[0-9]/, /[0-9]/, ' ' ,/[0-9]/, /[0-9]/, /[0-9]/,' ' ,/[0-9]/, /[0-9]/, /[0-9]/]"
          prefix="+62"
          v-model="phone_number_user"
        ></v-text-field>
      </v-card-text>
      <v-card-text>
        <p class="titletext">Corporate Phone Number</p>
        <v-text-field
          outlined
          placeholder="Enter Corporate Phone Number"
          :error-messages="
            $v.phone_number_org.$errors.length
              ? $v.phone_number_org.$errors[0].$message
              : ''
          "
          v-mask="[/[1-9]/, /[0-9]/, /[0-9]/, ' ' ,/[0-9]/, /[0-9]/, /[0-9]/, ' ' ,/[0-9]/, /[0-9]/, /[0-9]/,' ' ,/[0-9]/, /[0-9]/, /[0-9]/]"
          prefix="+62"
          v-model="phone_number_org"
        ></v-text-field>
      </v-card-text>
      <v-card-text>
        <p class="titletext">NPWP Number</p>
        <v-text-field
          :disabled="!!organization"
          outlined
          placeholder="Enter NPWP Number"
          :error-messages="
            $v.npwp_corporate.$errors.length
              ? $v.npwp_corporate.$errors[0].$message
              : ''
          "
          v-mask="'##.###.###.#-###.###'"
          v-model="npwp_corporate"
        ></v-text-field>
      </v-card-text>
      <v-card-text>
        <v-row>
          <v-col
            ><v-btn
              @click="
                () => {
                  dialog = false;
                }
              "
              depressed
              height="45"
              block
              class="accent"
              ><span class="fz-14">Cancel</span></v-btn
            ></v-col
          >
          <v-col
            ><v-btn
              id="Save"
              v-if="organization"
              @click="
                () => {
                  save();
                }
              "
              depressed
              height="45"
              block
              class="secondary"
              ><span class="fz-14">Save</span></v-btn
            >
            <v-btn
              id="Create"
              v-else
              @click="
                () => {
                  submit();
                }
              "
              depressed
              height="45"
              block
              class="secondary"
              ><span class="fz-14">Create</span></v-btn
            ></v-col
          >
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
import { ref, watch } from "@vue/composition-api";
import {
  useNamespacedActions,
  useNamespacedMutations,
} from "vuex-composition-helpers";
import { SUPERADMIN } from "../superadmin/namespace";
import { useDialog } from "@/composable/usedialog";
import { required, email as Email } from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";
import { SUPERADMINMANAGEORG } from "./namespace";
export default {
  props: ["value", "organization"],
  setup(props, context) {
    const name = ref();
    const full_name = ref();
    const email = ref();
    const details = ref();
    const address = ref();
    const phone_number_org = ref();
    const phone_number_user = ref();
    const npwp_corporate = ref();
    const { dialog } = useDialog(props, context);
    const { setCurrentOrg, setCurrentProj } = useNamespacedMutations(
      SUPERADMIN,
      ["setCurrentOrg", "setCurrentProj"]
    );
    if (props.organization) {
      name.value = props.organization.name;
      full_name.value = props.organization.full_name;
      email.value = props.organization.email;
      details.value = props.organization.details;
      address.value = props.organization.address;
      phone_number_org.value = props.organization.phone_number_org.replaceAll("+62", "");
      phone_number_user.value = props.organization.phone_number_org.replaceAll("+62", "");
      npwp_corporate.value = props.organization.npwp_corporate;
    }
    const emailalreadyexist = ref();
    const emaildetectedasfree = ref();
    const orgnamealreadyexist = ref();

    let $v;
    if (!props.organization) {
      $v = useVuelidate(
        {
          name: { required, conditional: {
              $message: "this name already registered",
              $validator: () => {
                return !orgnamealreadyexist.value;
              },
            }, },
          details: { required },
          full_name: { required },
          email: {
            required,
            Email,
            conditional: {
              $message: ()=>emailalreadyexist.value,
              $validator: () => {
                return !emailalreadyexist.value;
              },
            },
            // conditional2: {
            //   $message: "cannot use this email",
            //   $validator: () => {
            //     return !emaildetectedasfree.value;
            //   },
            // },
          },
          address: { required },
          phone_number_org: { required },
          phone_number_user: { required },
          npwp_corporate: { required },
        },
        {
          name,
          full_name,
          email,
          details,
          address,
          phone_number_org,
          phone_number_user,
          npwp_corporate,
        }
      );
      watch(email, (val) => {
        emailalreadyexist.value = "";
        emaildetectedasfree.value = "";
        
        $v.value.email.$reset();
      });
      watch(name, (val)=>{
        orgnamealreadyexist.value = "";
        $v.value.name.$reset();
      })
    } else {
      $v = useVuelidate(
        {
          name: { required },
          details: { required },
          full_name: {},
          email:{},
          address: { required },
          phone_number_org: { required },
          phone_number_user: { required },
          npwp_corporate: { required },
        },
        {
          name,
          full_name,
          email,
          details,
          address,
          phone_number_org,
          phone_number_user,
          npwp_corporate,
        }
      );
    }
    const {
      addorganization,
      editorganization,
    } = useNamespacedActions(SUPERADMINMANAGEORG, [
      "addorganization",
      "editorganization",
    ]);
    const {
      setopendialogcommonsuccess,
    } = useNamespacedMutations("DASHBOARD", [
      "setopendialogcommonsuccess",
    ]);
    const submit = () => {
      $v.value.$touch();
      if ($v.value.$errors.length) return;
      const Value = {
        name: name.value,
        details: details.value,
        full_name: full_name.value,
        email: email.value,
        address: address.value,
        phone_number_org: "+62" + phone_number_org.value,
        phone_number_user: "+62" + phone_number_user.value,
        npwp_corporate: npwp_corporate.value,
      };
      addorganization(Value)
        .then(() => {
          name.value = "";
          details.value = "";
          full_name.value = "";
          email.value = "";
          address.value = "";
          phone_number_org.value = "";
          phone_number_user.value = "";
          npwp_corporate.value = "";
          $v.value.$reset();
          dialog.value = false;
          context.emit("added");
          setopendialogcommonsuccess(true)
        })
        .catch((e) => {
          const { data } = e.response.data;
          if (data == "Email was ready exist") {
            emailalreadyexist.value = 'Email already exist';
          }
          if (data == "email detected as free email provider domain") {
            emailalreadyexist.value = 'Cannot use this Email';
          }
          if( data == "Organization name already exist"){
            emailalreadyexist.value = data
          }
        });
    };
    const save = () => {
      $v.value.$touch();
      if ($v.value.$errors.length) return;
      const Value = {
        name: name.value,
        details: details.value,
        full_name: full_name.value,
        address: address.value,
        phone_number_org: "+62" + phone_number_org.value,
        npwp_corporate: npwp_corporate.value,
      };

      Value.id = props.organization.id;
      editorganization(Value).then(() => {
        dialog.value = false;
        context.emit("updated");
        setopendialogcommonsuccess(true)
      });
    };
    return {
      name,
      full_name,
      email,
      details,
      address,
      phone_number_org,
      phone_number_user,
      submit,
      npwp_corporate,
      dialog,
      $v,
      save,
    };
  },
};
</script>
<style lang="scss" scoped>
.titletext {
  font-size: 12px;
  margin-bottom: 12px;
  font-weight: 700;
}
.v-input--is-disabled {
  ::v-deep .v-input__slot {
    background: #f1f2f2;
  }
}
</style>