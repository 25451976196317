<template>
  <v-container fluid >
    <v-row>
      <v-col class="pa-0">
        <v-card flat class="rounded-lg pa-3">
          <v-card-text>
            <div class="headline font-weight-bold font--text">
              Take snapshot
            </div>
          </v-card-text>
          <v-card-text>
            <p>
              Power down your Instance before taking a snapshot to ensure data
              consistency.
            </p>
            <p>
              We recommend turning off your Instance before taking a snapshot to
              ensure data consistency.
            </p>
          </v-card-text>
          <v-card-text>
            <p class="font-weight-bold">Enter Snapshot Name</p>
            <div class="d-flex">
              <v-text-field
                maxlength="50"
                v-model="snapshotName"
                outlined
                placeholder="Lintasarta-Super-Server-1606244240339"
              ></v-text-field>
              <div
                style="display: inline-block; width: 30px; height: auto"
              ></div>
              <v-btn 
                :disabled=" isLoading || !['ACTIVE', 'SHUTOFF'].includes(instance.state) || ![null, ''].includes(instance.revert_expired)" 
                depressed class="secondary" height="50" width="150"
               
                @click="() => { 
                  validateprivilages([
                    'Instance',
                    'editor',
                  ]).then(() => {
                    CreateSnapshot(true)
                  });  
                }"
                >Take Snapshot</v-btn
              >
            </div>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" class="px-0 py-5">
        <v-card flat class="rounded-lg pa-3">
          <v-card-text>
            <div class="headline font-weight-bold font--text">
              Snapshot List
            </div>
          </v-card-text>
          <v-card-text>
            <v-data-table
              hide-default-footer
              :headers="headers"
              :items="instanceSnapshots"
              :items-per-page="5"
            >
              <template v-slot:item.name="{ item }">
                <div class="d-flex">
                  <v-icon color="primary" class="mr-3"
                    >mdi-checkbox-blank-circle</v-icon
                  >
                  <div>
                    <p class="mb-0 primary--text">{{ item.name }}</p>
                  </div>
                </div>
              </template>
              <template v-slot:item.status="{ item }">
                <div>
                  <p v-if="item.is_upload_success" class="mb-0 primary--text">Active</p>
                  <p v-else-if="item.is_upload_failed" class="mb-0 danger--text">Failed</p>
                  <p v-else class="mb-0">Not ready yet</p>
                </div>
              </template>
              <template v-slot:item.size="{ item }">
                <div>{{ item.size ? `${item.size} GB` : '-' }}</div>
              </template>
              <template v-slot:item.createddate="{ item }">
                <div>
                  <p class="mb-0">{{ moment(item.createddate).format('DD/MM/YYYY') }}</p>
                </div>
              </template>
              <template v-slot:item.action="{ item }">
                <div class="linkpointer">
                <div
                  style="position: relative"
                  @click="show($event, item)"
                  >More
                  <v-icon color="primary">mdi-chevron-down</v-icon>
                </div>
                </div>
              </template>
              <template v-if="instanceSnapshots.length > 5" v-slot:footer="{ props }">
                <custom-footer-datatable :props="props" />
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-menu
      v-model="showMenu"
      :position-x="x - 10"
      :position-y="y"
      absolute
      allow-overflow
    >
      <v-list>
        <!-- <v-list-item :disabled="!validateprivilagesync(['Instance','editor'])" @click="()=>{opendialogrenameimage=true}"> -->
        <!-- <v-list-item disabled @click="()=>{opendialogrenameimage=true}">
          <v-list-item-title >Rename</v-list-item-title>
        </v-list-item> -->
        <!-- <v-list-item :disabled="!validateprivilagesync(['Instance','editor'])" to="/instance/create" @click.native="scrollTop"> -->
        <v-list-item 
          :disabled="disabledField || showMenu && !activeSnapshot.is_upload_success" 
          to="/instance/create" 
          @click.native="scrollTop">
          <v-list-item-title>Create Instance</v-list-item-title>
        </v-list-item>
        <!-- <v-list-item :disabled="!validateprivilagesync(['Instance','editor'])" @click="()=>{opendialogrestoreinstance = true}"> -->

        <!-- <v-list-item @click="()=>{ 
            opendialogrestoreinstance = true 
          }"
          :disabled="showMenu && !activeSnapshot.is_upload_success"
        >
          <v-list-item-title>Restore Instance</v-list-item-title>
        </v-list-item> -->

        <v-list-item 
        :disabled="disabledField"
        @click="()=>{ 
            opendialogrestoreinstance = true 
          }"
        >
          <v-list-item-title>Restore Instance</v-list-item-title>
        </v-list-item>

        <v-list-item  style="min-height: 20px"><v-divider /></v-list-item>
        <v-list-item
         :disabled="disabledField"
         @click="()=>{
          opendialogdeletesnapshot = true
        }">
          <v-list-item-title class="error--text">Delete</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
    <dialogRenameImage v-if="selectedsnapshot" :snapshot="activeSnapshot" v-model="opendialogrenameimage" @close="($event)=>{opendialogrenameimage=$event}" />
    
    <dialogBeforeRestoreInstance
      v-model="opendialogbeforerestoreinstance"
      @close="($event)=>{ opendialogbeforerestoreinstance = $event }"
      :item="payloadbeforerestore"
    />

    <dialogRestoreInstance
      v-model="opendialogrestoreinstance"
      :selectedSnapshot="activeSnapshot"
      :selectedInstance="instance"
      @close="($event)=>{opendialogrestoreinstance=$event}"
      @submit="
        ($event) => {
          opendialogrestoreinstance = false;
          opendialogbeforerestoreinstance = true;
          payloadbeforerestore = $event;
        }
      "
    />

    <dialogDeleteSnapshot v-model="opendialogdeletesnapshot" :snapshot="activeSnapshot" :filterByInstanceID="true" @close="($event)=>{opendialogdeletesnapshot=$event}" />
  </v-container>
</template>

<script>
import moment from 'moment'
import dialogRenameImage from "../../images/dialogRenameImage";
import dialogBeforeRestoreInstance from '@/modules/images/dialogBeforeRestoreInstance.vue';
import dialogRestoreInstance from '../../images/dialogRestoreInstance'
import dialogDeleteSnapshot from '../../images/dialogDelete'
import { useCreateSnapshot } from '../../images/composableapi/usecreatesnapshot'
import { ref, onMounted } from '@vue/composition-api'
import { useNamespacedActions } from 'vuex-composition-helpers';

export default {
  props: ['instance'],
  components: { dialogRenameImage, dialogBeforeRestoreInstance, dialogRestoreInstance, dialogDeleteSnapshot },
  setup(props, context){
    
    const Composable = {
      ...useCreateSnapshot()
    }
    
    const { 
      snapshotFrom, 
      instanceSnapshots,
      fetchInstanceSnapshots,
      fetchInstanceSnapshotsByInstanceID,
    } = Composable

    const disabledField = ref(false);
    snapshotFrom.value = props.instance
    snapshotFrom.value.__typename = 'instance'

    const {
      validateprivilages,
      validateprivilagesync,
      validateprivilagesyncnew,
    } = useNamespacedActions("HOMEPAGE", [
      "validateprivilages",
      "validateprivilagesync",
      "validateprivilagesyncnew",
      
    ]);

    validateprivilagesyncnew(["Instance", "editor"]).then((res) => {
      disabledField.value = res;
    });

    const instance_id = context.root._route.params.instanceid
    onMounted(async () => {
      if(instance_id) await fetchInstanceSnapshotsByInstanceID({ instance_id })
      // else await fetchInstanceSnapshots()
    })

    return {
      disabledField,
      validateprivilages,
      validateprivilagesyncnew,
      moment,
      instanceSnapshots,
      validateprivilagesync,
      selectedsnapshot: ref(''),
      ...Composable,
    }
  },
  data() {
    return {
      opendialogbeforerestoreinstance: false,
      payloadbeforerestore: null,
      opendialogdeletesnapshot: false,
      opendialogrestoreinstance: false,
      opendialogrenameimage: false,
      x: 0,
      y: 0,
      showMenu: false,
      activeSnapshot: null,
      headers: [
        {
          text: "Snapshot name",
          value: "name",
        },
        {
          text: "Status",
          value: "status",
        },
        {
          text: "Size",
          value: "size",
        },
        {
          text: "Created Date",
          value: "createddate",
        },
        {
          text: "Action",
          value: "action",
        },
      ]
    };
  },
  methods: {
    scrollTop(){
      document.documentElement.scrollTop = 0
    },
    show(e, snapshot) {
      e.preventDefault();
      this.showMenu = false
      this.activeSnapshot = snapshot
      this.x = e.clientX
      this.y = e.clientY
      this.$nextTick(() => {
        this.showMenu = true
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.headline {
  font-weight: 600;
  font-family: "Poppins", sans-serif;
}
[role="menu"] {
  box-shadow: none;
  &::before {
    content: url(/tri.png);
    position: relative;
    left: 5px;
  }
  .v-list-item {
    min-height: 38px;
  }
  .v-list-item__title {
    font-weight: 600;
  }
  .v-list {
    border-radius: 5px;
    margin: -8px 2px 2px 2px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }
}
</style>
