<template>
  <v-card-text>
    <v-container>
      <v-col class="justify-center">
        <h1
          style="
            text-align: center;
            font-weight: 800;
            font-size: 28px;
            line-height: 36px;
            letter-spacing: 0.04em;
            font-family: Mont;
            font-style: normal;
          "
        >
          {{isNeedCreateProject ? '1' : '2' }}. CREATE NEW PROJECT
        </h1>
        <p
          style="font-size: 20px; color: #a5b3bf; line-height: 27.24px"
          class="text-center mt-5"
        >
          You have to create project <br />for using the app
        </p>
        <v-form ref="form" style="margin-top: 40px" lazy-validation>
          <v-alert v-if="errorMessage" color="red" type="error"
            >{{ errorMessage }}
          </v-alert>

          <v-text-field
            id="projectName"
            flat
            outlined
            maxlength="30"
            v-model="projectName"
            placeholder="Project Name"
            required
            style="margin-bottom: 22px"
            :error-messages="
              projectNameValidation.$errors.length
                ? projectNameValidation.$errors[0].$message
                : ''
            "
          ></v-text-field>

          <v-textarea
            rows="5"
            id="description"
            flat
            outlined
            maxlength="255"
            v-model="description"
            placeholder="Description Project"
            required
            style="margin-bottom: 22px"
            :error-messages="
              descriptionValidation.$errors.length
                ? descriptionValidation.$errors[0].$message
                : ''
            "
          ></v-textarea>

          <v-btn
            id="submit"
            :disabled="isLoading"
            block
            height="50"
            depressed
            color="secondary"
            class="mr-4 mt-7 text-capitalize font-weight-bold"
            @click="save"
          >
            <beat-loader
              v-if="isLoading"
              :loading="isLoading"
              :color="'white'"
              :size="'10px'"
              class="ml-2"
            ></beat-loader>
            <span v-else>Create</span>
          </v-btn>
          <br />
        </v-form>
      </v-col>
    </v-container>
  </v-card-text>
</template>
<script>
import { useAction } from "./useAction";
import { mapGetters } from "vuex";
import localstorage from "@/lib/localstorage";

export default {
  computed: {
    ...mapGetters({
      loggedUser: "CREATEORGANIZATION/getProfile",
      isLoading: "CREATENEWPROJECT/isLoading",
    }),
    isNeedCreateProject() {
      return localstorage.getItem("need_create_project")
    }
  },
  data() {
    return {
      errorMessage: undefined,
      success: false,
    };
  },
  mounted() {
    // Gak tau kenapa, after create organization,
    // terus gak bisa nge call API create project (error 400)
    // harus relogin dlu (refresh token) baru bisa :D
    this.$store.dispatch("HOMEPAGE/relogin");
    const token = localstorage.getItem("token")
    if (!token) {
      this.$router.push("/")
    }
  },
  methods: {
  },
  setup(props, context) {
    const Composable = useAction();
    const { save: Save } = Composable;
    const $auth = context.root.$auth;
    return {
      ...Composable,
      async save() {
        try {
          let result = await Save();
          if (result.code === 200) {
            localstorage.removeItem("need_create_project");
            location.href = "/";
          } else {
            let error = result.response.data.data
            if (error.includes('already exist')) this.errorMessage = 'Project name already exists'
            else this.errorMessage = 'Internal server error. Please contact administrator.'
          }
        } catch (err) {
          let response_data = err.response.data.response_data;
          if (!response_data)
            response_data =
              "Internal server error. Please contact administrator for more help.";
          this.errorMessage = response_data;
        }
      },
    };
  },
};
</script>
<style lang="scss" scoped>
.v-card__text {
  ::v-deep .v-btn__content {
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 0.02em;
  }
}

.v-input {
  ::v-deep .v-input__slot {
    margin-bottom: 0;
  }
}
</style>