var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{staticClass:"rounded-lg",attrs:{"flat":""}},[_c('v-container',{staticClass:"pa-4"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"headline font-weight-bold font--text"},[_vm._v(" Create Instance ")])])],1),_c('v-row',[_c('v-col',{staticClass:"d-flex flex-row"},[_c('div',{staticClass:"d-flex align-center flex-row px-4 rounded-lg",style:({
                  border: ("2px solid " + (_vm.selecttypeinstance == 'operatingsystem'
                      ? '#2C94D2'
                      : '#BDBDBD')),
                  width: '233px',
                  cursor: 'pointer',
                }),attrs:{"id":"SelectOperatingSystem"},on:{"click":function () {
                    _vm.selecttypeinstance = 'operatingsystem';
                  }}},[_c('v-radio-group',{model:{value:(_vm.selecttypeinstance),callback:function ($$v) {_vm.selecttypeinstance=$$v},expression:"selecttypeinstance"}},[_c('v-radio',{attrs:{"value":"operatingsystem"}})],1),_c('p',{staticClass:"mb-0 font-weight-bold",staticStyle:{"font-size":"16px"}},[_vm._v(" Operating System ")])],1),_c('div',{staticStyle:{"width":"30px","height":"auto"}}),_c('div',{staticClass:"d-flex align-center flex-row px-4 rounded-lg",style:({
                  border: ("2px solid " + (_vm.selecttypeinstance == 'instanceimage'
                      ? '#2C94D2'
                      : '#BDBDBD')),
                  width: '233px',
                  cursor: 'pointer',
                }),on:{"click":function () {
                    _vm.selecttypeinstance = 'instanceimage';
                  }}},[_c('v-radio-group',{model:{value:(_vm.selecttypeinstance),callback:function ($$v) {_vm.selecttypeinstance=$$v},expression:"selecttypeinstance"}},[_c('v-radio',{attrs:{"value":"instanceimage"}})],1),_c('p',{staticClass:"mb-0 font-weight-bold",staticStyle:{"font-size":"16px"}},[_vm._v(" Instance Image ")])],1)])],1),_c('small',{staticClass:"error--text"},[_vm._v(" "+_vm._s(_vm.$v.selecttypeinstance.$errors.length ? _vm.$v.selecttypeinstance.$errors[0].$message : "")+" ")]),(_vm.selecttypeinstance == 'operatingsystem')?_c('v-row',[_c('v-col',{staticClass:"mb-0 pb-0",attrs:{"cols":"12"}},[_c('p',{staticClass:"font-weight-bold"},[_vm._v(" Select Operating System & Version ")])]),_c('v-col',{staticClass:"d-flex flex-wrap pt-0 pl-0 ml-0"},[_c('v-sheet',{staticClass:"mt-0 pl-0 ml-0",attrs:{"max-width":"100%"}},[_c('v-slide-group',{staticClass:"py-2 pt-0 ma-0",attrs:{"active-class":"success","show-arrows":""}},_vm._l((_vm.distinctOS),function(os){return _c('v-card',{key:os.value,staticClass:"rounded-lg select-instance pb-0 ",class:{
                      selected: _vm.isSelected(os.value),
                    },staticStyle:{"margin-right":"20px","min-width":"170px","max-width":"210px","max-height":"210px"},style:({
                      border: ("2px solid " + (os.value === _vm.osSelected ? '#2C94D2' : '#BDBDBD')),
                      width: '233px',
                      cursor: 'pointer',
                    }),attrs:{"border-color":"primary","outlined":""},on:{"click":function () {
                        _vm.osSelected = os.value;
                      }}},[_c('v-card-text',{staticClass:"pb-0 px-2 text-center"},[_c('div',{staticClass:"pa-0 ma-0 d-flex justify-center"},[_c('v-img',{staticClass:"mb-2",staticStyle:{"max-height":"75px","max-width":"75px"},attrs:{"aspect-ratio":1 / 1,"src":os.logo,"alt":os.text}})],1),_c('div',{staticClass:"pa-0 ma-0"},[_c('p',{staticClass:"font-weight-bold ",style:({
                            color: ("" + (os.value === _vm.osSelected ? '#2C94D2' : '#BDBDBD')),
                          })},[_vm._v(" "+_vm._s(os.text)+" ")])])]),_c('v-divider',{staticClass:"mb-1",style:({
                        border: ("1px solid " + (os.value === _vm.osSelected ? '#2C94D2' : '#BDBDBD')),
                        width: '233px',
                        cursor: 'pointer',
                        color: ("" + (os.value === _vm.osSelected ? '#2C94D2' : '#BDBDBD')),
                        background: ("" + (os.value === _vm.osSelected ? '#2C94D2' : '#BDBDBD')),
                      })}),_c('v-card-text',{staticClass:"ma-0 pa-0 "},[(os.value === _vm.osSelected)?_c('v-select',{staticClass:"ma-0 px-0 py-0 select-version",style:({
                          color: ("" + (os.value === _vm.osSelected ? '#2C94D2' : '#BDBDBD')),
                        }),attrs:{"id":"SelectVersion","error-messages":_vm.$v.versionSelected.$errors.length
                            ? _vm.$v.versionSelected.$errors[0].$message
                            : '',"items":_vm.images
                            .filter(function (x) { return x.os_id === _vm.osSelected; })
                            .map(function (x) {
                              return {
                                text: ((x.version_number) + " " + (x.version_name)),
                                value: x.id,
                              };
                            }),"placeholder":"Select Version","outlined":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
                            var item = ref.item;
return [_c('p',{staticClass:"font-weight-medium mb-0 my-0 pa-0",style:({
                              color: ("" + (os.value === _vm.osSelected
                                  ? '#2C94D2'
                                  : '#BDBDBD')),
                            })},[_vm._v(" "+_vm._s(item.text)+" ")])]}},{key:"item",fn:function(ref){
                            var item = ref.item;
return [_vm._v(" "+_vm._s(item.text)+" ")]}}],null,true),model:{value:(_vm.versionSelected),callback:function ($$v) {_vm.versionSelected=$$v},expression:"versionSelected"}}):_c('v-select',{staticClass:"ma-0 px-0 py-0 select-version",style:({
                          color: ("" + (os.value === _vm.osSelected ? '#2C94D2' : '#BDBDBD')),
                        }),attrs:{"id":"SelectVersion","error-messages":_vm.$v.versionSelected.$errors.length
                            ? _vm.$v.versionSelected.$errors[0].$message
                            : '',"items":_vm.images
                            .filter(function (x) { return x.os_id === _vm.osSelected; })
                            .map(function (x) {
                              return {
                                text: ((x.version_number) + " " + (x.version_name)),
                                value: x.id,
                              };
                            }),"placeholder":"Select Version","outlined":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
                            var item = ref.item;
return [_c('p',{staticClass:"font-weight-medium mb-0 my-0 pa-0",style:({
                              color: ("" + (os.value === _vm.osSelected
                                  ? '#2C94D2'
                                  : '#BDBDBD')),
                            })},[_vm._v(" "+_vm._s(item.text)+" ")])]}},{key:"item",fn:function(ref){
                            var item = ref.item;
return [_vm._v(" "+_vm._s(item.text)+" ")]}}],null,true)})],1)],1)}),1)],1)],1)],1):_vm._e(),(_vm.selecttypeinstance == 'instanceimage')?_c('v-row',[_c('v-col',{attrs:{"sm":"12","md":"6"}},[_c('p',{staticClass:"font-weight-bold"},[_vm._v("Instance Image")]),_c('v-select',{attrs:{"id":"selectCustomImages","error-messages":_vm.$v.selectedCustomImage.$errors.length
                    ? _vm.$v.selectedCustomImage.$errors[0].$message
                    : '',"outlined":"","items":_vm.selectCustomImages,"placeholder":"Choose Custom Image"},model:{value:(_vm.selectedCustomImage),callback:function ($$v) {_vm.selectedCustomImage=$$v},expression:"selectedCustomImage"}})],1)],1):_vm._e(),_c('v-row',[_c('v-col',{staticClass:"pt-0",attrs:{"sm":"12","md":"6"}},[_c('p',{staticClass:"font-weight-bold"},[_vm._v("Choose Region")]),_c('v-select',{attrs:{"id":"SelectRegion","outlined":"","items":_vm.selectregions,"placeholder":"Choose Region"},model:{value:(_vm.selectedRegion),callback:function ($$v) {_vm.selectedRegion=$$v},expression:"selectedRegion"}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"pt-0",attrs:{"md":"6","sm":"12"}},[_c('p',{staticClass:"font-weight-bold"},[_vm._v("VPC Network")]),_c('v-select',{attrs:{"id":"VPCNetwork","placeholder":"Select VPC Network","error-messages":_vm.$v.vpcidselected.$errors.length
                    ? _vm.$v.vpcidselected.$errors[0].$message
                    : '',"items":_vm.vpcItems,"outlined":""},model:{value:(_vm.vpcidselected),callback:function ($$v) {_vm.vpcidselected=$$v},expression:"vpcidselected"}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"pt-0",attrs:{"cols":"12","md":"6"}},[_c('p',[_c('b',[_vm._v("Instance Billing Type")])]),_c('v-select',{attrs:{"id":"billingType","placeholder":"Choose Instance Billing Type","error-messages":_vm.$v.selectedBillingType.$errors.length
                    ? _vm.$v.selectedBillingType.$errors[0].$message
                    : '',"items":_vm.billingTypes,"outlined":""},model:{value:(_vm.selectedBillingType),callback:function ($$v) {_vm.selectedBillingType=$$v},expression:"selectedBillingType"}})],1)],1),_c('v-row',[(_vm.selectedBillingType == 'Trial')?_c('v-col',{staticClass:"pt-0",attrs:{"cols":"12","md":"6"}},[_c('p',[_c('b',[_vm._v("Trial Voucher")])]),_c('v-select',{attrs:{"id":"trialVoucher","placeholder":"Choose trial voucher to use","error-messages":_vm.$v.selectedTrialVoucher.$errors.length
                    ? _vm.$v.selectedTrialVoucher.$errors[0].$message
                    : '',"items":_vm.vouchers,"item-text":"voucher.name","item-value":"id","outlined":""},model:{value:(_vm.selectedTrialVoucher),callback:function ($$v) {_vm.selectedTrialVoucher=$$v},expression:"selectedTrialVoucher"}})],1):_vm._e()],1),_c('v-row',[_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","md":"6"}},[_c('p',[_c('b',[_vm._v("Choose a Plan")])]),_c('v-select',{attrs:{"id":"chooseaplan","placeholder":"Choose a Plan","disabled":!_vm.selectedRegion,"error-messages":_vm.$v.planSelected.$errors.length
                    ? _vm.$v.planSelected.$errors[0].$message
                    : '',"items":_vm.plans,"outlined":""},model:{value:(_vm.planSelected),callback:function ($$v) {_vm.planSelected=$$v},expression:"planSelected"}})],1)],1),_c('v-row',[(_vm.filteredFlavors && Object.keys(_vm.planSelected).length > 0)?_c('v-col',{staticClass:"mb-2",attrs:{"cols":"12"}},[_c('div',[_c('p',{staticClass:"mb-0"},[_c('span',{staticClass:"font-weight-bold"},[_vm._v("Instance Size")])])])]):_vm._e(),_c('v-col',{staticClass:"d-flex flex-wrap pt-0",attrs:{"id":"SelectInstanceSize"}},[(
                  Object.keys(_vm.planSelected).length && !_vm.filteredFlavors.length
                )?_c('v-card',{staticClass:"rounded-lg select-instance mb-5 mt-4",staticStyle:{"margin-right":"20px"},attrs:{"width":"170","height":"100","border-color":"primary","outlined":"","disabled":""}},[_c('v-card-text',{staticClass:"pb-0 text-center"},[_c('p',{staticClass:"mb-0"},[_vm._v(" There is no available flavor for selected plan & region ")])])],1):_vm._e(),(
                  Object.keys(_vm.planSelected).length && _vm.filteredFlavors.length
                )?_c('v-sheet',{staticClass:"mt-0",attrs:{"max-width":"100%"}},[_c('v-slide-group',{staticClass:"py-4 pt-0",attrs:{"active-class":"success","show-arrows":""}},_vm._l((_vm.filteredFlavors),function(instance){return _c('v-card',{key:instance.id,staticClass:"rounded-lg select-instance",class:{
                      selected: _vm.isSelected(instance.id),
                    },staticStyle:{"margin-right":"20px","min-width":"170px"},style:({
                      border: _vm.$v.instanceselected.$errors.length
                        ? '1px solid #eb5757 !important'
                        : '',
                    }),attrs:{"disabled":instance.outOfStock,"border-color":"primary","outlined":""},on:{"click":function () {
                        _vm.instanceselected = instance.id;
                      }}},[_c('v-card-text',{staticClass:"m-0 p-0",staticStyle:{"padding":"20px"}},[_c('p',{staticClass:"fz-12 p-0",staticStyle:{"margin-bottom":"0"}},[_c('b',[_vm._v(_vm._s(instance.vcpu)+" CPU")]),_vm._v(" / "+_vm._s(instance.ram)+" GB Memory "),_c('br')]),(instance.gpu)?_c('p',{staticClass:"fz-12 ma-0 p-0"},[_vm._v(" with "),_c('b',[_vm._v(_vm._s(instance.gpu))]),_vm._v(" unit"+_vm._s(instance.gpu > 1 ? "s" : "")+" Nvidia T4 ")]):_vm._e(),(instance.outOfStock)?_c('p',{staticClass:"text-center mb-0",staticStyle:{"font-size":"10px"}},[_vm._v(" Out of Stock ")]):_vm._e()])],1)}),1)],1):_vm._e()],1)],1),(_vm.isDevModeStorageTiering2)?_c('v-row',[_c('v-col',{staticClass:"pt-0",attrs:{"cols":"12","md":"6"}},[_c('p',[_c('b',[_vm._v("Storage Type")])]),_c('v-select',{attrs:{"id":"vm_root_disk_type_id","placeholder":"Choose Storage Type","error-messages":_vm.$v.vm_root_disk_type_id &&
                  _vm.$v.vm_root_disk_type_id.$errors.length
                    ? _vm.$v.vm_root_disk_type_id.$errors[0].$message
                    : '',"items":_vm.volumeTypes,"outlined":""},model:{value:(_vm.vm_root_disk_type_id),callback:function ($$v) {_vm.vm_root_disk_type_id=$$v},expression:"vm_root_disk_type_id"}})],1)],1):_vm._e(),_c('v-row',[_c('v-col',{staticClass:"py-0"},[_c('p',{staticClass:"font-weight-bold"},[_vm._v(" Instance Root Disk "),(_vm.useRootDiskFromSnapshot)?_c('span',{staticClass:"primary--text ml-2",staticStyle:{"font-size":"12px"}},[_vm._v(" *Instance root disk size automatically uses the size of root disk in the selected snapshot. ")]):_vm._e(),(_vm.$v.rootDiskSelected.$errors.length)?_c('span',{staticClass:"ml-2",class:{
                    'error--text': _vm.$v.rootDiskSelected.$errors.length,
                  },staticStyle:{"font-size":"10px"}},[_vm._v(" *"+_vm._s(_vm.$v.rootDiskSelected.$errors[0].$message)+" ")]):_vm._e(),(_vm.$v.customRootDiskSize.$errors.length)?_c('span',{staticClass:"ml-2 pt-1",class:{
                    'error--text': _vm.$v.customRootDiskSize.$errors.length,
                  },staticStyle:{"font-size":"10px"}},[_vm._v(" *"+_vm._s(_vm.$v.customRootDiskSize.$errors[0].$message)+" ")]):_vm._e()])])],1),_c('v-row',[_c('v-slide-group',{staticClass:"py-4 pt-0",attrs:{"active-class":"success","show-arrows":""}},[_c('v-col',{staticClass:"d-flex py-0 flex-wrap"},[_c('v-card',{class:("custom-flavor-card rounded-lg select-instance mb-4 " + (_vm.isCustomRootDiskSizeHighlighted
                        ? 'highlighted selected'
                        : '')),staticStyle:{"text-align":"center","margin-right":"20px"},attrs:{"tabindex":"0","min-width":"170","border-color":"primary","outlined":"","readonly":_vm.useRootDiskFromSnapshot,"id":"customSizeRootDisk"}},[_c('v-card-text',{staticClass:"pa-0"},[_c('v-text-field',{staticClass:"px-4 pt-2",staticStyle:{"width":"170px"},attrs:{"flat":"","label":"Enter Size in GB","disabled":_vm.useRootDiskFromSnapshot},on:{"keydown":function (ev) { return (_vm.customRootDiskSize = _vm.addGBText(
                            ev,
                            _vm.customRootDiskSize
                          )); },"focus":function () {
                          _vm.rootDiskSelected = null;
                          _vm.isCustomRootDiskSizeHighlighted = true;
                        },"blur":function () {
                          if (!_vm.customRootDiskSize)
                            { _vm.isCustomRootDiskSizeHighlighted = false; }
                        }},model:{value:(_vm.customRootDiskSize),callback:function ($$v) {_vm.customRootDiskSize=$$v},expression:"customRootDiskSize"}})],1)],1),_vm._l((_vm.vm_root_disk_type_id // if vm_root_disk_type_id selected
                    ? _vm.rootDiskVolumeSize.filter(
                        function (v) { return v.type_id === _vm.vm_root_disk_type_id; }
                      ) // else
                    : _vm.rootDiskVolumeSize),function(storage){return _c('v-card',{key:storage.id,staticClass:"rounded-lg select-instance mb-4 flavor-card",class:{
                    bordererror: _vm.$v.rootDiskSelected.$errors.length,
                    highlight: _vm.rootDiskSelected
                      ? _vm.is(_vm.rootDiskSelected.id, storage.id)
                      : false,
                    selected: _vm.rootDiskSelected
                      ? _vm.is(_vm.rootDiskSelected.id, storage.id)
                      : false,
                  },staticStyle:{"text-align":"center","margin-right":"20px"},attrs:{"width":"170","disabled":_vm.useRootDiskFromSnapshot,"border-color":"primary","outlined":""},on:{"click":function () {
                      _vm.rootDiskSelected = storage;
                      _vm.isCustomRootDiskSizeHighlighted = false;
                      _vm.customRootDiskSize = null;
                    }}},[_c('v-card-text',[_c('p',{staticClass:"font-weight-bold mb-0"},[_vm._v(" "+_vm._s(storage.volume_size)+" GB ")])])],1)})],2)],1)],1),(!_vm.isDevModeStorageTiering2)?_c('v-row',[_c('v-col',{staticClass:"pt-0"},[_c('p',{staticClass:"font-weight-bold"},[_vm._v("Instance Data Disk")]),_c('v-btn',{staticClass:"primary--text",staticStyle:{"border":"3px solid #1f60a8"},attrs:{"depressed":"","outlined":"","height":"45","width":"150"},on:{"click":function () {
                    _vm.addvolume = true;
                  }}},[_vm._v("Add Data Disk ")])],1)],1):_vm._e(),(_vm.isDevModeStorageTiering2)?_c('v-row',[_c('v-col',[_c('p',{staticClass:"font-weight-bold",staticStyle:{"font-size":"21px","margin-bottom":"10px"}},[_vm._v(" Default Instance Data Disk ")])])],1):_vm._e(),(_vm.isDevModeStorageTiering2)?[_c('v-row',[_c('v-col',{staticClass:"pt-0",attrs:{"cols":"12","md":"5"}},[_c('p',[_c('b',[_vm._v("Storage Type")])]),_c('v-select',{attrs:{"id":"billingType","placeholder":"Choose Storage Type","error-messages":_vm.defaultDiskTypeError,"items":_vm.volumeTypes,"outlined":""},model:{value:(_vm.defaultDiskType),callback:function ($$v) {_vm.defaultDiskType=$$v},expression:"defaultDiskType"}})],1),_c('v-col',{staticClass:"pt-0",attrs:{"cols":"12","md":"5"}},[_c('p',[_c('b',[_vm._v("Size")])]),_c('v-select',{attrs:{"id":"size","placeholder":"Choose Size","error-messages":_vm.defaultDiskSizeError,"items":_vm.defaultDiskType
                      ? _vm.dataDisks
                          .filter(function (disk) { return disk.type_id === _vm.defaultDiskType; })
                          .map(function (disk2) {
                            return {
                              text: disk2.volume_size + ' GB',
                              value: disk2.id,
                            };
                          })
                      : _vm.dataDisks.map(function (disk2) {
                          return {
                            text: disk2.volume_size + ' GB',
                            value: disk2.id,
                          };
                        }),"outlined":""},model:{value:(_vm.defaultDiskSize),callback:function ($$v) {_vm.defaultDiskSize=$$v},expression:"defaultDiskSize"}})],1),_c('v-col',{staticClass:"pt-0",attrs:{"cols":"12","md":"2"}},[_c('p',{staticStyle:{"opacity":"0"}},[_vm._v(" asd ")]),_c('v-btn',{staticClass:"secondary white--text",attrs:{"depressed":"","outlined":"","height":"48","width":"100%"},on:{"click":_vm.addDefaultDisk}},[_vm._v(" Add ")])],1)],1),_c('v-row',[_c('v-col',[_c('p',{staticClass:"error--text",style:({
                    opacity: _vm.addDefaultDiskError ? 1 : 0,
                  })},[_c('b',[_vm._v(_vm._s(_vm.addDefaultDiskError))])])])],1)]:_vm._e(),(_vm.isDevModeStorageTiering2)?_c('v-row',{staticClass:"align-center"},[_c('v-col',[_c('h2',[_vm._v("Custom Instance Data Disk (max 12 Data Disk)")])]),_c('v-col',{staticClass:"d-flex flex-column align-end"},[_c('v-btn',{staticClass:"primary--text",staticStyle:{"border":"3px solid #1f60a8"},attrs:{"depressed":"","outlined":"","height":"45","width":"150"},on:{"click":_vm.addCustomDataDisk}},[_vm._v(" Add Custom Data Disk ")]),_c('p',{staticClass:"error--text",style:({
                  opacity: _vm.customDiskError ? 1 : 0,
                })},[_vm._v(" "+_vm._s(_vm.customDiskError)+" ")])],1)],1):_vm._e(),(_vm.isDevModeStorageTiering2)?_c('v-row',[_c('v-col',[_c('v-data-table',{attrs:{"headers":_vm.customDataDiskHeaders,"items":_vm.customDataDiskItems,"items-per-page":13,"hide-default-footer":""},scopedSlots:_vm._u([{key:"item.type",fn:function(ref){
                var item = ref.item;
return [_c('v-select',{attrs:{"placeholder":"Choose Storage Type","value":item.type,"error-messages":item.typeError ? 'Value is required' : '',"items":_vm.volumeTypes,"outlined":"","disabled":item.disable,"filled":item.disable},on:{"input":function (e) {
                        _vm.customDiskItemChange(item.index, 'type', e);
                      }}})]}},{key:"item.size",fn:function(ref){
                      var item = ref.item;
return [_c('v-text-field',{attrs:{"value":item.size,"error-messages":item.sizeError,"outlined":"","placeholder":"Enter in GB","disabled":item.disable,"filled":item.disable},on:{"keypress":function($event){return _vm.isNumber($event)},"input":function (e) {
                        _vm.customDiskItemChange(
                          item.index,
                          'size',
                          e ? parseInt(e) : e
                        );
                      }},scopedSlots:_vm._u([(item.size)?{key:"append",fn:function(){return [_c('span',{staticClass:"font--text"},[_vm._v(" GB ")])]},proxy:true}:null],null,true)})]}},{key:"item.index",fn:function(ref){
                      var item = ref.item;
return [_c('v-btn',{staticClass:"error white--text",attrs:{"depressed":"","outlined":"","height":"45","width":"150"},on:{"click":function($event){return _vm.deleteCustomDataDisk(item.index)}}},[_vm._v(" Delete ")])]}}],null,false,1176426187)})],1)],1):_c('v-row',[_c('v-col',{staticClass:"d-flex pt-0 flex-wrap"},[_c('v-card',{key:_vm.defaultstoragesize.id,class:("rounded-lg py-0 select-instance custom-flavor-card mb-4 " + (_vm.isCustomSizeHighlighted ? 'highlighted selected' : '')),staticStyle:{"text-align":"center","margin-right":"20px"},attrs:{"tabindex":"0","min-width":"170","border-color":"primary","outlined":""}},[_c('v-card-text',{staticClass:"pa-0"},[_c('v-text-field',{staticClass:"px-4 pt-2",staticStyle:{"width":"170px"},attrs:{"flat":"","label":"Enter Size in GB"},on:{"keydown":function (ev) { return (_vm.customSize = _vm.addGBText(ev, _vm.customSize)); },"focus":function () {
                        _vm.storageselected = null;
                        _vm.isCustomSizeHighlighted = true;
                      },"blur":function () {
                        if (!_vm.customSize) { _vm.isCustomSizeHighlighted = false; }
                      }},model:{value:(_vm.customSize),callback:function ($$v) {_vm.customSize=$$v},expression:"customSize"}})],1)],1),_vm._l((_vm.selectedVolumeType
                  ? _vm.dataDisks.filter(
                      function (disk) { return disk.type_id === _vm.selectedVolumeType; }
                    )
                  : _vm.dataDisks),function(storage){return _c('v-card',{key:storage.id,staticClass:"rounded-lg select-instance mb-4 flavor-card",class:{
                  highlight: _vm.storageselected
                    ? _vm.is(_vm.storageselected.id, storage.id)
                    : false,
                  selected: _vm.storageselected
                    ? _vm.is(_vm.storageselected.id, storage.id)
                    : false,
                },staticStyle:{"text-align":"center","margin-right":"20px"},attrs:{"width":"170","border-color":"primary","outlined":""},on:{"click":function () {
                    _vm.storageselected = storage;
                    _vm.isCustomSizeHighlighted = false;
                    _vm.customSize = null;
                  }}},[_c('v-card-text',[_c('p',{staticClass:"font-weight-bold mb-0"},[_vm._v(" "+_vm._s(storage.volume_size)+" GB ")])])],1)})],2)],1),_c('v-divider'),_c('v-row',[_c('v-col',[_c('p',{staticClass:"font-weight-bold",staticStyle:{"font-size":"21px","margin-bottom":"10px"}},[_vm._v(" Authentication ")])])],1),_c('v-row',[_c('v-col',{staticClass:"d-flex flex-nowrap",attrs:{"cols":"12","sm":"6","md":"4"}},[_c('v-card',{staticClass:"rounded-lg d-flex flex-grow-1",style:({
                  border: _vm.$v.authentication.$errors.length
                    ? '2px solid #eb5757 !important'
                    : ("2px solid " + (_vm.authentication == 'ssh' ? '#2C94D2' : '#BDBDBD')),
                }),attrs:{"flat":"","outlined":""},on:{"click":function () {
                    _vm.authentication = 'ssh';
                  }}},[_c('v-card-title',{staticClass:"d-flex flex-nowrap"},[_c('div',[_c('v-radio-group',{staticStyle:{"margin-top":"-10px"},attrs:{"error-messages":_vm.$v.authentication.$errors.length
                          ? _vm.$v.authentication.$errors[0].$message
                          : '',"hide-details":""},model:{value:(_vm.authentication),callback:function ($$v) {_vm.authentication=$$v},expression:"authentication"}},[_c('v-radio',{staticClass:"authentication",attrs:{"value":"ssh"}})],1)],1),_c('div',{staticClass:"ml-2"},[_c('p',{staticClass:"my-0 font-weight-bold",staticStyle:{"font-size":"21px"}},[_vm._v(" SSH Keys ")]),_c('p',{staticClass:"my-0 accent--text",staticStyle:{"font-size":"12px"}},[_vm._v(" A more secure authentication method ")])])])],1)],1),_c('v-col',{staticClass:"d-flex flex-nowrap",attrs:{"cols":"12","sm":"6","md":"4"}},[_c('v-card',{staticClass:"rounded-lg d-flex flex-grow-1",style:({
                  border: _vm.$v.authentication.$errors.length
                    ? '2px solid #eb5757 !important'
                    : ("2px solid " + (_vm.authentication == 'password' ? '#2C94D2' : '#BDBDBD')),
                }),attrs:{"flat":"","outlined":""},on:{"click":function () {
                    _vm.authentication = 'password';
                  }}},[_c('v-card-title',{staticClass:"d-flex flex-nowrap"},[_c('div',[_c('v-radio-group',{staticStyle:{"margin-top":"-10px"},attrs:{"error-messages":_vm.$v.authentication.$errors.length
                          ? _vm.$v.authentication.$errors[0].$message
                          : '',"hide-details":""},model:{value:(_vm.authentication),callback:function ($$v) {_vm.authentication=$$v},expression:"authentication"}},[_c('v-radio',{staticClass:"authentication",attrs:{"value":"password"}})],1)],1),_c('div',{staticClass:"ml-2"},[_c('p',{staticClass:"my-0 font-weight-bold",staticStyle:{"font-size":"21px"}},[_vm._v(" Password ")]),_c('p',{staticClass:"my-0 accent--text",staticStyle:{"font-size":"12px"}},[_vm._v(" Create a root password to access VM (less secure) ")])])])],1)],1)],1),(_vm.$v.authentication.$errors.length)?_c('span',{staticClass:"ml-3 red--text"},[_vm._v("You are required to choose one authentication method")]):_vm._e(),_c('v-row',{staticStyle:{"position":"relative"}},[_c('transition',{attrs:{"name":"slide-fade"}},[_c('v-col',{directives:[{name:"show",rawName:"v-show",value:(_vm.authentication == 'ssh'),expression:"authentication == 'ssh'"}],staticClass:"ssh",attrs:{"cols":"12","md":"12"}},[_c('v-card',{staticClass:"rounded-lg d-flex flex-grow-1",attrs:{"flat":"","outlined":""}},[_c('v-card-title',{staticClass:"d-flex flex-nowrap"},[_c('div',{staticClass:"ml-2"},[_c('div',{staticClass:"d-flex mb-0"},[_c('p',{staticClass:"font-weight-bold"},[_vm._v("Choose your SSH Keys")])]),_c('div',{staticClass:"d-flex flex-wrap"},_vm._l((_vm.sshkeys),function(sshkey){return _c('div',{key:sshkey.id,staticClass:"d-flex flex-wrap mr-5"},[_c('v-checkbox',{key:sshkey.id,staticClass:"m-0 mr-0",attrs:{"value":sshkey.id,"label":sshkey.name},model:{value:(_vm.selectedssh),callback:function ($$v) {_vm.selectedssh=$$v},expression:"selectedssh"}}),_c('v-icon',{attrs:{"color":"red"},on:{"click":function () {
                                _vm.keyid = sshkey.id;
                                _vm.openDialogDeleteSsh = true;
                              }}},[_vm._v("mdi-delete-outline")]),_c('v-icon',{attrs:{"color":"secondary"},on:{"click":function () {
                                _vm.sshkeyselected = sshkey;
                                _vm.dialogupdate = true;
                              }}},[_vm._v("mdi-pencil-box-outline")])],1)}),0),_c('div',{staticClass:"d-flex"},[_c('v-btn',{attrs:{"depressed":"","id":"NewKey","height":"45","color":"secondary","outlined":""},on:{"click":function($event){_vm.dialogssh = true}}},[_c('span',{staticStyle:{"font-size":"16px"}},[_vm._v("Add New SSH Key")])])],1)])])],1)],1)],1),_c('transition',{attrs:{"name":"slide-fade"}},[_c('v-col',{directives:[{name:"show",rawName:"v-show",value:(_vm.authentication == 'password'),expression:"authentication == 'password'"}],staticClass:"password",attrs:{"cols":"12"}},[_c('v-card',{staticClass:"rounded-lg",attrs:{"flat":"","outlined":""}},[_c('v-card-text',{staticClass:"pb-0"},[_c('span',{staticClass:"font-weight-bold",staticStyle:{"font-size":"16px"}},[_vm._v("Create root password")])]),_c('v-card-text',[_c('v-text-field',{attrs:{"id":"createpassword","type":"password","error-messages":_vm.$v.password.$errors.length
                          ? _vm.$v.password.$errors[0].$message
                          : '',"placeholder":"Type your password","outlined":""},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}}),_c('p',{staticClass:"accent--text"},[_c('v-icon',{attrs:{"color":"primary","left":""}},[_vm._v("mdi-alert-circle-outline ")]),_vm._v(" You will not be sent an email containing the Instance details or password. Please store your password securely. ")],1)],1)],1)],1)],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('p',{staticClass:"font-weight-bold"},[_vm._v("Instance Name")]),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"6"}},[_c('v-text-field',{attrs:{"maxlength":"50","id":"instancename","error-messages":_vm.$v.instanceName.$errors.length
                        ? _vm.$v.instanceName.$errors[0].$message
                        : '',"outlined":"","placeholder":"Instance Name"},model:{value:(_vm.instanceName),callback:function ($$v) {_vm.instanceName=$$v},expression:"instanceName"}})],1)],1)],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('v-btn',{staticClass:"primary",staticStyle:{"background-color":"#a5b3bf !important"},attrs:{"depressed":"","block":"","height":"58","to":"/instance","exact":""}},[_c('span',{staticStyle:{"font-size":"16px"}},[_vm._v("Cancel")])])],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-btn',{staticClass:"secondary",attrs:{"depressed":"","id":"SubmitCreateInstance","block":"","height":"58"},on:{"click":_vm.createInstance}},[_c('span',{staticStyle:{"font-size":"16px"}},[_vm._v("Create Instance")])])],1)],1)],2)],1)],1)],1),_c('dialogcreatessh',{on:{"close":function () {
        _vm.dialogssh = false;
      }},model:{value:(_vm.dialogssh),callback:function ($$v) {_vm.dialogssh=$$v},expression:"dialogssh"}}),(_vm.sshkeyselected && _vm.dialogupdate)?_c('dialogupdatessh',{attrs:{"sshselected":_vm.sshkeyselected},on:{"close":function () {
        _vm.dialogupdate = false;
      }},model:{value:(_vm.dialogupdate),callback:function ($$v) {_vm.dialogupdate=$$v},expression:"dialogupdate"}}):_vm._e(),_c('v-dialog',{attrs:{"max-width":"500"},model:{value:(_vm.openDialogDeleteSsh),callback:function ($$v) {_vm.openDialogDeleteSsh=$$v},expression:"openDialogDeleteSsh"}},[_c('v-card',[_c('v-card-title',[_c('div',{staticClass:"headline font-weight-bold font--text pb-4"},[_vm._v(" Delete this Ssh key ")])]),_c('v-card-text',{staticClass:"pb-0"},[_c('p',[_vm._v("Are you sure you delete this ssh key?")])]),_c('v-card-actions',[_c('v-row',{staticClass:"ma-2"},[_c('v-col',{attrs:{"cols":"6"}},[_c('v-btn',{attrs:{"color":"accent","block":"","depressed":"","height":"50"},on:{"click":function () {
                  _vm.openDialogDeleteSsh = false;
                }}},[_vm._v(" Cancel ")])],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-btn',{attrs:{"color":"error","block":"","height":"50","depressed":""},on:{"click":function () {
                  _vm.deletekey(_vm.keyid);
                }}},[(_vm.isLoading)?_c('beat-loader',{staticClass:"mr-2",staticStyle:{"transform":"translateY(3px)"},attrs:{"loading":_vm.isLoading,"color":'white',"size":'10px'}}):_vm._e(),_vm._v(" Yes ")],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }