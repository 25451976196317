
























































































































































































































































































































































import { defineComponent, ref, watch, onMounted } from  "@vue/composition-api";
import { useCreateSecurity } from "./useCreateSecurity";
import { useNamespacedState } from 'vuex-composition-helpers'

export default defineComponent({
  setup(props, context) {
    const { id } = context.root.$route.params
    const Composable = {
      ...useCreateSecurity(props, context),
    };

    const { name, inbound, outbound, securityedit, setSecurityEdit, instancesselected, instanceSelect, instances, FETCH_SECURITY_DETAIL } = Composable;
    onMounted(async () => {
      if(id){
        const response = await FETCH_SECURITY_DETAIL({ id })
        if(response.code == 200) {
          await setSecurityEdit(response.data)
          inbound.value = response.data.security_rules.filter((x:any) => x.direction === 'ingress').map((x:any) => {
            return {
              type: x.type,
              ethertype: x.ethertype,
              protocol: x.protocol,
              port_range_min: x.port_range_min,
              port_range_max: x.port_range_max,
              sources: x.sources, 
              direction: x.direction
            }
          })
          outbound.value = response.data.security_rules.filter((x:any) => x.direction === 'egress').map((x:any) => {
            return {
              type: x.type,
              ethertype: x.ethertype,
              protocol: x.protocol,
              port_range_min: x.port_range_min,
              port_range_max: x.port_range_max,
              sources: x.sources,
              direction: x.direction
            }
          });
          instancesselected.value = response.data.instance_ids
        }
      }
    })
    
    const changePort = (ref:any, type:any, index:number) => {
      const dataset = ref == 'inbound' ? inbound : outbound
      if(type === 'SSH') {
        dataset.value[index].port_range_min = '22'
        dataset.value[index].port_range_max = '22'
      } else if(type === 'HTTP') {
        dataset.value[index].port_range_min = '80'
        dataset.value[index].port_range_max = '80'
      } else if(type === 'HTTPS') {
        dataset.value[index].port_range_min = '443'
        dataset.value[index].port_range_max = '443'
      } else {
        dataset.value[index].port_range_min = ''
        dataset.value[index].port_range_max = ''
      }

      if(type === 'icmp') dataset.value[index].protocol = 'ICMP'
      else if(type === 'udp') dataset.value[index].protocol = 'UDP'
      else if(type === 'tcp') dataset.value[index].protocol = 'TCP'
    }

    const {
      isLoading: isInstancesLoading
    } = useNamespacedState('INSTANCE', ['isLoading'])

    return {
      changePort,
      isInstancesLoading,
      ...Composable,
      deleteselect(text: string) {
        // findindex
        const index = instancesselected.value.findIndex((a: any) => {
          return a.instanceName == text;
        });
        instancesselected.value.splice(index, 1);
      },
    }
  },
  async created() {
    await this.fetchinstances();
  },
});
