<template>
  <v-tab-item>
    <!-- default table header 1 -->
    <v-card-text class="d-flex pa-8 flex-row align-center">
      <div class="headline font-weight-bold font--text">
        Default Reserved Price
      </div>
    </v-card-text>

    <!-- default table 1 -->
    <v-card-text class="px-8">
      <v-data-table
        :hide-default-footer="true"
        :headers="defaultHeaders"
        :items-per-page="5"
        :items="defaultItems"
        class="elevation-0"
      >
        <template v-slot:item.price="{ item }">
          <b>Rp {{ item.price.toLocaleString("en-US") }}</b
          >/Month
        </template>

        <!-- edit btn -->
        <template v-slot:item.action="{ item }">
          <div>
            <label
              style="cursor: pointer"
              @click="
                () => {
                  selectedFloatingIP = item;
                  openDialogEditFloatingIP = true;
                }
              "
            >
              <span class="primary--text">Edit</span>
            </label>
          </div>
        </template>
      </v-data-table>
    </v-card-text>

    <v-divider />

    <!-- default table header 2 -->
    <v-card-text class="d-flex pa-8 flex-row align-center">
      <div class="headline font-weight-bold font--text">
        Default Non-Reserved Price
      </div>
    </v-card-text>

    <!-- default table 2 -->
    <v-card-text class="px-8">
      <v-data-table
        :hide-default-footer="true"
        :headers="defaultHeaders"
        :items-per-page="5"
        :items="defaultItems2"
        class="elevation-0"
      >
        <template v-slot:item.price="{ item }">
          <b>Rp {{ item.price.toLocaleString("en-US") }}</b
          >/Month
        </template>

        <!-- edit btn -->
        <template v-slot:item.action="{ item }">
          <div>
            <label
              style="cursor: pointer"
              @click="
                () => {
                  selectedFloatingIP = { ...item, type: 'Non-Reserved' };
                  openDialogEditFloatingIP = true;
                }
              "
            >
              <span class="primary--text">Edit</span>
            </label>
          </div>
        </template>
      </v-data-table>
    </v-card-text>

    <v-divider />

    <!-- edit popup -->
    <dialogEditFloatingIP
      v-if="selectedFloatingIP && openDialogEditFloatingIP"
      :item="selectedFloatingIP"
      v-model="openDialogEditFloatingIP"
      @close="openDialogEditFloatingIP = false"
      @updateDefaultFloatingIP="updateDefaultFloatingIP($event)"
    />

    <!-- table 3 header -->
    <v-card-text class="d-flex pa-8 align-center">
      <div class="headline font-weight-bold font--text">
        Manage Floating IP (Customized)
      </div>

      <v-spacer />

      <v-text-field
        maxlength="50"
        style="max-width: 233px"
        outlined
        placeholder="Search Total"
        hide-details=""
        v-model="searchText"
      />

      <v-btn
        height="50"
        width="200"
        depressed
        class="secondary ml-8"
        @click="openDialogCreateFloatingIP = true"
      >
        <span style="font-size: 14px">
          Create Floating IP
        </span>
      </v-btn>
    </v-card-text>

    <!-- table body -->
    <v-card-text class="px-8">
      <!-- customized table -->
      <v-data-table
        hide-default-header
        hide-default-footer
        :items-per-page="5"
        :headers="customizedHeaders"
        :items="customizedItems"
        :server-items-length="customizedPagination.count"
        :options.sync="customizedPagination"
        class="elevation-0"
      >
        <!-- loading / no data -->
        <template v-slot:no-data>
          {{ loading ? "Loading..." : "No data available" }}
        </template>

        <!-- header -->
        <template v-slot:header="{ props, on }">
          <v-data-table-header
            v-on="on"
            v-bind="props"
            sort-icon="mdi-menu-down"
          />
        </template>

        <!-- <template v-slot:item.total="{ item }"> {{ item.total }} GB </template> -->

        <!-- <template v-slot:item.price="{ item }">
          Rp {{ item.price.toLocaleString("en-US") }}
        </template> -->

        <!-- status -->
        <template v-slot:item.status="{ item }">
          <v-switch
            class="mt-0 mb-2"
            hide-details
            flat
            readonly
            @click="
              () => {
                openDialogCustomizedSwitch = true;
                selectedCustomized = item;
              }
            "
            v-model="item.status"
            true-value="active"
            false-value="deactive"
          ></v-switch>
        </template>

        <!-- btns -->
        <template v-slot:item.action="{ item }">
          <div>
            <label
              style="cursor: pointer"
              @click="
                () => {
                  selectedCustomized = item;
                  openDialogCustomizedDelete = true;
                }
              "
            >
              <span class="error--text">Hapus</span>
            </label>
          </div>
        </template>

        <!-- pagination -->
        <template v-slot:footer="{ props }">
          <custom-footer-datatable
            v-if="
              (customizedPagination && customizedPagination.count > 5) ||
                (customizedPagination && customizedPagination.count === 5)
            "
            @input="
              (val) => {
                customizedPagination = { ...customizedPagination, ...val };
              }
            "
            :props="props"
          />
        </template>
      </v-data-table>
    </v-card-text>

    <!-- create popup -->
    <dialogCreateFloatingIP
      v-if="openDialogCreateFloatingIP"
      v-model="openDialogCreateFloatingIP"
      @close="openDialogCreateFloatingIP = false"
      @refreshData="refreshData"
    />
  </v-tab-item>
</template>

<script>
import api from "@/lib/api";
import dialogEditFloatingIP from "./dialogEditFloatingIP.vue";
import dialogCreateFloatingIP from "./dialogCreateFloatingIP.vue";

export default {
  components: {
    dialogEditFloatingIP,
    dialogCreateFloatingIP,
  },
  data() {
    return {
      loading: false,
      // upper table (default)
      defaultHeaders: [
        { text: "Default", value: "default", sortable: false },
        { text: "Price", value: "price", sortable: false },
        { text: "Action", value: "action", align: "center", sortable: false },
      ],
      defaultItems: [
        {
          default: 1,
          total: 1,
          price: 10000,
          price_per_month: 10000,
        },
      ],
      defaultItems2: [
        {
          default: 1,
          total: 1,
          price: 10000,
          price_per_month: 10000,
        },
      ],
      selectedFloatingIP: null,
      openDialogEditFloatingIP: false,
      // table below
      searchText: "",
      openDialogCreateFloatingIP: false,
      customizedHeaders: [
        { text: "Total", value: "total", sortable: false },
        { text: "Reserved Price", value: "reserved_price" },
        {
          text: "Non-Reserved Price",
          value: "non_reserved_price",
          sortable: false,
        },
        { text: "Start Date", value: "start_date", sortable: false },
        { text: "Status", value: "status", sortable: false },
        { text: "Action", value: "action", sortable: false },
      ],
      customizedItems: [
        {
          total: 123,
          reserved_price: 2000000,
          non_reserved_price: 3000000,
          status: true,
        },
        {
          total: 456,
          reserved_price: 2000000,
          non_reserved_price: 3000000,
          status: true,
        },
      ],
      customizedPagination: {
        count: 0,
        page: 1,
        totalpage: 1,
      },
      openDialogCustomizedSwitch: false,
      openDialogCustomizedDelete: false,
      selectedCustomized: null,
    };
  },
  methods: {
    async deleteRestore(id, type) {
      this.loading = true;

      try {
        await api.DELETE("/flavor/snapshot/delete/" + id);

        this.$store.dispatch(
          "HOMEPAGE/showSuccessToast",
          "Restore status has been successfully deleted"
        );

        if (type === "public") {
          const newArr = this.publicItems.filter((p) => p.id !== id);

          this.publicItems = newArr;
          this.openDialogPublicDelete = false;
        } else {
          const newArr = this.customizedItems.filter((c) => c.id !== id);

          this.customizedItems = newArr;
          this.openDialogCustomizedDelete = false;
        }
      } catch {
        this.$store.dispatch(
          "HOMEPAGE/showErrorToast",
          "Sorry, an error occurred while deleting Restore status"
        );
      }

      this.loading = false;
    },
    async changeStatus(item, type) {
      this.loading = true;
      const status = item.status === "active" ? "deactive" : "active";

      try {
        await api.PUT(`/flavor/snapshot/${status}/${item.id}`);

        this.$store.dispatch(
          "HOMEPAGE/showSuccessToast",
          "Restore status has been successfully updated"
        );

        if (type === "public") {
          const newArr = this.publicItems.map((p) =>
            p.id === item.id
              ? {
                  ...p,
                  status,
                }
              : p
          );

          this.publicItems = newArr;
          this.openDialogPublicSwitch = false;
        } else {
          const newArr = this.customizedItems.map((c) =>
            c.id === item.id
              ? {
                  ...c,
                  status,
                }
              : c
          );

          this.customizedItems = newArr;
          this.openDialogCustomizedSwitch = false;
        }
      } catch (err) {
        let errmsg = "Sorry, an error occurred while submitting Restore status";

        if (
          err &&
          err.response &&
          err.response.data &&
          err.response.data.data
        ) {
          errmsg = err.response.data.data;
        }

        if (type === "public") {
          this.openDialogPublicSwitch = false;
        } else {
          this.openDialogCustomizedSwitch = false;
        }

        this.$store.dispatch("HOMEPAGE/showErrorToast", errmsg);
      }

      this.loading = false;
    },
    updateCustomizedItem(obj) {
      const newArr = this.customizedItems.map((item) =>
        item.id === obj.id ? obj : item
      );

      this.customizedItems = newArr;
    },
    async getCustomizedItems(search, page, limit, sortBy, sortDesc) {
      if (this.isDevMode) {
        this.loading = true;

        try {
          // const res = await api.GET("/flavor/snapshot?type=customized", {
          const res = await api.GET("/flavor/snapshot", {
            search,
            page,
            limit,
            sortBy,
            sortDesc,
          });

          let newArr = [];

          if (res.data.snapshot.length) {
            newArr = res.data.snapshot.map((s) => {
              const {
                total,
                price_per_month,
                start_date,
                status,
                organization,
              } = s;
              return {
                ...s,
                organization: organization.name,
                total,
                price: price_per_month,
                startDate: start_date
                  ? this.$moment(start_date).format("DD/MM/YYYY")
                  : "-",
                status,
              };
            });
          }

          this.customizedItems = newArr;
          this.customizedPagination = res.data.pagination;
        } catch (err) {
          this.$store.dispatch(
            "HOMEPAGE/showErrorToast",
            "Sorry, an error occurred while displaying customized snapshot data"
          );
        }

        this.loading = false;
      }
    },
    refreshData() {
      this.getCustomizedItems();
    },
    async getDefaultItems() {
      try {
        const res = await api.GET("/flavor/snapshot/default");
        this.tableItems = [
          {
            default: res.data.total + " GB",
            price: res.data.price_per_month,
            ...res.data,
          },
        ];
      } catch {
        this.$store.dispatch(
          "HOMEPAGE/showErrorToast",
          "Sorry, an error occurred while displaying Default FloatingIP datas"
        );
      }
    },
    updateDefaultFloatingIP(pricePerMonth) {
      const item = {
        ...this.tableItems[0],
        price: pricePerMonth,
        price_per_month: pricePerMonth,
      };

      this.tableItems = [item];

      this.selectedFloatingIP = item;
    },
  },
  mounted() {
    // this.getCustomizedItems();
    // getDefaultItems();
  },
  watch: {
    searchText() {
      if (this.tabs > 0) {
        this.getCustomizedItems(this.restoreSizeSearchText);
      } else {
        this.getPublicItems(this.restoreSizeSearchText);
      }
    },
    customizedPagination(newVal, oldVal) {
      if (JSON.stringify(newVal) !== JSON.stringify(oldVal)) {
        this.getCustomizedItems(
          this.restoreSizeSearchText,
          this.customizedPagination.page,
          // itemsPerPage
          oldVal.itemsPerPage
            ? oldVal.itemsPerPage
            : this.customizedPagination.itemsPerPage,
          // sortBy
          oldVal.sortBy ? oldVal.sortBy : this.customizedPagination.sortBy,
          // sortDesc
          oldVal.sortDesc ? oldVal.sortDesc : this.customizedPagination.sortDesc
        );
      }
    },
  },
};
</script>
