<template>
  <v-menu ref="menu" v-model="showMenu" offset-y allow-overflow>
    <template v-slot:activator="{ on, attrs }">
      <div
        id="BroadcastMemberNav"
        @click="
          ($event) => {
            on.click($event);
          }
        "
        v-bind="attrs"
        v-if="role !== 'Superadmin'"
        style="cursor: pointer"
        class="d-flex justify-center align-center navmenu"
      >
        <v-icon style="width:20px; height:20px" class="mr-3">
          $vuetify.icons.broadcastBlue
        </v-icon>

        <p class="mb-0">
          Broadcast
        </p>
      </div>
    </template>

    <v-list>
      <v-list-item v-if="loading">
        <v-list-item-title class="text-center">
          Loading ...
        </v-list-item-title>
      </v-list-item>

      <template v-else>
        <template v-if="broadcasts.length">
          <ListItem
            v-for="broadcast in broadcasts"
            :key="'list-item-' + broadcast.id"
            :item="broadcast"
          />
        </template>

        <v-list-item v-else>
          <v-list-item-title class="text-center">
            No broadcast
          </v-list-item-title>
        </v-list-item>
      </template>

      <v-list-item>
        <v-list-item-title>
          <v-btn
            to="/broadcast-user"
            depressed
            block
            height="58"
            class="secondary fz-16px"
          >
            See All Broadcast
          </v-btn>
        </v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import localstorage from "@/lib/localstorage";
import ListItem from "./listItem.vue";
import api from "@/lib/api";

export default {
  components: {
    ListItem,
  },
  data() {
    return {
      showMenu: false,
      loading: false,
      broadcasts: [],
    };
  },
  computed: {
    role() {
      return localstorage.getItem("role");
    },
  },
  methods: {
    async getBroadcasts() {
      this.loading = true;

      try {
        const res = await api.GET("/user/broadcast");
        this.broadcasts = res.data && res.data.length ? res.data : [];
      } catch (err) {
        let errmsg = "Sorry, an error occurred while displaying broadcasts";
        const thereIsErrorMessage = err && err.response && err.response.data;

        if (thereIsErrorMessage && err.response.data.message) {
          errmsg = err.response.data.message;
        }

        if (
          thereIsErrorMessage &&
          err.response.data.data !== "record not found"
        ) {
          this.$store.dispatch("HOMEPAGE/showErrorToast", errmsg);
        }
      }

      this.loading = false;
    },
  },
  mounted() {
    this.getBroadcasts();
  },
};
</script>

<style scoped>
[role="menu"] {
  margin-top: 10px;
}

.v-list-item__title {
  font-size: 12px;
}

#BroadcastMemberNav p {
  font-weight: 700;
  font-size: 12px;
}

.fz-16px {
  font-size: 16px;
}
</style>
