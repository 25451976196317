




































































import Vue from "vue";
import moment from "moment/moment.js";
import { useNamespacedActions, useNamespacedGetters } from "vuex-composition-helpers";
import { INSTANCEDETAIL } from "./namespace";
import {
  computed,
  defineComponent,
  ref,
  watch,
} from "@vue/composition-api";
import INSTANCE from "../namespace";
import VueNativeSock from 'vue-native-websocket'

import chart from "./chart.vue";


export default defineComponent({
  data() {
    return {
      metrics: [
        {
          title: 'CPU Usage',
          color: '#F2994A',
          type: 'cpumetrics',
        }, {
          title: 'RAM Usage',
          color: '#2C94D2',
          type: 'rammetrics',
        }, {
          title: 'Network In Usage',
          color: '#5B4DBE',
          type: 'inputin',
        }, {
          title: 'Network Out Usage',
          color: '#5B4DBE',
          type: 'inputoutput',
        }, {
          title: 'Disk Read',
          color: '#27AE60',
          type: 'diskread',
        }, {
          title: 'Disk Write',
          color: '#27AE60',
          type: 'diskwrite',
        }
      ]
    }
  },
  setup(props: any, context: any) {
    const { getinstancemetricssocket } = useNamespacedActions(INSTANCEDETAIL, [
      "getinstancemetricssocket",
    ]);

    const { getInstanceDetail } = useNamespacedGetters(INSTANCE, [
      "getInstanceDetail",
    ]);

    const type = ['cpu', 'ram', 'network-in', 'network-out', 'disk-read', 'disk-write']

    const range = ref({
      start: new Date(),
      end: new Date(),
    });

    watch(range, (val) => {
      const { instanceid } = context.root.$route.params;

      const start= moment(new Date(val.start)).format('DD-MM hh')
      const end= moment(new Date(val.end)).format('DD-MM hh')
      const currentDays = moment(new Date()).format('DD-MM hh')

      if(currentDays !== (start || end)){
        getinstancemetricssocket({ id: instanceid, range: val, status: 'stop' });
      } else {
        getinstancemetricssocket({ id: instanceid });
      }
    });

    return {
      getinstancemetricssocket,
      getInstanceDetail,
      vcalendar: false,
      range,
      type,

    };
  },

  created() {
    const { instanceid } = this.$route.params;

    this.type.forEach((data => {
      this.getinstancemetricssocket({ id: instanceid, type: data });
    }))
  },
  components: {
    chart,
  },
});
