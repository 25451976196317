<template>
  <div>
    <!-- if user corporate -->
    <v-card v-if="userType === 'C'" flat class="rounded-lg pa-2 mt-3">
      <v-card-text class="d-flex" style="justify-content: space-between">
        <p class="fz-21 font-weight-bold mt-4">
          List Billing All Project {{ currentPeriod }}
        </p>
        <v-btn
          class="secondary"
          dark
          depressed
          height="45"
          style="font-size: 14px"
          @click="downloadInvoice"
          v-if="billing"
        >
          Download Invoice
        </v-btn>
      </v-card-text>
      <v-card-text v-if="billing">
        <v-simple-table>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">List Project</th>
                <th class="text-left">Monthly Cost</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in billing.details" :key="item.project.id">
                <td style="width:65%;padding: 2rem 1.25rem !important;">
                  <router-link
                    @click.native="
                      () => {
                        setbillingdetails(item);
                      }
                    "
                    :to="`/billing/${item.project.id}`"
                    >{{ item.project.name }}</router-link
                  >
                  <!-- <span class="ml-4"></span>
                  <v-icon :color="item.commitment ? '#FF8C5A' : '#A5B3BF'">
                    $vuetify.icons.antdesigntagsIcon
                  </v-icon>
                  <span
                    :style="{ color: item.commitment ? '#FF8C5A' : '#A5B3BF' }"
                    >{{
                      item.commitment
                        ? "Commitment Project"
                        : "Non-Commitment Project"
                    }}</span
                  > -->
                </td>
                <td>{{ IDRformat.format(item.billed) }}</td>
              </tr>
              <tr>
                <td style="padding-left: 0px; padding-right: 0px">
                  <div
                    class="d-flex align-center px-4 rounded-l-lg"
                    style="
                      height: 80px;
                      background-color: #f1f2f2;
                      margin-top: 20px;
                      font-family: Fira Sans;
                      font-size: 21px;
                      font-style: normal;
                      font-weight: 700;
                      line-height: 29px;
                      letter-spacing: 0em;
                      text-align: left;
                    "
                  >
                    Total
                  </div>
                </td>
                <td style="padding-right: 0px; padding-left: 0px">
                  <div
                    class="d-flex align-center px-4 rounded-r-lg"
                    style="
                      height: 80px;
                      background-color: #f1f2f2;
                      margin-top: 20px;
                      font-size: 30px;
                      font-style: normal;
                      font-weight: 600;
                      line-height: 50px;
                      letter-spacing: 0;
                      text-align: left;
                    "
                  >
                    {{
                      IDRformat.format(billing.price)
                    }}
                  </div>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card-text>
      <v-card-text v-else>
        <v-simple-table>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">List Project</th>
                <th class="text-left">Monthly Cost</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td style="width:100%;padding: 2rem 1.25rem !important;text-align: center;" colspan="2">
                  No data available
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card-text>
      <!-- <invoice-corporate v-if="billing" :organization="organization" :billing="billing"/> -->
      <vue-html2pdf
        :show-layout="false"
        :float-layout="true"
        :enable-download="true"
        :filename="`Invoice ${ currentPeriod }`"
        :pdf-quality="2"
        :manual-pagination="true"
        pdf-format="a4"
        pdf-orientation="portrait"
        ref="invoiceCorporate"
      >
        <section slot="pdf-content">
          <invoice-corporate v-if="billing" :organization="organization" :billing="billing"/>
        </section>
      </vue-html2pdf>
    </v-card>

    <!-- if user personal -->
    <!-- <template v-else>
      <Header />

      <v-card class="rounded-lg pa-7 mt-7" flat>
        <Tablepersonal />
      </v-card>
    </template> -->

    <!-- if user personal -->
    <Summarypersonal v-else />
  </div>
</template>

<script>
import { defineComponent, onMounted, ref, computed } from "@vue/composition-api";
import moment from 'moment'
import {
  useNamespacedActions,
  useNamespacedMutations,
  useNamespacedState,
} from "vuex-composition-helpers";
import { BILLING } from "./namespace";
import { IDRformat } from "@/lib/formatter";
import localstorage from "@/lib/localstorage";
import Summarypersonal from "./summarypersonal";
import invoiceCorporate from "@/app/downloadinvoice/invoiceCorporate";
import VueHtml2pdf from 'vue-html2pdf'

export default defineComponent({
  components: {
    Summarypersonal,
    invoiceCorporate,
    VueHtml2pdf,
  },
  computed: {
    userType() {
      return localstorage.getItem('type')
    },
  },
  setup(props, { root }) {
    const { 
      setbillingdetails,
    } = useNamespacedMutations(BILLING, [
      "setbillingdetails",
    ])

    const { fetchorganizationByUser } = useNamespacedActions('ORGANIZATION', [
      "fetchorganizationByUser",
    ])

    const organization = ref(null)

    const { 
      fetchBillingOrganization,
    } = useNamespacedActions(BILLING, [
      "fetchBillingOrganization",
    ])
    
    onMounted(async () => {
      await fetchBillingOrganization()
      const response = await fetchorganizationByUser()
      organization.value = response
    })

    const { 
      billing 
    } = useNamespacedState(BILLING, [
      "billing"
    ])
    
    const currentPeriod = computed(() => {
      if (!billing.value) return
      return `${moment(billing.value.date_start).format('MMMM YYYY')} - ${moment(billing.value.date_end).format('MMMM YYYY')}`
    })

    const invoiceCorporate = ref(null)
    const downloadInvoice = () => {
      invoiceCorporate.value.generatePdf()
    }
    
    return {
      downloadInvoice,
      invoiceCorporate,
      setbillingdetails,
      currentPeriod,
      IDRformat,
      billing,
      moment,
      organization,
    };
  },
});
</script>