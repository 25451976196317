<template>
  <v-card flat class="rounded-lg pa-4 mt-3">
    <v-card-text>
      <p class="fz-21 font-weight-bold">
        History Top Up
      </p>
    </v-card-text>

    <v-data-table
      :headers="headers"
      :items="topups"
      :items-per-page="5"
      class="elevation-0"
      hide-default-footer
    >
      <!-- balance -->
      <template v-slot:item.balance="{ item }">
        <p :style="item.type === 'plus' ? 'color: #27AE60' : 'color: red'">
          {{ item.type === "plus" ? "+" : "-" }}
          IDR {{ item.balance.toLocaleString("en-US") }}
        </p>
      </template>

      <!-- date -->
      <template v-slot:item.date="{ item }">
        <p>
          {{ $moment(item.date).format("DD/MM/YYYY") }}
        </p>
      </template>

      <!-- pagination -->
      <template v-slot:footer="{ props }">
        <custom-footer-datatable :props="props" />
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import api from "@/lib/api";

export default {
  data() {
    return {
      headers: [
        { text: "Transaction", value: "transaction", sortable: false },
        { text: "Balance", value: "balance", sortable: false },
        { text: "Date", value: "date", sortable: true },
      ],
      topups: [],
    };
  },
  methods: {
    async getBalanceHistory() {
      try {
        const res = await api.GET("/balance/history");
        // console.log(res.data, 'res getBalanceHistory')
        if (res.data && res.data.account_balance_detail) {
          this.topups = res.data.account_balance_detail
            .map((topup) => {
              return {
                transaction: topup.source,
                balance: topup.amount,
                date: topup.created_at,
                type: topup.type,
              };
            })
            .sort((a, b) => new Date(b.date) - new Date(a.date));
        }
      } catch {
        this.$store.dispatch(
          "HOMEPAGE/showErrorToast",
          "Sorry, an error occurred while displaying History Top Up"
        );
      }
    },
  },
  mounted() {
    this.getBalanceHistory();
  },
};
</script>
