<template>
  <div>
    <!-- dialog to select a project -->
    <!-- <Dialogproject v-model="displayProjectDialog" /> -->

    <!-- top white box (Balance) -->
    <v-card v-if="isPersonal" flat class="rounded-lg pa-4 mt-3">
      <v-card-text>
        <div
          class="d-flex flex-column flex-md-row justify-space-between align-center"
        >
          <p class="fz-21 font-weight-bold">
            Balance
          </p>

          <div>
            <v-btn
              outlined
              depressed
              color="secondary"
              style="margin-bottom: 20px"
              height="45"
              max-width="197"
              to="/billing/payment/history"
              class="mr-4"
            >
              History Top Up
            </v-btn>

            <v-btn
              depressed
              class="secondary"
              style="margin-bottom: 20px"
              height="45"
              max-width="197"
              to="/billing/payment/history"
            >
              Top Up Balance
            </v-btn>
          </div>
        </div>

        <v-row>
          <v-col cols="12" md="6">
            <div class="balance-card">
              <p class="card-title">
                The Remaining Balance
                <!-- Total Daily Billed -->
              </p>

              <p class="card-idr">IDR {{ balance.toLocaleString("en-US") }}</p>
            </div>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <!-- middle white box (Detail Payment) -->
    <v-card flat class="rounded-lg pa-4 mt-3">
      <v-card-text v-if="isPersonal">
        <p class="fz-21 font-weight-bold">
          Detail Payment
        </p>
      </v-card-text>

      <v-card-text class="d-md-flex justify-space-between align-center" v-else>
        <p class="fz-21 font-weight-bold">
          Detail Payment
        </p>

        <v-btn
          depressed
          outlined
          color="secondary"
          height="45"
          max-width="197"
          to="/billing/payment/transaction-history"
        >
          Transaction History
        </v-btn>
      </v-card-text>

      <v-card-text class="pt-0">
        <v-row>
          <v-col>
            <p>
              <b>
                {{ isPersonal ? "User for" : "Organization" }}
              </b>
            </p>
            <p>
              {{ isPersonal ? "Personal" : currentOrg.name }}
            </p>
          </v-col>
          <v-col>
            <p><b>Total Project</b></p>
            <p>
              {{ totalproject }}
            </p>
          </v-col>
          <v-col>
            <p><b>Project</b></p>
            <p
              :class="{
                'red--text': error,
              }"
            >
              {{ projectname }}
              {{ error ? "Sorry, project not found" : "" }}
            </p>
          </v-col>
          <v-col>
            <p>
              <b>
                {{ isPersonal ? "Email" : "Total Unpaid Billed" }}
              </b>
            </p>
            <p
              :style="!isPersonal ? 'color: #1F60A8' : ''"
              :class="{
                'font-weight-bold': !isPersonal,
              }"
            >
              {{
                isPersonal
                  ? userprofile.email
                  : totalUnpaidBilledError
                  ? "Belum Ada Tagihan"
                  : "IDR " + totalUnpaidBilled.toLocaleString("en-US")
              }}
            </p>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <!-- bottom white box (Choose Payment) -->
    <v-card flat class="rounded-lg pa-4 mt-3">
      <v-card-text>
        <p class="fz-21 font-weight-bold">
          {{ isPersonal ? "Payment" : "Choose Payment" }}
        </p>
      </v-card-text>

      <v-card-text class="pt-0" :style="isPersonal ? '' : 'margin-top: -40px'">
        <v-row>
          <!-- nominal balance -->
          <v-col v-if="isPersonal" cols="12" sm="12" md="6">
            <p><b>Nominal Balance</b></p>
            <v-text-field
              id="idr"
              flat
              outlined
              v-model="idr"
              @keypress="isNumber($event)"
              placeholder="IDR"
              required
              style="margin-bottom: 22px"
              :error-messages="idrError"
              @blur="onChangeHandler"
            />
          </v-col>

          <!-- choose payment -->
          <v-col cols="12" sm="12" :md="isPersonal ? 6 : 12">
            <p v-if="isPersonal"><b>Choose Payment</b></p>
            <v-radio-group v-model="payment" row>
              <v-row>
                <v-col
                  v-for="(method, index) in paymentMethods"
                  :key="'payment-method-' + method.value + index"
                  style="font-size: 10px"
                >
                  <v-radio :label="method.label" :value="method.value">
                    <template v-slot:label>
                      <img
                        v-if="method.label === 'Credit Card'"
                        src="@/assets/payment-method/credit-card.png"
                        class="mr-2"
                      />
                      <img
                        v-if="method.label === 'BNI VA'"
                        src="@/assets/payment-method/bni.png"
                        class="mr-2"
                      />
                      <img
                        v-if="method.label === 'Maybank VA'"
                        src="@/assets/payment-method/maybank.png"
                        class="mr-2"
                      />
                      <img
                        v-if="method.label === 'Permata VA'"
                        src="@/assets/payment-method/permata.png"
                        class="mr-2"
                      />
                      <img
                        v-if="method.label === 'Mandiri ATM'"
                        src="@/assets/payment-method/mandiri.png"
                        class="mr-2"
                      />
                      <img
                        v-if="method.label === 'Mandiri VA'"
                        src="@/assets/payment-method/mandiri.png"
                        class="mr-2"
                      />

                      <span class="fs-14">{{ method.label }}</span>
                    </template>
                  </v-radio>
                </v-col>
              </v-row>
            </v-radio-group>
          </v-col>
        </v-row>
      </v-card-text>

      <!-- continue payment button -->
      <v-row>
        <v-col cols="12" sm="12" md="6" />

        <v-col cols="12" sm="12" md="6">
          <v-btn
            id="submit"
            block
            height="50"
            depressed
            color="secondary"
            class="mr-4 text-capitalize font-weight-bold"
            @click="submit"
            :disabled="loading || totalUnpaidBilledError || !payment"
          >
            <!-- loading indicator -->
            <beat-loader
              v-if="loading"
              :loading="loading"
              :color="'white'"
              :size="'10px'"
              class="ml-2"
            />

            {{ loading ? "Continue Payment..." : "Continue Payment" }}
          </v-btn>
        </v-col>
      </v-row>
    </v-card>

    <form
      ref="enterPaymentPageForm"
      class="d-none"
      :action="enterPaymentPageRes.payment_url"
      method="POST"
    >
      <input
        type="hidden"
        name="CheckoutID"
        :value="enterPaymentPageRes.checkout_id"
      />
      <input
        type="hidden"
        name="Signature"
        :value="enterPaymentPageRes.signature"
      />
    </form>
  </div>
</template>

<script>
import { useNamespacedState } from "vuex-composition-helpers";
import localstorage from "@/lib/localstorage";
// import Dialogproject from './dialogproject.vue'
import api from "@/lib/api";

export default {
  components: {
    // Dialogproject,
  },
  data() {
    return {
      totalUnpaidBilledError: false,
      totalUnpaidBilled: 0,
      balance: 0,
      idr: null,
      idrError: "",
      payment: null,
      loading: false,
      // displayProjectDialog: false,
      totalproject: "",
      projectname: "",
      error: false,
      paymentMethods: [
        {
          label: "Credit Card",
          value: 35,
        },
        {
          label: "BNI VA",
          value: 26,
        },
        {
          label: "Maybank VA",
          value: 9,
        },
        {
          label: "Permata VA",
          value: 31,
        },
        // {
        //   label: 'OVO',
        //   value: 63,
        // },
        // {
        //   label: 'Linkaja',
        //   value: 13,
        // },
        {
          label: "Mandiri VA",
          value: 69,
        },
        {
          label: "Mandiri ATM",
          value: 17,
        },
        // {
        //   label: 'BCA ATM',
        //   value: 21,
        // },
      ],
      enterPaymentPageRes: {
        payment_url: "",
        checkout_id: "",
        signature: "",
      },
    };
  },
  computed: {
    isPersonal() {
      return localstorage.getItem("type") === "P";
    },
    currentOrg() {
      return JSON.parse(localstorage.getItem("currentOrg"));
    },
  },
  setup() {
    const { userprofile } = useNamespacedState("ROLEPERMISSION", [
      "userprofile",
    ]);

    return {
      userprofile,
    };
  },
  methods: {
    // number input
    isNumber(e) {
      const charCode = e.which ? e.which : e.keyCode;

      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        e.preventDefault();
      } else {
        return true;
      }
    },
    onChangeHandler(e) {
      if (!this[e.target.id] && !this[e.target.id + "Error"]) {
        this[e.target.id + "Error"] = "Value is required";
      }

      if (this[e.target.id] && this[e.target.id + "Error"]) {
        this[e.target.id + "Error"] = "";
      }
    },
    // projectCheck() {
    //   const projectname = localstorage.getItem("projectname")

    //   if (!projectname) {
    //     this.displayProjectDialog = true
    //   }
    // },
    async getProjects() {
      try {
        this.error = false;
        const res = await api.GET("/user/organization/projects");
        // console.log(res.data, 'res projects')
        const projectnames = res.data.map((project) => project.name);
        this.projectname = projectnames.join(", ");
        this.totalproject = res.data.length;
      } catch {
        this.$store.dispatch(
          "HOMEPAGE/showErrorToast",
          "Sorry, an error occurred while displaying project list"
        );
      }
    },
    async submit() {
      // if nominal balance field is empty
      if (!this.idr && this.isPersonal) {
        // window.scrollTo({ top: 0, behavior: 'smooth' })
        this.idrError = "Value is required";
      } else {
        this.loading = true;

        const endpoint = this.isPersonal
          ? "/payment/top-up"
          : "/payment/corporate";

        try {
          const res = await api.POST(
            endpoint,
            this.isPersonal
              ? {
                  payment_id: this.payment.toString(),
                  amount: this.idr.toString(),
                }
              : { payment_id: this.payment.toString() }
          );

          this.enterPaymentPageRes = res.data;
        } catch (err) {
          let errmsg = "Sorry, there was an error while submitting data";

          if (err.response && err.response.data && err.response.data.data) {
            errmsg = err.response.data.data;
          }

          this.$store.dispatch("HOMEPAGE/showErrorToast", errmsg);
        }

        this.loading = false;
      }
    },
    async fetchBalance() {
      if (this.isPersonal) {
        try {
          const balance = await api.GET("/balance/remaining");
          this.balance = balance.data.balance;
        } catch {
          this.$store.dispatch(
            "HOMEPAGE/showErrorToast",
            "Sorry, an error occurred while displaying The Remaining Balance"
          );
        }
      }
    },
    async fetchTotalUnpaidBilled() {
      if (!this.isPersonal) {
        try {
          this.totalUnpaidBilledError = false;
          const res = await api.GET("/billing/total-unpaid/corporate");
          this.totalUnpaidBilled = res.data;
        } catch (err) {
          if (
            err &&
            err.response &&
            err.response.data &&
            err.response.data.data &&
            err.response.data.data === "No bill yet"
          ) {
            this.totalUnpaidBilledError = true;
          } else {
            this.$store.dispatch(
              "HOMEPAGE/showErrorToast",
              "Sorry, an error occurred while displaying Total Unpaid Billed"
            );
          }
        }
      }
    },
  },
  mounted() {
    // this.projectCheck()
    this.getProjects();
    this.fetchBalance();
    this.fetchTotalUnpaidBilled();
  },
  watch: {
    idr() {
      this.onChangeHandler({ target: { id: "idr" } });
    },
    "enterPaymentPageRes.signature"() {
      setTimeout(() => {
        this.$refs.enterPaymentPageForm.submit();
      }, 1000);
    },
  },
};
</script>

<style scoped>
.balance-card {
  width: 100%;
  padding: 23px 20px;
  border: 1px solid #1f60a8;
  border-radius: 10px;
  background-color: rgba(31, 96, 168, 0.02);
  margin-bottom: 28px;
  margin-right: 20px;
}

.card-title {
  margin-bottom: 10px;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
}

.card-idr {
  font-size: 36px;
  font-style: normal;
  font-weight: 600;
  line-height: 50px;
  letter-spacing: 0em;
  text-align: left;
  color: #1f60a8;
}

.fs-14 {
  font-size: 14px !important;
}
</style>
