import main from "./main";
import OtpInput from "@bachdgvn/vue-otp-input";

export default {
  install(Vue) {
    Vue.component("v-otp-input", OtpInput);
    Vue.addRoutes({
      path: "/otp",
      component: main,
    });
  },
};
