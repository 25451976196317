var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-tab-item',[_c('v-card-text',{staticClass:"d-flex pa-8 flex-row align-center"},[_c('div',{staticClass:"headline font-weight-bold font--text"},[_vm._v(" Default Reserved Price ")])]),_c('v-card-text',{staticClass:"px-8"},[_c('v-data-table',{staticClass:"elevation-0",attrs:{"hide-default-footer":true,"headers":_vm.defaultHeaders,"items-per-page":5,"items":_vm.defaultItems},scopedSlots:_vm._u([{key:"item.price",fn:function(ref){
var item = ref.item;
return [_c('b',[_vm._v("Rp "+_vm._s(item.price.toLocaleString("en-US")))]),_vm._v("/Month ")]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('div',[_c('label',{staticStyle:{"cursor":"pointer"},on:{"click":function () {
                _vm.selectedFloatingIP = item;
                _vm.openDialogEditFloatingIP = true;
              }}},[_c('span',{staticClass:"primary--text"},[_vm._v("Edit")])])])]}}])})],1),_c('v-divider'),_c('v-card-text',{staticClass:"d-flex pa-8 flex-row align-center"},[_c('div',{staticClass:"headline font-weight-bold font--text"},[_vm._v(" Default Non-Reserved Price ")])]),_c('v-card-text',{staticClass:"px-8"},[_c('v-data-table',{staticClass:"elevation-0",attrs:{"hide-default-footer":true,"headers":_vm.defaultHeaders,"items-per-page":5,"items":_vm.defaultItems2},scopedSlots:_vm._u([{key:"item.price",fn:function(ref){
              var item = ref.item;
return [_c('b',[_vm._v("Rp "+_vm._s(item.price.toLocaleString("en-US")))]),_vm._v("/Month ")]}},{key:"item.action",fn:function(ref){
              var item = ref.item;
return [_c('div',[_c('label',{staticStyle:{"cursor":"pointer"},on:{"click":function () {
                _vm.selectedFloatingIP = Object.assign({}, item, {type: 'Non-Reserved'});
                _vm.openDialogEditFloatingIP = true;
              }}},[_c('span',{staticClass:"primary--text"},[_vm._v("Edit")])])])]}}])})],1),_c('v-divider'),(_vm.selectedFloatingIP && _vm.openDialogEditFloatingIP)?_c('dialogEditFloatingIP',{attrs:{"item":_vm.selectedFloatingIP},on:{"close":function($event){_vm.openDialogEditFloatingIP = false},"updateDefaultFloatingIP":function($event){return _vm.updateDefaultFloatingIP($event)}},model:{value:(_vm.openDialogEditFloatingIP),callback:function ($$v) {_vm.openDialogEditFloatingIP=$$v},expression:"openDialogEditFloatingIP"}}):_vm._e(),_c('v-card-text',{staticClass:"d-flex pa-8 align-center"},[_c('div',{staticClass:"headline font-weight-bold font--text"},[_vm._v(" Manage Floating IP (Customized) ")]),_c('v-spacer'),_c('v-text-field',{staticStyle:{"max-width":"233px"},attrs:{"maxlength":"50","outlined":"","placeholder":"Search Total","hide-details":""},model:{value:(_vm.searchText),callback:function ($$v) {_vm.searchText=$$v},expression:"searchText"}}),_c('v-btn',{staticClass:"secondary ml-8",attrs:{"height":"50","width":"200","depressed":""},on:{"click":function($event){_vm.openDialogCreateFloatingIP = true}}},[_c('span',{staticStyle:{"font-size":"14px"}},[_vm._v(" Create Floating IP ")])])],1),_c('v-card-text',{staticClass:"px-8"},[_c('v-data-table',{staticClass:"elevation-0",attrs:{"hide-default-header":"","hide-default-footer":"","items-per-page":5,"headers":_vm.customizedHeaders,"items":_vm.customizedItems,"server-items-length":_vm.customizedPagination.count,"options":_vm.customizedPagination},on:{"update:options":function($event){_vm.customizedPagination=$event}},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_vm._v(" "+_vm._s(_vm.loading ? "Loading..." : "No data available")+" ")]},proxy:true},{key:"header",fn:function(ref){
              var props = ref.props;
              var on = ref.on;
return [_c('v-data-table-header',_vm._g(_vm._b({attrs:{"sort-icon":"mdi-menu-down"}},'v-data-table-header',props,false),on))]}},{key:"item.status",fn:function(ref){
              var item = ref.item;
return [_c('v-switch',{staticClass:"mt-0 mb-2",attrs:{"hide-details":"","flat":"","readonly":"","true-value":"active","false-value":"deactive"},on:{"click":function () {
              _vm.openDialogCustomizedSwitch = true;
              _vm.selectedCustomized = item;
            }},model:{value:(item.status),callback:function ($$v) {_vm.$set(item, "status", $$v)},expression:"item.status"}})]}},{key:"item.action",fn:function(ref){
            var item = ref.item;
return [_c('div',[_c('label',{staticStyle:{"cursor":"pointer"},on:{"click":function () {
                _vm.selectedCustomized = item;
                _vm.openDialogCustomizedDelete = true;
              }}},[_c('span',{staticClass:"error--text"},[_vm._v("Hapus")])])])]}},{key:"footer",fn:function(ref){
              var props = ref.props;
return [(
            (_vm.customizedPagination && _vm.customizedPagination.count > 5) ||
              (_vm.customizedPagination && _vm.customizedPagination.count === 5)
          )?_c('custom-footer-datatable',{attrs:{"props":props},on:{"input":function (val) {
              _vm.customizedPagination = Object.assign({}, _vm.customizedPagination, val);
            }}}):_vm._e()]}}])})],1),(_vm.openDialogCreateFloatingIP)?_c('dialogCreateFloatingIP',{on:{"close":function($event){_vm.openDialogCreateFloatingIP = false},"refreshData":_vm.refreshData},model:{value:(_vm.openDialogCreateFloatingIP),callback:function ($$v) {_vm.openDialogCreateFloatingIP=$$v},expression:"openDialogCreateFloatingIP"}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }