var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"max-width":"500"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',{staticClass:"pt-7"},[_c('v-card-text',[_c('div',{staticClass:"headline font-weight-bold font--text"},[_vm._v("Create Floating IP")])]),_c('v-card-text',[_c('p',{staticClass:"titletext"},[_vm._v("Organization")]),_c('v-select',{attrs:{"placeholder":"Select Organization","items":_vm.organizations,"outlined":"","single-line":"","append-icon":"mdi-chevron-down","error-messages":_vm.organizationError},model:{value:(_vm.organization),callback:function ($$v) {_vm.organization=$$v},expression:"organization"}})],1),_c('v-card-text',[_c('p',{staticClass:"titletext"},[_vm._v("Total")]),_c('v-text-field',{attrs:{"id":"total","maxlength":"3","type":"number","placeholder":"1","flat":"","outlined":"","error-messages":_vm.totalError},on:{"keypress":function($event){return _vm.isNumber($event)}},model:{value:(_vm.total),callback:function ($$v) {_vm.total=_vm._n($$v)},expression:"total"}})],1),_c('v-card-text',[_c('p',{staticClass:"titletext"},[_vm._v("Start Date")]),_c('v-text-field',{attrs:{"id":"startDate","maxlength":"10","placeholder":"0","type":"date","flat":"","outlined":"","error-messages":_vm.startDateError},model:{value:(_vm.startDate),callback:function ($$v) {_vm.startDate=$$v},expression:"startDate"}})],1),_c('v-card-text',[_c('p',{staticClass:"titletext"},[_vm._v("Reserved Price per Month")]),_c('v-text-field',{class:{ hideinput: _vm.reservedPriceFocus === false },attrs:{"id":"reservedPrice","maxlength":"10","placeholder":"0","type":"number","flat":"","outlined":"","error-messages":_vm.reservedPriceError},on:{"focus":function($event){_vm.reservedPriceFocus = true},"blur":function (e) {
            _vm.reservedPriceFocus = false;
            _vm.onChangeHandler(e);
          },"keypress":function($event){return _vm.isNumber($event)}},scopedSlots:_vm._u([{key:"prepend-inner",fn:function(){return [_c('span',[_c('span',{staticClass:"font--text"},[_vm._v("Rp | ")]),_vm._v(" "+_vm._s(_vm.reservedPriceFocus ? "" : _vm.reservedPrice.toLocaleString("en-US"))+" ")])]},proxy:true}]),model:{value:(_vm.reservedPrice),callback:function ($$v) {_vm.reservedPrice=_vm._n($$v)},expression:"reservedPrice"}})],1),_c('v-card-text',[_c('p',{staticClass:"titletext"},[_vm._v("Non-Reserved Price per Month")]),_c('v-text-field',{class:{ hideinput: _vm.nonReservedPriceFocus === false },attrs:{"id":"nonReservedPrice","maxlength":"10","placeholder":"0","type":"number","flat":"","outlined":"","error-messages":_vm.nonReservedPriceError},on:{"focus":function($event){_vm.nonReservedPriceFocus = true},"blur":function (e) {
            _vm.nonReservedPriceFocus = false;
            _vm.onChangeHandler(e);
          },"keypress":function($event){return _vm.isNumber($event)}},scopedSlots:_vm._u([{key:"prepend-inner",fn:function(){return [_c('span',[_c('span',{staticClass:"font--text"},[_vm._v("Rp | ")]),_vm._v(" "+_vm._s(_vm.nonReservedPriceFocus ? "" : _vm.nonReservedPrice.toLocaleString("en-US"))+" ")])]},proxy:true}]),model:{value:(_vm.nonReservedPrice),callback:function ($$v) {_vm.nonReservedPrice=_vm._n($$v)},expression:"nonReservedPrice"}})],1),_c('v-card-text',[_c('v-row',[_c('v-col',[_c('v-btn',{staticClass:"accent",attrs:{"block":"","height":"50","depressed":"","disabled":_vm.loading},on:{"click":function () {
                _vm.$emit('input', false);
              }}},[(_vm.loading)?_c('beat-loader',{staticClass:"ml-2",attrs:{"loading":_vm.loading,"color":"#2C94D2","size":"10px"}}):_vm._e(),_c('span',{staticStyle:{"font-size":"14px"}},[_vm._v("Cancel")])],1)],1),_c('v-col',[_c('v-btn',{staticClass:"secondary",attrs:{"block":"","height":"50","depressed":"","disabled":_vm.loading},on:{"click":_vm.submit}},[(_vm.loading)?_c('beat-loader',{staticClass:"ml-2",attrs:{"loading":_vm.loading,"color":"#2C94D2","size":"10px"}}):_vm._e(),_c('span',{staticStyle:{"font-size":"14px"}},[_vm._v("Create")])],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }