<template>
  <v-card flat class="rounded-lg pa-4 mt-3">
    <v-card-text>
      <p class="fz-21 font-weight-bold">
        Transaction History
      </p>
    </v-card-text>

    <v-data-table
      :headers="headers"
      :items="topups"
      :items-per-page="5"
      class="elevation-0"
      hide-default-footer
    >
      <!-- total_payment -->
      <template v-slot:item.total_payment="{ item }">
        <p :style="item.type === 'plus' ? 'color: #1F60A8' : 'color: red'">
          <!-- {{ item.type === "plus" ? "+" : "-" }} -->
          IDR {{ item.total_payment.toLocaleString("en-US") }}
        </p>
      </template>

      <!-- date -->
      <template v-slot:item.date="{ item }">
        <p>
          {{ $moment(item.date).format("DD/MM/YYYY") }}
        </p>
      </template>

      <!-- pagination -->
      <template v-slot:footer="{ props }">
        <custom-footer-datatable :props="props" />
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import api from "@/lib/api";

export default {
  data() {
    return {
      headers: [
        { text: "Transaction ID", value: "transaction", sortable: false },
        { text: "Transaction", value: "payment", sortable: false },
        { text: "Total Payment", value: "total_payment", sortable: false },
        { text: "Date", value: "date", sortable: true },
      ],
      topups: [],
    };
  },
  methods: {
    async getTransactionHistory() {
      try {
        const res = await api.GET("/payment/history");
        console.log(res.data, 'res getTransactionHistory');
        // if (res.data && res.data.account_balance_detail) {
          this.topups = res.data.map((topup) => {
              return {
                transaction: topup.transaction_id,
                payment: 'Payment',
                total_payment: topup.billed,
                date: topup.payment_date,
                type: topup.type,
              };
            })
        //     .sort((a, b) => new Date(b.date) - new Date(a.date));
        // }
      } catch {
        this.$store.dispatch(
          "HOMEPAGE/showErrorToast",
          "Sorry, an error occurred while displaying transaction history"
        );
      }
    },
  },
  mounted() {
    this.getTransactionHistory();
  },
};
</script>
