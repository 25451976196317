<template>
  <div>
    <div class="px-3">
      <v-row>
        <v-col cols="12" class="pl-0">
          <v-card class="rounded-0 rounded-t-lg" flat>
            <v-container fluid class="pa-7 pb-0">
              <div class="page-title pb-5">Network</div>

              <v-tabs class="pt-2">
                <v-tab to="/network/vpc"><span>VPC</span></v-tab>
                <v-tab to="/network/floatingip">Floating IP</v-tab>
                <v-tab to="/network/loadbalancer">
                  Load Balancer 
                  <span 
                    class="px-2 py-1 ml-1 rounded-pill primary white--text" 
                    style="font-weight:500; font-size:12px"
                  >Beta</span>
                </v-tab>
              </v-tabs>
            </v-container>
          </v-card>
          <v-divider />

          <v-tabs-items v-model="$route.path" style="background: transparent">
            <v-tab-item value="/network/vpc">
              <router-view></router-view>
            </v-tab-item>

            <v-tab-item value="/network/floatingip">
              <router-view></router-view>
            </v-tab-item>

            <v-tab-item value="/network/loadbalancer">
              <router-view></router-view>
            </v-tab-item>
          </v-tabs-items>
        </v-col>
      </v-row>

      <v-row v-if="loadbalances.length > 0 && $route.name === 'loadbalancer'">
        <v-col cols="12" class="pl-0">
          <v-card class="rounded-lg" flat>
            <v-container class="pa-8">
              <h3>Load Balance</h3>
              <br />
              <v-simple-table>
                <thead>
                  <tr>
                    <th>Domain</th>
                    <th>Status</th>
                    <th>IP Adress</th>
                    <th>Healthy</th>
                    <th>Req/s</th>
                    <th>Created</th>
                    <th>Action</th>
                  </tr>
                </thead>

                <tbody>
                  <tr
                    v-for="loadbalance in loadbalances"
                    :key="loadbalance.name"
                  >
                    <td>
                      <span class="blue--text">{{ loadbalance.name }}</span
                      ><br />REG x Instance
                    </td>
                    <td>X GB</td>
                    <td>192.191.000.2</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td>
                      <router-link to="/network/loadbalancer/edit">
                        <span class="primary--text edit">Edit</span>
                      </router-link>
                      <span
                        class="error--text pr-3 delete ml-4"
                        @click="deleteModal()"
                        >Delete</span
                      >
                    </td>
                  </tr>
                </tbody>
              </v-simple-table>
            </v-container>
          </v-card>
        </v-col>
      </v-row>

      <v-row v-if="floatingip.length > 0 && $route.name === 'floatingip'">
        <v-col cols="12" class="pl-0">
          
        </v-col>
      </v-row>

      <v-row v-if="domains.length > 0 && $route.name === 'dns'">
        <v-col cols="12" class="pl-0">
          <v-card class="rounded-lg" flat>
            <v-container class="pa-8">
              <h3>Domain</h3>
              <br />
              <v-simple-table>
                <thead>
                  <tr>
                    <th>Domain</th>
                    <th>Direct to</th>
                    <th>Action</th>
                  </tr>
                </thead>

                <tbody>
                  <tr v-for="ip in domains" :key="ip.name">
                    <td><a href="">xxx.yyy.xyz</a></td>
                    <td>192.000.111.02</td>
                    <td>
                      <span class="primary--text edit">Edit</span
                      ><span
                        class="error--text pr-3 delete ml-4"
                        @click="deleteModal()"
                        >Delete</span
                      >
                    </td>
                  </tr>
                </tbody>
              </v-simple-table>
            </v-container>
          </v-card>
        </v-col>
      </v-row>

      <v-menu
        class="tip"
        v-model="showMenu"
        :position-x="x - 10"
        :position-y="y - 70"
        absolute
        allow-overflow
        width="310"
      >
        <v-list>
          <v-list-item>
            <div class="menu-title pa-3">Select Project</div>
          </v-list-item>

          <v-list-item>
            <v-divider />
            <div class="d-flex">
              <div class="dotblue"></div>
              <div class="project-list">Lintasarta</div>
            </div>
            <v-divider />
          </v-list-item>
          <v-list-item>
            <v-btn class="grey lighten-1" depressed block dark>Delete</v-btn>
          </v-list-item>
        </v-list>
      </v-menu>

      <v-menu
        v-model="showAssign"
        :position-x="x - 450"
        :position-y="y - 100"
        absolute
        allow-overflow
        width="471"
      >
        <v-list>
          <v-list-item>
            <v-text-field maxlength="50" label="Search for a Instance"></v-text-field>
          </v-list-item>
          <v-list-item style="border-bottom: 1px solid lightgrey">
            <v-list-item-title class="list"
              >Unassign floating IP</v-list-item-title
            >
          </v-list-item>
          <v-list-item>
            <div class="d-flex">
              <div class="dotblue pt-2"></div>
              <div class="list">
                <div class="project-list">Lintasarta-super-server</div>
                <div>4 GB / 80 GB / SGP1</div>
              </div>
            </div>
          </v-list-item>
        </v-list>
      </v-menu>

      <v-menu
        class="tip"
        v-model="more"
        :position-x="x - 10"
        :position-y="y"
        absolute
        allow-overflow
        width="310"
      >
        <v-list>
          <v-list-item>
            <v-list-item-title class="list" @click="assign()"
              >Reassign</v-list-item-title
            >
          </v-list-item>
          <v-list-item style="border-bottom: 1px solid lightgrey">
            <v-list-item-title class="list" @click="show(event)"
              >Move to...</v-list-item-title
            >
          </v-list-item>
          <v-list-item>
            <v-list-item-title class="error--text list"
              >Delete</v-list-item-title
            >
          </v-list-item>
        </v-list>
      </v-menu>
    </div>
    <router-view name="create"></router-view>
  </div>
</template>

<script>
import NetworkComposition from "./NetworkComposition";
import { ref } from "@vue/composition-api";
export default {
  data() {
    return {
      showMenu: false,
      showAssign: false,
      more: false,
      x: 0,
      y: 0,
      event: "",
    };
  },
  methods: {
    show() {
      // e.preventDefault();
      this.showMenu = false;

      // this.x = this.e.clientX;
      // this.y = this.e.clientY;
      this.$nextTick(() => {
        this.showMenu = true;
      });
    },

    moreAction(e) {
      this.more = false;
      this.x = e.clientX;
      this.y = e.clientY;
      this.$nextTick(() => {
        this.more = true;
      });
    },

    assign() {
      //e.preventDefault();
      this.showMenu = false;
      this.$nextTick(() => {
        this.showAssign = true;
      });
    },
  },
  setup() {
    const {
      loadbalances,
      floatingip,
      domains,
      vpcs,
      deleteModal,
      editVpc,
    } = NetworkComposition();

    //	const isBalancePage = computed(() => this.$route.name === "loadbalance");
    function onEditVpc() {
      editVpc();
      this.$router.push("/network/vpc/edit");
    }

    return {
      deleteModal,
      loadbalances,
      floatingip,
      domains,
      vpcs: ref([1, 2]),
      onEditVpc,
      //	isBalancePage,
    };
  },
};
</script>

<style scoped>
.page-title {
  font-style: normal;
  font-weight: bold;
  font-size: 21px;
  line-height: 140%;
  color: #556272;
}
.list {
  cursor: pointer;
}

.v-btn {
  height: 45px !important;
}
.menu-title {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  color: #556272;
}

.project-list {
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 19px;
  color: #556272;
}
</style>

<style lang="scss" scoped>
[role="menu"] {
  box-shadow: none;
  &::before {
    content: url(/tri.png);
    position: relative;
    left: 5px;
  }
  .v-list {
    border-radius: 5px;
    margin: -8px 2px 2px 2px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }
}
td {
  border-bottom: thin solid rgba(0, 0, 0, 0.12);
}
</style>