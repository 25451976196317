import { ref } from "@vue/composition-api";
import { required, email, minLength, helpers } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";
import { useNamespacedActions } from "vuex-composition-helpers";
import { SIGNUP } from "./namespace";

export const useSignup = () => {
  // const Keys = ['fullname', 'email', 'password', 'phone', 'npwp', 'npwp_file', 'ktp_file', 'agree', 'type']
  const Keys = [
    "fullname",
    "email",
    "password",
    "phone",
    "npwp",
    "npwp_file",
    "ktp_file",
    "agree",
  ];
  const Reference = Keys.map((x) => ref(""));
  const Validator = Keys.map((x) => ({ required }));

  Validator[1]["email"] = email;
  Validator[2] = {
    required,
    minLength: minLength(8),
    uppercase: {
      $message: "Must contains at least one uppercase character",
      $validator: (val) => {
        return /^(?=.*[A-Z])/g.test(val);
      },
    },
    lowercase: {
      $message: "Must contains at least one lowercase character",
      $validator: (val) => {
        return /^(?=.*[a-z])/g.test(val);
      },
    },
    numeric: {
      $message: "Must contains at least one numeric character",
      $validator: (val) => {
        return /^(?=.*[0-9])/g.test(val);
      },
    },
    symbol: {
      $message: "Must contains at least one symbol (!@#$%^&*)",
      $validator: (val) => {
        return /^(?=.*[!@#$%^&*])/g.test(val);
      },
    },
  };
  Validator[3] = { required };
  Validator[4] = {
    required,
    conditional: {
      $message: "NPWP is not valid",
      $validator: (val) => {
        return /^[\d]{2}[.]([\d]{3})[.]([\d]{3})[.][\d][-]([\d]{3})[.]([\d]{3})$/g.test(
          val
        );
      },
    },
  };
  // Validator[5] = {required}
  // Validator[6] = {required}
  Validator[7] = {
    required: helpers.withMessage("Must be filled", required),
  };

  const $v = useVuelidate(Validator, Reference);

  const Validation = {};
  const Composable = {};
  Keys.forEach((key, index) => {
    Composable[key] = Reference[index];
    Validation[key + "Validation"] = $v.value[index];
  });

  const { signup: Signup } = useNamespacedActions(SIGNUP, ["signup"]);
  return {
    $v,
    ...Composable,
    show1: ref(false),
    loading: ref(false),
    ...Validation,
    signup: async () => {
      $v.value.$touch();
      console.log($v.value);
      if ($v.value.$errors.length) return;
      const Value = {};
      Keys.forEach((key) => {
        Value[key] = Composable[key].value;
      });
      Value["phone"] = "+62" + Value["phone"].replaceAll(" ", "");

      const urlParams = new URLSearchParams(window.location.search);
      const myParam = urlParams.get("userType");

      Value["type"] = myParam ? "P" : "C";

      return Signup(Value);
    },
  };
};
