<template>
  <div>
    <!-- contoh template -->
    <v-row>
      <v-col cols="12" class="pt-3">
        <v-card flat class="rounded-lg">
          <v-container fluid class="pa-7">
            <v-row>
              <v-col cols="12">
            <div class="headline font-weight-bold font--text">Create Object Storage</div>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="pb-0" cols="12">
                <p>
                  <span class="font-weight-bold mb-0">Select Volume Size</span>
                  <span
                  :class="{ 'error--text': $v.selectedS3Flavor.$errors.length }"
                    class="ml-2"
                    style="font-size: 10px; position: absolute"
                    v-if="$v.selectedS3Flavor.$errors.length"
                  >
                    *{{ $v.selectedS3Flavor.$errors[0].$message }}</span>
                </p>
              </v-col>
              <v-slide-group
                class="py-4 pt-0"
                active-class="success"
                show-arrows
              >
                <v-col class="d-flex flex-row flex-wrap">
                  <v-card
                    tabindex="0"
                    v-if="s3DefaultPrice"
                    min-width="170"
                    :class="{
                      bordererror: $v.selectedS3Flavor.$errors.length,
                      highlight: (isCustomQuotaHighlighted),
                      selected: (isCustomQuotaHighlighted),
                      }"
                    :key="s3DefaultPrice.id"
                    @click="
                      () => {
                        isCustomQuotaHighlighted = true
                        selectedS3Flavor = null
                      }
                    "
                    border-color="primary"
                    outlined
                    class="rounded-lg select-instance mb-4"
                    style="text-align: center; margin-right: 20px"
                  >
                    <v-card-text  class="pb-0 pt-4 d-flex flex-column m-0">
                      <p class="font-weight-bold mb-0">
                        {{ IDRformat.format(s3DefaultPrice.price_per_month *  (customQuota ? Number(customQuota.slice(0, customQuota.length - 3)): 0)) }}/mo
                      </p>
                      <p>{{ IDRformat.format(s3DefaultPrice.price_per_hours * (customQuota ? Number(customQuota.slice(0, customQuota.length - 3)): 0)) }}/hour</p>
                    </v-card-text>
                    <v-divider />
                    <v-card-text class="pa-0" >
                      <v-text-field
                        v-model="customQuota"
                        @keydown="(ev) => customQuota = addGBText(ev, customQuota)"
                        maxlength="50"
                        class="px-4 pt-2 mt-2 label-bold"
                        style="width:170px"
                        flat 
                        label="Enter Size in GB"
                        ></v-text-field>
                    </v-card-text>
                  </v-card>
                  <v-card
                    width="170"
                    :class="{
                      bordererror: $v.selectedS3Flavor.$errors.length,
                      highlight: (selectedS3Flavor && selectedS3Flavor.id == storage.id) ,
                      selected: (selectedS3Flavor && selectedS3Flavor.id == storage.id) ,
                      }"
                    v-for="storage in filteredS3Flavors"
                    :key="storage.id"
                    @click="
                      () => {
                        selectedS3Flavor = storage
                        customQuota = null
                        isCustomQuotaHighlighted = false
                      }
                    "
                    border-color="primary"
                    outlined
                    class="rounded-lg select-instance mb-4"
                    style="text-align: center;margin-right:20px;"
                  >
                    <v-card-text class="pb-0 pt-5">
                      <p class="font-weight-bold mb-0">
                        {{ IDRformat.format(s3DefaultPrice.price_per_month * storage.object_storage_size) }}/mo
                      </p>
                      <p>{{ IDRformat.format(s3DefaultPrice.price_per_hours * storage.object_storage_size) }}/hour</p>
                    </v-card-text>
                    <v-divider />
                    <v-card-text class="d-flex flavor-card">
                      <p class="mb-0">{{ storage.object_storage_size }} GB</p>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-slide-group>
            </v-row>
            <v-row>
              <v-col sm="12" md="6">
                <p class="font-weight-bold">Region</p>
                <v-select
                  id="SelectRegion"
                  outlined
                  disabled
                  placeholder="Choose Region"
                  :items="s3RegionOptions"
                  v-model="selectedRegion"
                >
                </v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col sm="12" md="6">
                <p class="font-weight-bold">Billing Type</p>
                <v-select
                  id="SelectRegion"
                  outlined
                  disabled
                  placeholder="Choose Billing Type"
                  :items="billingTypes"
                  v-model="selectedBillingType"
                  :error-messages="$v.selectedS3Flavor.$errors.length ? $v.selectedS3Flavor.$errors[0].$message : ''"
                >
                </v-select>
              </v-col>
            </v-row>
            <v-divider class="my-4" />
            <v-row>
              <v-col>
                <p class="font-weight-bold mb-0" style="font-size:21px">File Listing</p>
              </v-col>
            </v-row>
            <!-- <v-row id="FileListing">
              <v-col cols="12" sm="6" md="4" class="d-flex flex-nowrap">
                <v-card
                 style="cursor:pointer"
                 @click="()=>{
                   allowFileListing = 'disable'
                   }"
                 :style="{
                   border: createErrorMessage('allowFileListing') ? '2px solid #eb5757 !important' : ''
                 }"
                 :class="{higlight: is(allowFileListing, 'disable')}"
                 class="rounded-lg d-flex flex-grow-1" flat outlined>
                  <v-card-title class="d-flex flex-nowrap">
                    <div>
                      <v-radio-group
                        class="mt-0" v-model="allowFileListing">
                        <v-radio value="disable"> </v-radio>
                      </v-radio-group>
                    </div>
                    <div class="ml-2" style="height:100%">
                      <p class="my-0 font-weight-bold">Restrict File Listing</p>
                      <p class="my-0" style="font-size: 12px; color:#A5B3BF">
                        Only users who connect to this bucket using access keys can list the contents.
                      </p>
                    </div>
                  </v-card-title>
                </v-card>
              </v-col>
              <v-col cols="12" sm="6" md="4" class="d-flex flex-nowrap">
                <v-card
                style="cursor:pointer"
                 @click="()=>{
                   allowFileListing = 'enable'
                   }"
                  :style="{
                   border: createErrorMessage('allowFileListing') ? '2px solid #eb5757 !important' : ''
                  }"
                  :class="{higlight: is(allowFileListing, 'enable')}"
                 
                 class="rounded-lg d-flex flex-grow-1" flat outlined>
                  <v-card-title class="d-flex flex-nowrap">
                    <div>
                      <v-radio-group
                       class="mt-0" v-model="allowFileListing">
                        <v-radio value="enable"> </v-radio>
                      </v-radio-group>
                    </div>
                    <div class="ml-2" style="height:100%">
                      <p class="my-0 font-weight-bold">Enable File Listing</p>
                      <p class="my-0" style="font-size: 12px; color:#A5B3BF">
                        Anyone can list the contents of this bucket.
                      </p>
                    </div>
                  </v-card-title>
                </v-card>
              </v-col>
            </v-row> -->
            <!-- <span class="error--text">{{ createErrorMessage('allowFileListing') }}</span> -->
            <v-row>
                <v-col class="12" md="6">
                    <p style="font-size:12px"><span class="primary--text"><b>Important:</b></span>  User who create the object storage or the Creator, will be given an access key to the bucket. Any access to the bucket will require an access key. The Creator can also create new access key to other user in Access Keys menu.</p>
                </v-col>
            </v-row>
            <v-divider/>
            <v-row>
              <v-col cols="12" sm="12" md="6" class="mt-2">
                <p class="font-weight-bold">Choose a unique name</p>
                <v-text-field
                  maxlength="50"
                  :error-messages="createErrorMessage('objectStorageName')"
                  id="ChooseUniqueName"
                  v-model="objectStorageName"
                  outlined
                  placeholder="Type a unique subdomain name for your bucket"
                ></v-text-field>
              </v-col>
            </v-row>
            <div style="width:auto;height:10px"/>
            <v-divider/>
            <div style="width:auto;height:10px"/>
            <v-row>
              <v-col cols="6"
                ><v-btn depressed block height="50" class="accent"
                  to="/object-storage"
                  >Cancel</v-btn
                ></v-col
              >
              <v-col cols="6">
                <v-btn depressed
                 id="CreateObjectStorage"
                 :disabled="isLoading"
                 @click="create" block height="50" class="secondary">
                  <beat-loader
                    v-if="isLoading"
                    :loading="isLoading"
                    :color="'white'"
                    :size="'10px'"
                  ></beat-loader>
                  <span v-else>
                    Create Object Storage
                  </span>
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import { useRegion } from '../instance/compositionapi/useregion'
const { required, requiredIf, helpers } = require("@vuelidate/validators");
const useVuelidate = require("@vuelidate/core").default;
import { ref, watch, onMounted, computed } from '@vue/composition-api'
import { useProjects } from '../project/useprojects'
import { useObjectStorage } from './composableapi/useobjectstorage'
import { useSelectVolume } from './useSelectVolume'
import { IDRformat } from '@/lib/formatter'
import libProject from '@/lib/project'
import { toThousands, addGBText, removeGBText } from '@/lib/formatter'

export default {
  setup(props, context){
    props;
    context.root.$router
    const useComposable = {
      ...useObjectStorage(),
    }

    const s3RegionOptions = [
      {
        text: 'us-east-1',
        value: 'us-east-1',
      }
    ]

    const selectedRegion = ref('us-east-1')
    const selectedBillingType = ref('PPU')
    const billingTypes = ref([
      {
        text: 'Pay Per Use',
        value: 'PPU',
      },
      {
        text: 'Trial',
        value: 'Trial',
      },
    ])
    

    const { 
       createObjectStorage,
       s3Flavors,
       customQuota,
       selectedS3Flavor,
       fetchS3Flavors,
      } = useComposable
    const allowFileListing = ref('disable')
    const objectStorageName = ref('')
    const $v = useVuelidate({
      selectedRegion: { required },
      allowFileListing: { required, $autoDirty: true },
      objectStorageName: { 
        required, 
        $autoDirty: true,
        clean: helpers.withMessage('Can only contains lowercase letters, numbers, and dash.', (val) => {
          return !val.match(/([^a-z0-9-])+/g)
        })
      },
      selectedS3Flavor: {
        required: requiredIf((val) => {
          return customQuota.value === null && selectedS3Flavor.value === null;
        }),
      },
      customQuota: {
        required: requiredIf((val) => {
          return customQuota.value === null && selectedS3Flavor.value === null;
        }),
      },
      selectedBillingType: { required },
    },
    {
      selectedRegion,
      selectedBillingType,
      selectedS3Flavor,
      allowFileListing,
      objectStorageName
    })

    async function create(){
      $v.value.$touch()
      if($v.value.$errors.length) return
      
      let payload = {
        region: selectedRegion.value.region,
        enable_file_listing: allowFileListing.value === 'enable' ? true : false,
        name: objectStorageName.value,
        project_id: await libProject.getActiveProjectID(),
        billing_type: selectedBillingType.value,
      }

      if(selectedS3Flavor.value) payload.package_object_storage_id = selectedS3Flavor.value.id
      if(customQuota.value) {
        const flavorSameSize = s3Flavors.value.filter(x => parseInt(x.object_storage_size) === removeGBText(customQuota.value))
        if(flavorSameSize.length > 0) payload.package_object_storage_id = flavorSameSize[0].id
        else payload.custom_size = parseInt(removeGBText(customQuota.value))
      }
      const response = await createObjectStorage(payload)
      if(response.status === 200) context.root.$router.replace('/object-storage')
    }
     
    function createErrorMessage(element){
      return ($v.value[element].$errors.length) ? $v.value[element].$errors[0].$message : ''
    }

    const filteredS3Flavors = computed(() => {
      let showCustomFlavor = process.env.VUE_APP_SHOW_CUSTOM_S3_FLAVORS == 'true' ? true : false
      return s3Flavors.value.filter(x => !x.is_default && x.status == 'active').filter(x => {
        if (showCustomFlavor) return true
        else {
          if (x.organization_id) return false // custom flavor has org id
          return true
        }
      }).sort((a, b) => a.object_storage_size - b.object_storage_size)
    })

    onMounted(async () => {
      await fetchS3Flavors({ limit: 999 })
    })

    return{
      ...useComposable,
      s3RegionOptions,
      billingTypes,
      selectedRegion,
      filteredS3Flavors,
      addGBText,
      removeGBText,
      $v,
      allowFileListing,
      objectStorageName,
      create,
      createErrorMessage,
      IDRformat,
      selectedBillingType,
    }
  },
  created(){
    const currentOrg = JSON.parse(localStorage.getItem('currentOrg'))
    const currentOrgID = currentOrg ? currentOrg.id : null
    this.fetchS3DefaultPrice()
  },
  data() {
    return {
      radios: "",
    };
  },
  computed: {},
  methods: {
    is(a,b){
      return a == b
    },
    select(instance) {
      this.instanceselected = instance;
    },
    isSelected(id) {
      return id == this.instanceselected.id;
    },
  },
};
</script>

<style scoped lang="scss">
.select-instance,
.select-storage {
  cursor: pointer;
  &.selected {
    border-color: #2c94d2;
    .v-divider {
      border-color: #2c94d2;
    }
  }
}

.v-card.select-storage {
  p {
    margin-bottom: 0px;
    text-align: left;
  }
}
.v-card__title {
  line-height: 1.5rem;
}
.v-input--radio-group{
  ::v-deep .v-messages{
    width: 50px;
    }
  &:not(.error--text){
    ::v-deep .v-messages{
    display: none;
  }
  
  }
  ::v-deep .v-input__slot{
    margin-bottom: 0;
  }
}
.higlight{
  border: 1px solid #2C94D2;
}
.bordererror{
  border: 1px solid red;
}
button {
  ::v-deep .v-btn__content{
    font-size: 14px;
    font-weight: 700;
  }
}
.v-input--is-disabled {
  ::v-deep .v-input__slot{
    background: #F1F2F2;
  }
}
</style>