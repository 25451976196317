







































































































































































































































































































































































































































































































































































import {
  useNamespacedActions,
  useNamespacedState,
  useNamespacedMutations,
} from "vuex-composition-helpers";

import dialogSuccess from "@/modules/costumedialog/dialogSuccess.vue";
import dialogEditMember from "./dialogEditMember.vue";
import dialogDeleteMember from "./dialogDeleteMember.vue";
import dialogAddMember from "./dialogAddMember.vue";
import { useMember } from "./usemember";
import dialogDetailUser from "../superadmin-manageuser/dialogDetailUser.vue";
import dialogListProject from "./dialogListProject.vue";
import { onMounted, reactive, ref, watch } from "@vue/composition-api";
import dialogHasBeenSaved from "./dialogHaveBeenSubmitted.vue";
import dialogActivateUser from "../superadmin-manageuser/dialogActivateUser.vue";
import { ROLEPERMISSION } from "./namespace";
import dialogRecendInviteUser from "../superadmin-manageuser/dialogResendInviteUser.vue";
import dialogTransferRoleUser from "../superadmin-manageuser/dialogTransferRoleUser.vue";
import localstorage from "@/lib/localstorage";

export default {
  components: {
    dialogAddMember,
    dialogDeleteMember,
    dialogEditMember,
    dialogDetailUser,
    dialogListProject,
    dialogHasBeenSaved,
    dialogActivateUser,
    dialogRecendInviteUser,
    dialogTransferRoleUser,
    dialogSuccess
  },
  props: ["title", "organization", "fontsize"],
  setup() {
   
    const { userrole } = useNamespacedState("ROLEPERMISSION", ["userrole"]);
    const Composable = useMember();
    const { fetchmembers, pagination } = Composable;
    const loading = ref(false);
    const limit = ref(5);
    const options = ref({ itemsPerPage: 10, sortBy: [], sortDesc: [] });
    
    watch(options, async (val) => {
      loading.value = true;
      try {
        await fetchmembers(val);
      } finally {
        loading.value = false;
      }
    });

    const memberselectedvalue = ref();
    const opendialogdetailuser = ref(false);
    const opendialoglistproject = ref(false);
    const opendialoghasbeensaved = ref(false);
    const opendialogtransferuser= ref(false);
    const opendialogresendinviteuser= ref(false);
    const opendialogsuccess = ref(false)

    const is_creator = localstorage.getItem("is_creator");

    const { validateprivilages, validateprivilagesync } = useNamespacedActions(
      "HOMEPAGE",
      ["validateprivilages", "validateprivilagesync"]
    );
    const opendialogactivateuser = ref(false);
    const { changestatus } = useNamespacedActions(ROLEPERMISSION, [
      "changestatus",
    ]);
    const { setopendialogcommonsuccess, setmessagecommonsuccess } =
      useNamespacedMutations("DASHBOARD", [
        "setopendialogcommonsuccess",
        "setmessagecommonsuccess",
      ]);
    return {
      opendialogsuccess,
      dialogetitlesuccess:ref(""),
      dialogemsgsuccess:ref(""),
      setmessagecommonsuccess,
      setopendialogcommonsuccess,
      changestatus,
      opendialogresendinviteuser,
      opendialogtransferuser,
      opendialogactivateuser,
      validateprivilages,
      validateprivilagesync,
      opendialoghasbeensaved,
      opendialoglistproject,
      opendialogdetailuser,
      memberselectedvalue,
      log(e: any) {
      },
      loading,
      limit,
      is_creator,
      options,
      userrole,
      ...Composable,
       show: ($event: any, item: any) => {
        memberselectedvalue.value = item;
      },
      dialogaddmember: ref(""),
      dialogdeletemember: ref(false),
      dialogeditemember: ref(false),
    };
  },
  data() {
    return {
      dialogedit: false,
      dialog: false,
      headers: [
        {
          text: "Name",
          value: "fullname",
          sortable: true,
        },
        {
          text: "Project",
          value: "project",
          sortable: true,
        },
        {
          text: "Role",
          value: "role_name",
          align: "center",
          sortable: false,
          width: 220,
        },
        {
          text: "",
          value: "",
          sortable: false,
        },
        {
        text: "User Status",
        value: "verified_at",
        sortable: false,
        },
        {
          text: "Status",
          value: "status",
          width: 100,
          sortable: true,
        },
        {
          text: "Created Date",
          value: "created_at",
          width: 150,
          sortable: true,
        },
        {
          text: "Action",
          value: "action",
          align: "center",
          sortable: false,
          width: 150,
        },
      ],
    };
  },
};
