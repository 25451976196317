














































































































import {
  computed,
  defineComponent,
  onMounted,
  ref,
  watch,
} from "@vue/composition-api";
import {
  useNamespacedActions,
  useNamespacedMutations,
  useNamespacedState,
} from "vuex-composition-helpers";
import { SUPERADMIN } from "../superadmin/namespace";
import dialogDeleteProj from "./dialogDeleteProj.vue";
import dialogAreYouSure from "./dialogAreYouSure.vue";
import dialogErrorDelete from "./dialogErrorDelete.vue";
import findprojectbyname from "./findprojectbyname";
import { PROJECT } from "./namespace";
import { useCreateProject } from "./useCreateProject";


export default defineComponent({
  props: ["project"],
  components: { dialogDeleteProj, dialogErrorDelete, dialogAreYouSure },
  setup(props, context: any) {
    const { deleteproject,checkproject } = useNamespacedActions(PROJECT, ["deleteproject","checkproject"]);
    const { currentProj } = useNamespacedState(SUPERADMIN, ["currentProj"]);
    const { name, description: desc, $v } = useCreateProject();
    
    onMounted(() => {
      const proj = currentProj as any;
      name.value = proj.value.name;
      desc.value = proj.value.description;
    });

 
    const purpose = ref("");
    const env = ref("");
    const nameErrors = computed(() => {
      return $v.value.name.$errors;
    });
    const descErrors = computed(() => {
      return $v.value.description.$errors;
    });
    const { updateCurrentProj } = useNamespacedActions(SUPERADMIN, [
      "updateCurrentProj",
    ]);
    const { editproject } = useNamespacedActions(PROJECT, ["editproject"]);
    const { projectname } = useNamespacedState(PROJECT, ["projectname"]);
    const {
      setopendialoghavebeensubmitted,
    } = useNamespacedMutations("DASHBOARD", ["setopendialoghavebeensubmitted"]);
    const { opendialoghavebeensubmitted } = useNamespacedState("DASHBOARD", [
      "opendialoghavebeensubmitted",
    ]);

    
    const updateProject = () => {
      $v.value.$touch();
      if ($v.value.$errors.length) return;
      const curr = currentProj.value as any;
      const Value = {
        id: curr.id,
        name: name.value,
        description: desc.value,
      };
      editproject(Value).then(() => {
        updateCurrentProj(Value);
        setopendialoghavebeensubmitted(true);
        watch(opendialoghavebeensubmitted, (val) => {
          if (val == false) {
            context.root.$router.go(-1);
          }
        });
      });
    };

    
    const { setprojectname } = useNamespacedMutations(PROJECT, [
      "setprojectname",
    ]);
    const { setCurrentProj } = useNamespacedMutations(SUPERADMIN, [
      "setCurrentProj",
    ]);
    const dialogerrormsg = ref("");
    const opendialogerror = ref(false);
    const opendeleteproject=ref(false);
    const opendialogareyousure = ref(false)
    const dialogareyousureprojectname = ref("")

    const hapusProject = () => {
      $v.value.$touch();
      if ($v.value.$errors.length) return;
      const curr = currentProj.value as any;
      const Value = {
        id: curr.id,
        name: name.value,
        description: desc.value,
      };
      checkproject(Value).then((e:any) => {
        opendeleteproject.value=true;
        //context.root.$router.replace(`/`);
        //opendialogareyousure=true;
        //opendialogareyousure(true);
        // updateCurrentProj(Value);
        // setopendialoghavebeensubmitted(true);
        // watch(opendialoghavebeensubmitted, (val) => {
        //   if (val == false) {
        //     context.root.$router.replace(`/project/${name.value}`);
        //   }
        // });
      }).catch((e:any)=>{
        const {data} = e.response.data
        opendialogerror.value=true;
        dialogerrormsg.value=data;
        //opendialogerror.message=data;
      });
    };

    return {
     
      dialogareyousureprojectname,
      opendialogareyousure,
      dialogerrormsg,
      opendialogerror,
      $v,
      setprojectname,
      setCurrentProj,
      opendeleteproject,
      name,
      nameErrors,
      desc,
      descErrors,
      purpose,
      env,
      hapusProject,
      updateProject,
      currentProj,
    };
  },
  
  beforeRouteEnter: async (to, from, next) => {
    const projectname = to.params.project;
    const project = await findprojectbyname(projectname);
    if (project) {
      next((vm: any) => {
        vm.setprojectname(projectname);
        vm.setCurrentProj(project);
      });
    } else {
      next("/notfound");
    }
  },
});
