var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',{staticClass:"pa-7 rounded-0 rounded-b-lg",attrs:{"flat":""}},[_c('v-card',{staticClass:"px-7 rounded-lg",attrs:{"outlined":"","flat":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"4"}},[_c('p',[_c('b',[_vm._v("Billing Type")])]),_c('v-select',{attrs:{"id":"billingType","placeholder":"Choose Instance Billing Type","items":_vm.billingTypes,"outlined":""},model:{value:(_vm.selectedBillingType),callback:function ($$v) {_vm.selectedBillingType=$$v},expression:"selectedBillingType"}})],1),(_vm.selectedBillingType == 'Trial')?_c('v-col',{attrs:{"cols":"4"}},[_c('p',[_c('b',[_vm._v("Trial Voucher")])]),_c('v-select',{attrs:{"id":"trialVoucher","placeholder":"Choose trial voucher to use","error-messages":_vm.$v.selectedTrialVoucher.$errors.length
                ? _vm.$v.selectedTrialVoucher.$errors[0].$message
                : '',"items":_vm.vouchers,"item-text":"voucher.name","item-value":"id","outlined":""},model:{value:(_vm.selectedTrialVoucher),callback:function ($$v) {_vm.selectedTrialVoucher=$$v},expression:"selectedTrialVoucher"}})],1):_vm._e()],1),_c('v-row',[_c('v-col',{staticClass:"pt-0",attrs:{"cols":"4"}},[_c('p',[_c('b',[_vm._v("Resource Type")])]),_c('v-select',{staticClass:"mt-2",attrs:{"placeholder":"Resource Type","single-line":"","outlined":"","items":_vm.resourceTypes,"error-messages":_vm.$v.resourceType.$errors.length
                ? _vm.$v.resourceType.$errors[0].$message
                : ''},model:{value:(_vm.resourceType),callback:function ($$v) {_vm.resourceType=$$v},expression:"resourceType"}})],1),_c('v-col',{staticClass:"pt-0",attrs:{"cols":"4"}},[_c('p',[_c('b',[_vm._v("Resource")])]),_c('v-autocomplete',{staticClass:"mt-2",attrs:{"placeholder":("Search for " + (_vm.resourceType == 'Instance' ? 'an Instance' : 'a Load Balancer')),"single-line":"","outlined":"","filled":!_vm.resourceType,"disabled":!_vm.resourceType,"items":_vm.resources,"error-messages":_vm.$v.resourceSelected.$errors.length
                ? _vm.$v.resourceSelected.$errors[0].$message
                : ''},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
                var item = ref.item;
return [_c('v-icon',{attrs:{"left":"","color":"primary"}},[_vm._v("mdi-circle")]),_vm._v(" "+_vm._s(item.name)+" ")]}},{key:"item",fn:function(ref){
                var item = ref.item;
return [_c('v-icon',{attrs:{"left":"","color":"primary"}},[_vm._v("mdi-circle")]),_vm._v(" "+_vm._s(item.name)+" ")]}}]),model:{value:(_vm.resourceSelected),callback:function ($$v) {_vm.resourceSelected=$$v},expression:"resourceSelected"}})],1),_c('v-col',{staticClass:"pt-0",attrs:{"cols":"4"}},[_c('p'),_c('br'),_c('v-btn',{staticClass:"secondary",attrs:{"block":"","disabled":_vm.isLoading,"depressed":""},on:{"click":function () {
                _vm.validateprivilages([
                  'Network',
                  'editor' ]).then(function () {
                  _vm.addFloatingIp()
                });
              }}},[(_vm.isLoading)?_c('beat-loader',{staticClass:"mr-2",attrs:{"loading":_vm.isLoading,"color":'white',"size":'10px'}}):_c('span',{staticStyle:{"font-size":"14px"}},[_vm._v("Assign Floating IP")])],1)],1)],1)],1)],1),(_vm.floatingips.length)?_c('v-card',{staticClass:"rounded-lg mt-7",attrs:{"flat":""}},[_c('v-container',{staticClass:"pa-8"},[_c('h3',[_vm._v("Floating IP")]),_c('br'),_c('v-data-table',{attrs:{"hide-default-footer":"","items":_vm.floatingips,"headers":_vm.headers,"items-per-page":10},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_vm._v(" "+_vm._s(_vm.isLoading ? 'Loading..' : 'No data available')+" ")]},proxy:true},{key:"item.ip",fn:function(ref){
              var item = ref.item;
return [_c('div',[_c('p',{staticClass:"mb-0"},[_vm._v("TKP / "+_vm._s(item.ip_address))])])]}},{key:"item.appliedto",fn:function(ref){
              var item = ref.item;
return [(_vm.floatingipselected != item)?_c('div',{staticClass:"d-flex flex-column justify-center",staticStyle:{"height":"50px"}},[(item.instance)?_c('div',[_c('router-link',{attrs:{"to":{ path: ("/project/" + _vm.activeProject + "/" + (item.instance_id) + "/") }}},[_c('span',{staticClass:"primary--text"},[_vm._v(" "+_vm._s(item.instance.name)+" ")])])],1):(item.load_balancer)?_c('div',[_c('router-link',{attrs:{"to":{ path: ("/network/loadbalancer/" + (item.load_balancer_id) + "/instances") }}},[_c('span',{staticClass:"primary--text"},[_vm._v(" "+_vm._s(item.load_balancer.name)+" ")])])],1):_c('div',{class:{'linkpointer': !_vm.isFloatingIPLoading},staticStyle:{"text-decoration":"underline"},on:{"click":function () {
                  if (_vm.isFloatingIPLoading) { return false; }
                  _vm.validateprivilages([
                  'Network',
                  'editor' ]).then(function () {
                    _vm.floatingipselected = item;
                  });
                }}},[_vm._v(" Assign to Instance ")])]):_vm._e(),(_vm.floatingipselected == item)?_c('div',[_c('v-autocomplete',{ref:("searchinstance" + (item.id)),staticStyle:{"width":"250px"},attrs:{"items":_vm.availableInstances,"outlined":"","placeholder":"Search for a Instance","hide-details":""},on:{"change":function (val) {
                  _vm.assignnewvalue(item, val);
                },"blur":function () {
                  _vm.floatingipselected = null;
                }},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
                var item = ref.item;
return [_vm._v(_vm._s(item.name))]}},{key:"item",fn:function(ref){
                var item = ref.item;
return [_c('div',[(item.unassigned)?_c('div',[_vm._v(" "+_vm._s(item.name)+" ")]):_c('div',{staticClass:"d-flex flex-row"},[_c('v-icon',{attrs:{"left":"","color":"primary"}},[_vm._v("mdi-circle")]),_c('div',[_c('p',{staticClass:"mb-0"},[_vm._v(_vm._s(item.name))]),_c('p',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(item.instancetype)+" ")])])],1)])]}}],null,true),model:{value:(_vm.assignselected),callback:function ($$v) {_vm.assignselected=$$v},expression:"assignselected"}})],1):_vm._e()]}},{key:"item.action",fn:function(ref){
                var item = ref.item;
return [_c('popupquote',{ref:("menu" + (item.id)),attrs:{"close-on-content-click":false,"nudge-left":30,"nudge-width":80,"offsetTop":21,"documentid":'popup' + item.id,"offset-y":"","allow-overflow":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('label',_vm._b({staticClass:"primary--text linkpointer",staticStyle:{"position":"relative","white-space":"nowrap"},on:{"click":function ($event) {
                    on.click($event);
                  }}},'label',attrs,false),[_vm._v("More "),_c('v-icon',{class:{
                    rotate: item.open,
                  },attrs:{"id":'popup' + item.id,"color":"primary"}},[_vm._v("mdi-chevron-down")])],1)]}}],null,true)},[_c('v-list',[(!item.load_balancer)?_c('v-list-item',{attrs:{"disabled":_vm.disabledField || _vm.isFloatingIPLoading},on:{"click":function () {
                    _vm.floatingipselected = item;
                    _vm.$refs['menu' + item.id]['dialog'] = false;
                  }}},[_c('v-list-item-title',[_vm._v("Reassign")])],1):_vm._e(),(item.instance || item.load_balancer)?_c('v-list-item',{attrs:{"disabled":_vm.disabledField || _vm.isFloatingIPLoading},on:{"click":function () {
                  _vm.unassignFloatingIP(item.id)
                  _vm.$refs['menu' + item.id]['dialog'] = false;
                }}},[_c('v-list-item-title',[_vm._v("Unassign")])],1):_vm._e(),(item.appliedto)?_c('v-list-item',{attrs:{"disabled":_vm.disabledField},on:{"click":function () {
                    _vm.assignselected = {
                      unassigned: true,
                      instanceName: 'Unassign floating IP',
                    };

                    _vm.assignnewvalue(item, {
                      id: item.id,
                      unassigned: true,
                      instanceName: 'Unassign floating IP',
                    });

                    _vm.$refs['menu' + item.id]['dialog'] = false;
                  }}},[_c('v-list-item-title',[_vm._v("Unassign")])],1):_vm._e(),_c('v-list-item',{attrs:{"disabled":!!item.appliedto || _vm.disabledField},on:{"click":function () {
                    _vm.opendialogdelete = true;
                    _vm.deleteselected = item;
                    _vm.$refs['menu' + item.id]['dialog'] = false;
                  }}},[_c('v-list-item-title',[_c('span',{class:{
                      'accent--text': item.appliedto,
                      'error--text': !item.appliedto,
                    }},[_vm._v("Delete")])])],1)],1)],1)]}},{key:"footer",fn:function(ref){
                    var props = ref.props;
return [_c('custom-footer-datatable',{attrs:{"props":props}})]}}],null,false,404002999)})],1)],1):_vm._e(),_c('dialogDeleteFloat',{on:{"delete":function () {
        _vm.deletef().then(function (){
          _vm.opendialogdelete = false
        })
      }},model:{value:(_vm.opendialogdelete),callback:function ($$v) {_vm.opendialogdelete=$$v},expression:"opendialogdelete"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }