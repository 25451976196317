











import { ref, watch, SetupContext, computed, Ref, defineComponent } from "@vue/composition-api";
export default defineComponent({
  props: ["value","coordinate","documentid","offsetTop"],
  setup(props:any, context: SetupContext) {
    context;
    const dialog = ref('')
    const x = ref(0)
    const y = ref(0)
    return {
      dialog,
      x,
      y,
    };
  },
  data():{tri:{img:HTMLElement|null}}{
    return{
      tri:{
        img: null
      }
    }
  },
  watch:{
    dialog(val){
      const content = document.getElementById(this.documentid)
      if (val && content) {
        const img = document.createElement("img");
        img.src = "/tri.png";
        img.style.position = "absolute";
        img.style.top = `${ this.offsetTop }px`;
        // img.style.left = `${content.offsetLeft}px`;
        img.style.zIndex = "20";
        this.tri.img = img;
        content.insertBefore(img, content.firstElementChild);
      } else {
        this.tri.img?.remove()
      }
    }
  },
  destroyed(){
    this.tri.img?.remove()
  }
});
