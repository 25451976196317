import axios from '@/lib/axios'
import libProject from '@/lib/project'
import { Promise } from 'core-js';

// import {localstroge} from '@/lib/localstorage'

function sortByName(a, b) {
  if (a.name < b.name) return -1
  if (a.name > b.name) return 1
  return 0;
}

export default {
  namespaced: true,
  state: {
    type: [],
    version: [],
    regions: [],
    vpcs: [],
    images: [],
    instancesize: [],
    storagesize: [],
    sshkeys: [],
    sshid: {},
    instances: [],
    masterInstances: [],
    isLoadingCreateSSH: false,
    instanceDetail: null,
    isLoadingConsole: false,
    isLoadingTurn: false,
    isLoading: false,
    isLoadingInstanceStatus: true,
    instanceStatus: '',
    instancePaginator: {
      count: 1,
      totalpage: 1,
      page: 1,
    }
  },
  getters: {
    getType: state => state.type,
    getVersion: state => state.version,
    getregions: state => state.regions,
    getMasterInstances: state => state.masterInstances,
    getvpcs: state => state.vpcs,
    userProfile(state, getters, rootState, rootGetters) {
      return rootGetters['ROLEPERMISSION/getUserProfile']
    },
    activeProject(state, getters, rootState, rootGetters) {
      return rootGetters['PROJECT/getprojects']
    },
    getIsLoadingCreateSSH: state => state.isLoadingCreateSSH,
    getInstanceDetail: state => state.instanceDetail,
    getIsLoadingConsole: state => state.isLoadingConsole,
    getIsLoadingTurn: state => state.isLoadingTurn,
    getIsLoading: state => state.isLoading,
    getIsLoadingInstanceStatus: state => state.isLoadingInstanceStatus,
    getInstanceStatus: state => state.instanceStatus
  },
  mutations: {
    isLoadingCreateSSH: (state, flag) => state.isLoadingCreateSSH = flag,
    setType: (state, type) => state.type = type,
    setVersion: (state, type) => state.version = type,
    setMasterInstances: (state, type) => state.masterInstances = type,
    setInstancePaginator: (state, payload) => state.instancePaginator = payload,
    setregions(state, regions) {
      state.regions = regions
    },
    setvpcs(state, vpcs) {
      state.vpcs = vpcs
    },
    setimages(state, images) {
      state.images = images
    },
    setinstancesize(state, instancesize) {
      state.instancesize = instancesize
    },
    setstoragesize(state, storagesize) {
      state.storagesize = storagesize
    },
    setsshkeys(state, sshkeys) {
      state.sshkeys = sshkeys
    },
    setsshid(state, sshid) {
      state.sshid = sshid
    },
    setinstances(state, instances) {
      state.instances = instances
    },
    setInstanceDetail(state, instance) {
      state.instanceDetail = instance
    },
    setIsLoadingConsole(state, instance) {
      state.isLoadingConsole = instance
    },
    setIsLoadingTurn(state, instance) {
      state.isLoadingTurn = instance
    },
    setIsLoading(state, flag) {
      state.isLoading = flag
    },
    setInstanceStatus(state, flag) {
      state.instanceStatus = flag
    },
    setIsLoadingInstanceStatus(state, flag) {
      state.isLoadingInstanceStatus = flag
    }
  },
  actions: {
    fetchregions: async (context) => {
      context.commit('setregions', [{
        region: 'TKP'
      }])
    },
    fetchvpcs: async (context) => {
      let data = []
      const activeProjectID = await libProject.getActiveProjectID()
      if (!activeProjectID) context.dispatch('HOMEPAGE/showErrorToast', 'Could not find project data.', { root: true })

      const response = await axios.instance.get(`/network/vpc/project/${activeProjectID}`)
      if (response.status === 200) {
        response.data.data.map((item) => data.push(item))
        data.sort(sortByName)
        context.commit('setvpcs', data)
      }
      return data
    },
    resize: async ({ commit, dispatch }, payload) => {
      try {
        commit('setIsLoading', true)
        const { region, instance_id } = payload
        const response = await axios.instance.post('/user/instances/resize', payload)
        await dispatch('fetchInstanceDetail', instance_id)
        await dispatch('fetchMasterInstances', { region })
        dispatch('HOMEPAGE/showInfoToast', 'Instance has been scheduled to resize the flavor', { root: true })
        commit('setIsLoading', false)
        return response
      } catch (e) {
        commit('setIsLoading', false)
        const error = e.response.data.data
        let errorMessage = 'A problem encountered while trying to resize instance flavor'
        if (error.includes('Please upgrade your organization')) commit('DASHBOARD/setopendialogreachlimit', true, { root: true })
        else if (error.includes('vCPU has exceeded the limit')) errorMessage = 'vCPU has exceeded the limit. Please contact the administrator.'
        else if (error.includes('RAM has exceeded the limit')) errorMessage = 'RAM has exceeded the limit. Please contact the administrator.'

        dispatch('HOMEPAGE/showErrorToast', errorMessage, { root: true })
      }
    },
    revert: async ({ commit, dispatch }, payload) => {
      try {
        commit('setIsLoading', true)
        const response = await axios.instance.post('/user/instances/resize-revert', payload)
        dispatch('fetchInstanceDetail', payload.instance_id)
        dispatch('fetchMasterInstances')
        dispatch('HOMEPAGE/showSuccessToast', 'Instance flavor has been successfully reverted.', { root: true })
        commit('setIsLoading', false)
      } catch (e) {
        commit('setIsLoading', false)
        const error = e.response.data.data
        let errorMessage = 'Failed to revert instance flavor.'
        if (error.includes('15 seconds has already passed, you can\'t revert anymore')) errorMessage = 'Revert expired.'
        dispatch('HOMEPAGE/showErrorToast', errorMessage, { root: true })
      }
    },
    resizeRootDisk: async ({ commit, dispatch }, payload) => {
      try {
        commit('setIsLoading', true)
        const response = await axios.instance.post('/user/instances/resize-root-disk', payload)
        dispatch('fetchPaginatedInstances')
        dispatch('HOMEPAGE/showProcessToastWS', 'Extending instance root disk size', { root: true })
        commit('setIsLoading', false)
        return response
      } catch (e) {
        commit('setIsLoading', false)
        let errorMessage = 'A problem encountered while resizing root disk'
        const error = e.response.data.data
        if (error.includes('Please upgrade your organization')) commit('DASHBOARD/setopendialogreachlimit', true, { root: true })
        else {
          if (error.includes('new size can\'t be smaller than size before')) errorMessage = 'New root disk size must be greater than current root disk size'
          else if (error.includes('Volume size has exceeded the limit')) errorMessage = 'Volume size has exceeded the limit. Please contact the administrator.'
          dispatch('HOMEPAGE/showErrorToast', errorMessage, { root: true })
        }
      }
    },
    async fetchType({ commit }) {
      // TODO: Ferry - Fase 1
      let data = await axios.instance.get('/flavor/instance/type');
      commit('setType', data.data.data)
    },
    async fetchVersion({ commit }) {
      // TODO: Ferry - Fase 1
      let data = await axios.instance.get('/flavor/image/version');
      commit('setVersion', data.data.data)
    },
    async fetchMasterInstances({ commit }, param) {
      // TODO: Ferry - Fase 1
      commit('setIsLoading', true)
      commit('setMasterInstances', [])
      const data = await axios.instance.get('/flavor/instance', { params: param })
      let flavors = data.data.data.instance.filter(x => x.status == 'active').sort((a, b) => {
        if (a.root_disk > b.root_disk) {
          if (a.vcpu > b.vcpu) {
            if (a.ram > b.ram) return 1
            else return 1
          }
          else return -1
        }
        else return -1
      })
      commit('setMasterInstances', flavors)
      commit('setIsLoading', false)
    },
    async fetchimages({ commit }) {
      // TODO: Ferry - Fase 1
      let data = await axios.instance.get('/flavor/image', { params: { limit: 999 } });
      commit('setimages', data.data.data.image)
    },
    fetchinstancesize(context) {
    },
    fetchstoragesize(context) {
    },
    async createsshkey({ commit, dispatch }, payload) {
      try {
        commit('setIsLoading', true)
        const projectID = await libProject.getActiveProjectID()
        if (!projectID) dispatch('HOMEPAGE/showErrorToast', `Cannot find project data.`, { root: true })
        payload.project_id = projectID;
        payload.content = payload.sshkey
        delete payload.sshkey
        const response = await axios.instance.post('/user/keypair', payload);
        dispatch('fetchsshkeys')
        commit('setIsLoading', false)
        dispatch('HOMEPAGE/showSuccessToast', `New SSH Key has been successfully created.`, { root: true })
        return response
      } catch (e) {
        const errorMessage = e.response.data.data
        if (errorMessage.includes('Key pair') && errorMessage.includes('already exists')) dispatch('HOMEPAGE/showErrorToast', `Keypair name already exists`, { root: true })
        else if (errorMessage.includes('Can not start or end with whitespace')) dispatch('HOMEPAGE/showErrorToast', `Keypair name cannot start or end with whitespace`, { root: true })
        else if (errorMessage.includes('Keypair name contains unsafe characters')) dispatch('HOMEPAGE/showErrorToast', `Keypair name contains unsafe characters`, { root: true })
        else if (errorMessage.includes('name can only letter, digit, - or _')) dispatch('HOMEPAGE/showErrorToast', `Keypair name can only contains letter, digit, dash (-), and underscore (_)`, { root: true })
        else if (errorMessage.includes('Keypair data is invalid')) dispatch('HOMEPAGE/showErrorToast', `Keypair is invalid.`, { root: true })
        else dispatch('HOMEPAGE/showErrorToast', `Failed to create new SSH Key.`, { root: true })
        commit('setIsLoading', false)
        return e.response
      }
    },
    deletesshkey: async ({ commit, dispatch }, payload) => {
      commit('setIsLoading', true);
      const sshkeyID = payload;
      let result = await axios.instance.delete(`/user/keypair/${sshkeyID}`);
      if (result) {
        dispatch('fetchsshkeys')
        commit('setIsLoading', false)
        return result
      }
    },
    updatesshkey: async ({ commit, dispatch }, payload) => {
      try {
        commit('setIsLoading', true);
        return new Promise((resolve, reject) => {
          return axios.instance.put(`/user/keypair/${payload.id}`, payload)
            .then((res) => {
              resolve(res)
              dispatch('fetchsshkeys')
              commit('setIsLoading', false)
            })
            .catch((err) => {
              const errorMessage = err.response.data.data
              if (errorMessage.includes('Key pair') && errorMessage.includes('already exists')) dispatch('HOMEPAGE/showErrorToast', `Keypair name already exists`, { root: true })
              else if (errorMessage.includes('Can not start or end with whitespace')) dispatch('HOMEPAGE/showErrorToast', `Keypair name cannot start or end with whitespace`, { root: true })
              else if (errorMessage.includes('Keypair name contains unsafe characters')) dispatch('HOMEPAGE/showErrorToast', `Keypair name contains unsafe characters`, { root: true })
              else if (errorMessage.includes('name can only letter, digit, - or _')) dispatch('HOMEPAGE/showErrorToast', `Keypair name can only contains letter, digit, dash (-), and underscore (_)`, { root: true })
              else if (errorMessage.includes('Keypair data is invalid')) dispatch('HOMEPAGE/showErrorToast', `Keypair is invalid.`, { root: true })
              else dispatch('HOMEPAGE/showErrorToast', `Keypair name already exists`, { root: true })
              commit('setIsLoading', false)
              return err.response
            })
        })
      } catch (e) {
        const errorMessage = e.response.data.data
        if (errorMessage.includes('Key pair') && errorMessage.includes('already exists')) dispatch('HOMEPAGE/showErrorToast', `Keypair name already exists`, { root: true })
        else if (errorMessage.includes('Can not start or end with whitespace')) dispatch('HOMEPAGE/showErrorToast', `Keypair name cannot start or end with whitespace`, { root: true })
        else if (errorMessage.includes('Keypair name contains unsafe characters')) dispatch('HOMEPAGE/showErrorToast', `Keypair name contains unsafe characters`, { root: true })
        else if (errorMessage.includes('name can only letter, digit, - or _')) dispatch('HOMEPAGE/showErrorToast', `Keypair name can only contains letter, digit, dash (-), and underscore (_)`, { root: true })
        else if (errorMessage.includes('Keypair data is invalid')) dispatch('HOMEPAGE/showErrorToast', `Keypair is invalid.`, { root: true })
        else dispatch('HOMEPAGE/showErrorToast', `Failed to update SSH Key.`, { root: true })
        commit('setIsLoading', false)
        return e.response
      }
    },
    async getsshkey({ commit }, payload) {
      const keyID = payload;
      return await axios.instance.get(`/user/keypair/${keyID}`).then(response => {
        const data = response.data.data;
        // console.log('tes' + response.data.data)
        commit('setsshid', data)
      })
    },
    async fetchsshkeys({ commit, getters, context }) {
      commit('setIsLoading', true)
      let profile = getters.userProfile;
      return new Promise((resolve, reject) => {
        axios.instance
          .get(`/user/keypair/user/${profile.id}`, {
            params: {
              page: 1,
              paginate: false,
            },
          })
          .then((res) => {
            resolve(res);
            const keypair = res.data.data;
            commit("setsshkeys", keypair);
            commit("setIsLoading", false);
          })
          .catch((err) => {
            reject(err);
          });
      })
    },
    fetchinstances: async (context) => {
      context.commit('setIsLoading', true)
      const currentProj = context.rootState.SUPERADMIN.currentProj
      const userrole = context.rootState.ROLEPERMISSION.userrole
      let params = {}
      if (currentProj) {
        params = {
          pro_id: currentProj.id
        }
      }
      context.commit('setinstances', [])
      return await axios.instance.get('/user/instances', { params }).then(response => {
        const data = response.data.data.map(x => {
          let image = null

          if (x.image_os && x.image_os.os) {
            image = {
              name: x.image_os.os.name,
              file_system: x.image_os.os.file_system,
              version_number: x.image_os.version_number,
            }
          }

          if (x.image) {
            image = {
              name: `(${x.image.is_snapshot ? 'Snapshot' : x.image.is_backup ? 'Backup' : 'Custom'}) ${x.image.name}`,
              file_system: '',
              version_number: x.image.version,
            }
          }

          let root_disk = null
          if (x.vm_root_disk_id) {
            root_disk = x.root_disk_package ? x.root_disk_package.volume_size : '-'
          } else {
            root_disk = x.package_instance ? x.package_instance.root_disk : '-'
          }

          return {
            id: x.id,
            createddate: x.created_at,
            instanceName: x.name,
            billing_type: x.billing_type,
            is_deleting: x.is_deleting,
            revert_expired: x.revert_expired,
            error_message: x.error_message,
            state: x.state,
            tags: [x.tags],
            image,
            vpc: x.vpc,
            package: {
              vcpu: x.package_instance?.vcpu,
              ram: x.package_instance?.ram,
              root_disk: root_disk,
            },
            region: {
              region: x.region,
              city: x.region
            },
            ip_network: x.vpc ? x.vpc.ip_prefix : null,
            ip_gateway: x.vpc ? x.vpc.ip_gateway : null,
            ipext: x.floating_ip ? x.floating_ip.ip_address : null,
            floating_ip: x.floating_ip,
            ipv4: x.ip_address_internal,
            instancetype: x.memory + ' GB',
            versionid: 'ubuntu 10.04',
            project_id: x.project_id,
            openstack_vm_uuid: x.openstack_vm_uuid,
          }
        })
        context.commit('setinstances', data)
        context.commit('setIsLoading', false)
        return data

      }).catch((e) => {
        context.dispatch('HOMEPAGE/showErrorToast', e.message, { root: true })
      })
    },

    fetchPaginatedInstances: async (context, payload = { page: 1, limit: 10, search: '', order_column: null, order_by: null }) => {
      context.commit('setIsLoading', true)
      const currentProj = context.rootState.SUPERADMIN.currentProj
      let params = {}
      if (currentProj) {
        params = {
          project_id: currentProj.id
        }
      }
      params = { ...params, ...payload }
      context.commit('setinstances', [])
      return await axios.instance.get('/user/instances/paginated', {
        params
      }).then(response => {
        const data = response.data.data.instances.map(x => {
          let image = null

          if (x.image_os && x.image_os.os) {
            image = {
              name: x.image_os.os.name,
              file_system: x.image_os.os.file_system,
              version_number: x.image_os.version_number,
            }
          }

          if (x.image) {
            image = {
              name: `(${x.image.is_snapshot ? 'Snapshot' : x.image.is_backup ? 'Backup' : 'Custom'}) ${x.image.name}`,
              file_system: '',
              version_number: x.image.version,
            }
          }

          let root_disk = null
          if (x.vm_root_disk_id) {
            root_disk = x.root_disk_package ? x.root_disk_package.volume_size : '-'
          } else {
            root_disk = x.package_instance ? x.package_instance.root_disk : '-'
          }

          return {
            id: x.id,
            createddate: x.created_at,
            instanceName: x.name,
            billing_type: x.billing_type,
            is_deleting: x.is_deleting,
            revert_expired: x.revert_expired,
            error_message: x.error_message,
            state: x.state,
            tags: [x.tags],
            image,
            vpc: x.vpc,
            package: {
              vcpu: x.package_instance?.vcpu,
              ram: x.package_instance?.ram,
              root_disk: root_disk,
            },
            region: {
              region: x.region,
              city: x.region
            },
            ip_network: x.vpc ? x.vpc.ip_prefix : null,
            ip_gateway: x.vpc ? x.vpc.ip_gateway : null,
            ipext: x.floating_ip ? x.floating_ip.ip_address : null,
            floating_ip: x.floating_ip,
            ipv4: x.ip_address_internal,
            instancetype: x.memory + ' GB',
            versionid: 'ubuntu 10.04',
            project_id: x.project_id,
            openstack_vm_uuid: x.openstack_vm_uuid,
            storage_type: x.root_disk_package && x.root_disk_package.type && x.root_disk_package.type.name ? x.root_disk_package.type.name : '-'
          }
        })
        context.commit('setinstances', data)
        context.commit('setIsLoading', false)
        context.commit('setInstancePaginator', response.data.data.pagination)

        return response.data.data
      }).catch((e) => {
        context.dispatch('HOMEPAGE/showErrorToast', e.message, { root: true })
      })
    },

    instancefetch: async (context) => {
      context.commit('setIsLoading', true)

      const currentProj = localStorage.getItem('projectid')
      let params = {}
      if (currentProj) {
        params = {
          pro_id: currentProj
        }
      }
      return await axios.instance.get('/user/instances', { params }).then(response => {
        const data = response.data.data.map(x => {
          let image = null

          if (x.image_os && x.image_os.os) {
            image = {
              name: x.image_os.os.name,
              file_system: x.image_os.os.file_system,
              version_number: x.image_os.version_number,
            }
          }

          if (x.image) {
            image = {
              name: `(${x.image.is_snapshot ? 'Snapshot' : x.image.is_backup ? 'Backup' : 'Custom'}) ${x.image.name}`,
              file_system: '',
              version_number: x.image.version,
            }
          }

          return {
            id: x.id,
            createddate: x.created_at,
            instanceName: x.name,
            billing_type: x.billing_type,
            is_deleting: x.is_deleting,
            revert_expired: x.revert_expired,
            error_message: x.error_message,
            state: x.state,
            tags: [x.tags],
            image,
            vpc: x.vpc,
            package: {
              vcpu: x.package_instance?.vcpu,
              ram: x.package_instance?.ram,
              root_disk: x.root_disk_package ? x.root_disk_package.volume_size : x.package_instance ? x.package_instance.root_disk : null,
            },
            region: {
              region: x.region,
              city: x.region
            },
            ip_network: x.vpc ? x.vpc.ip_prefix : null,
            ip_gateway: x.vpc ? x.vpc.ip_gateway : null,
            ipext: x.floating_ip ? x.floating_ip.ip_address : null,
            floating_ip: x.floating_ip,
            ipv4: x.ip_address_internal,
            instancetype: x.memory + ' GB',
            versionid: 'ubuntu 10.04',
            project_id: x.project_id,
            openstack_vm_uuid: x.openstack_vm_uuid,
          }
        })
        context.commit('setinstances', data)
        context.commit('setIsLoading', false)
        return data

      }).catch((e) => {
        context.dispatch('HOMEPAGE/showErrorToast', e.message, { root: true })
      })
    },

    createinstance: async ({ getters, commit, dispatch, rootState }, payload) => {
      // TODO: Ferry - Fase 1
      const activeProjectID = await libProject.getActiveProjectID()
      const user_role = rootState.ROLEPERMISSION.userrole
      let result;
      const currentProj = rootState.SUPERADMIN.currentProj
      const currentOrg = rootState.SUPERADMIN.currentOrg
      payload.project_id = currentProj.id
      if (user_role == 'Superadmin') {
        result = await axios.instance.post(`/superadmin/instances/create/${currentOrg.id}`, payload)
      } else {
        payload.project_id = activeProjectID
        result = await axios.instance.post('/user/instances', payload)
      }
      if (result) {
        dispatch('fetchPaginatedInstances')
        dispatch('HOMEPAGE/showProcessToastWS', `Building instance ${payload.name}`, { root: true })
      }
    },
    deleteInstance: async ({ commit, dispatch, rootState }, payload) => {
      commit('setIsLoading', true);
      const user_role = rootState.ROLEPERMISSION.userrole
      if (user_role == 'Superadmin') {
        try {
          const currentProj = rootState.SUPERADMIN.currentProj
          const param = {}
          param.project_id = currentProj.id
          param.instance_id = payload.instance_id
          const result = await axios.instance.post(`/superadmin/instances/delete`, param)
          if (result) {
            dispatch('fetchPaginatedInstances')
            return result
          }
        } catch (e) {
          commit('setIsLoading', false);
          const error = e.response.data.data
          let errorMessage = 'A problem encountered while trying to destroy the instance'
          if (error.includes('please detach load balancer')) errorMessage = 'Please detach all attached Load Balancer from selected Instance and try again.'
          else if (error.includes('please detach storage')) errorMessage = 'Please detach all attached Storage from selected Instance and try again.'
          else if (error.includes('please detach floating ip')) errorMessage = 'Please detach all attached Floating IP from selected Instance and try again.'
          else if (error.includes('please detach firewall')) errorMessage = 'Please detach all attached Firewall from selected Instance and try again.'
          else {
            dispatch('HOMEPAGE/showErrorToast', errorMessage, { root: true })
            return
          }
          dispatch('DASHBOARD/showCommonWarning', { title: 'Warning!', message: errorMessage }, { root: true })
        }
      } else {
        try {
          let result = await axios.instance.post('/user/instances/delete', payload);
          if (result) {
            dispatch('fetchPaginatedInstances')
            return result
          }
        } catch (e) {
          commit('setIsLoading', false);
          const error = e.response.data.data
          let errorMessage = 'A problem encountered while trying to destroy the instance'
          if (error.includes('please detach load balancer')) errorMessage = 'Please detach Load Balancer from selected Instance and try again.'
          else if (error.includes('please detach storage')) errorMessage = 'Please detach Storage from selected Instance and try again.'
          else if (error.includes('please detach floating ip')) errorMessage = 'Please detach Floating IP from selected Instance and try again.'
          else if (error.includes('please detach firewall')) errorMessage = 'Please detach Firewall from selected Instance and try again.'
          else if (error.includes('please delete backup scheduler')) errorMessage = 'Please delete Backup Scheduler from selected Instance and try again.'
          else {
            dispatch('HOMEPAGE/showErrorToast', errorMessage, { root: true })
            return
          }
          dispatch('DASHBOARD/showCommonWarning', { title: 'Warning!', message: errorMessage }, { root: true })
        }
      }
    },
    openconsole: async (context, payload) => {
      // TODO: Ferry - Fase 1
      context.commit('setIsLoadingConsole', true)
      let data
      const user_role = context.rootState.ROLEPERMISSION.userrole
      if (user_role == 'Superadmin') {
        const currentProj = context.rootState.SUPERADMIN.currentProj
        const param = {}
        param.project_id = currentProj.id
        param.openstack_vm_uuid = payload.openstack_vm_uuid
        data = await axios.instance.post(`/superadmin/instances/console`, param)
      } else {
        data = await axios.instance.post('/user/instances/console', payload);
      }

      context.commit('setIsLoadingConsole', false)
      if (data) {
        let myURL = data.data.data
        let title = 'console'
        let myWidth = 700
        let myHeight = 500
        var left = (screen.width - myWidth) / 2;
        var top = (screen.height - myHeight) / 4;
        window.open(
          myURL,
          title,
          "toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=" +
          myWidth +
          ", height=" +
          myHeight +
          ", top=" +
          top +
          ", left=" +
          left
        );
      }
    },
    fetchInstanceDetail: async ({ commit }, uuid) => {
      let response = await axios.instance.get('/user/instances/detail/' + uuid);
      const instance = response.data.data
      if (instance) {
        commit('setInstanceDetail', instance)
        commit('setInstanceStatus', instance.state)
      }
    },
    startInstance: async ({ commit, dispatch, rootState }, payload) => {
      commit('setIsLoadingTurn', true);
      try {
        let result;
        const user_role = rootState.ROLEPERMISSION.userrole
        if (user_role == 'Superadmin') {
          const currentProj = rootState.SUPERADMIN.currentProj
          const param = {}
          param.project_id = currentProj.id
          param.openstack_vm_uuid = payload.openstack_vm_uuid
          result = await axios.instance.post(`/superadmin/instances/start`, payload)
        } else {
          result = await axios.instance.post('/user/instances/start', payload);
        }
        if (result) {
          dispatch('HOMEPAGE/showProcessToastWS', 'Powering on the instance', { root: true })
        } else {
          dispatch('HOMEPAGE/showErrorToast', 'Failed to power on the instance', { root: true })
          commit('setIsLoadingTurn', false);
        }
      } catch (e) {
        dispatch('HOMEPAGE/showErrorToast', 'Failed to power on the instance', { root: true })
        commit('setIsLoadingTurn', false);
      }
    },
    stopInstance: async ({ commit, dispatch, rootState }, payload) => {
      commit('setIsLoadingTurn', true);
      try {
        let result;
        const user_role = rootState.ROLEPERMISSION.userrole
        if (user_role == 'Superadmin') {
          const currentProj = rootState.SUPERADMIN.currentProj
          const param = {}
          param.project_id = currentProj.id
          param.openstack_vm_uuid = payload.openstack_vm_uuid
          result = await axios.instance.post(`/superadmin/instances/stop`, param)
        } else {
          result = await axios.instance.post('/user/instances/stop', payload);
        }
        if (result) {
          dispatch('HOMEPAGE/showProcessToastWS', 'Shutting down the instance', { root: true })
        } else {
          dispatch('HOMEPAGE/showErrorToast', 'Failed to shutdown the instance', { root: true })
          commit('setIsLoadingTurn', false);
        }
      } catch (e) {
        dispatch('HOMEPAGE/showErrorToast', 'Failed to shutdown the instance', { root: true })
        commit('setIsLoadingTurn', false);
      }
    },
    restartInstance: async ({ commit, dispatch, rootState }, payload) => {
      commit('setIsLoadingTurn', true);
      try {
        let result;
        const user_role = rootState.ROLEPERMISSION.userrole
        if (user_role == 'Superadmin') {
          const currentProj = rootState.SUPERADMIN.currentProj
          const param = {}
          param.project_id = currentProj.id
          param.openstack_vm_uuid = payload.openstack_vm_uuid
          result = await axios.instance.post(`/superadmin/instances/restart`, param)
        } else {
          result = await axios.instance.post('/user/instances/restart', payload);
        }
        if (result) {
          dispatch('HOMEPAGE/showProcessToastWS', 'Restarting the instance', { root: true })
        } else {
          dispatch('HOMEPAGE/showErrorToast', 'Failed to restart the instance.', { root: true })
          commit('setIsLoadingTurn', false);
        }
      } catch (e) {
        dispatch('HOMEPAGE/showErrorToast', 'Failed to restart the instance.', { root: true })
        commit('setIsLoadingTurn', false);
      }
    },
    fetchInstanceStatus: async ({ getters, commit, dispatch }, payload) => {
      commit('setIsLoadingInstanceStatus', true);
      try {
        let result = await axios.instance.post('/user/instances/status', payload);
        commit('setInstanceStatus', result.data.data);
        commit('setIsLoadingInstanceStatus', false);
      } catch (e) {
        commit('setIsLoadingInstanceStatus', false);
      }
    },
    validateInstanceCreate: async (context, payload) => {
      const role = localStorage.getItem('role')
      if (role == 'Superadmin') {
        return Promise.resolve()
      }
      const currentOrg = JSON.parse(localStorage.getItem('currentOrg'))
      return await axios.instance.get(`/user/instances/validate/${currentOrg.id}`).then(response => {
        const { can_create } = response.data.data
        if (can_create) {
          return Promise.resolve('')
        } else {
          context.commit('DASHBOARD/setopendialogreachlimit', true, { root: true })
          return Promise.reject('')
        }
      })
    }
  }
}