var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{staticClass:"pa-0"},[_c('v-card',{staticClass:"rounded-lg pa-3",attrs:{"flat":""}},[_c('v-card-text',[_c('div',{staticClass:"headline font-weight-bold font--text"},[_vm._v(" Take snapshot ")])]),_c('v-card-text',[_c('p',[_vm._v(" Power down your Instance before taking a snapshot to ensure data consistency. ")]),_c('p',[_vm._v(" We recommend turning off your Instance before taking a snapshot to ensure data consistency. ")])]),_c('v-card-text',[_c('p',{staticClass:"font-weight-bold"},[_vm._v("Enter Snapshot Name")]),_c('div',{staticClass:"d-flex"},[_c('v-text-field',{attrs:{"maxlength":"50","outlined":"","placeholder":"Lintasarta-Super-Server-1606244240339"},model:{value:(_vm.snapshotName),callback:function ($$v) {_vm.snapshotName=$$v},expression:"snapshotName"}}),_c('div',{staticStyle:{"display":"inline-block","width":"30px","height":"auto"}}),_c('v-btn',{staticClass:"secondary",attrs:{"disabled":_vm.isLoading || !['ACTIVE', 'SHUTOFF'].includes(_vm.instance.state) || ![null, ''].includes(_vm.instance.revert_expired),"depressed":"","height":"50","width":"150"},on:{"click":function () { 
                _vm.validateprivilages([
                  'Instance',
                  'editor' ]).then(function () {
                  _vm.CreateSnapshot(true)
                });  
              }}},[_vm._v("Take Snapshot")])],1)])],1)],1),_c('v-col',{staticClass:"px-0 py-5",attrs:{"cols":"12"}},[_c('v-card',{staticClass:"rounded-lg pa-3",attrs:{"flat":""}},[_c('v-card-text',[_c('div',{staticClass:"headline font-weight-bold font--text"},[_vm._v(" Snapshot List ")])]),_c('v-card-text',[_c('v-data-table',{attrs:{"hide-default-footer":"","headers":_vm.headers,"items":_vm.instanceSnapshots,"items-per-page":5},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
              var item = ref.item;
return [_c('div',{staticClass:"d-flex"},[_c('v-icon',{staticClass:"mr-3",attrs:{"color":"primary"}},[_vm._v("mdi-checkbox-blank-circle")]),_c('div',[_c('p',{staticClass:"mb-0 primary--text"},[_vm._v(_vm._s(item.name))])])],1)]}},{key:"item.status",fn:function(ref){
              var item = ref.item;
return [_c('div',[(item.is_upload_success)?_c('p',{staticClass:"mb-0 primary--text"},[_vm._v("Active")]):(item.is_upload_failed)?_c('p',{staticClass:"mb-0 danger--text"},[_vm._v("Failed")]):_c('p',{staticClass:"mb-0"},[_vm._v("Not ready yet")])])]}},{key:"item.size",fn:function(ref){
              var item = ref.item;
return [_c('div',[_vm._v(_vm._s(item.size ? ((item.size) + " GB") : '-'))])]}},{key:"item.createddate",fn:function(ref){
              var item = ref.item;
return [_c('div',[_c('p',{staticClass:"mb-0"},[_vm._v(_vm._s(_vm.moment(item.createddate).format('DD/MM/YYYY')))])])]}},{key:"item.action",fn:function(ref){
              var item = ref.item;
return [_c('div',{staticClass:"linkpointer"},[_c('div',{staticStyle:{"position":"relative"},on:{"click":function($event){return _vm.show($event, item)}}},[_vm._v("More "),_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-chevron-down")])],1)])]}},(_vm.instanceSnapshots.length > 5)?{key:"footer",fn:function(ref){
              var props = ref.props;
return [_c('custom-footer-datatable',{attrs:{"props":props}})]}}:null],null,true)})],1)],1)],1)],1),_c('v-menu',{attrs:{"position-x":_vm.x - 10,"position-y":_vm.y,"absolute":"","allow-overflow":""},model:{value:(_vm.showMenu),callback:function ($$v) {_vm.showMenu=$$v},expression:"showMenu"}},[_c('v-list',[_c('v-list-item',{attrs:{"disabled":_vm.disabledField || _vm.showMenu && !_vm.activeSnapshot.is_upload_success,"to":"/instance/create"},nativeOn:{"click":function($event){return _vm.scrollTop($event)}}},[_c('v-list-item-title',[_vm._v("Create Instance")])],1),_c('v-list-item',{attrs:{"disabled":_vm.disabledField},on:{"click":function (){ 
          _vm.opendialogrestoreinstance = true 
        }}},[_c('v-list-item-title',[_vm._v("Restore Instance")])],1),_c('v-list-item',{staticStyle:{"min-height":"20px"}},[_c('v-divider')],1),_c('v-list-item',{attrs:{"disabled":_vm.disabledField},on:{"click":function (){
        _vm.opendialogdeletesnapshot = true
      }}},[_c('v-list-item-title',{staticClass:"error--text"},[_vm._v("Delete")])],1)],1)],1),(_vm.selectedsnapshot)?_c('dialogRenameImage',{attrs:{"snapshot":_vm.activeSnapshot},on:{"close":function ($event){_vm.opendialogrenameimage=$event}},model:{value:(_vm.opendialogrenameimage),callback:function ($$v) {_vm.opendialogrenameimage=$$v},expression:"opendialogrenameimage"}}):_vm._e(),_c('dialogBeforeRestoreInstance',{attrs:{"item":_vm.payloadbeforerestore},on:{"close":function ($event){ _vm.opendialogbeforerestoreinstance = $event }},model:{value:(_vm.opendialogbeforerestoreinstance),callback:function ($$v) {_vm.opendialogbeforerestoreinstance=$$v},expression:"opendialogbeforerestoreinstance"}}),_c('dialogRestoreInstance',{attrs:{"selectedSnapshot":_vm.activeSnapshot,"selectedInstance":_vm.instance},on:{"close":function ($event){_vm.opendialogrestoreinstance=$event},"submit":function ($event) {
        _vm.opendialogrestoreinstance = false;
        _vm.opendialogbeforerestoreinstance = true;
        _vm.payloadbeforerestore = $event;
      }},model:{value:(_vm.opendialogrestoreinstance),callback:function ($$v) {_vm.opendialogrestoreinstance=$$v},expression:"opendialogrestoreinstance"}}),_c('dialogDeleteSnapshot',{attrs:{"snapshot":_vm.activeSnapshot,"filterByInstanceID":true},on:{"close":function ($event){_vm.opendialogdeletesnapshot=$event}},model:{value:(_vm.opendialogdeletesnapshot),callback:function ($$v) {_vm.opendialogdeletesnapshot=$$v},expression:"opendialogdeletesnapshot"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }