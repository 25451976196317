<template>
  <div class="d-flex justify-center align-center">
    <!-- ticket btn -->
    <div
      v-if="userType === 'C'"
      style="cursor: pointer"
      class="d-flex justify-center align-center navmenu"
      @click="
        () => {
          $router.push(`/ticket`);
        }
      "
    >
      <v-icon style="width:20px; height:20px" class="mr-3"
        >$vuetify.icons.ticketIcon</v-icon
      >
      <p class="mb-0">
        {{ userType === "C" ? "Ticket" : "" }}
      </p>
    </div>

    <v-menu ref="menu" v-model="showMenu" offset-y allow-overflow>
      <template v-slot:activator="{ on, attrs }">
        <div
          id="OrganizationNav"
          @click="
            ($event) => {
              on.click($event);
            }
          "
          v-bind="attrs"
          style="cursor: pointer"
          class="d-flex justify-center align-center navmenu"
        >
          <v-icon style="width:20px; height:20px" class="mr-3"
            >$vuetify.icons.organizationIcon</v-icon
          >
          <p class="mb-0">
            {{ userType === "C" ? "Organization" : "Manage Project" }}
          </p>
          <v-icon id="IconOrg" :class="{ rotate: !showMenu }"
            >mdi-chevron-up</v-icon
          >
        </div>
      </template>

      <v-list v-if="userType === 'C'">
        <v-list-item to="/voucher">
          <v-list-item-title>Voucher</v-list-item-title>
        </v-list-item>
        <v-list-item to="/role-permission">
          <v-list-item-title>Manage Role</v-list-item-title>
        </v-list-item>
        <v-list-item to="/setting-organization">
          <v-list-item-title>Setting Organization</v-list-item-title>
        </v-list-item>
        <v-list-item to="/project">
          <v-list-item-title>List Project</v-list-item-title>
        </v-list-item>
        <v-list-item v-if="!loading && !unlimited" to="/service-limit">
          <v-list-item-title>Service Limit</v-list-item-title>
        </v-list-item>
        <v-list-item v-if="!loading && unlimited" to="/service-limit">
          <v-list-item-title>List Service</v-list-item-title>
        </v-list-item>
        <v-list-item to="/audit-log">
          <v-list-item-title>Audit Log</v-list-item-title>
        </v-list-item>
      </v-list>

      <v-list v-else>
        <v-list-item to="/project">
          <v-list-item-title>List Project</v-list-item-title>
        </v-list-item>
        <v-list-item v-if="!loading && !unlimited" to="/service-limit">
          <v-list-item-title>Service Limit</v-list-item-title>
        </v-list-item>
        <v-list-item to="/audit-log">
          <v-list-item-title>Activity</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>

    <!-- broadcast btn -->
    <BroadcastBtn />
  </div>
</template>

<script>
import { onMounted, ref } from "@vue/composition-api";
import {
  useNamespacedActions,
  useNamespacedGetters,
} from "vuex-composition-helpers";
import { LIMITSERVICE } from "../service-limit/namespace";
import localstorage from "@/lib/localstorage";
import BroadcastBtn from "./navmenu/broadcastBtn.vue";

export default {
  components: {
    BroadcastBtn,
  },
  setup() {
    const { fetchlimitservice } = useNamespacedActions(LIMITSERVICE, [
      "fetchlimitservice",
    ]);
    const { unlimited } = useNamespacedGetters(LIMITSERVICE, ["unlimited"]);
    const loading = ref(true);
    onMounted(() => {
      fetchlimitservice().then(() => {
        loading.value = false;
      });
    });
    return {
      loading,
      unlimited,
    };
  },
  data() {
    return {
      caret: false,
      showMenu: false,
      x: null,
      y: null,
      tri: null,
    };
  },
  computed: {
    userType() {
      return localstorage.getItem("type");
    },
  },
  watch: {
    showMenu: function(val) {
      if (val) {
        const nav = document.getElementById("IconOrg");
        const content = document.getElementsByTagName("BODY")[0];
        const img = document.createElement("img");
        img.src = "/tri.png";
        img.style.position = "fixed";
        img.style.top = `${nav.offsetTop + nav.offsetHeight - 5}px`;
        img.style.left = `${nav.offsetLeft}px`;
        img.style.zIndex = "10";
        this.tri = img;
        content.insertBefore(img, content.firstElementChild);
      } else {
        this.tri.remove();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
[role="menu"] {
  margin-top: -10px;
}
.mdi-chevron-up.rotate::before {
  transform: rotate(-180deg);
  transition: 100ms linear all;
}
.mdi-chevron-up::before {
  transition: 100ms linear all;
}
.v-list-item {
  min-height: 38px;
}
p,
.v-list-item__title {
  font-weight: 700;
  font-size: 12px;
}
</style>
