var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',[(_vm.projectBilling.length)?_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{staticClass:"rounded-lg",attrs:{"flat":""}},[_c('v-container',{staticClass:"pa-7",attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{staticClass:"py-0",attrs:{"cols":"12"}},[_c('p',{staticClass:"project-name"},[_vm._v(_vm._s(_vm.projectBilling[0].project.name))])]),_c('v-col',{staticClass:"d-flex mb-2 pt-0"},[_c('div',[_c('p',{staticClass:"font-weight-bold fz-21 mb-4 mr-4"},[_vm._v(" Cost for "+_vm._s(_vm.currentPeriod)+" ")]),_c('p',[_vm._v(" This is the current cost for your usage this billing period. A breakdown of your cost is available below. ")])]),_c('v-spacer'),_c('div',{staticClass:"summary-billed"},[_c('b',{staticClass:"font--text"},[_vm._v("Billed")]),_c('p',{staticClass:"total-billed"},[_vm._v(_vm._s(_vm.IDRformat.format(_vm.totalBilled)))])])],1)],1),_c('v-row',[_c('v-col',[_c('div',{staticClass:"total primary--text"})])],1),_c('br'),_c('v-data-table',{staticClass:"datatable-services",attrs:{"headers":_vm.servicesHeaders,"items":_vm.billServices,"hide-default-footer":""},scopedSlots:_vm._u([{key:"item.billing_type",fn:function(ref){
var item = ref.item;
return [_c('router-link',{attrs:{"to":("/billing/" + _vm.projectID + "/" + (item.billing_type))}},[_vm._v(_vm._s(_vm.getBillingTypeLabel(item.billing_type)))])]}},{key:"item.amount",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.instances.length + item.root_disks.length + item.storages.length + item.object_storages.length || '-')+" ")]}},{key:"item.billed",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.billed ? _vm.IDRformat.format(Math.round(item.billed)) : '-')+" ")]}},{key:"body.append",fn:function(){return [_c('tr',{staticStyle:{"border":"none"}},[_c('td',{staticClass:"border-bottom-none"}),_c('td',{staticClass:"border-bottom-none"}),_c('td',{staticClass:"border-bottom-none"},[_c('div',[_c('p',{staticClass:"font-weight-bold"},[_vm._v("Total")])]),_c('div',{staticClass:"d-flex flex-row justify-start"},[_vm._v(" "+_vm._s(_vm.IDRformat.format(_vm.totalBilled))+" ")])])])]},proxy:true}],null,false,2914021629)})],1)],1)],1):_vm._e()],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{staticClass:"rounded-lg pa-4 mt-3",attrs:{"flat":""}},[_c('v-card-text',{staticClass:"d-flex flex-row align-center"},[_c('div',{staticClass:"headline font-weight-bold font--text"},[_vm._v(" History Billing ")]),_c('v-spacer'),_c('div',{staticStyle:{"height":"auto","width":"20px"}})],1),_c('v-card-text',[_c('v-data-table',{attrs:{"hide-default-footer":"","headers":_vm.historyHeaders,"items":_vm.sortedBillingHistory,"items-per-page":10},scopedSlots:_vm._u([{key:"item.billed",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.IDRformat.format(Math.round(item.billed)))+" ")]}},{key:"item.date",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.moment(item.date).format('DD/MM/YYYY'))+" ")]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('router-link',{attrs:{"to":("/billing/" + (item.project_id) + "/history/" + (item.date))}},[_vm._v("Detail")])]}},{key:"no-data",fn:function(){return [_c('div',{staticClass:"mt-8"},[_c('p',{staticClass:"font-weight-bold fz-16"},[_vm._v(" Looks like you don’t have any History Billing ")]),_c('p',{staticClass:"accent-text font-weight-light fz-12"},[_vm._v(" Build and release faster with scalable instance products in the cloud. Provides flexible server configurations sized for any application, industry leading price to performance, and predictable pricing that is the same across regions and usage volumes. ")])])]},proxy:true},(_vm.sortedBillingHistory.length)?{key:"footer",fn:function(ref){
var props = ref.props;
return [_c('custom-footer-datatable',{attrs:{"props":props}})]}}:null],null,true)})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }